import * as React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CardActionArea, Chip, Grid, IconButton } from '@mui/material'
import {
  DriveEta,
  Favorite,
  HeartBrokenTwoTone,
  LocationOn,
  LocationOnOutlined,
  Phone,
  PhoneAndroidOutlined,
  RocketLaunchOutlined,
  Star,
} from '@mui/icons-material'
import S3Image from '../S3Image'
import { ReactComponent as TrainerStarRating } from '../../assets/icons/trainerStarRating.svg'

export default function SessionCardv2(props) {
  const { session } = props
  const [favorited, setFavorited] = React.useState(false)
  return (
    <Card
      sx={{
        width: '294px',
        height: '365px',
        borderRadius: '16px',

        position: 'relative',
      }}
    >
      <CardActionArea onClick={props.onClick}>
        <Box
          sx={{
            position: 'absolute',
            width: '73px',
            height: '24px',
            left: '207.55px',
            top: '13px',
          }}
        >
          <Box
            sx={{
              position: ' absolute',
              padding: '5px',
              textAlign: ' center',
              background: ' #000000',
              borderRadius: ' 50px',
            }}
          >
            <Typography
              sx={{
                fontStyle: ' normal',
                fontWeight: ' 700',
                fontSize: ' 18px',
                lineHeight: ' 21px',
                /* identical to box height */

                color: ' #FFFFFF',
              }}
            >
              {' '}
              ${session.price}/hr
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            left: '4.76%',
            right: '40.12%',
            top: '3.57%',
            bottom: '89.85%',
          }}
        >
          <Box
            sx={{
              position: ' absolute',
              padding: '5px',
              textAlign: ' center',
              background: ' #000000',
              borderRadius: ' 50px',
              display: ' flex',
              flexDirection: ' row',
              justifyContent: ' center',
              alignItems: ' center',
              gap: ' 4px',
            }}
          >
            <Typography
              sx={{
                fontStyle: ' normal',
                fontWeight: ' 400',
                fontSize: ' 14px',
                lineHeight: ' 21px',
                /* identical to box height */

                color: ' #FFFFFF',
              }}
            >
              {' '}
              {session.location}
            </Typography>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00083 7.83203C8.32206 7.83203 8.59657 7.71765 8.82435 7.48888C9.05213 7.26013 9.16602 6.98513 9.16602 6.66388C9.16602 6.34265 9.05163 6.06814 8.82287 5.84036C8.59411 5.61259 8.31911 5.4987 7.99787 5.4987C7.67663 5.4987 7.40213 5.61308 7.17435 5.84185C6.94657 6.0706 6.83268 6.3456 6.83268 6.66685C6.83268 6.98808 6.94707 7.26259 7.17583 7.49036C7.40459 7.71814 7.67959 7.83203 8.00083 7.83203ZM7.99935 13.3487C9.47713 12.0043 10.5688 10.7848 11.2743 9.69036C11.9799 8.59592 12.3327 7.63203 12.3327 6.7987C12.3327 5.48992 11.9144 4.41828 11.0778 3.58378C10.2412 2.74928 9.21509 2.33203 7.99935 2.33203C6.7836 2.33203 5.75745 2.74928 4.92088 3.58378C4.0843 4.41828 3.66602 5.48992 3.66602 6.7987C3.66602 7.63203 4.02713 8.59592 4.74935 9.69036C5.47157 10.7848 6.5549 12.0043 7.99935 13.3487ZM7.99935 14.6654C6.21046 13.1431 4.87435 11.7293 3.99102 10.4237C3.10768 9.11814 2.66602 7.90981 2.66602 6.7987C2.66602 5.13203 3.20213 3.80425 4.27435 2.81536C5.34657 1.82648 6.58824 1.33203 7.99935 1.33203C9.41046 1.33203 10.6521 1.82648 11.7243 2.81536C12.7966 3.80425 13.3327 5.13203 13.3327 6.7987C13.3327 7.90981 12.891 9.11814 12.0077 10.4237C11.1243 11.7293 9.78824 13.1431 7.99935 14.6654Z"
                fill="white"
              />
            </svg>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            left: '0%',
            right: '0%',
            top: '35.39%',
            bottom: '13.31%',

            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
            borderRadius: '0px 0px 16px 16px',
          }}
        ></Box>
        <Box
          sx={{
            position: ' absolute',
            width: '100%',

            top: ' 82.17%',
            bottom: ' 0%',
            background: '#000000',
          }}
        >
          <Box
            sx={{
              display: ' flex',
              flexDirection: ' column',
              justifyContent: ' center',
              alignItems: ' center',
              padding: ' 0px',

              position: ' absolute',
              width: ' 46px',
              height: ' 39px',
              left: ' 212.14px',
              top: ' 9.18px',
            }}
          >
            <Box
              sx={{
                /* Auto layout */

                display: ' flex',
                flexDirection: ' row',
                alignItems: ' center',
                padding: ' 0px',
                gap: ' 4px',

                width: ' 46px',
                height: ' 26px',

                borderRadius: ' 30px 0px 0px 30px',

                /* Inside auto layout */

                flex: ' none',
                order: ' 0',
                flexGrow: ' 0',
              }}
            >
              <TrainerStarRating />
              <Typography
                sx={{
                  fontFamily: ' Poppins',
                  fontStyle: ' normal',
                  fontWeight: ' 700',
                  fontSize: ' 17px',
                  lineHeight: ' 26px',
                  /* identical to box height */

                  color: '#FFFFFF',
                }}
              >
                4.8
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: ' flex',
              flexDirection: ' row',
              alignItems: ' center',
              padding: ' 0px',
              gap: ' 18px',

              position: ' absolute',
              width: ' 120px',
              height: ' 38px',
              left: '10px',
              top: ' 9.68px',
            }}
          >
            <Box
              sx={{
                width: '38px',
                height: '38px',
                borderRadius: '50%',
                backgroundImage: `url(/${session.trainerImage})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            />

            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontStyle: ' normal',
                fontWeight: ' 500',
                fontSize: ' 14px',
                lineHeight: ' 21px',
                /* identical to box height */

                textAlign: ' center',

                color: ' #FFFFFF',
              }}
            >
              {' '}
              {session.name}.
            </Typography>
          </Box>
        </Box>
        <Typography
          sx={{
            position: ' absolute',
            left: ' 5.44%',

            top: ' 73.25%',
            bottom: ' 19.07%',

            fontStyle: ' normal',
            fontWeight: ' 700',
            fontSize: ' 24px',
            lineHeight: ' 28px',
            letterSpacing: ' -0.04em',

            color: ' #FFFFFF',

            mixBlendMode: ' hard-light',
          }}
        >
          {' '}
          {session ? session?.title : 'Title with Trainer'}
        </Typography>
        <Box
          sx={{
            width: '294px',
            height: '365px',
            borderRadius: '16px',
            backgroundImage: `url(/${session.image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        />

        {/* <CardActionArea onClick={props.onClick}>
          <Box style={{ backgroundColor: 'white' }}></Box>

          <CardContent
            sx={{ p: 0, m: 0, width: '100%' }}
            style={{ padding: 0 }}
          >
            <Box sx={{ backgroundColor: 'primary.light', p: 0, pl: 1 }}>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{ fontWeight: 700, color: 'gunMetal.main' }}
              >
                {session ? session?.title : 'Title with Trainer'}
              </Typography>
            </Box>
            <Grid
              container
              sx={{ width: '100%', m: 0, p: 1 }}
              style={{ justifyContent: 'space-between' }}
            >
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Grid
                  container
                  style={{
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item>
                    <Chip
                      label={session ? session.category : 'Running'}
                      sx={{
                        backgroundColor: 'gunMetal.main',
                        color: 'white',
                        ml: 1,
                        fontWeight: 600,
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <Grid container style={{ justifyContent: 'left' }}>
                      <Star
                        fontSize="small"
                        sx={{ mr: 0.5, color: 'gunMetal.main' }}
                      />
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ fontWeight: 700 }}
                      >
                        4.8
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ p: 1 }}>
                  <Grid container style={{ justifyContent: 'left' }}>
                    <LocationOn fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography variant="body2" color="text.secondary">
                      Location , ST - 2 miles away
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ justifyContent: 'space-between', p: 1, pt: 0, pr: 0 }}
              >
                <Grid item>
                  <Box>
                    <Typography>
                      <strong>${session ? session?.defaultPrice : 47}</strong>
                      /hour
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Grid container>
                    <DriveEta sx={{ mr: 1 }} />
                    <RocketLaunchOutlined sx={{ mr: 1 }} />
                    <PhoneAndroidOutlined />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea> */}
      </CardActionArea>
    </Card>
  )
}
