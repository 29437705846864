import { Box, Grid, LinearProgress } from '@mui/material'
import {
  useGetMemberByIdQuery,
  useGetMemberStatsByIdQuery,
  useGetStatsByUserIdQuery,
} from '../../redux/api/api'
import Stat from '../buttons/Stat'
import SubTitleA from '../text/SubTitleA'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { setUserStatsForm } from '../../redux/slices/formsSlice'

export default function FitnessStats(props) {
  const dispatch = useDispatch()
  const { unit = 'us', editing = false } = props
  const cognitoUser = useSelector((state) => state.auth.cognitoUser)
  const units = {
    metric: { height: 'cm', weight: 'kg', distance: 'Km' },
    us: { height: '', weight: 'lb', distance: 'Miles' },
  }
  const { data, isLoading, isSuccess, isError, error } = useGetMemberByIdQuery(
    cognitoUser?.sub,
    {
      skip: !cognitoUser?.sub,
    }
  )

  const { data: statsData } = useGetStatsByUserIdQuery(cognitoUser?.sub, {
    skip: !cognitoUser?.sub,
  })

  useEffect(() => {
    if (statsData) {
      console.log('statsData', statsData)
      dispatch(setUserStatsForm(statsData))
    }
  }, [statsData])

  //const data = {}
  function toUsHeight(n) {
    var realFeet = (n * 0.3937) / 12
    var feet = Math.floor(realFeet)
    var inches = Math.round((realFeet - feet) * 12)
    return feet + `'` + inches
  }
  function toUsWeight(n) {
    return Math.round(n * 2.2046)
  }
  function getBmi(weight, height) {
    return (weight / Math.pow(height / 100, 2)).toFixed(1)
  }
  if (isLoading) {
    return <LinearProgress />
  }
  return (
    <Box>
      <SubTitleA text="Personal Stats" />
      <Grid container style={{ justifyContent: 'flex-start' }}>
        <Stat title="Age" value="22" field="age" />
        <Stat title="Height" value={data?.stats?.height} field="height" />
        <Stat title="Weight" value={data?.stats?.weight} field="weight" />
        <Stat
          field="bmi"
          title="BMI"
          value={getBmi(data?.stats?.weight, data?.stats?.height)}
        />
        <Stat
          field="fatPercentage"
          title="Fat percentage"
          value={data?.stats?.fatPercentage}
          unit="%"
        />
      </Grid>
      <SubTitleA text="Fitness Stats" />
      <Grid container style={{ justifyContent: 'flex-start' }}>
        <Stat
          field="stepsPerDay"
          title="Steps/day"
          value={data?.stats?.stepsPerDay}
        />
        <Stat
          field="calsPerDay"
          title="Cals burned/day"
          value={data?.stats?.calPerDay}
        />
        <Stat
          field="stairsPerDay"
          title="Stairs/day"
          value={data?.stats?.stairsPerDay}
        />
        <Stat
          field="milesPerWeekRunning"
          title={`mi/week (run)`}
          value={data?.stats?.runningDistancePerWeek}
        />
        <Stat
          field="milesPerWeekCycle"
          title={`mi/week (cycle)`}
          value={data?.stats?.bikingDistancePerWeek}
        />
      </Grid>
      <SubTitleA text="Fitness Goals" />
      <Grid container style={{ justifyContent: 'flex-start' }}>
        <Stat
          field="goalStepsPerDay"
          title="Steps/day"
          value={data?.stats?.stepsPerDayGoal}
        />
        <Stat
          field="goalCalsPerDay"
          title="Cals burned/day"
          value={data?.stats?.calPerDayGoal}
        />
        <Stat
          field="goalStairsPerDay"
          title="Stairs/day"
          value={data?.stats?.stairsPerDayGoal}
        />
        <Stat
          field="goalMilesPerWeekRunning"
          title={`miles/week (run)`}
          value={data?.stats?.runningDistancePerWeekGoal}
        />
        <Stat
          field="goalMilesPerWeekCycle"
          title={`miles/week (cycle)`}
          value={data?.stats?.bikingDistancePerWeekGoal}
        />
      </Grid>
    </Box>
  )
}
