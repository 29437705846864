import { useEffect } from 'react'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Grid,
  Typography,
} from '@mui/material'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import FilterTextField from '../components/filters/FilterTextField'
import SessionMain from '../components/session_page/SessionMain'
import { useNavigate } from 'react-router-dom'
import { useGetTrainersQuery } from '../redux/api/api'
import {
  setApiFilters,
  setTrainerDisplayName,
} from '../redux/slices/filtersSlice'
import { useDispatch, useSelector } from 'react-redux'
import TrainerCard from '../components/trainer_card/TrainerCard'
function TrainerSearch() {
  const defaultFilter = {
    trainerDisplayName: null,
  }
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { trainerDisplayName } = useSelector((state) => state.filters)
  const apiFilters = useSelector((state) => state.filters.apiFilters)
  const { data: trainersData } = useGetTrainersQuery(
    apiFilters ? apiFilters : { ...defaultFilter }
  )

  useEffect(() => {
    const apiFilterObject = {}
    if (trainerDisplayName) {
      apiFilterObject.displayName = { contains: trainerDisplayName }
    }
    dispatch(setApiFilters(apiFilterObject))
  }, [trainerDisplayName])
  useEffect(() => {
    dispatch(setTrainerDisplayName(null))
  }, [])
  return (
    <Grid container>
      <Header />
      <Grid
        align="center"
        justify="flex-start"
        container
        style={{
          width: '100%',
          height: '100%',
          margin: 0,
          padding: 0,
          display: 'block',
        }}
        //sx={{ m: 2 }}
        minHeight="50vh"
      >
        {/*content*/}

        {trainerDisplayName &&
          trainersData?.map((trainer) => <TrainerCard trainer={trainer} />)}
      </Grid>
      <Footer />
    </Grid>
  )
}

export default TrainerSearch
