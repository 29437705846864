import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  setGeneralModalComponent,
  setGeneralModalOpen,
} from '../../redux/slices/uiSlice'
import TitleB from '../text/TitleB'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import FitnessGoals from '../onboarding_modals/FitnessGoals'
import AboutMember from '../onboarding_modals/AboutMember'
import ChooseWorkouts from '../onboarding_modals/ChooseWorkouts'
import PreferredTrainingDaysTime from '../onboarding_modals/PreferredTrainingDaysTime'
import ChooseLocation from '../onboarding_modals/ChooseLocation'
import FitnessStats from '../onboarding_modals/FitnessStats'
import IntroduceMember from '../onboarding_modals/IntroduceMember'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PaymentMethodCard from '../payments/PaymentMethodCard'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import {
  updateSession,
  updateTrainer,
  updateUser,
} from '../../graphql/mutations'
import {
  useGetMemberByIdQuery,
  useUpdateStatsMutation,
} from '../../redux/api/api'
import PhotoCropper from '../profile/PhotoCropper'
import SessionIntro from './SessionIntro'
import SessionPricingCard from './SessionPricingCard'
import { setUserStatsForm } from '../../redux/slices/formsSlice'
import ChooseTrainingStyles from '../onboarding_modals/ChooseTrainingStyles'
import ProfilePhotoUpload from '../profile/ProfileComponents/ProfilePhotoUpload'
import DisplayName from '../onboarding_modals/DisplayName'
import Background from '../onboarding_modals/Background'
export default function GeneralModal(props) {
  const { title, content } = props
  const open = useSelector((state) => state.ui.generalModalOpen)
  const clientSecret = useSelector((state) => state.payments.stripeClientSecret)
  const cognitoUser = useSelector((state) => state.auth.cognitoUser)

  const currentUser = useSelector((state) => state.auth.cognitoUser)
  const { refetch } = useGetMemberByIdQuery(cognitoUser?.sub, {
    skip: !cognitoUser?.sub,
  })
  const generalModalComponent = useSelector(
    (state) => state.ui.generalModalComponent
  )
  const userFormFields = useSelector((state) => state.forms.userProfileModal)
  const sessionFormFields = useSelector(
    (state) => state.forms.createSessionForm
  )
  const dispatch = useDispatch()
  const stripePromise = loadStripe(
    'pk_test_51MEHOJAsCikE6AYhDJypiY8Yg4fiBIUnTuQjShVM3jZqPLXCaJcBOUrV22Gb4T9LSOkWFZhOZus1ZVRtpQ4eieGo00RAkLfJUJ'
  )
  const options = {
    // passing the client secret obtained from the server
    clientSecret,
  }

  const [updateStatsTrigger, updateStatsResponse] = useUpdateStatsMutation()
  const statsForm = useSelector((state) => state.forms.statsForm)

  let component
  let onClose = () => dispatch(setGeneralModalOpen(false))

  const onUpload = () => {
    dispatch(setGeneralModalComponent('cropper'))
  }

  switch (generalModalComponent) {
    case 'fitness_goals':
      component = <FitnessGoals />
      break
    case 'about_member':
      component = <AboutMember />
      break
    case 'choose_workouts':
      component = <ChooseWorkouts />
      break
    case 'training_styles':
      component = <ChooseTrainingStyles />
      break
    case 'preferred_date_time':
      component = <PreferredTrainingDaysTime />
      break
    case 'choose_location':
      component = <ChooseLocation />
      break
    case 'session_intro':
      component = <SessionIntro />
      break
    case 'session_pricing':
      component = <SessionPricingCard />
      break
    case 'fitness_stats':
      component = <FitnessStats />
      break
    case 'introduce_member':
      component = <IntroduceMember />
      break
    case 'payment_method':
      if (clientSecret !== '') {
        component = (
          <Elements stripe={stripePromise} options={options}>
            <PaymentMethodCard onSubmit={() => console.log('ONSUBMIT')} />
          </Elements>
        )
      } else {
        component = <div>Loading...</div>
      }

      break
    case 'photo_upload':
      component = <ProfilePhotoUpload onUpload={onUpload} />
      break
    case 'cropper':
      component = <PhotoCropper reload />
      break
    case 'session_cropper':
      component = <PhotoCropper true />
      break
    case 'cognito':
      component = <DisplayName />
      break
    case 'training_background':
      component = <Background />
      break
  }

  const handleCancel = () => {
    dispatch(setGeneralModalOpen(false))
  }

  const handleSave = async () => {
    var input = {
      id: cognitoUser?.sub,
    }
    let sessionInput
    let cognitoInput
    switch (generalModalComponent) {
      case 'fitness_goals':
        input.fitnessGoals = userFormFields.fitnessGoals
        break
      case 'about_member':
        input.about = userFormFields.about
        input.infoForTrainers = userFormFields.infoForTrainers
        break
      case 'choose_workouts':
        input.workoutPreferences = userFormFields.workoutPreferences
        break
      case 'training_styles':
        input.trainingStyles = userFormFields.trainingStyles
        break
      case 'training_background':
        input.yearsExperience = userFormFields.yearsExperience
        break
      case 'preferred_date_time':
        input.trainingTimePreferences = userFormFields.trainingTimePreferences
        input.trainingDayPreferences = userFormFields.trainingDayPreferences
        break
      case 'choose_location':
        input.homeLocation = userFormFields.homeLocation
        input.homeCity = userFormFields.homeCity
        input.homeState = userFormFields.homeState
        input.homeZip = userFormFields.homeZip
        break
      case 'session_intro':
        sessionInput = {
          id: cognitoUser?.sub,
          title: sessionFormFields.title,
          description: sessionFormFields.description,
          category: sessionFormFields.category,
          city: sessionFormFields.city,
          state: sessionFormFields.state,
          tagline: sessionFormFields.tagline,
        }
        break
      case 'fitness_stats':
        input = null

        console.log('TODO, SHOULD BE DONE IN FITNESS STATS COMPONENT')
        //field = 'userStatsId' // also trainingDayPreferences
        //value = ''
        break
      case 'introduce_member':
        input.pronouns = userFormFields.pronouns
        break
      case 'photo_upload':
        input = null
        break
      case 'cropper':
        input = null
        break
      case 'session_cropper':
        input = null
        break
      case 'cognito':
        cognitoInput = {
          given_name: cognitoUser.given_name,
          family_name: cognitoUser.family_name,
        }
        input.displayName =
          cognitoUser.given_name + ' ' + cognitoUser.family_name.substring(0, 1)
        break
      default:
        break
    }

    if (input) {
      try {
        // dispatch user update and save profile
        console.log('input: ', input)
        const userUpdate = await API.graphql(
          graphqlOperation(updateUser, {
            input: input,
          })
        )
        const response = userUpdate.data.updateUser
        console.log(response)
        refetch()
      } catch (error) {
        console.log(error)
      }
    }
    if (cognitoInput) {
      try {
        const user = await Auth.currentAuthenticatedUser()
        // dispatch cognito user update
        const updatedUser = await Auth.updateUserAttributes(user, {
          given_name: cognitoInput.given_name,
          family_name: cognitoInput.family_name,
        })
        //Should this be a trigger?
        const trainerUpdate = await API.graphql(
          graphqlOperation(updateTrainer, {
            input: input,
          })
        )
      } catch (error) {
        console.log(error)
      }
    }
    if (sessionInput) {
      console.log('sessionInput: ', sessionInput)
      try {
        console.log('sessionInput: ', sessionInput)
        // const sessionCreate = await API.graphql(
        //   graphqlOperation(updateSession, {
        //     input: sessionInput,
        //   })
        // )
        // const response = userUpdate.data.updateUser
        // console.log(response)
      } catch (error) {
        console.log(error)
      }
    }
    if (generalModalComponent === 'fitness_stats') {
      const updatedStats = await updateStatsTrigger({
        ...statsForm,
        id: cognitoUser.sub,
      }).unwrap()
      console.log('updatedStats: ', updatedStats)
      dispatch(setUserStatsForm(updatedStats))
    }
    dispatch(setGeneralModalOpen(false))
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      PaperProps={{
        sx: {
          overflow: 'hidden',
          alignItems: 'center',
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle style={{ width: '95%' }}>
        <Grid container sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <IconButton
              onClick={handleCancel}
              style={{ width: '20px', height: '20px', marginRight: '20px' }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{}}>
        <Grid container>{component}</Grid>
      </DialogContent>
      <DialogActions sx={{ width: '50%', justifyContent: 'space-around' }}>
        <Button variant="outlined" color="error" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
