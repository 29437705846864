import { Button, Grid, Input, TextField, Typography } from '@mui/material'
import InfoText from '../text/InfoText'
import SubTitleA from '../text/SubTitleA'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import TitleC from '../text/TitleC'
import { useDispatch, useSelector } from 'react-redux'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'
import { Auth } from 'aws-amplify'
import { useEffect } from 'react'
export default function MemberInfoB(props) {
  const { editMode } = props
  const dispatch = useDispatch()
  const handleChangeField = (value, field) => {
    dispatch(setUserProfileModalField({ value, field }))
  }
  const cognitoUser = useSelector((state) => state.auth.cognitoUser)
  const address = useSelector((state) => state.forms.userProfileModal.address)
  const isTrainerOnboard = useSelector((state) => state.ui.isTrainerOnboard)

  const getAuth = async () => {
    try {
      const auth = await Auth.currentAuthenticatedUser()
    } catch (error) {
      console.log('error getting auth: ', error)
    }
  }

  const updateUserAccountId = async (accountId) => {
    // Add accountId to user
    const user = await Auth.currentAuthenticatedUser()
    // dispatch cognito user update
    const updatedUser = await Auth.updateUserAttributes(user, {
      'custom:stripe_acct_id': accountId,
    })
  }
  useEffect(() => {
    if (!cognitoUser) {
      getAuth()
    }
  }, [cognitoUser])
  const addressString = cognitoUser?.address
  const addressArray = addressString?.split(',')
  const streetAddress = addressArray?.[0]
  const unitNumber = addressArray?.[1]
  const city = addressArray?.[2]
  const state = addressArray?.[3]
  const zipCode = addressArray?.[4]

  const handleChangeAddressField = (value, index) => {
    const currentReduxAddressCopy = [...address]
    currentReduxAddressCopy[index] = value
    dispatch(
      setUserProfileModalField({
        value: currentReduxAddressCopy,
        field: 'address',
      })
    )
  }
  return (
    <>
      {' '}
      <Grid
        container
        style={{
          width: '50%',
          justifyContent: 'space-around',
          rowGap: '16px',
        }}
      >
        <Grid item xs={12} style={{ justifyContent: 'flex-start' }}>
          <SubTitleA text="Street address" />
          {editMode || isTrainerOnboard ? (
            <TextField
              fullWidth
              placeholder="Street Address"
              onChange={(e) => handleChangeAddressField(e.target.value, 0)}
              defaultValue={streetAddress}
            />
          ) : (
            <InfoText text={streetAddress} />
          )}
        </Grid>
        <Grid item xs={12} style={{ justifyContent: 'flex-start' }}>
          <SubTitleA text="Unit number" />
          {editMode || isTrainerOnboard ? (
            <TextField
              fullWidth
              placeholder="Unit Number"
              onChange={(e) => handleChangeAddressField(e.target.value, 1)}
              defaultValue={unitNumber}
            />
          ) : (
            <InfoText text={unitNumber} />
          )}
        </Grid>
        <Grid item xs={12} style={{ justifyContent: 'flex-start' }}>
          <SubTitleA text="City" />
          {editMode || isTrainerOnboard ? (
            <TextField
              fullWidth
              placeholder="City"
              onChange={(e) => handleChangeAddressField(e.target.value, 2)}
              defaultValue={city}
            />
          ) : (
            <InfoText text={city} />
          )}
        </Grid>
        <Grid item xs={12} style={{ justifyContent: 'flex-start' }}>
          <SubTitleA text="State" />
          {editMode || isTrainerOnboard ? (
            <TextField
              fullWidth
              placeholder="State"
              onChange={(e) => handleChangeAddressField(e.target.value, 3)}
              defaultValue={state}
            />
          ) : (
            <InfoText text={state} />
          )}
        </Grid>
        <Grid item xs={12} style={{ justifyContent: 'flex-start' }}>
          <SubTitleA text="Zip code" />
          {editMode || isTrainerOnboard ? (
            <TextField
              fullWidth
              placeholder="Zip Code"
              onChange={(e) => handleChangeAddressField(e.target.value, 4)}
              defaultValue={zipCode}
            />
          ) : (
            <InfoText text={zipCode} />
          )}
        </Grid>
      </Grid>
    </>
  )
}
