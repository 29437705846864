import {
  AddRounded,
  BorderColor,
  CheckCircle,
  ChevronLeft,
  Favorite,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  Home,
  Share,
  StarRate,
} from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  OutlinedInput,
  Popover,
  Radio,
  Select,
  Snackbar,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import S3Image from '../S3Image'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useCreateChatMutation,
  useCreateLocationMutation,
  useCreateMessageMutation,
  useGetChatsQuery,
  useGetMemberByIdQuery,
  useGetSessionDetailsByIdQuery,
  useGetSessionsQuery,
  useUpdateSessionMutation,
} from '../../redux/api/api'
import Calendar from '../calendar/Calendar'
import { Storage } from 'aws-amplify'

import SessionCard from '../session_card/SessionCard'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCreateBookingCommute,
  setCreateBookingCommuteField,
  setCreateBookingField,
  setCreateSessionField,
} from '../../redux/slices/formsSlice'
import { createBooking } from '../../graphql/mutations'
import axios from 'axios'
import { setStripeClientSecret } from '../../redux/slices/paymentsSlice'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { API, graphqlOperation } from 'aws-amplify'
import ReviewStepper from '../browser_sessions/ReviewStepper'
import ExampleLocation from '../../assets/icons/exampleLocation.png'
import { ReactComponent as IndoorLocationIcon } from '../../assets/icons/material/IndoorFacility.svg'
import { ReactComponent as OutdoorLocationIcon } from '../../assets/icons/material/OutdoorFacility.svg'
import { ReactComponent as CommuteIcon } from '../../assets/icons/material/commuteToYou.svg'
import { ReactComponent as VirtualIcon } from '../../assets/icons/material/virtualSession.svg'
import TrainerSetAvailability from './TrainerSetAvailability'
import {
  setPaymentsModalOpen,
  setSessionPhotoCropModalOpen,
  setSessionPhotoNumber,
} from '../../redux/slices/uiSlice'
import SessionPhotoCropModal from './SessionPhotoCropModal'
import categories from '../../constants/categories'
import LocationsCardEditMode from '../session_details/LocationsCardEditMode'
import state_abbr from '../../constants/states_abbr'
import PopMap from '../location/PopMap'
import PaymentsModal from '../modals/PaymentsModal'
import mixpanel from 'mixpanel-browser'

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

function SessionMain() {
  const theme = useTheme()
  const large = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [paths, setPaths] = useState([])
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState(false)
  const [snackbarSuccessMessage, setSnackbarSuccessMessage] = useState('')
  const [snackbarSuccessOrigin, setSnackbarSuccessOrigin] = useState({
    vertical: 'bottom',
    horizontal: 'center',
  })
  const { sessionId } = useParams()
  const createBookingTime = useSelector(
    (state) => state.forms.createBookingTime
  )
  const currentUserId = useSelector((state) => state.auth.cognitoUser?.sub)
  //const viewTrainerId = useSelector((state) => state.ui.viewTrainerId)
  const bookingFormData = useSelector((state) => state.forms.createBookingForm)
  const bookingCommute = useSelector(
    (state) => state.forms.createBookingCommute
  )
  const createSessionFormData = useSelector(
    (state) => state.forms.createSessionForm
  )

  const [category, setCategory] = useState('')
  const [sessionState, setSessionState] = useState('AR')

  const [editPricingAnchorEl, setEditPricingAnchorEl] = useState(null)

  const handleClickEditPricing = (event) => {
    setEditPricingAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setEditPricingAnchorEl(null)
  }

  const editPricingOpen = Boolean(editPricingAnchorEl)
  const editPricingId = editPricingOpen
    ? 'edit-pricing-open-popover'
    : undefined

  const handleChangeCategory = async (event) => {
    setCategory(event.target.value)
    try {
      const updateSessionResponse = await updateSessionTrigger({
        id: sessionId,
        category: event.target.value,
      }).unwrap()

      console.log('updateSessionResponse: ', updateSessionResponse)

      setSnackbarSeverity('success')
      setSnackbarSuccessOrigin({ vertical: 'top', horizontal: 'center' })
      setSnackbarSuccessMessage('Successfully Saved Category!')
      setSnackbarSuccessOpen(true)

      refetch()
    } catch (error) {
      console.debug('error updating session', error)
    }
  }

  const [sessionNewEquipmentInput, setSessionNewEquipmentInput] = useState('')

  const handleEquipmentInputChange = (event) => {
    setSessionNewEquipmentInput(event.target.value)
  }

  const stripeCustomerId = useSelector((state) => state.auth.stripeCustomerId)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [editingSessionTitle, setEditingSessionTitle] = useState(false)
  const [sessionTitleInput, setSessionTitleInput] = useState('')
  const [editingSessionEquipment, setEditingSessionEquipment] = useState(false)
  const [sessionEquipmentInput, setSessionEquipmentInput] = useState([])

  const updateSessionEquipment = async (equipment) => {
    console.log('equipment: ', equipment)
    try {
      const updateSessionResponse = await updateSessionTrigger({
        id: sessionId,
        equipment,
      }).unwrap()

      console.log('updateSessionResponse: ', updateSessionResponse)
      refetch()
      setSnackbarSeverity('success')
      setSnackbarSuccessOrigin({ vertical: 'bottom', horizontal: 'center' })
      setSnackbarSuccessMessage('Successfully Saved Equipment!')
      setSnackbarSuccessOpen(true)
    } catch (error) {
      console.debug('error updating session', error)
    }
  }

  const handleDeleteEquipment = (chipToDelete) => () => {
    setSessionEquipmentInput((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    )
    // // TODO: call update session trigger here
    updateSessionEquipment(
      session?.equipment.filter((chip) => chip !== chipToDelete)
    )
  }

  const handleAddEquipment = () => {
    console.log('newChip: ', sessionNewEquipmentInput)
    setSessionEquipmentInput((chips) => [
      ...chips,
      {
        key: sessionEquipmentInput.length + 1,
        label: sessionNewEquipmentInput,
      },
    ])
    console.log('session?.equipment: ', session?.equipment)

    updateSessionEquipment([
      ...(session?.equipment || []),
      sessionNewEquipmentInput,
    ])
    setSessionNewEquipmentInput('')
    // TODO: call update session trigger here
  }

  // CHATROOM DATA
  const { data: chatroomData } = useGetChatsQuery(
    {
      userType: 'member',
      userId: currentUserId,
    },
    { skip: !currentUserId, keepUnusedData: true }
  )

  const [createChatTrigger, createChatResponse] = useCreateChatMutation()
  const [createMessageTrigger, createMessageResponse] =
    useCreateMessageMutation()

  const {
    data: session,
    isLoading: isLoadingSession,
    refetch,
  } = useGetSessionDetailsByIdQuery(sessionId, {
    //refetchOnMountOrArgChange: true,
    skip: !sessionId,
    //pollingInterval: 10000,
    keepUnusedDataFor: 10000,
  })
  const { data: trainerSessions } = useGetSessionsQuery(
    { trainerSessionsId: { eq: session?.trainerSessionsId } },
    {
      skip: !session?.trainerSessionsId,
      //pollingInterval: 10000,
      keepUnusedDataFor: 10000,
    }
  )
  const { data: trainerMemberProfile } = useGetMemberByIdQuery(
    session?.trainer?.id,
    {
      skip: !session?.trainer?.id,
      //pollingInterval: 10000,
      keepUnusedDataFor: 10000,
    }
  )

  const [updateSessionTrigger, updateSessionData] = useUpdateSessionMutation()

  const [createLocationTrigger, createLocationResponse] =
    useCreateLocationMutation()

  const handleUpdateSession = async (updates) => {
    console.log('handleUpdateSession: ', updates)
    try {
      const updateSessionResponse = await updateSessionTrigger({
        id: sessionId,
        ...updates,
      }).unwrap()

      console.log('updateSessionResponse: ', updateSessionResponse)
      refetch()
      setSnackbarSeverity('success')
      setEditingSessionTitle(false)
      setSnackbarSuccessOrigin({ vertical: 'bottom', horizontal: 'center' })
      setSnackbarSuccessMessage('Successfully Updated Session!')
      setSnackbarSuccessOpen(true)

      //TODO: REFRESH HERE
    } catch (error) {
      console.debug('error updating session', error)
      setEditingSessionTitle(false)
    }
  }

  useEffect(() => {
    console.log('bookingFormData: ', bookingFormData)
    if (currentUserId) {
      dispatch(
        setCreateBookingField({
          field: 'userBookingsId',
          value: currentUserId,
        })
      )
    }
  }, [bookingFormData])

  const listImages = async (path) => {
    try {
      const result = await Storage.list(path)
      console.log('result: ', result)
      const pathKeys = result.results.map((r) => r.key)
      // const urls = await Promise.all(
      //   result.map(async (r) => {
      //     const url = await Storage.get(r.key)
      //     return url
      //   })
      // )
      console.log('paths: ', pathKeys)
      setPaths(pathKeys)
      // return urls
    } catch (error) {
      console.log('error: ', error)
    }
  }

  useEffect(() => {
    console.log('Session: ', session)
    if (session?.trainer?.id) {
      dispatch(
        setCreateBookingField({
          field: 'trainerBookingsId',
          value: session?.trainer?.id,
        })
      )
      dispatch(
        setCreateBookingField({
          field: 'bookingSessionId',
          value: sessionId,
        })
      )
      listImages(`${session?.trainer?.id}/sessions/${sessionId}/`)
    }
  }, [session])

  const handleRadioChange = (location) => {
    console.log('location', location)
    if (location === '')
      dispatch(
        setCreateBookingField({
          field: 'bookingSelectedLocationId',
          value:
            bookingFormData.bookingSelectedLocationId === location?.id
              ? ''
              : location?.id,
        })
      )
  }

  const handleChangeBookingCommuteAddress = (location) => {
    console.log('location', location)
    const bookingCommuteLocation = {
      name: location?.text ? location?.text : '',
      address: location?.place_name,
      lng: location?.center[0],
      lat: location?.center[1],
      isIndoor: false,
    }
    dispatch(setCreateBookingCommute(bookingCommuteLocation))
  }

  const handleChangeBookingCommuteDirections = (directionsEvent) => {
    dispatch(
      setCreateBookingCommuteField({
        field: 'directions',
        value: directionsEvent.target.value,
      })
    )
  }

  // const handleChangeState = (event) => {
  //   console.log('event.target.value: ', event.target.value)
  //   // setSessionState(event.target.value)
  //   // dispatch(
  //   //   handleUpdateSession({
  //   //     state: event.target.value,
  //   //   })
  //   // )
  // }
  const handleChangeState = async (event) => {
    console.log('event.target.value: ', event.target.value)
    setSessionState(event.target.value)
    try {
      const updateSessionResponse = await updateSessionTrigger({
        id: sessionId,
        state: event.target.value,
      }).unwrap()

      console.log('updateSessionResponse: ', updateSessionResponse)
      refetch()
    } catch (error) {
      console.debug('error updating session', error)
    }
  }

  const handleClickLocationButton = (location) => {
    console.log('location', location)
    setSelectedLocation(location)

    if (location === 'commute') {
      dispatch(
        setCreateBookingField({
          field: 'bookingSelectedLocationId',
          value: 'commute',
        })
      )
    } else if (location === 'virtual') {
      if (!session?.virtual && session?.trainer?.id === currentUserId) {
        handleUpdateSession({
          virtual: true,
        })
      } else if (session?.virtual && session?.trainer?.id === currentUserId) {
        handleUpdateSession({
          virtual: false,
        })
      }
    } else {
      dispatch(
        setCreateBookingField({
          field: 'bookingSelectedLocationId',
          value:
            bookingFormData.bookingSelectedLocationId === location.id
              ? ''
              : location.id,
        })
      )
    }
  }

  const chooseLocationRow = (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '26px',
              lineHeight: '39px',
              color: 'gunMetal.main',
            }}
          >
            Choose A location
          </Typography>
          <Typography
            onClick={() => console.log(session)}
            sx={{
              fontWeight: 400,
              fontSize: '17px',
              lineHeight: '22.5px',
              color: 'gunMetal.main',
            }}
          >
            Where do you want to GET TRAIND?
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ justifyContent: 'space-around' }}>
        <Grid item xs={2} sx={{ p: 2 }}>
          <Tooltip title="Indoor">
            <IconButton
              onClick={() =>
                handleClickLocationButton('indoor', session?.indoorLocation)
              }
              disabled={
                !session?.indoorLocation?.address &&
                session?.trainer?.id !== currentUserId
              }
            >
              <IndoorLocationIcon
                style={{
                  fill:
                    selectedLocation === 'indoor'
                      ? theme.palette.primary.main
                      : session?.indoorLocation?.address === '' ||
                        session?.indoorLocation?.address === undefined
                      ? 'lightgrey'
                      : 'black',
                }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={2} sx={{ p: 2 }}>
          <Tooltip title="Outdoor">
            <IconButton
              onClick={() =>
                handleClickLocationButton('outdoor', session?.outdoorLocation)
              }
              disabled={
                !session?.outdoorLocation?.address &&
                session?.trainer?.id !== currentUserId
              }
            >
              <OutdoorLocationIcon
                style={{
                  fill:
                    selectedLocation === 'outdoor'
                      ? theme.palette.primary.main
                      : session?.indoorLocation === null ||
                        session?.indoorLocation === undefined
                      ? 'lightgrey'
                      : 'black',
                }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={2} sx={{ p: 2 }}>
          <Tooltip
            title={
              !session?.commuteToMember &&
              session?.trainer?.id === currentUserId
                ? 'Enable Commute'
                : 'Commute'
            }
          >
            <IconButton
              onClick={() => handleClickLocationButton('commute')}
              disabled={
                !session?.commuteToMember &&
                session?.trainer?.id !== currentUserId
              }
            >
              <CommuteIcon
                style={{
                  fill:
                    selectedLocation === 'commute'
                      ? theme.palette.primary.main
                      : !session?.commuteToMember
                      ? 'lightgrey'
                      : 'black',
                }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={2} sx={{ p: 2 }}>
          <Tooltip
            title={
              !session?.virtual && session?.trainer?.id === currentUserId
                ? 'Enable Virtual'
                : 'Virtual'
            }
          >
            <IconButton
              onClick={
                !session?.virtual && session?.trainer?.id === currentUserId
                  ? () =>
                      handleUpdateSession({
                        virtual: true,
                      })
                  : () => handleClickLocationButton('virtual')
              }
              disabled={
                !session?.virtual && session?.trainer?.id !== currentUserId
              }
            >
              <VirtualIcon
                style={{
                  fill:
                    selectedLocation === 'virtual'
                      ? theme.palette.primary.main
                      : !session?.virtual
                      ? 'lightgrey'
                      : 'black',
                }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {/* <Grid item xs={12} sx={{ justifyContent: 'flex-start', pl: 2 }}>
        <Typography sx={{ fontWeight: 700, fontSize: '24px' }}>
          County
        </Typography>
      </Grid> */}

      {session?.indoorLocation?.address && selectedLocation === 'indoor' && (
        <Grid container sx={{ justifyContent: 'flex-start', p: 2 }}>
          {/* <Grid item xs={12} sx={{ justifyContent: 'flex-start' }}>
            <Typography>session?.outdoorLocation?.name</Typography>
          </Grid> */}
          <Grid item xs={12} sx={{ justifyContent: 'flex-start', pl: 2 }}>
            <Typography sx={{ fontWeight: 700, fontSize: '24px' }}>
              Indoor Location
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ justifyContent: 'flex-start', pl: 2, pt: 2 }}
          >
            <Typography sx={{ fontWeight: 700 }}>
              {session?.indoorLocation?.address}
            </Typography>
          </Grid>
          {session?.indoorLocation?.directions && (
            <Grid item xs={12} sx={{ justifyContent: 'flex-start', pl: 2 }}>
              <Typography>{session?.indoorLocation?.directions}</Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={8}
            md={12}
            sx={{
              justifyContent: 'flex-start',
              p: 2,
              mb: 2,

              width: '100%',
            }}
          >
            <PopMap
              editMode={false}
              lat={session?.indoorLocation?.lat}
              lng={session?.indoorLocation?.lng}
            />
          </Grid>
        </Grid>
      )}
      {session?.outdoorLocation?.address && selectedLocation === 'outdoor' && (
        <Grid container sx={{ justifyContent: 'flex-start', p: 2 }}>
          {/* <Grid item xs={12} sx={{ justifyContent: 'flex-start' }}>
            <Typography>session?.outdoorLocation?.name</Typography>
          </Grid> */}
          <Grid item xs={12} sx={{ justifyContent: 'flex-start', pl: 2 }}>
            <Typography sx={{ fontWeight: 700, fontSize: '24px' }}>
              Outdoor Location
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ justifyContent: 'flex-start', pl: 2, pt: 2 }}
          >
            <Typography sx={{ fontWeight: 700 }}>
              {session?.outdoorLocation?.address}
            </Typography>
          </Grid>
          {session?.outdoorLocation?.directions && (
            <Grid item xs={12} sx={{ justifyContent: 'flex-start', pl: 2 }}>
              <Typography>{session?.outdoorLocation?.directions}</Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={8}
            md={12}
            sx={{
              justifyContent: 'flex-start',
              p: 2,
              mb: 2,

              width: '100%',
            }}
          >
            <PopMap
              editMode={false}
              lat={session?.outdoorLocation?.lat}
              lng={session?.outdoorLocation?.lng}
            />
          </Grid>
        </Grid>
      )}
      {session?.virtual && selectedLocation === 'virtual' && (
        <Grid container sx={{ justifyContent: 'flex-start' }}>
          <Grid item xs={12} sx={{ justifyContent: 'flex-start' }}>
            <Typography>Virtual Meeting Via our Playform</Typography>
          </Grid>
        </Grid>
      )}
      {session?.commuteToMember && selectedLocation === 'commute' && (
        <Grid container sx={{ justifyContent: 'flex-start', p: 2 }}>
          <Grid item xs={12} sx={{ justifyContent: 'flex-start' }}>
            <Typography
              sx={{ color: 'gunMetal.main', fontWeight: 700, fontSize: '24px' }}
            >
              Add a location to meet your trainer
            </Typography>
          </Grid>
          {/* <FormControlLabel
            control={<Checkbox sx={{ color: 'primary.main' }} defaultChecked />}
            label="Use saved location"
          /> */}
          <PopMap
            editMode={true}
            setLocationData={handleChangeBookingCommuteAddress}
            lat={bookingFormData?.commuteLocation?.lat || 41.789}
            lng={bookingFormData?.commuteLocation?.lng || -87.599}
          />

          <Grid container sx={{ justifyContent: 'left', pt: 2 }}>
            <Typography>
              More Directions - Describe location and use pin to specify exact
              meeting place
            </Typography>
            <OutlinedInput
              onChange={handleChangeBookingCommuteDirections}
              fullWidth
              sx={{ borderRadius: '20px', height: '37px' }}
              label="details"
            />
          </Grid>
        </Grid>
      )}
    </>
  )

  const handleSaveLocations = async () => {
    // if there is an address in forms.createSessionForm.indoorLocation.address
    // then create a new location and update session with that location id

    let indoorId
    let outdoorId

    if (createSessionFormData.indoorLocation.address !== '') {
      try {
        const indoorLocation = {
          name: createSessionFormData.indoorLocation.name,
          address: createSessionFormData.indoorLocation.address,
          directions: createSessionFormData.indoorLocation.directions,
          lat: createSessionFormData.indoorLocation.lat,
          lng: createSessionFormData.indoorLocation.lng,
          isIndoor: true,
        }
        const indoorLocationUpdate = await createLocationTrigger({
          location: indoorLocation,
          sessionId: session?.id,
        }).unwrap()
        console.log('indoorLocationUpdate: ', indoorLocationUpdate)
        indoorId = indoorLocationUpdate?.data?.createLocation?.id
      } catch (error) {
        console.log('error: ', error)
      }
    }

    if (createSessionFormData.outdoorLocation.address !== '') {
      try {
        const outdoorLocation = {
          name: createSessionFormData.outdoorLocation.name,
          address: createSessionFormData.outdoorLocation.address,
          directions: createSessionFormData.outdoorLocation.directions,
          lat: createSessionFormData.outdoorLocation.lat,
          lng: createSessionFormData.outdoorLocation.lng,
          isIndoor: true,
        }
        const outdoorLocationUpdate = await createLocationTrigger({
          location: outdoorLocation,
          sessionId: session?.id,
        }).unwrap()
        console.log('outdoorLocationUpdate: ', outdoorLocationUpdate)
        outdoorId = outdoorLocationUpdate?.data?.createLocation?.id
      } catch (error) {
        console.log('error: ', error)
      }
    }

    // Check if indoor/outdoor location does not match the trainer's location
    // if so, update session city to be the locations city

    var cityUpdate = null
    if (
      createSessionFormData.indoorLocation?.city &&
      createSessionFormData.indoorLocation?.city !== session?.city
    ) {
      cityUpdate = createSessionFormData.indoorLocation?.city
    } else if (
      createSessionFormData.outdoorLocation?.city &&
      createSessionFormData.outdoorLocation?.city !== session?.city
    ) {
      cityUpdate = createSessionFormData.outdoorLocation?.city
    }

    if (cityUpdate) {
      try {
        const updateSessionResponse = await updateSessionTrigger({
          id: session?.id,
          city: cityUpdate,
        }).unwrap()
        console.log('updateSessionResponse: ', updateSessionResponse)
      } catch (error) {
        console.log('error: ', error)
      }
    }

    const updatedSession = await updateSessionTrigger({
      id: session?.id,
      sessionIndoorLocationId: indoorId,
      sessionOutdoorLocationId: outdoorId,
      commuteToMember: createSessionFormData?.commute?.commuteToMember,
      maxCommuteDistance:
        createSessionFormData?.commute?.maxCommuteDistance || 5,
      commuteFlatFee: createSessionFormData?.commute?.commuteFlatFee || 0,
      virtual: session?.virtual,
    }).unwrap()
    console.log('updatedSession: ')
    setSnackbarSeverity('success')
    setSnackbarSuccessOrigin({ vertical: 'bottom', horizontal: 'center' })
    setSnackbarSuccessMessage('Successfully Saved Locations!')
    setSnackbarSuccessOpen(true)
    refetch()
  }

  const handleCreateCommuteLocation = async () => {
    console.log('bookingCommute: ', bookingCommute)
    let commuteId
    try {
      const commuteLocationResponse = await createLocationTrigger({
        location: bookingCommute,
        sessionId: session?.id,
      }).unwrap()
      console.log('commuteLocationResponse: ', commuteLocationResponse)
      commuteId = commuteLocationResponse?.data?.createLocation?.id
      console.log('commuteId: ', commuteId)
    } catch (error) {
      console.log('Error found: ', error)
      commuteId = null
    }
    return commuteId
  }

  const handleRequestBooking = async () => {
    if (!currentUserId) {
      setSnackbarSeverity('error')
      setSnackbarSuccessOrigin({ vertical: 'center', horizontal: 'center' })
      setSnackbarSuccessMessage(
        'Please login or create an account to request a booking'
      )
      setSnackbarSuccessOpen(true)
      // maybe open login modal here
      return
    } else {
      // Open PaymentModal
      mixpanel.track(`Booked Session`, {
        'Event Type': 'Booked Session',
      })
      dispatch(setPaymentsModalOpen(true))
    }
    // // Check Chatrooms for existing chatroom between trainer and user
    // let chatroomId
    // console.log(chatroomData)
    // console.log('session?.trainerSessionsId: ', session?.trainerSessionsId)
    // const hasRoomWithTrainer = (element) =>
    //   element.messageRoomTrainerId === session?.trainerSessionsId &&
    //   session?.trainerSessionsId !== null
    // const chatroom = chatroomData?.findIndex(hasRoomWithTrainer)
    // console.log('chatroom: ', chatroom)
    // if (chatroom === -1 || chatroom === undefined) {
    //   // Create Chatroom
    //   console.log('creating chatroom')
    //   const newChatCreatedResult = await createChatTrigger({
    //     trainerId: session?.trainerSessionsId,
    //     memberId: currentUserId,
    //   }).unwrap()
    //   console.log('newChatCreatedResult: ', newChatCreatedResult)
    //   const newChatroomId = newChatCreatedResult.data.createMessageRoom.id
    //   console.log('newChatroomId: ', newChatroomId)
    //   chatroomId = newChatroomId
    // } else {
    //   // Create Message
    //   chatroomId = chatroomData[chatroom].id
    // }
    // // Create Message
    // const message = {
    //   messageRoomMessagesId: chatroomId,
    //   messageSenderId: currentUserId,
    //   content: 'Booking Request',
    // }
    // const newMessage = await createMessageTrigger(message).unwrap()
    // console.log('newMessage: ', newMessage)
    // // Create Booking
    // try {
    //   console.log('bookingFormData: ', bookingFormData)
    //   console.log('createBookingTime: ', createBookingTime)
    //   const { amPm, date, duration, month, time, year, zone } =
    //     createBookingTime
    //   const formattedStartTime = dayjs(
    //     `${month}/${date}/${year} ${time}${amPm}`,
    //     'M/D/YYYY hA'
    //   )
    //   const testzone = 'America/Chicago'
    //   const testerr = dayjs(formattedStartTime).tz(testzone)
    //   testerr.format('YYYY-MM-DDTHH:mm:ss.sssZ')
    //   // TODO: add timezone to booking
    //   // TODO: change api call to useMutation trigger
    //   const input = {
    //     ...bookingFormData,
    //     startTime: testerr.format('YYYY-MM-DDTHH:mm:ss.sssZ'),
    //     bookingMessageRoomId: chatroomId,
    //   }
    //   console.log('testerr: ', input)
    //   const copyData = { ...bookingFormData }
    //   if (bookingFormData.bookingSelectedLocationId === 'commute') {
    //     // Create New Location Here for the commute and set the bookingFormData selectedLocationId piece here
    //     const newLocationId = await handleCreateCommuteLocation()
    //     console.log('newLocationId: ', newLocationId)
    //     copyData.bookingSelectedLocationId = newLocationId
    //     console.log('copyData: ', copyData)
    //   }
    //   const bookingData = await API.graphql(
    //     graphqlOperation(createBooking, {
    //       input: {
    //         ...copyData,
    //         startTime: testerr.format('YYYY-MM-DDTHH:mm:ss.sssZ'),
    //         bookingMessageRoomId: chatroomId,
    //       },
    //     })
    //   )
    //   const booking = bookingData.data.createBooking
    //   console.log('booking: ', booking)
    //   navigate('/success-booking')
    // } catch (error) {
    //   console.log('error requesting booking: ', error)
    // }
  }

  const handleGoToPayment = async () => {
    try {
      const stripeIntent = await axios.post(
        'https://auibul7a6pvvdywpofvw5rkkgy0kfduu.lambda-url.us-east-2.on.aws/',
        {
          //customerId: stripeCustomerId,
          customerId: stripeCustomerId,
        }
      )
      console.log('stripeIntent.data', stripeIntent.data)

      const secret = stripeIntent.data.client_secret
      console.log('secret', secret)
      //dispatch(setStripeIntent(stripeIntent.data.id))
      dispatch(setStripeClientSecret(secret))
      // dispatch(setGeneralModalComponent('payment_method'))
      // dispatch(setGeneralModalOpen())
    } catch (error) {
      console.log('error creating stripe intent: ', error)
    }
  }

  const handleChangePrice = (duration, e) => {
    const newPrice = e.target.value
    console.log('newPrice: ', newPrice)
    switch (duration) {
      case 30:
        dispatch(
          setCreateSessionField({ field: 'thirtyMinsPrice', value: newPrice })
        )
        break
      case 60:
        dispatch(
          setCreateSessionField({ field: 'sixtyMinsPrice', value: newPrice })
        )
        break
      case 90:
        dispatch(
          setCreateSessionField({ field: 'ninetyMinsPrice', value: newPrice })
        )
        break
      case 120:
        dispatch(
          setCreateSessionField({
            field: 'oneTwentyMinsPrice',
            value: newPrice,
          })
        )
        break
      default:
        break
    }
  }

  const handleUpdateSessionPricing = async () => {
    try {
      const sessionUpdate = await updateSessionTrigger({
        id: session?.id,
        thrityMinsPrice: createSessionFormData?.thirtyMinsPrice,
        sixtyMinsPrice: createSessionFormData?.sixtyMinsPrice,
        ninetyMinsPrice: createSessionFormData?.ninetyMinsPrice,
        oneTwentyMinsPrice: createSessionFormData?.oneTwentyMinsPrice,
      }).unwrap()
      console.log('sessionUpdate: ', sessionUpdate)

      handleClose()
      refetch()
      // also  send success alert
      setSnackbarSeverity('success')
      setSnackbarSuccessOrigin({ vertical: 'top', horizontal: 'center' })
      setSnackbarSuccessMessage('Successfully Saved Pricing!')
      setSnackbarSuccessOpen(true)
    } catch (error) {
      console.log('error updating session pricing', error)
    }
  }

  if (!session) return null
  else {
    return (
      <Grid container sx={{ p: 2, alignItems: 'flex-start', width: '100%' }}>
        <Grid
          container
          sx={{
            p: large ? 2 : 1,
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Grid item>
            <Grid
              container
              sx={{
                gap: '10px',
                pl: large ? 4 : 0,

                justifyContent: 'flex-start',
              }}
            >
              <Tooltip
                title={
                  <Typography variant="body1">
                    View {session?.trainer?.displayName}.'s Profile
                  </Typography>
                }
              >
                <Grid
                  item
                  sx={{ pr: 2, cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`/trainer/${session?.trainer?.id}`)
                  }}
                >
                  <S3Image
                    imagePath={`${session?.trainer?.id}/${trainerMemberProfile?.profilePhotoUrl}`}
                    dimensions={{
                      width: '86px',
                      height: '86px',
                      borderRadius: '50%',
                    }}
                  />
                </Grid>
              </Tooltip>

              <Grid item>
                <Grid container>
                  <Grid container sx={{ justifyContent: 'flex-start', pl: 1 }}>
                    {session?.trainer?.id === currentUserId &&
                      !editingSessionTitle && (
                        <Typography sx={{ fontWeight: 500, fontSize: '32px' }}>
                          {session?.trainer?.id === currentUserId
                            ? `${session?.title || 'Untitled Session'}`
                            : `${session?.title} with ${session?.trainer?.displayName}.`}
                        </Typography>
                      )}
                    {session?.trainer?.id !== currentUserId && (
                      <Typography sx={{ fontWeight: 500, fontSize: '32px' }}>
                        {session?.title} with {session?.trainer?.displayName}.
                      </Typography>
                    )}
                    {session?.trainer?.id === currentUserId &&
                      editingSessionTitle && (
                        <TextField
                          id="outlined-basic"
                          label="Session Title"
                          variant="outlined"
                          onChange={(e) => setSessionTitleInput(e.target.value)}
                        />
                      )}
                    {session?.trainer?.id === currentUserId && (
                      <Tooltip
                        title={
                          editingSessionTitle
                            ? 'Save Title'
                            : 'Edit Session Title'
                        }
                      >
                        <IconButton
                          onClick={
                            editingSessionTitle
                              ? () =>
                                  handleUpdateSession({
                                    title: sessionTitleInput,
                                  })
                              : () => setEditingSessionTitle(true)
                          }
                        >
                          {!editingSessionTitle ? (
                            <BorderColor color="primary" />
                          ) : (
                            <CheckCircle color="primary" />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>

                  <Grid container sx={{ justifyContent: 'flex-start', p: 1 }}>
                    {session?.trainer?.id === currentUserId ? (
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">
                          Category
                        </InputLabel>
                        <Select
                          sx={{ minWidth: '200px' }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={
                            session?.category ? session?.category : category
                          }
                          label="Category"
                          onChange={handleChangeCategory}
                        >
                          {categories.map((category) => (
                            <MenuItem value={category.name}>
                              <Grid
                                container
                                sx={{
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  gap: '10px',
                                }}
                              >
                                {category.icon}
                                {category.name}
                              </Grid>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      session?.category && (
                        <Grid
                          container
                          sx={{ justifyContent: 'flex-start', gap: '8px' }}
                        >
                          <Grid item>
                            {
                              categories.filter((cat) => {
                                return cat.name === session?.category
                              })[0].icon
                            }
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{ color: 'text.secondary', fontWeight: 700 }}
                            >
                              {session?.category}
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </Grid>
                  <Grid
                    container
                    sx={{ gap: '16px', justifyContent: 'flex-start' }}
                  >
                    <Grid item>
                      <Grid container sx={{}}>
                        <StarRate sx={{ p: 1 }}></StarRate>
                        <Typography> {session?.rating || '5.0'}</Typography>
                      </Grid>
                    </Grid>

                    {/* <Grid container>
                      {session?.trainer?.id === currentUserId ? (
                        <Select
                          sx={{ minWidth: '100px' }}
                          value={session?.state}
                          label="state"
                          onChange={handleChangeState}
                        >
                          {state_abbr.map((state, index) => (
                            <MenuItem value={state.abbr} key={index}>
                              <Grid
                                container
                                sx={{
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  gap: '10px',
                                }}
                              >
                                {state.abbr}
                              </Grid>
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        session?.category && (
                          <Grid
                            container
                            sx={{ justifyContent: 'flex-start', gap: '8px' }}
                          >
                            <Typography>
                              {session?.city}, {session?.state}
                            </Typography>
                          </Grid>
                        )
                      )}
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container>
              {session?.trainer?.id === currentUserId ? (
                <Grid item>
                  <Button
                    onClick={handleClickEditPricing}
                    variant="contained"
                    color="gunMetal"
                    sx={{ p: large ? 2 : 1, borderRadius: '100px' }}
                  >
                    <Typography variant="body1" sx={{ color: 'white' }}>
                      ${session?.sixtyMinsPrice}/hr
                    </Typography>
                  </Button>
                  <Popover
                    id={editPricingId}
                    open={editPricingOpen}
                    anchorEl={editPricingAnchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                  >
                    <Grid container sx={{ p: 2 }}>
                      <Grid item>
                        <TextField
                          size="small"
                          label="30 mins price"
                          onChange={(e) => handleChangePrice(30, e)}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          size="small"
                          label="60 mins price"
                          onChange={(e) => handleChangePrice(60, e)}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          size="small"
                          label="90 mins price"
                          onChange={(e) => handleChangePrice(90, e)}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          size="small"
                          label="120 mins price"
                          onChange={(e) => handleChangePrice(120, e)}
                        />
                      </Grid>

                      <Grid item>
                        <Button
                          variant="contained"
                          onClick={handleUpdateSessionPricing}
                        >
                          <Typography variant="body1">Save</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Popover>
                </Grid>
              ) : (
                <Box
                  variant="contained"
                  color="gunMetal"
                  sx={{ p: 2, borderRadius: '100px', bgcolor: 'gunMetal.main' }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: 'white', fontWeight: 700 }}
                  >
                    ${session?.sixtyMinsPrice}/HR
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid container sx={{ gap: '16px' }}>
              {/* <Grid item>
              <Grid container>
                <Share />
                <Typography> share</Typography>
              </Grid>
            </Grid> */}
              <Tooltip
                placement="left"
                title={<Typography variant="body1">Save </Typography>}
              >
                <IconButton>
                  <FavoriteBorderOutlined />0 likes
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {renderSessionImages()}
        {renderSessionEquipment()}
        <Grid container>
          <Grid item xs={12} md={12}>
            {currentUserId === session?.trainer?.id ? (
              <TrainerSetAvailability />
            ) : (
              <Calendar session={session} />
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            {currentUserId === session?.trainer?.id ? (
              <LocationsCardEditMode session={session} />
            ) : (
              chooseLocationRow
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Button
            variant="contained"
            sx={{ color: 'white', fontWeight: 700 }}
            onClick={
              session?.trainer?.id === currentUserId
                ? handleSaveLocations
                : handleRequestBooking
            }
          >
            {session?.trainer?.id === currentUserId
              ? 'Save Locations'
              : 'Book Now'}
          </Button>
        </Grid>
        <Snackbar
          autoHideDuration={6000}
          anchorOrigin={snackbarSuccessOrigin}
          open={snackbarSuccessOpen}
          onClose={() => setSnackbarSuccessOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarSuccessOpen(false)}
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
          >
            {snackbarSuccessMessage}
          </Alert>
        </Snackbar>

        {session?.trainer?.id !== currentUserId && renderTrainerOtherSessions()}
      </Grid>
    )
  }

  function renderTrainerOtherSessions() {
    return (
      <Grid
        container
        sx={{
          p: 2,
          alignItems: 'flex-start',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h6">
            Other Sessions by {session?.trainer?.displayName}.
          </Typography>
        </Grid>
        {large ? (
          <Grid
            container
            sx={{
              width: '100%',

              justifyContent: 'space-around',
            }}
          >
            {trainerSessions?.map((session, index) => (
              <Grid key={session.id} item xs={4} lg={3} sx={{ p: 1 }}>
                <SessionCard
                  session={session}
                  handleOpenTrainerAvailability={() =>
                    console.log('handleOpenTrainerAvailability')
                  }
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Tabs
            value={0}
            onChange={() => console.log('handleChangeCoachIndex')}
            variant="scrollable"
            scrollButtons
            aria-label="scrollable force tabs example"
            sx={{
              backgroundColor: 'white',
              color: '#7C7C7C',
              width: '100%',
            }}
          >
            {trainerSessions?.map((session) => (
              <Grid container key={session.id} sx={{ p: 1 }}>
                <SessionCard
                  session={session}
                  handleOpenTrainerAvailability={() =>
                    console.log('handleOpenTrainerAvailability')
                  }
                />
              </Grid>
            ))}
          </Tabs>
        )}

        {false && <ReviewStepper handleGoToPayment={handleGoToPayment} />}
      </Grid>
    )
  }

  function renderSessionImages() {
    return (
      <Grid
        container
        sx={{
          p: 2,
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '100%',
        }}
      >
        <SessionPhotoCropModal />
        <PaymentsModal />
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            maxWidth: '100%',
            justifyContent: 'flex-end',
            p: 2,
          }}
        >
          {currentUserId === session?.trainer?.id && (
            <Box
              style={{
                height: 932,
                width: 644,
                maxWidth: '100%',
                borderRadius: '12px 0px 0px 12px',
                backgroundColor: 'rgba(0,0,0,0)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 305,
              }}
            >
              <Button
                variant="outlined"
                sx={{ color: 'gunMetal.main', bgcolor: 'white' }}
                onClick={() => {
                  dispatch(setSessionPhotoCropModalOpen(true))
                  dispatch(setSessionPhotoNumber(1))
                }}
              >
                <AddRounded />
                Add Photo
              </Button>
            </Box>
          )}
          {session?.sessionPhotos &&
          session?.sessionPhotos.length &&
          session?.sessionPhotos[0] ? (
            <S3Image
              imagePath={`${session?.trainer?.id}/sessions/${sessionId}/${session?.sessionPhotos[0]}`}
              dimensions={{
                height: 932,
                width: 644,
                maxWidth: '100%',
                borderRadius: '12px 0px 0px 12px',
              }}
            />
          ) : (
            <Box
              style={{
                height: 932,
                width: 644,
                borderRadius: '12px 0px 0px 12px',
                backgroundColor: 'rgba(0,0,0,.6)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Grid
              container
              sx={{
                alignItems: 'space-between',
                p: 2,
                maxWidth: '100%',
              }}
            >
              {currentUserId === session?.trainer?.id && (
                <Box
                  style={{
                    height: 440,
                    width: 644,
                    maxWidth: '100%',
                    borderRadius: '16px 16px 0px 0px',
                    backgroundColor: 'rgba(0,0,0,0)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: 309,
                  }}
                >
                  {' '}
                  <Button
                    variant="outlined"
                    sx={{ color: 'gunMetal.main', bgcolor: 'white' }}
                    onClick={() => {
                      dispatch(setSessionPhotoCropModalOpen(true))
                      dispatch(setSessionPhotoNumber(2))
                    }}
                  >
                    <AddRounded />
                    Add Photo
                  </Button>
                </Box>
              )}
              {session?.sessionPhotos &&
              session?.sessionPhotos.length &&
              session?.sessionPhotos[1] ? (
                <S3Image
                  imagePath={`${session?.trainer?.id}/sessions/${sessionId}/${session?.sessionPhotos[1]}`}
                  dimensions={{
                    height: 440,
                    width: 644,
                    maxWidth: '100%',
                    borderRadius: '16px 16px 0px 0px',
                  }}
                />
              ) : (
                <Box
                  style={{
                    height: 440,
                    width: 644,
                    maxWidth: '100%',
                    borderRadius: '16px 16px 0px 0px',
                    backgroundColor: 'rgba(0,0,0,.6)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              )}
            </Grid>
            <Grid container sx={{ alignItems: 'space-between', p: 2 }}>
              {currentUserId === session?.trainer?.id && (
                <Box
                  style={{
                    height: 440,
                    width: 644,
                    maxWidth: '100%',
                    borderRadius: '0px 0px 16px 16px',
                    backgroundColor: 'rgba(0,0,0,0)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: 799,
                  }}
                >
                  {' '}
                  <Button
                    variant="outlined"
                    sx={{ color: 'gunMetal.main', bgcolor: 'white' }}
                    onClick={() => {
                      dispatch(setSessionPhotoCropModalOpen(true))
                      dispatch(setSessionPhotoNumber(3))
                    }}
                  >
                    <AddRounded />
                    Add Photo
                  </Button>
                </Box>
              )}
              {session?.sessionPhotos &&
              session?.sessionPhotos.length &&
              session?.sessionPhotos[2] ? (
                <S3Image
                  imagePath={`${session?.trainer?.id}/sessions/${sessionId}/${session?.sessionPhotos[2]}`}
                  dimensions={{
                    height: 440,
                    width: 644,
                    maxWidth: '100%',
                    borderRadius: '0px 0px 16px 16px',
                  }}
                />
              ) : (
                <Box
                  style={{
                    height: 440,
                    width: 644,
                    maxWidth: '100%',
                    borderRadius: '0px 0px 16px 16px',
                    backgroundColor: 'rgba(0,0,0,.6)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  function renderSessionEquipment() {
    return (
      <Grid
        container
        sx={{
          p: 2,
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Grid item>
          <Grid container sx={{ gap: '10px' }}>
            <Grid item>
              <Grid container>
                <Grid container sx={{ justifyContent: 'flex-start' }}>
                  <Typography sx={{ fontWeight: 500, fontSize: '28px' }}>
                    What you'll need to bring
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{ gap: '16px', justifyContent: 'flex-start' }}
                >
                  {session?.equipment &&
                    session?.equipment.map((data, index) => {
                      return (
                        <Grid item key={index}>
                          <Chip
                            label={data}
                            onDelete={
                              session?.trainer?.id === currentUserId
                                ? handleDeleteEquipment(data)
                                : null
                            }
                          />
                        </Grid>
                      )
                    })}
                  {session?.trainer?.id === currentUserId && (
                    <TextField
                      id="outlined-basic"
                      label="New Item"
                      variant="outlined"
                      onChange={handleEquipmentInputChange}
                      value={sessionNewEquipmentInput}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleAddEquipment()
                        }
                      }}
                    />
                  )}
                  {session?.trainer?.id === currentUserId && (
                    <Tooltip
                      title={
                        'add equipment to your session so clients know what to expect'
                      }
                    >
                      <IconButton onClick={handleAddEquipment}>
                        <CheckCircle color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default SessionMain
