/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      pronouns
      displayName
      profilePhotoUrl
      homeLocation
      homeCity
      homeState
      homeZip
      about
      verified
      virtualVerified
      infoForTrainers
      yearsExperience
      fitnessGoals
      workoutPreferences
      trainingTypes
      trainingTimePreferences
      trainingDayPreferences
      trainingStyles
      bookings {
        items {
          id
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          member {
            id
            username
            pronouns
            displayName
            profilePhotoUrl
            homeLocation
            homeCity
            homeState
            homeZip
            about
            verified
            virtualVerified
            infoForTrainers
            yearsExperience
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            trainingStyles
            createdAt
            updatedAt
            userStatsId
            __typename
          }
          trainer {
            id
            username
            displayName
            displayRating
            createdAt
            updatedAt
            __typename
          }
          status
          price
          startTime
          endTime
          location {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
            __typename
          }
          notes
          rating
          review
          sessionMaxHR
          sessionAvgHR
          sessionCalories
          trainerNotes
          trainerSuggestions
          selectedLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
            __typename
          }
          messageRoom {
            id
            createdAt
            updatedAt
            messageRoomMemberId
            messageRoomTrainerId
            __typename
          }
          virtual
          paymentStatus
          excersizes {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userBookingsId
          trainerBookingsId
          bookingSessionId
          bookingSelectedLocationId
          bookingMessageRoomId
          __typename
        }
        nextToken
        __typename
      }
      medicalConditions {
        items {
          id
          title
          description
          createdAt
          updatedAt
          userMedicalConditionsId
          __typename
        }
        nextToken
        __typename
      }
      stats {
        id
        age
        height
        weight
        bmi
        fatPercentage
        stepsPerDay
        calsPerDay
        stairsPerDay
        milesPerWeekRunning
        milesPerWeekCycle
        goalStepsPerDay
        goalCalsPerDay
        goalStairsPerDay
        goalMilesPerWeekRunning
        goalMilesPerWeekCycle
        createdAt
        updatedAt
        __typename
      }
      focusAreas {
        items {
          id
          injury
          locationX
          locationY
          createdAt
          updatedAt
          userFocusAreasId
          __typename
        }
        nextToken
        __typename
      }
      favoriteSessions {
        items {
          id
          member {
            id
            username
            pronouns
            displayName
            profilePhotoUrl
            homeLocation
            homeCity
            homeState
            homeZip
            about
            verified
            virtualVerified
            infoForTrainers
            yearsExperience
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            trainingStyles
            createdAt
            updatedAt
            userStatsId
            __typename
          }
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          createdAt
          updatedAt
          userFavoriteSessionsId
          favoritedSessionSessionId
          __typename
        }
        nextToken
        __typename
      }
      favoriteTrainers {
        items {
          id
          member {
            id
            username
            pronouns
            displayName
            profilePhotoUrl
            homeLocation
            homeCity
            homeState
            homeZip
            about
            verified
            virtualVerified
            infoForTrainers
            yearsExperience
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            trainingStyles
            createdAt
            updatedAt
            userStatsId
            __typename
          }
          trainer {
            id
            username
            displayName
            displayRating
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          userFavoriteTrainersId
          favoritedTrainerTrainerId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userStatsId
      __typename
    }
  }
`
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      pronouns
      displayName
      profilePhotoUrl
      homeLocation
      homeCity
      homeState
      homeZip
      about
      verified
      virtualVerified
      infoForTrainers
      yearsExperience
      fitnessGoals
      workoutPreferences
      trainingTypes
      trainingTimePreferences
      trainingDayPreferences
      trainingStyles
      bookings {
        items {
          id
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          member {
            id
            username
            pronouns
            displayName
            profilePhotoUrl
            homeLocation
            homeCity
            homeState
            homeZip
            about
            verified
            virtualVerified
            infoForTrainers
            yearsExperience
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            trainingStyles
            createdAt
            updatedAt
            userStatsId
            __typename
          }
          trainer {
            id
            username
            displayName
            displayRating
            createdAt
            updatedAt
            __typename
          }
          status
          price
          startTime
          endTime
          location {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
            __typename
          }
          notes
          rating
          review
          sessionMaxHR
          sessionAvgHR
          sessionCalories
          trainerNotes
          trainerSuggestions
          selectedLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
            __typename
          }
          messageRoom {
            id
            createdAt
            updatedAt
            messageRoomMemberId
            messageRoomTrainerId
            __typename
          }
          virtual
          paymentStatus
          excersizes {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userBookingsId
          trainerBookingsId
          bookingSessionId
          bookingSelectedLocationId
          bookingMessageRoomId
          __typename
        }
        nextToken
        __typename
      }
      medicalConditions {
        items {
          id
          title
          description
          createdAt
          updatedAt
          userMedicalConditionsId
          __typename
        }
        nextToken
        __typename
      }
      stats {
        id
        age
        height
        weight
        bmi
        fatPercentage
        stepsPerDay
        calsPerDay
        stairsPerDay
        milesPerWeekRunning
        milesPerWeekCycle
        goalStepsPerDay
        goalCalsPerDay
        goalStairsPerDay
        goalMilesPerWeekRunning
        goalMilesPerWeekCycle
        createdAt
        updatedAt
        __typename
      }
      focusAreas {
        items {
          id
          injury
          locationX
          locationY
          createdAt
          updatedAt
          userFocusAreasId
          __typename
        }
        nextToken
        __typename
      }
      favoriteSessions {
        items {
          id
          member {
            id
            username
            pronouns
            displayName
            profilePhotoUrl
            homeLocation
            homeCity
            homeState
            homeZip
            about
            verified
            virtualVerified
            infoForTrainers
            yearsExperience
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            trainingStyles
            createdAt
            updatedAt
            userStatsId
            __typename
          }
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          createdAt
          updatedAt
          userFavoriteSessionsId
          favoritedSessionSessionId
          __typename
        }
        nextToken
        __typename
      }
      favoriteTrainers {
        items {
          id
          member {
            id
            username
            pronouns
            displayName
            profilePhotoUrl
            homeLocation
            homeCity
            homeState
            homeZip
            about
            verified
            virtualVerified
            infoForTrainers
            yearsExperience
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            trainingStyles
            createdAt
            updatedAt
            userStatsId
            __typename
          }
          trainer {
            id
            username
            displayName
            displayRating
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          userFavoriteTrainersId
          favoritedTrainerTrainerId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userStatsId
      __typename
    }
  }
`
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      pronouns
      displayName
      profilePhotoUrl
      homeLocation
      homeCity
      homeState
      homeZip
      about
      verified
      virtualVerified
      infoForTrainers
      yearsExperience
      fitnessGoals
      workoutPreferences
      trainingTypes
      trainingTimePreferences
      trainingDayPreferences
      trainingStyles
      bookings {
        items {
          id
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          member {
            id
            username
            pronouns
            displayName
            profilePhotoUrl
            homeLocation
            homeCity
            homeState
            homeZip
            about
            verified
            virtualVerified
            infoForTrainers
            yearsExperience
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            trainingStyles
            createdAt
            updatedAt
            userStatsId
            __typename
          }
          trainer {
            id
            username
            displayName
            displayRating
            createdAt
            updatedAt
            __typename
          }
          status
          price
          startTime
          endTime
          location {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
            __typename
          }
          notes
          rating
          review
          sessionMaxHR
          sessionAvgHR
          sessionCalories
          trainerNotes
          trainerSuggestions
          selectedLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
            __typename
          }
          messageRoom {
            id
            createdAt
            updatedAt
            messageRoomMemberId
            messageRoomTrainerId
            __typename
          }
          virtual
          paymentStatus
          excersizes {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userBookingsId
          trainerBookingsId
          bookingSessionId
          bookingSelectedLocationId
          bookingMessageRoomId
          __typename
        }
        nextToken
        __typename
      }
      medicalConditions {
        items {
          id
          title
          description
          createdAt
          updatedAt
          userMedicalConditionsId
          __typename
        }
        nextToken
        __typename
      }
      stats {
        id
        age
        height
        weight
        bmi
        fatPercentage
        stepsPerDay
        calsPerDay
        stairsPerDay
        milesPerWeekRunning
        milesPerWeekCycle
        goalStepsPerDay
        goalCalsPerDay
        goalStairsPerDay
        goalMilesPerWeekRunning
        goalMilesPerWeekCycle
        createdAt
        updatedAt
        __typename
      }
      focusAreas {
        items {
          id
          injury
          locationX
          locationY
          createdAt
          updatedAt
          userFocusAreasId
          __typename
        }
        nextToken
        __typename
      }
      favoriteSessions {
        items {
          id
          member {
            id
            username
            pronouns
            displayName
            profilePhotoUrl
            homeLocation
            homeCity
            homeState
            homeZip
            about
            verified
            virtualVerified
            infoForTrainers
            yearsExperience
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            trainingStyles
            createdAt
            updatedAt
            userStatsId
            __typename
          }
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          createdAt
          updatedAt
          userFavoriteSessionsId
          favoritedSessionSessionId
          __typename
        }
        nextToken
        __typename
      }
      favoriteTrainers {
        items {
          id
          member {
            id
            username
            pronouns
            displayName
            profilePhotoUrl
            homeLocation
            homeCity
            homeState
            homeZip
            about
            verified
            virtualVerified
            infoForTrainers
            yearsExperience
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            trainingStyles
            createdAt
            updatedAt
            userStatsId
            __typename
          }
          trainer {
            id
            username
            displayName
            displayRating
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          userFavoriteTrainersId
          favoritedTrainerTrainerId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userStatsId
      __typename
    }
  }
`
export const createFocusArea = /* GraphQL */ `
  mutation CreateFocusArea(
    $input: CreateFocusAreaInput!
    $condition: ModelFocusAreaConditionInput
  ) {
    createFocusArea(input: $input, condition: $condition) {
      id
      injury
      locationX
      locationY
      createdAt
      updatedAt
      userFocusAreasId
      __typename
    }
  }
`
export const updateFocusArea = /* GraphQL */ `
  mutation UpdateFocusArea(
    $input: UpdateFocusAreaInput!
    $condition: ModelFocusAreaConditionInput
  ) {
    updateFocusArea(input: $input, condition: $condition) {
      id
      injury
      locationX
      locationY
      createdAt
      updatedAt
      userFocusAreasId
      __typename
    }
  }
`
export const deleteFocusArea = /* GraphQL */ `
  mutation DeleteFocusArea(
    $input: DeleteFocusAreaInput!
    $condition: ModelFocusAreaConditionInput
  ) {
    deleteFocusArea(input: $input, condition: $condition) {
      id
      injury
      locationX
      locationY
      createdAt
      updatedAt
      userFocusAreasId
      __typename
    }
  }
`
export const createStat = /* GraphQL */ `
  mutation CreateStat(
    $input: CreateStatInput!
    $condition: ModelStatConditionInput
  ) {
    createStat(input: $input, condition: $condition) {
      id
      age
      height
      weight
      bmi
      fatPercentage
      stepsPerDay
      calsPerDay
      stairsPerDay
      milesPerWeekRunning
      milesPerWeekCycle
      goalStepsPerDay
      goalCalsPerDay
      goalStairsPerDay
      goalMilesPerWeekRunning
      goalMilesPerWeekCycle
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateStat = /* GraphQL */ `
  mutation UpdateStat(
    $input: UpdateStatInput!
    $condition: ModelStatConditionInput
  ) {
    updateStat(input: $input, condition: $condition) {
      id
      age
      height
      weight
      bmi
      fatPercentage
      stepsPerDay
      calsPerDay
      stairsPerDay
      milesPerWeekRunning
      milesPerWeekCycle
      goalStepsPerDay
      goalCalsPerDay
      goalStairsPerDay
      goalMilesPerWeekRunning
      goalMilesPerWeekCycle
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteStat = /* GraphQL */ `
  mutation DeleteStat(
    $input: DeleteStatInput!
    $condition: ModelStatConditionInput
  ) {
    deleteStat(input: $input, condition: $condition) {
      id
      age
      height
      weight
      bmi
      fatPercentage
      stepsPerDay
      calsPerDay
      stairsPerDay
      milesPerWeekRunning
      milesPerWeekCycle
      goalStepsPerDay
      goalCalsPerDay
      goalStairsPerDay
      goalMilesPerWeekRunning
      goalMilesPerWeekCycle
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createMedicalCondition = /* GraphQL */ `
  mutation CreateMedicalCondition(
    $input: CreateMedicalConditionInput!
    $condition: ModelMedicalConditionConditionInput
  ) {
    createMedicalCondition(input: $input, condition: $condition) {
      id
      title
      description
      createdAt
      updatedAt
      userMedicalConditionsId
      __typename
    }
  }
`
export const updateMedicalCondition = /* GraphQL */ `
  mutation UpdateMedicalCondition(
    $input: UpdateMedicalConditionInput!
    $condition: ModelMedicalConditionConditionInput
  ) {
    updateMedicalCondition(input: $input, condition: $condition) {
      id
      title
      description
      createdAt
      updatedAt
      userMedicalConditionsId
      __typename
    }
  }
`
export const deleteMedicalCondition = /* GraphQL */ `
  mutation DeleteMedicalCondition(
    $input: DeleteMedicalConditionInput!
    $condition: ModelMedicalConditionConditionInput
  ) {
    deleteMedicalCondition(input: $input, condition: $condition) {
      id
      title
      description
      createdAt
      updatedAt
      userMedicalConditionsId
      __typename
    }
  }
`
export const createTrainer = /* GraphQL */ `
  mutation CreateTrainer(
    $input: CreateTrainerInput!
    $condition: ModelTrainerConditionInput
  ) {
    createTrainer(input: $input, condition: $condition) {
      id
      username
      displayName
      displayRating
      bookings {
        items {
          id
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          member {
            id
            username
            pronouns
            displayName
            profilePhotoUrl
            homeLocation
            homeCity
            homeState
            homeZip
            about
            verified
            virtualVerified
            infoForTrainers
            yearsExperience
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            trainingStyles
            createdAt
            updatedAt
            userStatsId
            __typename
          }
          trainer {
            id
            username
            displayName
            displayRating
            createdAt
            updatedAt
            __typename
          }
          status
          price
          startTime
          endTime
          location {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
            __typename
          }
          notes
          rating
          review
          sessionMaxHR
          sessionAvgHR
          sessionCalories
          trainerNotes
          trainerSuggestions
          selectedLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
            __typename
          }
          messageRoom {
            id
            createdAt
            updatedAt
            messageRoomMemberId
            messageRoomTrainerId
            __typename
          }
          virtual
          paymentStatus
          excersizes {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userBookingsId
          trainerBookingsId
          bookingSessionId
          bookingSelectedLocationId
          bookingMessageRoomId
          __typename
        }
        nextToken
        __typename
      }
      sessions {
        items {
          id
          title
          description
          inPerson
          virtual
          maxTimeAfterStart
          category
          city
          state
          defaultPrice
          indoorLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
            __typename
          }
          outdoorLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
            __typename
          }
          commuteToMember
          maxCommuteDistance
          commuteFlatFee
          equipment
          cheerleader
          drillSargeant
          professor
          workoutBuddy
          thrityMinsPrice
          sixtyMinsPrice
          ninetyMinsPrice
          oneTwentyMinsPrice
          mondayStartTime
          mondayEndTime
          tuesdayStartTime
          tuesdayEndTime
          wednesdayStartTime
          wednesdayEndTime
          thursdayStartTime
          thursdayEndTime
          fridayStartTime
          fridayEndTime
          saturdayStartTime
          saturdayEndTime
          sundayStartTime
          sundayEndTime
          coverPhotoIndex
          sessionPhotos
          equipmentList
          instantBookingEnabled
          minTimeBeforeInstantBooking
          paused
          searchable
          trainerGender
          createdAt
          updatedAt
          trainerSessionsId
          sessionIndoorLocationId
          sessionOutdoorLocationId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateTrainer = /* GraphQL */ `
  mutation UpdateTrainer(
    $input: UpdateTrainerInput!
    $condition: ModelTrainerConditionInput
  ) {
    updateTrainer(input: $input, condition: $condition) {
      id
      username
      displayName
      displayRating
      bookings {
        items {
          id
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          member {
            id
            username
            pronouns
            displayName
            profilePhotoUrl
            homeLocation
            homeCity
            homeState
            homeZip
            about
            verified
            virtualVerified
            infoForTrainers
            yearsExperience
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            trainingStyles
            createdAt
            updatedAt
            userStatsId
            __typename
          }
          trainer {
            id
            username
            displayName
            displayRating
            createdAt
            updatedAt
            __typename
          }
          status
          price
          startTime
          endTime
          location {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
            __typename
          }
          notes
          rating
          review
          sessionMaxHR
          sessionAvgHR
          sessionCalories
          trainerNotes
          trainerSuggestions
          selectedLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
            __typename
          }
          messageRoom {
            id
            createdAt
            updatedAt
            messageRoomMemberId
            messageRoomTrainerId
            __typename
          }
          virtual
          paymentStatus
          excersizes {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userBookingsId
          trainerBookingsId
          bookingSessionId
          bookingSelectedLocationId
          bookingMessageRoomId
          __typename
        }
        nextToken
        __typename
      }
      sessions {
        items {
          id
          title
          description
          inPerson
          virtual
          maxTimeAfterStart
          category
          city
          state
          defaultPrice
          indoorLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
            __typename
          }
          outdoorLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
            __typename
          }
          commuteToMember
          maxCommuteDistance
          commuteFlatFee
          equipment
          cheerleader
          drillSargeant
          professor
          workoutBuddy
          thrityMinsPrice
          sixtyMinsPrice
          ninetyMinsPrice
          oneTwentyMinsPrice
          mondayStartTime
          mondayEndTime
          tuesdayStartTime
          tuesdayEndTime
          wednesdayStartTime
          wednesdayEndTime
          thursdayStartTime
          thursdayEndTime
          fridayStartTime
          fridayEndTime
          saturdayStartTime
          saturdayEndTime
          sundayStartTime
          sundayEndTime
          coverPhotoIndex
          sessionPhotos
          equipmentList
          instantBookingEnabled
          minTimeBeforeInstantBooking
          paused
          searchable
          trainerGender
          createdAt
          updatedAt
          trainerSessionsId
          sessionIndoorLocationId
          sessionOutdoorLocationId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteTrainer = /* GraphQL */ `
  mutation DeleteTrainer(
    $input: DeleteTrainerInput!
    $condition: ModelTrainerConditionInput
  ) {
    deleteTrainer(input: $input, condition: $condition) {
      id
      username
      displayName
      displayRating
      bookings {
        items {
          id
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          member {
            id
            username
            pronouns
            displayName
            profilePhotoUrl
            homeLocation
            homeCity
            homeState
            homeZip
            about
            verified
            virtualVerified
            infoForTrainers
            yearsExperience
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            trainingStyles
            createdAt
            updatedAt
            userStatsId
            __typename
          }
          trainer {
            id
            username
            displayName
            displayRating
            createdAt
            updatedAt
            __typename
          }
          status
          price
          startTime
          endTime
          location {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
            __typename
          }
          notes
          rating
          review
          sessionMaxHR
          sessionAvgHR
          sessionCalories
          trainerNotes
          trainerSuggestions
          selectedLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
            __typename
          }
          messageRoom {
            id
            createdAt
            updatedAt
            messageRoomMemberId
            messageRoomTrainerId
            __typename
          }
          virtual
          paymentStatus
          excersizes {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userBookingsId
          trainerBookingsId
          bookingSessionId
          bookingSelectedLocationId
          bookingMessageRoomId
          __typename
        }
        nextToken
        __typename
      }
      sessions {
        items {
          id
          title
          description
          inPerson
          virtual
          maxTimeAfterStart
          category
          city
          state
          defaultPrice
          indoorLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
            __typename
          }
          outdoorLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
            __typename
          }
          commuteToMember
          maxCommuteDistance
          commuteFlatFee
          equipment
          cheerleader
          drillSargeant
          professor
          workoutBuddy
          thrityMinsPrice
          sixtyMinsPrice
          ninetyMinsPrice
          oneTwentyMinsPrice
          mondayStartTime
          mondayEndTime
          tuesdayStartTime
          tuesdayEndTime
          wednesdayStartTime
          wednesdayEndTime
          thursdayStartTime
          thursdayEndTime
          fridayStartTime
          fridayEndTime
          saturdayStartTime
          saturdayEndTime
          sundayStartTime
          sundayEndTime
          coverPhotoIndex
          sessionPhotos
          equipmentList
          instantBookingEnabled
          minTimeBeforeInstantBooking
          paused
          searchable
          trainerGender
          createdAt
          updatedAt
          trainerSessionsId
          sessionIndoorLocationId
          sessionOutdoorLocationId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      session {
        id
        title
        description
        inPerson
        virtual
        maxTimeAfterStart
        category
        city
        state
        defaultPrice
        indoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
          __typename
        }
        outdoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
          __typename
        }
        commuteToMember
        maxCommuteDistance
        commuteFlatFee
        equipment
        cheerleader
        drillSargeant
        professor
        workoutBuddy
        thrityMinsPrice
        sixtyMinsPrice
        ninetyMinsPrice
        oneTwentyMinsPrice
        mondayStartTime
        mondayEndTime
        tuesdayStartTime
        tuesdayEndTime
        wednesdayStartTime
        wednesdayEndTime
        thursdayStartTime
        thursdayEndTime
        fridayStartTime
        fridayEndTime
        saturdayStartTime
        saturdayEndTime
        sundayStartTime
        sundayEndTime
        coverPhotoIndex
        sessionPhotos
        equipmentList
        instantBookingEnabled
        minTimeBeforeInstantBooking
        paused
        searchable
        trainerGender
        createdAt
        updatedAt
        trainerSessionsId
        sessionIndoorLocationId
        sessionOutdoorLocationId
        __typename
      }
      member {
        id
        username
        pronouns
        displayName
        profilePhotoUrl
        homeLocation
        homeCity
        homeState
        homeZip
        about
        verified
        virtualVerified
        infoForTrainers
        yearsExperience
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        trainingStyles
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
            __typename
          }
          nextToken
          __typename
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
          __typename
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
            __typename
          }
          nextToken
          __typename
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
            __typename
          }
          nextToken
          __typename
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userStatsId
        __typename
      }
      trainer {
        id
        username
        displayName
        displayRating
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      status
      price
      startTime
      endTime
      location {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
        __typename
      }
      notes
      rating
      review
      sessionMaxHR
      sessionAvgHR
      sessionCalories
      trainerNotes
      trainerSuggestions
      selectedLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
        __typename
      }
      messageRoom {
        id
        member {
          id
          username
          pronouns
          displayName
          profilePhotoUrl
          homeLocation
          homeCity
          homeState
          homeZip
          about
          verified
          virtualVerified
          infoForTrainers
          yearsExperience
          fitnessGoals
          workoutPreferences
          trainingTypes
          trainingTimePreferences
          trainingDayPreferences
          trainingStyles
          bookings {
            nextToken
            __typename
          }
          medicalConditions {
            nextToken
            __typename
          }
          stats {
            id
            age
            height
            weight
            bmi
            fatPercentage
            stepsPerDay
            calsPerDay
            stairsPerDay
            milesPerWeekRunning
            milesPerWeekCycle
            goalStepsPerDay
            goalCalsPerDay
            goalStairsPerDay
            goalMilesPerWeekRunning
            goalMilesPerWeekCycle
            createdAt
            updatedAt
            __typename
          }
          focusAreas {
            nextToken
            __typename
          }
          favoriteSessions {
            nextToken
            __typename
          }
          favoriteTrainers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userStatsId
          __typename
        }
        trainer {
          id
          username
          displayName
          displayRating
          bookings {
            nextToken
            __typename
          }
          sessions {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        messages {
          items {
            id
            content
            read
            createdAt
            updatedAt
            messageRoomMessagesId
            messageSenderId
            messageTrainerSenderId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        messageRoomMemberId
        messageRoomTrainerId
        __typename
      }
      virtual
      paymentStatus
      excersizes {
        items {
          id
          name
          duration
          reps
          weight
          createdAt
          updatedAt
          bookingExcersizesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userBookingsId
      trainerBookingsId
      bookingSessionId
      bookingSelectedLocationId
      bookingMessageRoomId
      __typename
    }
  }
`
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      session {
        id
        title
        description
        inPerson
        virtual
        maxTimeAfterStart
        category
        city
        state
        defaultPrice
        indoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
          __typename
        }
        outdoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
          __typename
        }
        commuteToMember
        maxCommuteDistance
        commuteFlatFee
        equipment
        cheerleader
        drillSargeant
        professor
        workoutBuddy
        thrityMinsPrice
        sixtyMinsPrice
        ninetyMinsPrice
        oneTwentyMinsPrice
        mondayStartTime
        mondayEndTime
        tuesdayStartTime
        tuesdayEndTime
        wednesdayStartTime
        wednesdayEndTime
        thursdayStartTime
        thursdayEndTime
        fridayStartTime
        fridayEndTime
        saturdayStartTime
        saturdayEndTime
        sundayStartTime
        sundayEndTime
        coverPhotoIndex
        sessionPhotos
        equipmentList
        instantBookingEnabled
        minTimeBeforeInstantBooking
        paused
        searchable
        trainerGender
        createdAt
        updatedAt
        trainerSessionsId
        sessionIndoorLocationId
        sessionOutdoorLocationId
        __typename
      }
      member {
        id
        username
        pronouns
        displayName
        profilePhotoUrl
        homeLocation
        homeCity
        homeState
        homeZip
        about
        verified
        virtualVerified
        infoForTrainers
        yearsExperience
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        trainingStyles
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
            __typename
          }
          nextToken
          __typename
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
          __typename
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
            __typename
          }
          nextToken
          __typename
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
            __typename
          }
          nextToken
          __typename
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userStatsId
        __typename
      }
      trainer {
        id
        username
        displayName
        displayRating
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      status
      price
      startTime
      endTime
      location {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
        __typename
      }
      notes
      rating
      review
      sessionMaxHR
      sessionAvgHR
      sessionCalories
      trainerNotes
      trainerSuggestions
      selectedLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
        __typename
      }
      messageRoom {
        id
        member {
          id
          username
          pronouns
          displayName
          profilePhotoUrl
          homeLocation
          homeCity
          homeState
          homeZip
          about
          verified
          virtualVerified
          infoForTrainers
          yearsExperience
          fitnessGoals
          workoutPreferences
          trainingTypes
          trainingTimePreferences
          trainingDayPreferences
          trainingStyles
          bookings {
            nextToken
            __typename
          }
          medicalConditions {
            nextToken
            __typename
          }
          stats {
            id
            age
            height
            weight
            bmi
            fatPercentage
            stepsPerDay
            calsPerDay
            stairsPerDay
            milesPerWeekRunning
            milesPerWeekCycle
            goalStepsPerDay
            goalCalsPerDay
            goalStairsPerDay
            goalMilesPerWeekRunning
            goalMilesPerWeekCycle
            createdAt
            updatedAt
            __typename
          }
          focusAreas {
            nextToken
            __typename
          }
          favoriteSessions {
            nextToken
            __typename
          }
          favoriteTrainers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userStatsId
          __typename
        }
        trainer {
          id
          username
          displayName
          displayRating
          bookings {
            nextToken
            __typename
          }
          sessions {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        messages {
          items {
            id
            content
            read
            createdAt
            updatedAt
            messageRoomMessagesId
            messageSenderId
            messageTrainerSenderId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        messageRoomMemberId
        messageRoomTrainerId
        __typename
      }
      virtual
      paymentStatus
      excersizes {
        items {
          id
          name
          duration
          reps
          weight
          createdAt
          updatedAt
          bookingExcersizesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userBookingsId
      trainerBookingsId
      bookingSessionId
      bookingSelectedLocationId
      bookingMessageRoomId
      __typename
    }
  }
`
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      session {
        id
        title
        description
        inPerson
        virtual
        maxTimeAfterStart
        category
        city
        state
        defaultPrice
        indoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
          __typename
        }
        outdoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
          __typename
        }
        commuteToMember
        maxCommuteDistance
        commuteFlatFee
        equipment
        cheerleader
        drillSargeant
        professor
        workoutBuddy
        thrityMinsPrice
        sixtyMinsPrice
        ninetyMinsPrice
        oneTwentyMinsPrice
        mondayStartTime
        mondayEndTime
        tuesdayStartTime
        tuesdayEndTime
        wednesdayStartTime
        wednesdayEndTime
        thursdayStartTime
        thursdayEndTime
        fridayStartTime
        fridayEndTime
        saturdayStartTime
        saturdayEndTime
        sundayStartTime
        sundayEndTime
        coverPhotoIndex
        sessionPhotos
        equipmentList
        instantBookingEnabled
        minTimeBeforeInstantBooking
        paused
        searchable
        trainerGender
        createdAt
        updatedAt
        trainerSessionsId
        sessionIndoorLocationId
        sessionOutdoorLocationId
        __typename
      }
      member {
        id
        username
        pronouns
        displayName
        profilePhotoUrl
        homeLocation
        homeCity
        homeState
        homeZip
        about
        verified
        virtualVerified
        infoForTrainers
        yearsExperience
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        trainingStyles
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
            __typename
          }
          nextToken
          __typename
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
          __typename
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
            __typename
          }
          nextToken
          __typename
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
            __typename
          }
          nextToken
          __typename
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userStatsId
        __typename
      }
      trainer {
        id
        username
        displayName
        displayRating
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      status
      price
      startTime
      endTime
      location {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
        __typename
      }
      notes
      rating
      review
      sessionMaxHR
      sessionAvgHR
      sessionCalories
      trainerNotes
      trainerSuggestions
      selectedLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
        __typename
      }
      messageRoom {
        id
        member {
          id
          username
          pronouns
          displayName
          profilePhotoUrl
          homeLocation
          homeCity
          homeState
          homeZip
          about
          verified
          virtualVerified
          infoForTrainers
          yearsExperience
          fitnessGoals
          workoutPreferences
          trainingTypes
          trainingTimePreferences
          trainingDayPreferences
          trainingStyles
          bookings {
            nextToken
            __typename
          }
          medicalConditions {
            nextToken
            __typename
          }
          stats {
            id
            age
            height
            weight
            bmi
            fatPercentage
            stepsPerDay
            calsPerDay
            stairsPerDay
            milesPerWeekRunning
            milesPerWeekCycle
            goalStepsPerDay
            goalCalsPerDay
            goalStairsPerDay
            goalMilesPerWeekRunning
            goalMilesPerWeekCycle
            createdAt
            updatedAt
            __typename
          }
          focusAreas {
            nextToken
            __typename
          }
          favoriteSessions {
            nextToken
            __typename
          }
          favoriteTrainers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userStatsId
          __typename
        }
        trainer {
          id
          username
          displayName
          displayRating
          bookings {
            nextToken
            __typename
          }
          sessions {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        messages {
          items {
            id
            content
            read
            createdAt
            updatedAt
            messageRoomMessagesId
            messageSenderId
            messageTrainerSenderId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        messageRoomMemberId
        messageRoomTrainerId
        __typename
      }
      virtual
      paymentStatus
      excersizes {
        items {
          id
          name
          duration
          reps
          weight
          createdAt
          updatedAt
          bookingExcersizesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userBookingsId
      trainerBookingsId
      bookingSessionId
      bookingSelectedLocationId
      bookingMessageRoomId
      __typename
    }
  }
`
export const createExcersize = /* GraphQL */ `
  mutation CreateExcersize(
    $input: CreateExcersizeInput!
    $condition: ModelExcersizeConditionInput
  ) {
    createExcersize(input: $input, condition: $condition) {
      id
      name
      duration
      reps
      weight
      createdAt
      updatedAt
      bookingExcersizesId
      __typename
    }
  }
`
export const updateExcersize = /* GraphQL */ `
  mutation UpdateExcersize(
    $input: UpdateExcersizeInput!
    $condition: ModelExcersizeConditionInput
  ) {
    updateExcersize(input: $input, condition: $condition) {
      id
      name
      duration
      reps
      weight
      createdAt
      updatedAt
      bookingExcersizesId
      __typename
    }
  }
`
export const deleteExcersize = /* GraphQL */ `
  mutation DeleteExcersize(
    $input: DeleteExcersizeInput!
    $condition: ModelExcersizeConditionInput
  ) {
    deleteExcersize(input: $input, condition: $condition) {
      id
      name
      duration
      reps
      weight
      createdAt
      updatedAt
      bookingExcersizesId
      __typename
    }
  }
`
export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      id
      title
      description
      inPerson
      virtual
      maxTimeAfterStart
      category
      city
      state
      defaultPrice
      indoorLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
        __typename
      }
      outdoorLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
        __typename
      }
      commuteToMember
      maxCommuteDistance
      commuteFlatFee
      equipment
      cheerleader
      drillSargeant
      professor
      workoutBuddy
      thrityMinsPrice
      sixtyMinsPrice
      ninetyMinsPrice
      oneTwentyMinsPrice
      mondayStartTime
      mondayEndTime
      tuesdayStartTime
      tuesdayEndTime
      wednesdayStartTime
      wednesdayEndTime
      thursdayStartTime
      thursdayEndTime
      fridayStartTime
      fridayEndTime
      saturdayStartTime
      saturdayEndTime
      sundayStartTime
      sundayEndTime
      coverPhotoIndex
      sessionPhotos
      equipmentList
      instantBookingEnabled
      minTimeBeforeInstantBooking
      paused
      searchable
      trainerGender
      createdAt
      updatedAt
      trainerSessionsId
      sessionIndoorLocationId
      sessionOutdoorLocationId
      __typename
    }
  }
`
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      id
      title
      description
      inPerson
      virtual
      maxTimeAfterStart
      category
      city
      state
      defaultPrice
      indoorLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
        __typename
      }
      outdoorLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
        __typename
      }
      commuteToMember
      maxCommuteDistance
      commuteFlatFee
      equipment
      cheerleader
      drillSargeant
      professor
      workoutBuddy
      thrityMinsPrice
      sixtyMinsPrice
      ninetyMinsPrice
      oneTwentyMinsPrice
      mondayStartTime
      mondayEndTime
      tuesdayStartTime
      tuesdayEndTime
      wednesdayStartTime
      wednesdayEndTime
      thursdayStartTime
      thursdayEndTime
      fridayStartTime
      fridayEndTime
      saturdayStartTime
      saturdayEndTime
      sundayStartTime
      sundayEndTime
      coverPhotoIndex
      sessionPhotos
      equipmentList
      instantBookingEnabled
      minTimeBeforeInstantBooking
      paused
      searchable
      trainerGender
      createdAt
      updatedAt
      trainerSessionsId
      sessionIndoorLocationId
      sessionOutdoorLocationId
      __typename
    }
  }
`
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      id
      title
      description
      inPerson
      virtual
      maxTimeAfterStart
      category
      city
      state
      defaultPrice
      indoorLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
        __typename
      }
      outdoorLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
        __typename
      }
      commuteToMember
      maxCommuteDistance
      commuteFlatFee
      equipment
      cheerleader
      drillSargeant
      professor
      workoutBuddy
      thrityMinsPrice
      sixtyMinsPrice
      ninetyMinsPrice
      oneTwentyMinsPrice
      mondayStartTime
      mondayEndTime
      tuesdayStartTime
      tuesdayEndTime
      wednesdayStartTime
      wednesdayEndTime
      thursdayStartTime
      thursdayEndTime
      fridayStartTime
      fridayEndTime
      saturdayStartTime
      saturdayEndTime
      sundayStartTime
      sundayEndTime
      coverPhotoIndex
      sessionPhotos
      equipmentList
      instantBookingEnabled
      minTimeBeforeInstantBooking
      paused
      searchable
      trainerGender
      createdAt
      updatedAt
      trainerSessionsId
      sessionIndoorLocationId
      sessionOutdoorLocationId
      __typename
    }
  }
`
export const createAvailability = /* GraphQL */ `
  mutation CreateAvailability(
    $input: CreateAvailabilityInput!
    $condition: ModelAvailabilityConditionInput
  ) {
    createAvailability(input: $input, condition: $condition) {
      id
      daysAvailable
      maxSessionsPerDay
      minTimeBetweenSessions
      minTimeBeforeBooking
      startDate
      endDate
      isRepeat
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateAvailability = /* GraphQL */ `
  mutation UpdateAvailability(
    $input: UpdateAvailabilityInput!
    $condition: ModelAvailabilityConditionInput
  ) {
    updateAvailability(input: $input, condition: $condition) {
      id
      daysAvailable
      maxSessionsPerDay
      minTimeBetweenSessions
      minTimeBeforeBooking
      startDate
      endDate
      isRepeat
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteAvailability = /* GraphQL */ `
  mutation DeleteAvailability(
    $input: DeleteAvailabilityInput!
    $condition: ModelAvailabilityConditionInput
  ) {
    deleteAvailability(input: $input, condition: $condition) {
      id
      daysAvailable
      maxSessionsPerDay
      minTimeBetweenSessions
      minTimeBeforeBooking
      startDate
      endDate
      isRepeat
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createEquipment = /* GraphQL */ `
  mutation CreateEquipment(
    $input: CreateEquipmentInput!
    $condition: ModelEquipmentConditionInput
  ) {
    createEquipment(input: $input, condition: $condition) {
      id
      name
      optional
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateEquipment = /* GraphQL */ `
  mutation UpdateEquipment(
    $input: UpdateEquipmentInput!
    $condition: ModelEquipmentConditionInput
  ) {
    updateEquipment(input: $input, condition: $condition) {
      id
      name
      optional
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteEquipment = /* GraphQL */ `
  mutation DeleteEquipment(
    $input: DeleteEquipmentInput!
    $condition: ModelEquipmentConditionInput
  ) {
    deleteEquipment(input: $input, condition: $condition) {
      id
      name
      optional
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      name
      address
      directions
      lat
      lng
      isIndoor
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      name
      address
      directions
      lat
      lng
      isIndoor
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      name
      address
      directions
      lat
      lng
      isIndoor
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createMessageRoom = /* GraphQL */ `
  mutation CreateMessageRoom(
    $input: CreateMessageRoomInput!
    $condition: ModelMessageRoomConditionInput
  ) {
    createMessageRoom(input: $input, condition: $condition) {
      id
      member {
        id
        username
        pronouns
        displayName
        profilePhotoUrl
        homeLocation
        homeCity
        homeState
        homeZip
        about
        verified
        virtualVerified
        infoForTrainers
        yearsExperience
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        trainingStyles
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
            __typename
          }
          nextToken
          __typename
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
          __typename
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
            __typename
          }
          nextToken
          __typename
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
            __typename
          }
          nextToken
          __typename
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userStatsId
        __typename
      }
      trainer {
        id
        username
        displayName
        displayRating
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      messages {
        items {
          id
          content
          sender {
            id
            username
            pronouns
            displayName
            profilePhotoUrl
            homeLocation
            homeCity
            homeState
            homeZip
            about
            verified
            virtualVerified
            infoForTrainers
            yearsExperience
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            trainingStyles
            createdAt
            updatedAt
            userStatsId
            __typename
          }
          trainerSender {
            id
            username
            displayName
            displayRating
            createdAt
            updatedAt
            __typename
          }
          read
          createdAt
          updatedAt
          messageRoomMessagesId
          messageSenderId
          messageTrainerSenderId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      messageRoomMemberId
      messageRoomTrainerId
      __typename
    }
  }
`
export const updateMessageRoom = /* GraphQL */ `
  mutation UpdateMessageRoom(
    $input: UpdateMessageRoomInput!
    $condition: ModelMessageRoomConditionInput
  ) {
    updateMessageRoom(input: $input, condition: $condition) {
      id
      member {
        id
        username
        pronouns
        displayName
        profilePhotoUrl
        homeLocation
        homeCity
        homeState
        homeZip
        about
        verified
        virtualVerified
        infoForTrainers
        yearsExperience
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        trainingStyles
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
            __typename
          }
          nextToken
          __typename
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
          __typename
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
            __typename
          }
          nextToken
          __typename
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
            __typename
          }
          nextToken
          __typename
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userStatsId
        __typename
      }
      trainer {
        id
        username
        displayName
        displayRating
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      messages {
        items {
          id
          content
          sender {
            id
            username
            pronouns
            displayName
            profilePhotoUrl
            homeLocation
            homeCity
            homeState
            homeZip
            about
            verified
            virtualVerified
            infoForTrainers
            yearsExperience
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            trainingStyles
            createdAt
            updatedAt
            userStatsId
            __typename
          }
          trainerSender {
            id
            username
            displayName
            displayRating
            createdAt
            updatedAt
            __typename
          }
          read
          createdAt
          updatedAt
          messageRoomMessagesId
          messageSenderId
          messageTrainerSenderId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      messageRoomMemberId
      messageRoomTrainerId
      __typename
    }
  }
`
export const deleteMessageRoom = /* GraphQL */ `
  mutation DeleteMessageRoom(
    $input: DeleteMessageRoomInput!
    $condition: ModelMessageRoomConditionInput
  ) {
    deleteMessageRoom(input: $input, condition: $condition) {
      id
      member {
        id
        username
        pronouns
        displayName
        profilePhotoUrl
        homeLocation
        homeCity
        homeState
        homeZip
        about
        verified
        virtualVerified
        infoForTrainers
        yearsExperience
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        trainingStyles
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
            __typename
          }
          nextToken
          __typename
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
          __typename
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
            __typename
          }
          nextToken
          __typename
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
            __typename
          }
          nextToken
          __typename
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userStatsId
        __typename
      }
      trainer {
        id
        username
        displayName
        displayRating
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      messages {
        items {
          id
          content
          sender {
            id
            username
            pronouns
            displayName
            profilePhotoUrl
            homeLocation
            homeCity
            homeState
            homeZip
            about
            verified
            virtualVerified
            infoForTrainers
            yearsExperience
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            trainingStyles
            createdAt
            updatedAt
            userStatsId
            __typename
          }
          trainerSender {
            id
            username
            displayName
            displayRating
            createdAt
            updatedAt
            __typename
          }
          read
          createdAt
          updatedAt
          messageRoomMessagesId
          messageSenderId
          messageTrainerSenderId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      messageRoomMemberId
      messageRoomTrainerId
      __typename
    }
  }
`
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      content
      sender {
        id
        username
        pronouns
        displayName
        profilePhotoUrl
        homeLocation
        homeCity
        homeState
        homeZip
        about
        verified
        virtualVerified
        infoForTrainers
        yearsExperience
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        trainingStyles
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
            __typename
          }
          nextToken
          __typename
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
          __typename
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
            __typename
          }
          nextToken
          __typename
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
            __typename
          }
          nextToken
          __typename
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userStatsId
        __typename
      }
      trainerSender {
        id
        username
        displayName
        displayRating
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      read
      createdAt
      updatedAt
      messageRoomMessagesId
      messageSenderId
      messageTrainerSenderId
      __typename
    }
  }
`
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      content
      sender {
        id
        username
        pronouns
        displayName
        profilePhotoUrl
        homeLocation
        homeCity
        homeState
        homeZip
        about
        verified
        virtualVerified
        infoForTrainers
        yearsExperience
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        trainingStyles
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
            __typename
          }
          nextToken
          __typename
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
          __typename
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
            __typename
          }
          nextToken
          __typename
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
            __typename
          }
          nextToken
          __typename
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userStatsId
        __typename
      }
      trainerSender {
        id
        username
        displayName
        displayRating
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      read
      createdAt
      updatedAt
      messageRoomMessagesId
      messageSenderId
      messageTrainerSenderId
      __typename
    }
  }
`
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      content
      sender {
        id
        username
        pronouns
        displayName
        profilePhotoUrl
        homeLocation
        homeCity
        homeState
        homeZip
        about
        verified
        virtualVerified
        infoForTrainers
        yearsExperience
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        trainingStyles
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
            __typename
          }
          nextToken
          __typename
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
          __typename
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
            __typename
          }
          nextToken
          __typename
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
            __typename
          }
          nextToken
          __typename
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userStatsId
        __typename
      }
      trainerSender {
        id
        username
        displayName
        displayRating
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      read
      createdAt
      updatedAt
      messageRoomMessagesId
      messageSenderId
      messageTrainerSenderId
      __typename
    }
  }
`
export const createFavoritedSession = /* GraphQL */ `
  mutation CreateFavoritedSession(
    $input: CreateFavoritedSessionInput!
    $condition: ModelFavoritedSessionConditionInput
  ) {
    createFavoritedSession(input: $input, condition: $condition) {
      id
      member {
        id
        username
        pronouns
        displayName
        profilePhotoUrl
        homeLocation
        homeCity
        homeState
        homeZip
        about
        verified
        virtualVerified
        infoForTrainers
        yearsExperience
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        trainingStyles
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
            __typename
          }
          nextToken
          __typename
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
          __typename
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
            __typename
          }
          nextToken
          __typename
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
            __typename
          }
          nextToken
          __typename
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userStatsId
        __typename
      }
      session {
        id
        title
        description
        inPerson
        virtual
        maxTimeAfterStart
        category
        city
        state
        defaultPrice
        indoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
          __typename
        }
        outdoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
          __typename
        }
        commuteToMember
        maxCommuteDistance
        commuteFlatFee
        equipment
        cheerleader
        drillSargeant
        professor
        workoutBuddy
        thrityMinsPrice
        sixtyMinsPrice
        ninetyMinsPrice
        oneTwentyMinsPrice
        mondayStartTime
        mondayEndTime
        tuesdayStartTime
        tuesdayEndTime
        wednesdayStartTime
        wednesdayEndTime
        thursdayStartTime
        thursdayEndTime
        fridayStartTime
        fridayEndTime
        saturdayStartTime
        saturdayEndTime
        sundayStartTime
        sundayEndTime
        coverPhotoIndex
        sessionPhotos
        equipmentList
        instantBookingEnabled
        minTimeBeforeInstantBooking
        paused
        searchable
        trainerGender
        createdAt
        updatedAt
        trainerSessionsId
        sessionIndoorLocationId
        sessionOutdoorLocationId
        __typename
      }
      createdAt
      updatedAt
      userFavoriteSessionsId
      favoritedSessionSessionId
      __typename
    }
  }
`
export const updateFavoritedSession = /* GraphQL */ `
  mutation UpdateFavoritedSession(
    $input: UpdateFavoritedSessionInput!
    $condition: ModelFavoritedSessionConditionInput
  ) {
    updateFavoritedSession(input: $input, condition: $condition) {
      id
      member {
        id
        username
        pronouns
        displayName
        profilePhotoUrl
        homeLocation
        homeCity
        homeState
        homeZip
        about
        verified
        virtualVerified
        infoForTrainers
        yearsExperience
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        trainingStyles
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
            __typename
          }
          nextToken
          __typename
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
          __typename
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
            __typename
          }
          nextToken
          __typename
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
            __typename
          }
          nextToken
          __typename
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userStatsId
        __typename
      }
      session {
        id
        title
        description
        inPerson
        virtual
        maxTimeAfterStart
        category
        city
        state
        defaultPrice
        indoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
          __typename
        }
        outdoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
          __typename
        }
        commuteToMember
        maxCommuteDistance
        commuteFlatFee
        equipment
        cheerleader
        drillSargeant
        professor
        workoutBuddy
        thrityMinsPrice
        sixtyMinsPrice
        ninetyMinsPrice
        oneTwentyMinsPrice
        mondayStartTime
        mondayEndTime
        tuesdayStartTime
        tuesdayEndTime
        wednesdayStartTime
        wednesdayEndTime
        thursdayStartTime
        thursdayEndTime
        fridayStartTime
        fridayEndTime
        saturdayStartTime
        saturdayEndTime
        sundayStartTime
        sundayEndTime
        coverPhotoIndex
        sessionPhotos
        equipmentList
        instantBookingEnabled
        minTimeBeforeInstantBooking
        paused
        searchable
        trainerGender
        createdAt
        updatedAt
        trainerSessionsId
        sessionIndoorLocationId
        sessionOutdoorLocationId
        __typename
      }
      createdAt
      updatedAt
      userFavoriteSessionsId
      favoritedSessionSessionId
      __typename
    }
  }
`
export const deleteFavoritedSession = /* GraphQL */ `
  mutation DeleteFavoritedSession(
    $input: DeleteFavoritedSessionInput!
    $condition: ModelFavoritedSessionConditionInput
  ) {
    deleteFavoritedSession(input: $input, condition: $condition) {
      id
      member {
        id
        username
        pronouns
        displayName
        profilePhotoUrl
        homeLocation
        homeCity
        homeState
        homeZip
        about
        verified
        virtualVerified
        infoForTrainers
        yearsExperience
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        trainingStyles
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
            __typename
          }
          nextToken
          __typename
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
          __typename
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
            __typename
          }
          nextToken
          __typename
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
            __typename
          }
          nextToken
          __typename
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userStatsId
        __typename
      }
      session {
        id
        title
        description
        inPerson
        virtual
        maxTimeAfterStart
        category
        city
        state
        defaultPrice
        indoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
          __typename
        }
        outdoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
          __typename
        }
        commuteToMember
        maxCommuteDistance
        commuteFlatFee
        equipment
        cheerleader
        drillSargeant
        professor
        workoutBuddy
        thrityMinsPrice
        sixtyMinsPrice
        ninetyMinsPrice
        oneTwentyMinsPrice
        mondayStartTime
        mondayEndTime
        tuesdayStartTime
        tuesdayEndTime
        wednesdayStartTime
        wednesdayEndTime
        thursdayStartTime
        thursdayEndTime
        fridayStartTime
        fridayEndTime
        saturdayStartTime
        saturdayEndTime
        sundayStartTime
        sundayEndTime
        coverPhotoIndex
        sessionPhotos
        equipmentList
        instantBookingEnabled
        minTimeBeforeInstantBooking
        paused
        searchable
        trainerGender
        createdAt
        updatedAt
        trainerSessionsId
        sessionIndoorLocationId
        sessionOutdoorLocationId
        __typename
      }
      createdAt
      updatedAt
      userFavoriteSessionsId
      favoritedSessionSessionId
      __typename
    }
  }
`
export const createFavoritedTrainer = /* GraphQL */ `
  mutation CreateFavoritedTrainer(
    $input: CreateFavoritedTrainerInput!
    $condition: ModelFavoritedTrainerConditionInput
  ) {
    createFavoritedTrainer(input: $input, condition: $condition) {
      id
      member {
        id
        username
        pronouns
        displayName
        profilePhotoUrl
        homeLocation
        homeCity
        homeState
        homeZip
        about
        verified
        virtualVerified
        infoForTrainers
        yearsExperience
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        trainingStyles
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
            __typename
          }
          nextToken
          __typename
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
          __typename
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
            __typename
          }
          nextToken
          __typename
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
            __typename
          }
          nextToken
          __typename
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userStatsId
        __typename
      }
      trainer {
        id
        username
        displayName
        displayRating
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userFavoriteTrainersId
      favoritedTrainerTrainerId
      __typename
    }
  }
`
export const updateFavoritedTrainer = /* GraphQL */ `
  mutation UpdateFavoritedTrainer(
    $input: UpdateFavoritedTrainerInput!
    $condition: ModelFavoritedTrainerConditionInput
  ) {
    updateFavoritedTrainer(input: $input, condition: $condition) {
      id
      member {
        id
        username
        pronouns
        displayName
        profilePhotoUrl
        homeLocation
        homeCity
        homeState
        homeZip
        about
        verified
        virtualVerified
        infoForTrainers
        yearsExperience
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        trainingStyles
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
            __typename
          }
          nextToken
          __typename
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
          __typename
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
            __typename
          }
          nextToken
          __typename
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
            __typename
          }
          nextToken
          __typename
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userStatsId
        __typename
      }
      trainer {
        id
        username
        displayName
        displayRating
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userFavoriteTrainersId
      favoritedTrainerTrainerId
      __typename
    }
  }
`
export const deleteFavoritedTrainer = /* GraphQL */ `
  mutation DeleteFavoritedTrainer(
    $input: DeleteFavoritedTrainerInput!
    $condition: ModelFavoritedTrainerConditionInput
  ) {
    deleteFavoritedTrainer(input: $input, condition: $condition) {
      id
      member {
        id
        username
        pronouns
        displayName
        profilePhotoUrl
        homeLocation
        homeCity
        homeState
        homeZip
        about
        verified
        virtualVerified
        infoForTrainers
        yearsExperience
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        trainingStyles
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
            __typename
          }
          nextToken
          __typename
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
          __typename
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
            __typename
          }
          nextToken
          __typename
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
            __typename
          }
          nextToken
          __typename
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userStatsId
        __typename
      }
      trainer {
        id
        username
        displayName
        displayRating
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            paymentStatus
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
            bookingMessageRoomId
            __typename
          }
          nextToken
          __typename
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            equipment
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            mondayStartTime
            mondayEndTime
            tuesdayStartTime
            tuesdayEndTime
            wednesdayStartTime
            wednesdayEndTime
            thursdayStartTime
            thursdayEndTime
            fridayStartTime
            fridayEndTime
            saturdayStartTime
            saturdayEndTime
            sundayStartTime
            sundayEndTime
            coverPhotoIndex
            sessionPhotos
            equipmentList
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            trainerGender
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userFavoriteTrainersId
      favoritedTrainerTrainerId
      __typename
    }
  }
`

export const createEvent = /* GraphQL */ `
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      id
      type
      userId
      createdAt
    }
  }
`
