import { Grid, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'
import SubTitleA from '../text/SubTitleA'
import TitleC from '../text/TitleC'

const AboutMember = () => {
  const about = useSelector((state) => state.forms.userProfileModal.about)
  const isTrainerOnboard = useSelector((state) => state.ui.isTrainerOnboard)
  const infoForTrainers = useSelector(
    (state) => state.forms.userProfileModal.infoForTrainers
  )
  const dispatch = useDispatch()
  const handleChangeAbout = (e) => {
    dispatch(
      setUserProfileModalField({ field: 'about', value: e.target.value })
    )
  }
  const handleChangeInfoForTrainers = (e) => {
    dispatch(
      setUserProfileModalField({
        field: 'infoForTrainers',
        value: e.target.value,
      })
    )
  }
  return (
    <>
      <Grid item xs={12}>
        <TitleC text="What would you like us to know about you?" />
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
        <SubTitleA
          text={
            isTrainerOnboard
              ? 'Let your future clients know a bit more about you'
              : 'Let your trainers know a bit more about you'
          }
        />
        <TextField
          sx={{
            height: 229,
            '& fieldset': {
              borderRadius: '20px',
              height: 229,
            },
          }}
          defaultValue={about}
          onChange={handleChangeAbout}
          multiline
          fullWidth
          placeholder="Only if you want to, no pressure..."
        />
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
        <SubTitleA
          text={
            isTrainerOnboard
              ? 'What do you love about training'
              : 'What would you like your Trainer to know about you?'
          }
        />
        <TextField
          sx={{
            height: 229,
            '& fieldset': {
              borderRadius: '20px',
              height: 229,
            },
          }}
          defaultValue={infoForTrainers}
          onChange={handleChangeInfoForTrainers}
          multiline
          fullWidth
          placeholder={
            isTrainerOnboard
              ? 'Let your trainees know what to expect from your training sessions...'
              : 'Write your fitness goals and anything else you want your Trainer to know... like maybe you hate jumping jacks, etc.'
          }
        />
      </Grid>
    </>
  )
}

export default AboutMember
