import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import TitleC from '../text/TitleC'
import SubTitleA from '../text/SubTitleA'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import HeaderWorkoutTypeSelect from '../header/HeaderWorkoutTypeSelect'
import { ReactComponent as LocationIcon } from '../../assets/icons/location_pin.svg'
import { useState } from 'react'
import { CheckCircle, SaveOutlined } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCreateSessionField,
  setUserProfileModalField,
} from '../../redux/slices/formsSlice'
import InfoText from '../text/InfoText'
import theme from '../../styles/theme'
import state_abbr from '../../constants/states_abbr'

const SessionIntro = () => {
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  }
  const dispatch = useDispatch()
  const [editing, setEditing] = useState(false)
  const [location, setLocation] = useState('home')
  const sessionForm = useSelector(
    (state) => state.forms.userProfileModal.homeLocation
  )

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleChangeSessionFormField = (e, field) => {
    console.log('changed: ', e.target.value)
    // Check Regex for city, ST
    dispatch(setCreateSessionField({ field, value: e.target.value }))
  }

  const sessionFormFields = useSelector(
    (state) => state.forms.createSessionForm
  )

  const [selectedCategory, setSelectedCategory] = useState('Category')

  const [typesSelected, setTypesSelected] = useState([])
  const [workoutTypes, setWorkoutTypes] = useState([
    { id: 0, parentTrainingId: null, trainingType: 'Adventure Trainings' },
    { id: 1, parentTrainingId: 0, trainingType: 'Hiking' },
    { id: 2, parentTrainingId: 0, trainingType: 'Rock Climbing' },
    { id: 3, parentTrainingId: 0, trainingType: 'Mountain Biking' },
    { id: 4, parentTrainingId: null, trainingType: 'Yoga and Pilates' },
    { id: 5, parentTrainingId: 4, trainingType: 'Vinyasa Yoga' },
    { id: 6, parentTrainingId: 4, trainingType: 'Power Yoga' },
    { id: 7, parentTrainingId: 4, trainingType: 'Iyengar Yoga' },
    { id: 8, parentTrainingId: 4, trainingType: 'Pilates Mat' },
    { id: 9, parentTrainingId: 4, trainingType: 'Athletic Stretching' },
    { id: 10, parentTrainingId: null, trainingType: 'Sports Coaching' },
    { id: 11, parentTrainingId: 10, trainingType: 'Running' },
    { id: 12, parentTrainingId: 10, trainingType: 'Road Cycling' },
    { id: 13, parentTrainingId: 10, trainingType: 'Marathon/Triathalon' },
    { id: 14, parentTrainingId: 10, trainingType: 'Tennis' },
    { id: 15, parentTrainingId: 10, trainingType: 'Golf' },
    { id: 16, parentTrainingId: 10, trainingType: 'Swimming' },
    { id: 17, parentTrainingId: null, trainingType: 'Strength Training' },
    { id: 18, parentTrainingId: 17, trainingType: 'Resistance Training' },
    { id: 19, parentTrainingId: 17, trainingType: 'Weight Training' },
    { id: 20, parentTrainingId: 17, trainingType: 'CrossFit' },
    { id: 21, parentTrainingId: 17, trainingType: 'Sports Performance' },
    { id: 22, parentTrainingId: 17, trainingType: 'Core Strengthening' },
    {
      id: 23,
      parentTrainingId: 17,
      trainingType: 'Calisthenics/Body Weight Training',
    },
  ])
  const [parents, setParents] = useState(
    workoutTypes?.filter((workoutType) => workoutType.parentTrainingId == null)
  )

  const handleClickCategory = (type) => {
    console.log('selected: ', type)
    setSelectedCategory(type)
    dispatch(setCreateSessionField({ field: 'category', value: type }))
    handleClose()
  }
  const workoutCategoriesSelect = () => {
    return (
      <Box
        fullWidth
        sx={{
          height: 314,
          border: 1,
          borderColor: 'gunMetal.main',
          borderRadius: '30px',
          padding: '10px',
          paddingBottom: 2,
        }}
      >
        <Box
          sx={{
            borderRadius: '30px',
            height: 314,
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              marginRight: '10px',
              maxWidth: '10px',
              maxHeight: '10px',
            },
            '&::-webkit-scrollbar-track': {
              marginRight: '10px',
              maxWidth: '1px',
              hmaxHeight: '10px',
              //'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '::-webkit-scrollbar-track-piece': {
              maxHeight: '10px',
              right: '30px',
            },
            '&::-webkit-scrollbar-thumb': {
              maxHeight: '10px',
              maxWidth: '1px',
              borderRadius: '30px',
              backgroundColor: 'rgba(0,0,0,.1)',
            },
          }}
        >
          {parents.map((parent) => {
            return (
              <Box
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => {
                  const result = parents.map((type) => {
                    if (type.id == parent.id) {
                      return { ...type, active: true }
                    }
                    return { ...type, active: false }
                  })
                  setParents(result)
                }}
              >
                <Grid
                  container
                  style={{
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <Grid item>
                    <SubTitleA
                      text={parent.trainingType}
                      onClick={() => {
                        console.log(parent.id)
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <ChevronRightIcon />
                  </Grid>
                </Grid>

                {parent.active &&
                  workoutTypes.map((child) => {
                    if (child.parentTrainingId === parent.id) {
                      return (
                        <Grid
                          onClick={() => {
                            handleClickCategory(child.trainingType)
                          }}
                        >
                          <InfoText
                            color={
                              child.selected
                                ? theme.palette.primary.dark
                                : 'gunMetal.main'
                            }
                            text={child.trainingType}
                          />
                        </Grid>
                      )
                    }
                  })}
              </Box>
            )
          })}
        </Box>
      </Box>
    )
  }
  return (
    <Grid container>
      <TitleC text="Session Details" />
      <Grid container justifyContent="space-between">
        <Grid item xs={5}>
          <SubTitleA text="Session Title" />
        </Grid>
        <Grid item xs={5}>
          <SubTitleA text="Session Category" />
        </Grid>
        <Grid item xs={5}>
          <TextField
            sx={{ width: '100%' }}
            fullWidth
            onChange={(e) => handleChangeSessionFormField(e, 'title')}
            label="Session Title"
            variant="outlined"
            defaultValue={sessionFormFields.title}
          />{' '}
        </Grid>
        <Grid item xs={5}>
          <Chip
            label={sessionFormFields.category}
            onClick={handleClick}
            aria-describedby={id}
          />

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            PaperProps={{
              sx: {
                borderRadius: '30px',
              },
            }}
          >
            {workoutCategoriesSelect()}
          </Popover>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <SubTitleA text="Session Tagline" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            sx={{ width: '100%' }}
            fullWidth
            onChange={(e) => handleChangeSessionFormField(e, 'tagline')}
            label="Session Tagline"
            variant="outlined"
            defaultValue={sessionFormFields.tagline}
          />{' '}
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item xs={6}>
          <SubTitleA text="Session City" />
        </Grid>
        <Grid item xs={4}>
          <SubTitleA text="Session State" />
        </Grid>
        <Grid item xs={6}>
          <TextField
            sx={{ width: '100%' }}
            fullWidth
            onChange={(e) => handleChangeSessionFormField(e, 'city')}
            label="City"
            variant="outlined"
            defaultValue={sessionFormFields.city}
          />{' '}
        </Grid>
        <Grid item xs={4}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={sessionFormFields.state}
            label="State"
            MenuProps={MenuProps}
            onChange={(e) => handleChangeSessionFormField(e, 'state')}
          >
            {state_abbr.map((state) => {
              return <MenuItem value={state.abbr}>{state.abbr}</MenuItem>
            })}
          </Select>
          {/* <TextField
            sx={{ width: '100%' }}
            fullWidth
            onChange={(e) => handleChangeSessionFormField(e, 'state')}
            label="ST"
            variant="outlined"
            defaultValue={sessionFormFields.state}
          />{' '} */}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <SubTitleA text="Session Description" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            rows={4}
            sx={{ width: '100%' }}
            fullWidth
            onChange={(e) => handleChangeSessionFormField(e, 'description')}
            label="Session Description"
            variant="outlined"
            defaultValue={sessionFormFields.description}
          />{' '}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SessionIntro
