import * as React from 'react'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import {
  Grid,
  Typography,
  Divider,
  Chip,
  LinearProgress,
  useMediaQuery,
  Tabs,
} from '@mui/material'
import SessionCard from '../session_card/SessionCard'
import TrainerAvailabilityModal from './TrainerAvailabilityModal'
import { useDispatch, useSelector } from 'react-redux'
import {
  setTrainerAvailabilityModalVisible,
  setViewTrainerId,
} from '../../redux/slices/uiSlice'
import theme from '../../styles/theme'

import {
  useGetSessionsQuery,
  useGetTrainingQuery,
} from '../../redux/api/api'
import { setIsTrainer } from '../../redux/slices/authSlice'
import { useEffect } from 'react'
import { listSessions } from '../../graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'
import { setCreateBookingField } from '../../redux/slices/formsSlice'
import { useParams } from 'react-router-dom'
import categories from '../../constants/categories'
import FilterBar from '../filters/FilterBar'
import { setApiFilters, setTrainerDisplayName } from '../../redux/slices/filtersSlice'
import mixpanel from 'mixpanel-browser'

export default function BrowseSessions(props) {
  const { category } = useParams()
  const { size, favoritesPage } = props
  const large = useMediaQuery(theme.breakpoints.up('sm'))
  const userId = useSelector((state) => state.auth.cognitoUser?.sub)
  const dispatch = useDispatch()
  const apiFilters = useSelector((state) => state.filters.apiFilters)
  const trainerDisplayName = useSelector(
    (state) => state.filters.trainerDisplayName
  )

  const defaultFilter = {
    //...apiFilters,
    category: { eq: category },
    sessionPhotos: { size: { ge: 1 } },
    trainerDisplayName: null,
  }

  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch: refetchSessions,
  } = useGetSessionsQuery(
    //TODO ON SESSION WE NEED TO ADD CURRENT TRAINER's
    //more filters: commuteToMember = true, indoorLocation, outdoorLocation, virtual=true, sixtyMinsPrice = lte, sixtyMinsPrice = gte, city = city, cheerleader>0, drillSargeant>0, professor>0, workoutBuddy>0
    // TODO need to add trainer gender to session

    apiFilters
      ? { ...apiFilters, sessionPhotos: { size: { ge: 1 } } }
      : { ...defaultFilter },

    {
      keepUnusedDataFor: 100000,
      // // skip: !userId,
      // pollingInterval: 100000,
    }
  )


  useEffect(() => {
    refetchSessions()
    dispatch(setApiFilters(null))
  }, [category])
  useEffect(() => {
    dispatch(setTrainerDisplayName(null))
  }, [])

  const handleOpenTrainerAvailability = (session) => {
    dispatch(setTrainerAvailabilityModalVisible(true))
    dispatch(setViewTrainerId(session?.trainerSessionsId))
    dispatch(
      setCreateBookingField({
        field: 'trainerBookingsId',
        value: session?.trainerSessionsId,
      })
    )
    dispatch(setCreateBookingField({ field: 'userBookingsId', value: userId }))
    dispatch(
      setCreateBookingField({
        field: 'bookingSessionId',
        value: session?.id,
      })
    )
    console.log('handleOpenTrainerAvailability: Session: ', session)
  }

  return (
    <Grid
      container
      sx={{
        justifyContent: 'flex-start',
      }}
    >
      {category && (
        <Grid container>
          {/* <Grid item xs={12}>
            <Typography variant="h5" sx={{ p: 1 }}>
              {category}
            </Typography>
          </Grid> */}
          <Grid
            item
            xs={12}
            onClick={() => console.log('SESSIONS DATA: ', error)}
          >
            <Typography
              variant="h5"
              sx={{
                p: 1,
                textAlign: 'left',
                fontSize: '24px',
                color: 'gunMetal.main',
                fontWeight: 'regular',
              }}
            >
              <span style={{ fontWeight: 700 }}>{category}</span>:{' '}
              {
                categories.filter((cat) => {
                  return cat.name === category
                })[0].description
              }
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid container style={{ justifyContent: 'left' }} sx={{ m: 2 }}>
        <FilterBar favoritesPage={favoritesPage} />
      </Grid>
      {data?.length === 0 && (
        <Box
          sx={{
            width: '100%',
            p: 1,
            backgroundColor: 'primary.main',
            borderRadius: 2,
          }}
        >
          <Grid container xs={12} sx={{ bgcolor: 'white', borderRadius: 1 }}>
            <Typography
              variant="h5"
              sx={{ p: 1, fontWeight: 700, color: 'gunMetal.main' }}
            >
              Oops! It seems the session you're seeking isn't available just
              yet, but we're thrilled you're interested! Fear not, as we're busy
              gathering a team of expert trainers to deliver top-notch live
              personal training experiences just for you. Try adjusting your
              filters or search through our existing experts to schedule
              something ASAP.
            </Typography>
          </Grid>
        </Box>
      )}
      {large &&
        data?.map(
          (session, index) =>
            (!trainerDisplayName ||
              (trainerDisplayName &&
                session?.trainer?.displayName
                  .toLowerCase()
                  .includes(trainerDisplayName.toLowerCase()))) && (
              <Grid key={session.id} item md={4} lg={3} sx={{ p: 1 }}>
                <SessionCard
                  session={session}
                  onClick={() =>
                    //handleOpenTrainerAvailability(session?.trainer?.user?.userId)
                    //console.log('clicked', session)
                    handleOpenTrainerAvailability(session)
                  }
                />
              </Grid>
            )
        )}
      {!large && (
        <Tabs
          value={0}
          onChange={() => console.log('handleChangeCoachIndex')}
          variant="scrollable"
          scrollButtons
          aria-label="scrollable force tabs example"
          sx={{
            backgroundColor: 'white',
            color: '#7C7C7C',
            width: '100%',
          }}
        >
          {' '}
          {}
          {data?.map(
            (session) =>
              (!trainerDisplayName ||
                (trainerDisplayName &&
                  session?.trainer?.displayName
                    .toLowerCase()
                    .includes(trainerDisplayName.toLowerCase()))) && (
                <Grid container key={session.id} sx={{ p: 1 }}>
                  <SessionCard
                    session={session}
                    onClick={() => {
                      mixpanel.track(`Clicked Session ${session.id}`, {
                        'Event Type': 'View Session',
                      })
                      mixpanel.track_pageview()
                      //handleOpenTrainerAvailability(session?.trainer?.user?.userId)
                      //console.log('clicked', session)
                      handleOpenTrainerAvailability(session)
                    }}
                  />
                </Grid>
              )
          )}
        </Tabs>
      )}

      <TrainerAvailabilityModal />
    </Grid>
  )
}
