import { Grid, Typography } from '@mui/material'
import CertificationRow from './CertificationRow'
import { ReactComponent as IdentityVerified } from '../../assets/icons/identity_verified.svg'
import { useParams } from 'react-router-dom'
import { useGetTrainerByIdQuery } from '../../redux/api/api'

const CertificationsConfirmed = () => {
  const { trainerId } = useParams()
  const { data: trainer } = useGetTrainerByIdQuery(trainerId, {
    skip: !trainerId,
  })
  const certificationRows = [
    { name: 'ID Verified', confirmed: trainer?.confirmedID },
    { name: 'CPR Certified', confirmed: trainer?.confirmedCPR },
    { name: 'Criminal Background Verified', confirmed: trainer?.crimVerified },
    { name: 'Certifications Verified', confirmed: trainer?.certsVerified },
  ]

  return (
    <Grid container sx={{ pt: 4 }}>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '36px',
            letterSpacing: '-0.02em',

            color: '#000000',
          }}
        >
          Certifications confirmed:
        </Typography>
      </Grid>
      {certificationRows.map((cert) => (
        <CertificationRow certification={cert} />
      ))}
    </Grid>
  )
}
export default CertificationsConfirmed
