import { Button, Grid, Typography, styled } from '@mui/material'

const Stat = (props) => {
  const { title, value } = props
  const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: '20px',
    width: 144,
    height: 72,
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '15px',
    padding: '0 8x',
    margin: '8px 16px 8px 0px',
    '&:disabled': {
      backgroundColor: theme.palette.gunMetal.main,
      color: 'white',
    },
  }))
  return (
    <Grid item>
      <Typography>{title}</Typography>
      <StyledButton variant="contained" disabled>
        {value}
      </StyledButton>
    </Grid>
  )
}

export default Stat
