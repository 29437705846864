import { Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useGetTrainerByIdQuery } from '../../redux/api/api'
import { useDispatch, useSelector } from 'react-redux'
import { BorderColor } from '@mui/icons-material'
import {
  setGeneralModalComponent,
  setGeneralModalOpen,
  setIsTrainerOnboard,
} from '../../redux/slices/uiSlice'
import mixpanel from 'mixpanel-browser'

const FitnessGoalsRow = ({ editing, ...other }) => {
  const dispatch = useDispatch()
  const { trainerId } = useParams()
  const { data: trainer } = useGetTrainerByIdQuery(trainerId, {
    skip: !trainerId,
  })

  const handleEditFitnessGoalsClick = async () => {
    console.log('edit training styles')
    dispatch(setIsTrainerOnboard(true))
    dispatch(setGeneralModalComponent('fitness_goals'))
    dispatch(setGeneralModalOpen(true))
  }
  const currentUserId = useSelector((state) => state.auth.cognitoUser?.sub)

  return (
    <Grid container sx={{ pt: 4 }}>
      <Grid item xs={12}>
        <Grid
          container
          sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
        >
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: '24px',
              lineHeight: '36px',
              letterSpacing: '-0.02em',
              mr: 2,
              color: '#000000',
            }}
          >
            Areas of Expertise
          </Typography>
          {currentUserId === trainerId && (
            <Tooltip title="Edit Areas of Expertise">
              <IconButton onClick={handleEditFitnessGoalsClick}>
                <BorderColor color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          justifyContent: 'flex-start',
          gap: 1,
          pt: 2,
          alignItems: 'flex-start',
        }}
      >
        {trainer?.fitnessGoals?.map((goal) => (
          <Grid item xs={12} key={goal}>
            <Typography>{goal}</Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
export default FitnessGoalsRow
