import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import PersonIcon from '@mui/icons-material/Person'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import { blue } from '@mui/material/colors'
import { DialogContent, Grid, IconButton } from '@mui/material'
import { ArrowBackIos, Close } from '@mui/icons-material'
import UpcomingSessionPreview from '../session_card/UpcomingSessionPreview'

export default function PreSessionBookingModal(props) {
  const { onClose, selectedValue, open, trainerRequested } = props
  const [cancelConfirm, setCancelConfirm] = React.useState(false)
  const handleCancelClick = () => {
    setCancelConfirm(true)
  }
  const handleBackClick = () => {
    setCancelConfirm(false)
  }
  const handleConfirmCancelClick = () => {
    setCancelConfirm(false)
    onClose()
  }
  return (
    <Dialog onClose={onClose} open={open}>
      {!cancelConfirm && (
        <>
          <DialogTitle sx={{ paddingBottom: 0, m: 0 }}>
            <Grid container justifyContent="space-between">
              <Grid item></Grid>
              <Grid item>
                {' '}
                <IconButton onClick={onClose}>
                  {' '}
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid container sx={{ pb: '20px' }}>
                {' '}
                <Typography sx={{ fontWeight: 700, fontSize: '26px' }}>
                  {' '}
                  Reschedule {!trainerRequested ? ' or Cancel ' : ''} Request
                </Typography>
              </Grid>
              {trainerRequested && (
                <>
                  <Grid container sx={{ pb: '20px' }}>
                    {' '}
                    <Typography>
                      {' '}
                      Tim O has asked to reschedule your workout
                    </Typography>
                  </Grid>
                  <Grid container sx={{ pb: '20px' }}>
                    {' '}
                    <Typography>
                      {' '}
                      <strong>Reason: </strong> Last Minute Appointment
                    </Typography>
                  </Grid>
                </>
              )}

              <Grid container sx={{ pb: '20px' }}>
                <UpcomingSessionPreview colored workout={selectedValue} />
              </Grid>
              <Grid container justifyContent="space-around">
                <Grid item>
                  {' '}
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleCancelClick}
                  >
                    {' '}
                    Cancel Workout
                  </Button>{' '}
                </Grid>
                {trainerRequested && (
                  <Grid item>
                    {' '}
                    <Button variant="outlined" color="primary">
                      {' '}
                      Return to Search
                    </Button>{' '}
                  </Grid>
                )}

                <Grid item>
                  {' '}
                  <Button variant="outlined" color="primary">
                    {' '}
                    {trainerRequested ? 'Reschedule Now' : 'Ask to Reschedule'}
                  </Button>{' '}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )}
      {cancelConfirm && (
        <>
          <DialogTitle sx={{ paddingBottom: 0, m: 0 }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                {' '}
                <IconButton onClick={handleBackClick}>
                  {' '}
                  <ArrowBackIos />
                </IconButton>
              </Grid>
              <Grid item>
                {' '}
                <IconButton onClick={onClose}>
                  {' '}
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid container sx={{ pb: '20px' }}>
                {' '}
                <Typography sx={{ fontWeight: 700, fontSize: '26px' }}>
                  {' '}
                  Cancel your session?
                </Typography>
              </Grid>
              <Grid container sx={{ pb: '60px' }}>
                {' '}
                <Typography>
                  {' '}
                  Are you sure you want to cancel your appointment with{' '}
                  {selectedValue?.trainer?.username}?
                </Typography>
              </Grid>
              <Grid container justifyContent="space-around">
                <Grid item>
                  {' '}
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleBackClick}
                  >
                    {' '}
                    Back
                  </Button>{' '}
                </Grid>
                <Grid item>
                  {' '}
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleConfirmCancelClick}
                  >
                    {' '}
                    Cancel Session
                  </Button>{' '}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}
