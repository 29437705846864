import { Typography } from "@mui/material"

export default function TitleC(props) {
  const { text } = props
  return (
    <>
      <Typography
        align="center"
        sx={{ color: 'primary.main', fontWeight: 700, fontSize: 26 }}
      >
        {text}
      </Typography>
    </>
  )
}