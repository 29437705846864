import * as React from 'react'
import PropTypes from 'prop-types'

import { Typography, Box, Tabs, Tab, Grid } from '@mui/material'
import ChatPreview from '../messages/ChatPreview'
import UpcomingSessionPreview from '../session_card/UpcomingSessionPreview'

export default function DashboardOverview() {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Grid container justifyContent="left">
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: '26px',
          color: 'gunMetal.main',
          lineHeight: '39px',
          pb: 2,
        }}
      >
        Profile
      </Typography>
      <Grid
        container
        justifyContent="left"
        sx={{ bgcolor: 'white', p: 4, mb: 2, borderRadius: '16px' }}
      >
        <Grid container justifyContent="left">
          <Typography
            sx={{
              fontWeight: '700',
              fontSize: '17px',
              lineHeight: '25.5px',
              pb: 1,
              color: 'gunMetal.main',
            }}
          >
            1 Incomplete document
          </Typography>
        </Grid>
        <Grid container justifyContent="left" sx={{ pt: 1 }}>
          <Box sx={{ border: 1, borderRadius: 3, p: 1 }}>
            <Typography sx={{ color: 'gunMetal.main' }}>
              Background Check
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: 26,
          lineHeight: '39px',
          pb: 2,
          color: 'gunMetal.main',
        }}
      >
        Messages
      </Typography>
      <Grid
        container
        justifyContent="left"
        sx={{ bgcolor: 'white', p: 4, borderRadius: '16px' }}
      >
        <Grid container justifyContent="left">
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: 17,
              lineHeight: '25.5px',
              color: 'gunMetal.main',
            }}
          >
            1 New Message
          </Typography>
        </Grid>
        <Grid container justifyContent="left" sx={{ pt: 2 }}>
          <Grid item>
            <ChatPreview outlined />
          </Grid>
        </Grid>
      </Grid>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: 26,
          lineHeight: '39px',
          pb: 2,
          pt: 2,
          color: 'gunMetal.main',
        }}
      >
        Workout Sessions & Tasks
      </Typography>
      <Grid
        container
        justifyContent="left"
        sx={{ bgcolor: 'white', p: 4, borderRadius: '16px' }}
      >
        <Grid container justifyContent="left">
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: 17,
              lineHeight: '25.5px',
              pb: 1,
              color: 'gunMetal.main',
            }}
          >
            1 upcoming session
          </Typography>
        </Grid>
        <Grid container justifyContent="left">
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 17,
              lineHeight: '25.5px',
              pb: 1,
              color: 'gunMetal.main',
            }}
          >
            Tomorrow
          </Typography>
        </Grid>
        <Grid container justifyContent="left">
          <UpcomingSessionPreview />
        </Grid>
        <Grid container justifyContent="left" sx={{ pt: 1, pb: 1 }}>
          <Typography>3 upcoming tasks</Typography>
        </Grid>
        <Grid container justifyContent="left" spacing={2}>
          <Grid item>
            <Box
              sx={{
                border: 1,
                borderRadius: '16px',
                p: 2,
                borderColor: 'gunMetal.main',
              }}
            >
              <Typography>1 Session to reschedule</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                border: 1,
                borderRadius: '16px',
                p: 2,
                borderColor: 'gunMetal.main',
              }}
            >
              <Typography>1 post-session review</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                border: 1,
                borderRadius: '16px',
                p: 2,
                borderColor: 'gunMetal.main',
              }}
            >
              <Typography>1 in progress booking payment</Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
