import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { ReactComponent as CertifiedBadge } from '../../assets/icons/CertifiedBadge.svg'

import S3Image from '../S3Image'
import TrainingStyles from './TrainingStyles'
import CertificationsConfirmed from './CertificationsConfirmed'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetMemberByIdQuery,
  useGetTrainerByIdQuery,
} from '../../redux/api/api'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import { useDispatch, useSelector } from 'react-redux'
import FitnessGoalsRow from './FitnessGoalsRow'
import {
  setGeneralModalComponent,
  setGeneralModalOpen,
} from '../../redux/slices/uiSlice'
import {
  ArrowForwardIosOutlined,
  CameraAltOutlined,
  InfoOutlined,
} from '@mui/icons-material'
import axios from 'axios'

const TrainerOverView = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { trainerId } = useParams()
  const { data: trainer } = useGetTrainerByIdQuery(trainerId, {
    skip: !trainerId,
  })
  const { data: user } = useGetMemberByIdQuery(trainerId, {
    skip: !trainerId,
  })
  const userEmail = useSelector((state) => state.auth?.cognitoUser?.email)
  const currentUserId = useSelector((state) => state.auth.cognitoUser?.sub)
  const handleClickProfilePictureEdit = () => {
    console.log('profile picture edit clicked: ', user.profilePhotoUrl)
    dispatch(setGeneralModalComponent('photo_upload'))
    dispatch(setGeneralModalOpen(true))
  }
  const handleClickGoToVerification = () => {
    navigate(`/verify-trainer`)
  }
  async function generateTruaLink() {
    try {
      const link = await axios.post(
        'https://6vax2q3yum73q2nan4j2nxs2su0qorjn.lambda-url.us-east-2.on.aws/',
        {
          crim: true,
          email: userEmail,
        }
      )
      console.log('link: ', link)
      // navigate to link
      const w = window.open('', '_blank')
      const url = link?.data
      w.location = url
    } catch (error) {
      console.log('error resending code:', error)
    }
  }

  return (
    <Grid item xs={12} sm={6} md={4} sx={{ p: 2 }}>
      <Box
        sx={{
          border: 2,
          borderColor: 'black',
          borderRadius: '12px',

          width: '100%',
        }}
      >
        <Grid container sx={{ p: 4, position: 'relative' }}>
          <Grid container>
            {user?.profilePhotoUrl && (
              <Box
                sx={{
                  position: 'absolute',
                  left: '50%',
                  top: '10.06%',
                  bottom: '88.49%',
                  paddingLeft: '50px',
                }}
                onClick={() => console.log('certified badge clicked', trainer)}
              >
                <CertifiedBadge />
              </Box>
            )}
            {currentUserId === trainerId && (
              <Tooltip title="Edit Profile Photo">
                <Box
                  sx={{
                    position: 'absolute',

                    top: '32px',
                    bottom: '88.49%',
                    height: '140px',
                    width: '140px',
                    borderRadius: '50%',
                    opacity: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: 'gunMetal.main',
                      opacity: 0.8,
                      display: 'flex',
                    },
                  }}
                  onClick={handleClickProfilePictureEdit}
                >
                  <BorderColorIcon color="primary" />
                </Box>
              </Tooltip>
            )}

            {user?.profilePhotoUrl && (
              <S3Image
                imagePath={`${trainerId}/${user?.profilePhotoUrl}`}
                dimensions={{
                  height: '140px',
                  width: '140px',
                  borderRadius: '50%',
                }}
              />
            )}
            {!user?.profilePhotoUrl && (
              <div
                style={{
                  height: '140px',
                  width: '140px',
                  borderRadius: '50%',
                  backgroundColor: 'lightgray',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CameraAltOutlined />
              </div>
            )}
          </Grid>
          <TrainingStyles />
          <FitnessGoalsRow />
          <CertificationsConfirmed />
          {/* {!user?.verified && <CertificationsConfirmed />} */}
          {trainer?.confirmedID &&
            trainer?.crimVerified &&
            trainer?.confirmedCPR &&
            trainer?.certsVerified && (
              <Grid container>
                <Grid container sx={{ pt: 2 }}>
                  <InfoOutlined
                    color="success"
                    sx={{ mr: 1 }}
                    onClick={() => console.log('user: ', user)}
                  />
                  <Typography
                    color="success"
                    sx={{
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      fontSize: '18px',
                      lineHeight: '36px',
                      letterSpacing: '-0.02em',
                    }}
                  >
                    Trainer is Verified!
                  </Typography>
                  {currentUserId === trainer?.id && (
                    <Tooltip title="Go To Profile Verification" sx={{ ml: 1 }}>
                      <IconButton
                        onClick={handleClickGoToVerification}
                        color="success"
                      >
                        <ArrowForwardIosOutlined color="sucess" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid container sx={{ justifyContent: 'left', pt: 2 }}>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                      fontSeight: 500,
                      fontSize: '16px',
                    }}
                  >
                    <a
                      href="https://www.gettraind.net/privacy"
                      target="black"
                      rel="no_ref"
                      style={{
                        color: 'black',
                        fontWeight: 700,
                      }}
                    >
                      Learn more
                    </a>{' '}
                    about how confirming users helps keep GetTraind community
                    safe and secure
                  </Typography>
                </Grid>
              </Grid>
            )}
          {trainer?.confirmedID && !trainer?.crimVerified && (
            <Grid container>
              <Grid container sx={{ pt: 2 }}>
                <InfoOutlined
                  color="info"
                  sx={{ mr: 1 }}
                  onClick={() => console.log('user: ', user)}
                />
                <Typography
                  color="info"
                  sx={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '18px',
                    lineHeight: '36px',
                    letterSpacing: '-0.02em',
                  }}
                >
                  Trainer is Virtual Only
                </Typography>
                {currentUserId === trainer?.id && (
                  <Tooltip title="Go To Profile Verification" sx={{ ml: 1 }}>
                    <IconButton
                      onClick={handleClickGoToVerification}
                      color="info"
                    >
                      <ArrowForwardIosOutlined color="info" />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
              <Grid container sx={{ justifyContent: 'left', pt: 2 }}>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontSeight: 500,
                    fontSize: '16px',
                  }}
                >
                  <a
                    href="https://www.gettraind.net/privacy"
                    target="black"
                    rel="no_ref"
                    style={{
                      color: 'black',
                      fontWeight: 700,
                    }}
                  >
                    Learn more
                  </a>{' '}
                  about how confirming users helps keep GetTraind community safe
                  and secure
                </Typography>
              </Grid>
            </Grid>
          )}
          {!trainer?.confirmedID && (
            <Grid container>
              <Grid container sx={{ pt: 2 }}>
                <InfoOutlined color="error" sx={{ mr: 1 }} />
                <Typography
                  color="error"
                  sx={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '18px',
                    lineHeight: '36px',
                    letterSpacing: '-0.02em',
                    cursor:'pointer',
                    textDecoration:'underline'
                  }}
                  onClick={generateTruaLink}
                >
                  Trainer Verification is Pending
                </Typography>
                {currentUserId === trainer?.id && (
                  <Tooltip title="Go To Profile Verification" sx={{ ml: 1 }}>
                    <IconButton
                      onClick={generateTruaLink}
                      color="error"
                    >
                      <ArrowForwardIosOutlined color="error" />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
              <Grid container sx={{ justifyContent: 'left', pt: 2 }}>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontSeight: 500,
                    fontSize: '16px',
                  }}
                >
                  <a
                    href="https://www.gettraind.net/privacy"
                    target="black"
                    rel="no_ref"
                    style={{
                      color: 'black',
                      fontWeight: 700,
                    }}
                  >
                    Learn more
                  </a>{' '}
                  about how confirming users helps keep GetTraind community safe
                  and secure
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  )
}

export default TrainerOverView
