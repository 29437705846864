import { createSlice } from '@reduxjs/toolkit'

/*
title: String!
	inPerson: Boolean
	virtual: Boolean
	instantBooking: Boolean
	instantBookingMinimum: Int
	description: String
	photos: [String]
	coverPhotoIndex: Int
	trainingType: String
	defaultPrice: Int
	indoorLocation: LocationInput!
	outdoorLocation: LocationInput!
	commute: CommuteInput
	equipment: [EquipmentInput]
	trainingStyle: TrainingStyleInput
	pricing: [PricingInput]
	isPaused: Boolean
	isSearchable: Boolean
	availability: AvailabilityInput
*/

const today = new Date()

const formsSlice = createSlice({
  name: 'forms',
  initialState: {
    userProfileModal: {
      initialLoad: true,
      id: '',
      username: '',
      pronouns: '',
      profilePhotoUrl: '',
      homeLocation: '',
      homeCity: '',
      homeState: '',
      homeZip: '',
      about: '',
      infoForTrainers: '',
      fitnessGoals: [],
      workoutPreferences: [],
      trainingTypes: ['in person', 'virtual'],
      trainingTimePreferences: [],
      trainingDayPreferences: [],
      userStatsId: '',
      gender: '',
      profilePicture: 'https://via.placeholder.com/150',
      uploadedImage: null,
      trainingStyles: [],
      address: ['', '', '', '', ''],
      hasCrime: false,
      descriptionCrime: ''
    },
    createBookingTime: {
      month: today.getMonth() + 1,
      date: today.getDate(),
      year: today.getFullYear(),
      time: '8',
      amPm: 'AM',
      zone: 'PST',
      duration: '60',
    },
    createBookingCommute: {},
    createBookingForm: {
      status: 'pending',
      price: 0,
      startTime: 'start-time',
      endTime: 'end-time',
      userBookingsId: '',
      trainerBookingsId: '',
      bookingSessionId: '',
      bookingSelectedLocationId: '',
    },
    bookingReviewForm: {
      id: '',
      sessionMaxHR: 0,
      sessionAvgHR: 0,
      sessionCalories: 0,
      trainerNotes: '',
      trainerSuggestions: '',
    },
    statsForm: {
      id: '',
      age: 0,
      height: '',
      weight: 0,
      bmi: 0.0,
      fatPercentage: 0.0,
      stepsPerDay: 0,
      calsPerDay: 0,
      stairsPerDay: 0,
      milesPerWeekRunning: 0,
      milesPerWeekCycle: 0,
      goalStepsPerDay: 0,
      goalCalsPerDay: 0,
      goalStairsPerDay: 0,
      goalMilesPerWeekRunning: 0,
      goalMilesPerWeekCycle: 0,
    },
    createSessionForm: {
      mondayStartTime: '',
      mondayEndTime: '',
      tuesdayStartTime: '',
      tuesdayEndTime: '',
      wednesdayStartTime: '',
      wednesdayEndTime: '',
      thursdayStartTime: '',
      thursdayEndTime: '',
      fridayStartTime: '',
      fridayEndTime: '',
      saturdayStartTime: '',
      saturdayEndTime: '',
      sundayStartTime: '',
      sundayEndTime: '',
      title: '',
      description: '',
      inPerson: false,
      virtual: false,
      maxTimeAfterStart: 0,
      category: '',
      city: '',
      state: '',
      defaultPrice: 0,
      commuteToMember: false,
      maxCommuteDistance: 0,
      commuteFlatFee: 0,
      cheerleader: 0,
      drillSargeant: 0,
      professor: 0,
      workoutBuddy: 0,
      //Update this for typo in appsync schema
      thrityMinsPrice: 0,
      thirtyMinsPrice: 0,
      sixtyMinsPrice: 0,
      ninetyMinsPrice: 0,
      oneTwentyMinsPrice: 0,
      coverPhotoIndex: 0,
      sessionPhotos: [],
      instantBookingEnabled: false,
      minTimeBeforeInstantBooking: 24,
      indoorLocation: {
        name: '',
        address: '',
        isIndoor: true,
        directions: '',
        lng: -118.323414,
        lat: 34.100493,
        // city: '',
        // state: '',
        // zip: '',
        // lat: 0,
        // lng: 0,
        enabled: false,
      },
      outdoorLocation: {
        name: '',
        address: '',
        isIndoor: false,
        directions: '',
        lng: -118.323414,
        lat: 34.100493,
        enabled: false,
        // city: '',
        // state: '',
        // zip: '',
        // lat: 0,
        // lng: 0,
      },
      commute: {
        commuteEnabled: false,
        maxDistance: 0,
        flatFee: 0,
      },
      equipment: [],
      paused: false,
      searchable: false,
      availability: {
        //durationsEnabled: [],
        maxSessionsPerDay: 0,
        minTimeBetweenSessions: 0,
        minTimeBeforeBooking: 0,
        isRepeat: true,
        startDate: 'default start',
        endDate: 'default end',
        daysAvailable: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      },
    },
  },
  reducers: {
    setUploadedImage: (state, action) => {
      if (action.payload?.session) {
        state.createSessionForm.sessionPhotos[
          state.createSessionForm.sessionPhotos.length
        ] = action.payload.data
      } else {
        state.userProfileModal.uploadedImage = action.payload
      }
    },
    setUserSessionPicture: (state, action) => {
      state.createSessionForm.sessionPhotos[
        state.createSessionForm.sessionPhotos.length - 1
      ] = action.payload
    },
    setUserProfilePicture: (state, action) => {
      state.userProfileModal.profilePicture = action.payload
    },
    setUserProfileModalField: (state, action) => {
      state.userProfileModal[action.payload.field] = action.payload.value
    },
    setUserStatsField: (state, action) => {
      state.statsForm[action.payload.field] = action.payload.value
    },
    setInitialProfileModalState: (state, action) => {
      state.userProfileModal = { ...action.payload, initialLoad: false }
    },
    setCreateSessionField: (state, action) => {
      state.createSessionForm[action.payload.field] = action.payload.value
    },
    setBatchSessionFields: (state, action) => {
      //console.log('action.payload', action.payload.equipment.items)
      const copy = { ...action.payload }
      copy.equipment = action.payload.equipment.items

      state.createSessionForm = copy
    },
    setUserStatsForm: (state, action) => {
      state.statsForm = action.payload
    },
    setCreateBookingField: (state, action) => {
      state.createBookingForm[action.payload.field] = action.payload.value
    },
    setCreateBookingCommute: (state, action) => {
      state.createBookingCommute = action.payload
    },
    setCreateBookingCommuteField: (state, action) => {
      state.createBookingCommute[action.payload.field] = action.payload.value
    },
    setCreateBookingTime: (state, action) => {
      state.createBookingTime[action.payload.field] = action.payload.value
    },
    setBookingReviewField: (state, action) => {
      state.bookingReviewForm[action.payload.field] = action.payload.value
    },
    setSessionCommuteField: (state, action) => {
      state.createSessionForm.commute[action.payload.field] =
        action.payload.value
    },
    setSessionAvailabilityField: (state, action) => {
      state.createSessionForm.availability[action.payload.field] =
        action.payload.value
    },
    setSessionIndoorLocationField: (state, action) => {
      state.createSessionForm.indoorLocation[action.payload.field] =
        action.payload.value
    },
    setSessionOutdoorLocationField: (state, action) => {
      state.createSessionForm.outdoorLocation[action.payload.field] =
        action.payload.value
    },
  },
})

export const {
  setSessionCommuteField,
  setSessionAvailabilityField,
  setSessionIndoorLocationField,
  setSessionOutdoorLocationField,
  setUploadedImage,
  setUserSessionPicture,
  setUserProfilePicture,
  setInitialProfileModalState,
  setUserProfileModalField,
  setCreateSessionField,
  setCreateBookingField,
  setCreateBookingTime,
  setBatchSessionFields,
  setUserStatsField,
  setUserStatsForm,
  setBookingReviewField,
  setCreateBookingCommuteField,
  setCreateBookingCommute,
} = formsSlice.actions
export default formsSlice.reducer
