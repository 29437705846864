import { Button } from '@mui/material'
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg'

const AddInfoIconButton = (props) => {
  const { text, onClick } = props
  return (
    <Button
      variant="outlined"
      startIcon={<Plus />}
      style={{ color: 'gunMetal.main' }}
      onClick={onClick}
    >
      {text}
    </Button>
  )
}
export default AddInfoIconButton
