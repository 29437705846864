import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import PersonIcon from '@mui/icons-material/Person'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import { blue } from '@mui/material/colors'
import { DialogContent, Grid, IconButton, TextField } from '@mui/material'
import { ArrowBackIos, Close, Star } from '@mui/icons-material'
import UpcomingSessionPreview from '../session_card/UpcomingSessionPreview'
import TextSwitch from '../buttons/TextSwitch'

export default function PostSessionReviewModal(props) {
  const { onClose, open } = props
  const [reportConfirm, setReportConfirm] = React.useState(false)
  const [stars, setStars] = React.useState(0)
  const handleCancelClick = () => {
    onClose()
  }
  const handleBackClick = () => {
    setReportConfirm(false)
  }
  const handleConfirmReportClick = () => {
    setReportConfirm(false)
    onClose()
  }
  const handleStarClick = (index) => {
    setStars(index)
  }
  const handleReportClick = (index) => {
    setReportConfirm(true)
  }
  const handleSubmitClick = (index) => {
    onClose()
  }

  return (
    <Dialog onClose={onClose} open={open}>
      {!reportConfirm && (
        <>
          <DialogTitle>
            <Grid container justifyContent="space-between">
              <Grid item></Grid>
              <Grid item>
                {' '}
                <IconButton onClick={onClose}>
                  {' '}
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid container>
                {' '}
                <Typography> Post-Session Trainer Evaluation</Typography>
              </Grid>
              <Grid container>
                <UpcomingSessionPreview colored />
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Typography>Your rating for Tim O</Typography>
                </Grid>
                <Grid item xs={12}>
                  {[0, 1, 2, 3, 4].map((star, index) => (
                    <IconButton onClick={() => handleStarClick(index)}>
                      <Star color={stars >= index ? 'primary' : 'inherit'} />
                    </IconButton>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Typography>Why have you given this rating</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    label=""
                    multiline
                    rows={4}
                    placeholder="Your Review"
                  />
                </Grid>
                <Grid container justifyContent="space-between">
                  <Grid item></Grid>
                  <Grid item>
                    <Grid container>
                      <Typography>Report Tim?</Typography>
                      <TextSwitch
                        defaultState={true}
                        textA="NO"
                        textB="YES"
                        oneOption
                        onSelect={handleReportClick}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-around">
                <Grid item>
                  {' '}
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleCancelClick}
                  >
                    {' '}
                    Cancel
                  </Button>{' '}
                </Grid>

                <Grid item>
                  {' '}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitClick}
                  >
                    {' '}
                    Submit
                  </Button>{' '}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )}
      {reportConfirm && (
        <>
          <DialogTitle>
            <Grid container justifyContent="space-between">
              <Grid item>
                {' '}
                <IconButton onClick={handleBackClick}>
                  {' '}
                  <ArrowBackIos />
                </IconButton>
              </Grid>
              <Grid item>
                {' '}
                <IconButton onClick={onClose}>
                  {' '}
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid container>
                {' '}
                <Typography> Are you sure you want to report Tim?</Typography>
              </Grid>
              <Grid container>
                {' '}
                <Typography>
                  {' '}
                  Please provide more information on the reason for your report
                </Typography>
              </Grid>
              <Grid container>
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label=""
                  multiline
                  rows={4}
                  placeholder="Reason for Report"
                />
              </Grid>
              <Grid container justifyContent="space-around">
                <Grid item>
                  {' '}
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleBackClick}
                  >
                    {' '}
                    Cancel
                  </Button>{' '}
                </Grid>
                <Grid item>
                  {' '}
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleConfirmReportClick}
                  >
                    {' '}
                    Report
                  </Button>{' '}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}
