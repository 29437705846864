import { Box, Typography } from '@mui/material'

const TrainingStyleCard = (props) => {
  const { trainingStyle } = props

  return (
    <Box
      onClick={() => {
        console.log('clicked', trainingStyle)
      }}
      sx={{
        width: '196px',
        height: '277px',
        borderRadius: '12px',
        backgroundImage: `url(/${trainingStyle.image})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        cursor: 'pointer',
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '75px',
          borderRadius: '0px 0px 12px 12px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontStyle: ' normal',
            fontWeight: ' 500',
            fontSize: ' 14px',
            lineHeight: ' 21px',
            /* identical to box height */

            textAlign: ' center',

            color: 'white',
          }}
        >
          {trainingStyle.title}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontStyle: ' normal',
            fontWeight: ' 500',
            fontSize: ' 14px',
            lineHeight: ' 21px',
            /* identical to box height */

            textAlign: ' center',

            color: 'white',
          }}
        >
          {trainingStyle.subtitle}
        </Typography>
      </Box>
    </Box>
  )
}

export default TrainingStyleCard
