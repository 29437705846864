import * as React from 'react'
import dayjs from 'dayjs'
import Grid from '@mui/material/Grid'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker'
import { MonthPicker } from '@mui/x-date-pickers/MonthPicker'
import { YearPicker } from '@mui/x-date-pickers/YearPicker'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { Box, Button, IconButton, Popover, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Circle, ExpandMore, RadioButtonUnchecked } from '@mui/icons-material'
import { setHeaderSearchData } from '../../redux/slices/uiSlice'
import { useDispatch } from 'react-redux'
import { setCreateBookingTime } from '../../redux/slices/formsSlice'

const minDate = dayjs('2020-01-01T00:00:00.000')
const maxDate = dayjs('2034-01-01T00:00:00.000')

export default function CalendarHeaderSearch() {
  const dispatch = useDispatch()
  const StyledDatePicker = styled(StaticDatePicker)({
    '& .MuiPickersCalendarHeader-root ': {
      display: 'none',
    },
    '& .MuiPickersDay-root ': {
      fontWeight: 'bold',
    },
    '& .MuiPickersDay-today ': {
      textEmphasisStyle: 'circle',
      textEmphasisPosition: 'under',
      border: 'none',
      fontWeight: 'bold',
    },
    '& .Mui-disabled ': {
      textDecoration: 'line-through',
      fontWeight: 'bold',
    },
    '& .MuiDayPicker-weekDayLabel ': {
      fontWeight: 'bold',
    },
  })
  // TODO: SET INITIAL DATE TO CURRENT DATE FROM HEADER SEARCH
  const [date, setDate] = React.useState(dayjs())
  const [monthOpen, setMonthOpen] = React.useState(false)
  const [yearOpen, setYearOpen] = React.useState(false)
  const [monthEl, setMonthEl] = React.useState(null)
  const [yearEl, setYearEl] = React.useState(null)
  const [workoutTime, setWorkoutTime] = React.useState(null)
  const clickMonth = (e) => {
    setMonthOpen(true)
    setMonthEl(e.currentTarget)
    console.log(date.month())
  }
  const clickYear = (e) => {
    setYearOpen(true)
    setYearEl(e.currentTarget)
    console.log(date.year())
  }
  const handleChangeMonth = (newDate) => {
    setDate(newDate)
    setMonthOpen(false)
  }
  const handleChangeYear = (newDate) => {
    setDate(newDate)
    setYearOpen(false)
  }

  const isDisabled = (date) => {
    const day = date.day()

    return day === 0 || day === 6
  }
  const handleSelectDate = (value) => {
    setDate(value)
    console.log('date: ', value.format('DD/MM/YY'))
    dispatch(
      setHeaderSearchData({ field: 'date', value: value.format('MM/DD/YY') })
    )
    // dispatch to redux to prefill session booking time
    dispatch(setCreateBookingTime({ field: 'date', value: value.format('DD') }))
    dispatch(
      setCreateBookingTime({ field: 'month', value: value.format('MM') })
    )
    dispatch(
      setCreateBookingTime({ field: 'year', value: value.format('YYYY') })
    )
  }
  const handleSelectTime = (value) => {
    setWorkoutTime(value)

    dispatch(setHeaderSearchData({ field: 'time', value }))
  }

  const calendarView = (
    <Grid item xs={12} md={6}>
      <Grid container sx={{ justifyContent: 'space-around' }}>
        <Grid item>
          <Button
            variant="contained"
            sx={{ bgcolor: 'gunMetal.main', color: 'white' }}
            onClick={clickMonth}
          >
            {date.format('MMMM')} <ExpandMore sx={{ pl: 1 }} />
          </Button>

          <Popover
            PaperProps={{ sx: { borderRadius: '30px' } }}
            open={monthOpen}
            anchorEl={monthEl}
            sx={{ width: '250px', top: '40px' }}
          >
            <MonthPicker
              sx={{ width: '150px', height: '300px' }}
              disablePast
              date={date}
              minDate={minDate}
              maxDate={maxDate}
              onChange={handleChangeMonth}
            />
          </Popover>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{ bgcolor: 'gunMetal.main', color: 'white' }}
            onClick={clickYear}
          >
            {date.format('YYYY')} <ExpandMore sx={{ pl: 1 }} />
          </Button>

          <Popover
            PaperProps={{ sx: { borderRadius: '30px' } }}
            open={yearOpen}
            anchorEl={yearEl}
            sx={{ width: '250px', top: '40px' }}
          >
            <YearPicker
              sx={{ width: '150px', height: '300px' }}
              disablePast
              date={date}
              minDate={minDate}
              maxDate={maxDate}
              onChange={handleChangeYear}
            />
          </Popover>
        </Grid>
      </Grid>
      <Grid container>
        <StyledDatePicker
          shouldDisableDate={isDisabled}
          disablePast
          displayStaticWrapperAs="desktop"
          value={date}
          views={['day']}
          onChange={(newValue) => {
            handleSelectDate(newValue)
          }}
          componentProps={{
            switchViewButton: { sx: { display: 'none' } },
          }}
        />
      </Grid>
    </Grid>
  )
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid
        container
        sx={{
          border: 1,
          borderColor: 'primary.main',
          borderRadius: 4,
          p: 2,
          justifyContent: 'space-around',
          alignItems: 'flex-start',
        }}
      >
        {calendarView}
        <Grid item xs={12} md={3}>
          <Grid container>
            <Grid container justifyContent="flex-start">
              {' '}
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '22.5px',
                  color: 'gunMetal.main',
                  pb: 2,
                  pt: 2,
                }}
              >
                What time do you want to work out?
              </Typography>
            </Grid>
            {/* <Grid
              container
              justifyContent="flex-start"
              sx={{ pt: 2, cursor: 'pointer' }}
              onClick={() => handleSelectTime('saved time')}
            >
              {workoutTime === 'saved time' ? (
                <Circle color="primary" />
              ) : (
                <RadioButtonUnchecked color="primary" />
              )}

              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '13px',
                  lineHeight: '19.5px',
                  color: 'gunMetal.main',
                  pl: 1,
                }}
              >
                {' '}
                Use saved times from profile
              </Typography>
            </Grid> */}
            <Grid
              container
              justifyContent="flex-start"
              sx={{ pt: 2, cursor: 'pointer' }}
              onClick={() => handleSelectTime('Morning')}
            >
              {workoutTime === 'Morning' ? (
                <Circle color="primary" />
              ) : (
                <RadioButtonUnchecked color="primary" />
              )}

              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '13px',
                  lineHeight: '19.5px',
                  color: 'gunMetal.main',
                  pl: 1,
                }}
              >
                {' '}
                Morning
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="flex-start"
              sx={{ pt: 2, cursor: 'pointer' }}
              onClick={() => handleSelectTime('Afternoon')}
            >
              {workoutTime === 'Afternoon' ? (
                <Circle color="primary" />
              ) : (
                <RadioButtonUnchecked color="primary" />
              )}
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '13px',
                  lineHeight: '19.5px',
                  color: 'gunMetal.main',
                  pl: 1,
                }}
              >
                {' '}
                Afternoon
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="flex-start"
              sx={{ pt: 2, cursor: 'pointer' }}
              onClick={() => handleSelectTime('Evening')}
            >
              {workoutTime === 'Evening' ? (
                <Circle color="primary" />
              ) : (
                <RadioButtonUnchecked color="primary" />
              )}

              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '13px',
                  lineHeight: '19.5px',
                  color: 'gunMetal.main',
                  pl: 1,
                }}
              >
                Evening
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}
