import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'

import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard.svg'
import { ReactComponent as MessagesIcon } from '../../assets/icons/messages.svg'
import { ReactComponent as WorkoutIcon } from '../../assets/icons/workout.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile.svg'
import { ReactComponent as LikedTrainersIcon } from '../../assets/icons/likedtrainers.svg'
import { ReactComponent as LineChartIcon } from '../../assets/icons/line_chart.svg'
import { ReactComponent as SessionCardIcon } from '../../assets/icons/session_card.svg'

import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import { Collapse, Grid, ListSubheader, Tooltip } from '@mui/material'
import {
  AccountCircleOutlined,
  AccountCircleRounded,
  ArrowLeft,
  AssessmentOutlined,
  Bookmarks,
  CalendarMonthOutlined,
  DraftsOutlined,
  ExpandLess,
  ExpandMore,
  FavoriteBorderOutlined,
  FitnessCenterOutlined,
  FitnessCenterRounded,
  HelpCenterOutlined,
  MenuOpenOutlined,
  MessageSharp,
  PermContactCalendarOutlined,
  PieChart,
  SendOutlined,
  SettingsOutlined,
  StarBorder,
} from '@mui/icons-material'
import DashboardPage from './DashboardPage'
import DashboardFavoritesPage from './DashboardFavoritesPage'
import DashboardWorkoutsPage from './DashboardWorkoutsPage'
import DashboardMessagesPage from './DashboardMessagesPage'
import DashboardCalendarPage from './DashboardCalendarPage'
import DashboardTrainerBookings from './DashboardTrainerBookings'
import Menu from '@mui/icons-material/Menu'
import { useSelector } from 'react-redux'
import TrainerSessionCardsPage from './TrainerSessionCardsPage'

import Profile from '../profile/Profile'
import MemberSettings from '../member_settings/MemberSettings'

const drawerWidth = 240
const gunMetal = '#2B303E'
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
)

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

export default function MemberDashboard() {
  const theme = useTheme()
  const [open, setOpen] = React.useState(true)
  const [page, setPage] = React.useState('Dashboard')
  const isTrainer = useSelector((state) => state.auth.isTrainer)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }
  const [expanded, setExpanded] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  const [overviewExpanded, setOverviewExpanded] = React.useState(false)
  const [verificationExpanded, setVerificationExpanded] = React.useState(false)
  const [bankingExpanded, setBankingExpanded] = React.useState(false)
  const [paymentsExpanded, setPaymentsExpanded] = React.useState(false)

  const handleExpandClick = (index) => {
    var copy = [...expanded]
    copy[index] = !copy[index]
    setExpanded(copy)
  }

  const dashInfo = [
    {
      name: 'Overview',
      sublist: [
        'Profile - Info Needed',
        'Messages Inbox',
        'Workout Sessions & Tasks',
      ],
      onClick: () => setOverviewExpanded(!overviewExpanded),
      bool: overviewExpanded,
    },
    {
      name: 'Member Verification',
      sublist: ['Verify'],
      onClick: () => setVerificationExpanded(!verificationExpanded),
      bool: verificationExpanded,
    },
    {
      name: 'Banking',
      sublist: ['Payment Information'],
      onClick: () => setBankingExpanded(!bankingExpanded),
      bool: bankingExpanded,
    },
    {
      name: 'Payment History',
      sublist: ['Payments'],
      onClick: () => setPaymentsExpanded(!paymentsExpanded),
      bool: paymentsExpanded,
    },
  ]

  const navInfo = [
    {
      name: 'Dashboard',
      icon: true ? (
        <DashboardIcon
          stroke={page === 'Dashboard' ? '#00C7C7' : gunMetal}
          style={{ width: '24px' }}
        />
      ) : (
        <PieChart
          sx={{ color: page === 'Dashboard' ? 'primary.main' : 'inherit' }}
        />
      ),
      children: dashInfo,
    },
    {
      name: 'Workouts',
      icon: true ? (
        <WorkoutIcon
          stroke={page === 'Workouts' ? '#00C7C7' : gunMetal}
          style={{ width: '24px' }}
        />
      ) : (
        <FitnessCenterRounded
          sx={{ color: page === 'Workouts' ? 'primary.main' : 'inherit' }}
        />
      ),
    },
    //{ name: 'Bookings', icon: <MailIcon /> },
    //{ name: 'Session Cards', icon: <MailIcon /> },
    {
      name: 'Messages',
      icon: true ? (
        <MessagesIcon
          stroke={page === 'Messages' ? '#00C7C7' : gunMetal}
          style={{ width: '24px' }}
        />
      ) : (
        <SendOutlined
          sx={{ color: page === 'Messages' ? 'primary.main' : 'inherit' }}
        />
      ),
    },
    {
      name: 'Calendar',
      icon: true ? (
        <CalendarIcon
          stroke={page === 'Calendar' ? '#00C7C7' : gunMetal}
          style={{ width: '24px' }}
        />
      ) : (
        <CalendarMonthOutlined
          sx={{ color: page === 'Calendar' ? 'primary.main' : 'inherit' }}
        />
      ),
    },
    {
      name: 'Profile',
      icon: true ? (
        <ProfileIcon
          stroke={page === 'Profile' ? '#00C7C7' : gunMetal}
          style={{ width: '24px' }}
        />
      ) : (
        <AccountCircleRounded
          sx={{ color: page === 'Profile' ? 'primary.main' : 'inherit' }}
        />
      ),
    },
    //{ name: 'Performance', icon: <InboxIcon /> },
    {
      name: 'Favorites',
      icon: true ? (
        <LikedTrainersIcon
          stroke={page === 'Favorites' ? '#00C7C7' : gunMetal}
          style={{ width: '24px' }}
        />
      ) : (
        <FavoriteBorderOutlined
          sx={{ color: page === 'Favorites' ? 'primary.main' : 'inherit' }}
        />
      ),
    },
    {
      name: 'Settings',
      icon: (
        <SettingsOutlined
          sx={{ color: page === 'Settings' ? 'primary.main' : 'inherit' }}
        />
      ),
    },
    {
      name: 'Help',
      icon: (
        <HelpCenterOutlined
          sx={{ color: page === 'Help' ? 'primary.main' : 'inherit' }}
        />
      ),
    },
  ]

  const trainerNavInfo = [
    {
      name: 'Dashboard',
      icon: true ? (
        <DashboardIcon
          stroke={page === 'Dashboard' ? '#00C7C7' : gunMetal}
          style={{ width: '24px' }}
        />
      ) : (
        <PieChart
          sx={{ color: page === 'Dashboard' ? 'primary.main' : 'inherit' }}
        />
      ),
      children: dashInfo,
    },
    {
      name: 'Bookings',
      icon: (
        <PermContactCalendarOutlined
          sx={{ color: page === 'Bookings' ? 'primary.main' : 'inherit' }}
        />
      ),
    },
    {
      name: 'Session Cards',
      icon: true ? (
        <SessionCardIcon
          stroke={page === 'Session Cards' ? '#00C7C7' : gunMetal}
          fill={page === 'Session Cards' ? '#00C7C7' : gunMetal}
          style={{ width: '24px' }}
        />
      ) : (
        <Bookmarks
          sx={{ color: page === 'Session Cards' ? 'primary.main' : 'inherit' }}
        />
      ),
    },
    //{ name: 'Bookings', icon: <MailIcon /> },
    //{ name: 'Session Cards', icon: <MailIcon /> },
    {
      name: 'Messages',
      icon: true ? (
        <MessagesIcon
          stroke={page === 'Messages' ? '#00C7C7' : gunMetal}
          style={{ width: '24px' }}
        />
      ) : (
        <SendOutlined
          sx={{ color: page === 'Messages' ? 'primary.main' : 'inherit' }}
        />
      ),
    },
    {
      name: 'Calendar',
      icon: true ? (
        <CalendarIcon
          stroke={page === 'Calendar' ? '#00C7C7' : gunMetal}
          style={{ width: '24px' }}
        />
      ) : (
        <CalendarMonthOutlined
          sx={{ color: page === 'Calendar' ? 'primary.main' : 'inherit' }}
        />
      ),
    },
    {
      name: 'Profile',
      icon: true ? (
        <ProfileIcon
          stroke={page === 'Profile' ? '#00C7C7' : gunMetal}
          style={{ width: '24px' }}
        />
      ) : (
        <AccountCircleRounded
          sx={{ color: page === 'Profile' ? 'primary.main' : 'inherit' }}
        />
      ),
    },
    //{ name: 'Performance', icon: <InboxIcon /> },
    {
      name: 'Performance',
      icon: true ? (
        <LineChartIcon
          stroke={page === 'Performance' ? '#00C7C7' : gunMetal}
          style={{ width: '24px' }}
        />
      ) : (
        <AssessmentOutlined
          sx={{ color: page === 'Performance' ? 'primary.main' : 'inherit' }}
        />
      ),
    },

    {
      name: 'Settings',
      icon: (
        <SettingsOutlined
          sx={{ color: page === 'Settings' ? 'primary.main' : 'inherit' }}
        />
      ),
    },
    {
      name: 'Help',
      icon: (
        <HelpCenterOutlined
          sx={{ color: page === 'Help' ? 'primary.main' : 'inherit' }}
        />
      ),
    },
  ]

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const renderCurrentPage = () => {
    switch (page) {
      case 'Dashboard':
        return <DashboardPage />
      case 'Favorites':
        return <DashboardFavoritesPage />
      case 'Workouts':
        return <DashboardWorkoutsPage />
      case 'Messages':
        return <DashboardMessagesPage />
      case 'Calendar':
        return <DashboardCalendarPage />
      case 'Profile':
        return <Profile drawer />
      case 'Session Cards':
        return <TrainerSessionCardsPage />
      case 'Bookings':
        return <DashboardTrainerBookings />
      case 'Settings':
        return <MemberSettings drawer />

      default:
        return <Typography>{page} page</Typography>
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'start',
      }}
    >
      {/* {!open && (
        <Box sx={{ pl: 2, pt: 2, pr: 2 }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ textAlign: 'center' }}
          >
            <Tooltip title="Open">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  borderRadius: '50%',
                  boxShadow: 3,
                  backgroundColor: 'white',
                }}
              >
                <Menu color="primary" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Box>
      )}

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Box sx={{ height: 75, width: 2, backgroundColor: 'red' }}></Box>

        <List>
          {(isTrainer ? trainerNavInfo : navInfo).map((nav, index) => (
            <React.Fragment key={index}>
              <ListItem key={nav.name} disablePadding>
                <ListItemButton onClick={() => handleChangePage(nav.name)}>
                  <ListItemIcon>{nav.icon}</ListItemIcon>
                  <ListItemText
                    sx={{
                      color: page === nav.name ? 'primary.main' : 'inherit',
                    }}
                    primary={nav.name}
                  />
                  {nav.children && (
                    <IconButton onClick={() => handleExpandClick(index)}>
                      {expanded[index] ? (
                        <ChevronLeftIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}
                    </IconButton>
                  )}
                </ListItemButton>
              </ListItem>
              {nav.children && (
                <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {nav.children.map((child, index) => (
                      <React.Fragment key={index}>
                        <ListItemButton key={index} sx={{ pl: 4 }}>
                          <ListItemText primary={child.name} />
                          {child.sublist && (
                            <IconButton onClick={child.onClick}>
                              {child.bool ? (
                                <ChevronLeftIcon />
                              ) : (
                                <ChevronRightIcon />
                              )}
                            </IconButton>
                          )}
                        </ListItemButton>
                        <Collapse in={child.bool} timeout="auto" unmountOnExit>
                          {child.sublist.map((sub, index) => (
                            <ListItemButton key={index} sx={{ pl: 4 }}>
                              <ListItemText primary={sub} />
                            </ListItemButton>
                          ))}
                        </Collapse>
                      </React.Fragment>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
        <Divider />

        <Box
          sx={{
            paddingTop: 11.5,

            position: 'fixed',
            left: drawerWidth - 25,
          }}
        >
          <Grid container sx={{}}>
            <Grid item>
              <IconButton
                onClick={handleDrawerClose}
                sx={{ bgcolor: 'white', borderRadius: '50%', boxShadow: 2 }}
              >
                <ChevronLeftIcon color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Drawer>

      <Main open={open}>
        <Box sx={{ width: '100%' }}>{renderCurrentPage()}</Box>
      </Main> */}
    </Box>
  )
}
