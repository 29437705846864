import { Button, Grid, LinearProgress, Paper } from '@mui/material'
import TitleB from '../../text/TitleB'
import SearchBar from './SearchBar'
import EntryCard from './EntryCard'
import SubTitleB from '../../text/SubTitleB'
import Stat from './Stat'
import Notes from './Notes'
import {
  useGetBookingByIdQuery,
  useGetMemberByIdQuery,
} from '../../../redux/api/api'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import formatFromDB, { isPast } from '../../../helpers/datetime'

const WorkoutHistory = (props) => {
  const [selectedWorkout, setSelectedWorkout] = useState(null)
  const { viewMemberId, memberName } = props
  const cognitoUser = useSelector((state) => state.auth.cognitoUser)
  const cognitoUserId = useSelector((state) => state.auth.cognitoUser?.sub)
  const { data, isLoading, isSuccess } = useGetMemberByIdQuery(cognitoUserId, {
    skip: !cognitoUserId,
    keepUnusedData: true,
  })

  const { data: viewMemberData, isSuccess: viewMemberIsSuccess } =
    useGetMemberByIdQuery(viewMemberId, {
      skip: viewMemberId === undefined || viewMemberId === null,
      keepUnusedData: true,
    })

  const selectedBookingDetails = useGetBookingByIdQuery(selectedWorkout?.id, {
    skip: !selectedWorkout?.id,
    keepUnusedDataFor: 10000000,
  })

  const handleClickWorkout = (workout) => {
    console.log('workout: ', workout)
    setSelectedWorkout(workout)
  }

  if (isLoading) {
    return <LinearProgress />
  }

  if (viewMemberId && viewMemberIsSuccess && viewMemberData) {
    return (
      <Grid container sx={{ p: '32px' }}>
        <Grid
          container
          style={{ width: '100%', justifyContent: 'space-between' }}
        >
          <Grid item>
            <TitleB text={`${viewMemberData.firstName}'s Workout History`} />
          </Grid>
          <Grid item>
            <SubTitleB text=" Jun 12th 2021, 11:00 AM PDT" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar />
        </Grid>
        <Button onClick={() => console.log('test: ', viewMemberData)}>
          try
        </Button>
        {viewMemberData?.workoutHistory?.map((entry) => {
          return (
            <Grid item xs={12}>
              <EntryCard />
            </Grid>
          )
        })}
      </Grid>
    )
  }

  if (data && isSuccess) {
    return (
      <Paper
        style={{
          minheight: 620,
          borderRadius: 16,
        }}
      >
        <Grid
          container
          style={{
            padding: '32px 64px',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Grid item onClick={() => console.log(data?.bookings?.items[0])}>
            <TitleB text={`${memberName}'s Workout History`} />

            <SearchBar />
            {data?.bookings?.items
              ?.filter((booking) => isPast(booking.startTime))
              .map((booking) => {
                return (
                  <Grid item xs={12}>
                    <EntryCard
                      active={selectedWorkout?.id === booking.id}
                      booking={booking}
                      onClick={handleClickWorkout}
                    />
                  </Grid>
                )
              })}
          </Grid>

          {selectedWorkout && (
            <Grid item>
              <Grid container style={{ justifyContent: 'space-between' }}>
                <Grid item>
                  <TitleB text="Session Details" />
                </Grid>
                <Grid item>
                  <SubTitleB
                    text={formatFromDB(
                      selectedWorkout.startTime,
                      'MMM Do, YYYY h:mm A'
                    )}
                  />
                </Grid>
              </Grid>
              <TitleB text="Exercises" />

              {selectedBookingDetails?.data?.excersizes?.items.map(
                (excersize) => (
                  <Grid container style={{ justifyContent: 'space-around' }}>
                    <Grid
                      container
                      sx={{ justifyContent: 'flex-start', pt: 1, pb: 1 }}
                    >
                      {' '}
                      <SubTitleB text={excersize.name} />
                    </Grid>
                    <Stat title="Duration" history value={excersize.duration} />
                    <Stat title="Weight" history value={excersize.weight} />
                    <Stat title="Reps" history value={excersize.reps} />
                  </Grid>
                )
              )}
              <TitleB text="Session Stats" />

              <Grid container>
                <Grid item>
                  <Stat
                    title="Max heart rate"
                    history
                    value={selectedWorkout.sessionMaxHR}
                  />
                </Grid>
                <Grid item>
                  <Stat
                    title="Avg heart rate"
                    history
                    value={selectedWorkout.sessionAvgHR}
                  />
                </Grid>
                <Grid item>
                  <Stat
                    history
                    value={selectedWorkout.sessionCalories}
                    title="Calories burned"
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <TitleB text="Trainer’s Notes" />
                </Grid>
                <Grid item xs={12}>
                  <Notes content={selectedWorkout.trainerNotes} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <TitleB text="Trainer’s Suggestions" />
                </Grid>
                <Grid item xs={12}>
                  <Notes content={selectedWorkout.trainerSuggestions} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    )
  } else {
    return <TitleB text={`SOMETHING WENT WRONG`} />
  }
}

export default WorkoutHistory
