import { useDispatch, useSelector } from 'react-redux'
import { useGetMemberByIdQuery } from '../../../redux/api/api'
import { useEffect } from 'react'
import { setInitialProfileModalState } from '../../../redux/slices/formsSlice'
import {
  Grid,
  LinearProgress,
  Switch,
  Typography,
  useMediaQuery,
} from '@mui/material'
import EditIconButtonBorder from '../../buttons/EditIconButtonBorder'
import {
  setEditMode,
  setGeneralModalComponent,
  setGeneralModalOpen,
} from '../../../redux/slices/uiSlice'
import MemberGeneralInfo from './MemberGeneralInfo'
import MemberOtherInfo from './MemberOtherInfo'
import { useParams } from 'react-router-dom'
import theme from '../../../styles/theme'

const MemberProfile = (props) => {
  const large = useMediaQuery(theme.breakpoints.up('sm'))
  const { viewMemberId } = useParams()
  const dispatch = useDispatch()
  const editMode = useSelector((state) => state.ui.editMode)
  const profileFormData = useSelector((state) => state.forms.userProfileModal)
  const cognitoUser = useSelector((state) => state.auth.cognitoUser)
  const cognitoUserId = useSelector((state) => state.auth.cognitoUser?.sub)
  const { data, isLoading, isSuccess } = useGetMemberByIdQuery(cognitoUserId, {
    skip: !cognitoUserId,
    keepUnusedData: true,
  })

  const { data: viewMemberData, isSuccess: viewMemberIsSuccess } =
    useGetMemberByIdQuery(viewMemberId, {
      skip: viewMemberId === undefined || viewMemberId === null,
      keepUnusedData: true,
    })

  useEffect(() => {
    if (data && isSuccess && profileFormData.initialLoad) {
      dispatch(setInitialProfileModalState(data))
    }
  }, [data])

  useEffect(() => {
    if (viewMemberData && viewMemberIsSuccess) {
      console.log('viewMemberData', viewMemberData)
    }
  }, [viewMemberData])

  useEffect(() => {
    console.log('viewMemberId', viewMemberId)
  }, [])

  if (isLoading) {
    return <LinearProgress />
  }
  // TODO MAKE THIS ARE FOR is cognitosub user === parmas id
  if (
    viewMemberId &&
    isSuccess &&
    viewMemberIsSuccess &&
    viewMemberData &&
    viewMemberData.id === cognitoUserId
  ) {
    const memberName = `${
      cognitoUser?.given_name
    } ${cognitoUser?.family_name.substring(0, 1)}.`
    return (
      <Grid container sx={{ p: large ? 4 : 0, pt: large ? null : 2 }}>
        <Grid
          container
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            p: large ? 2 : 1,
            pb: 0,
          }}
        >
          <Grid item>
            <Grid container style={{ justifyContent: 'flex-start' }}>
              <Grid item>
                <Typography
                  display="inline"
                  sx={{
                    fontWeight: 700,
                    fontSize: '34px',
                    lineHeight: '25.5px',
                  }}
                >
                  {memberName}'s Fitness Profile
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  display="inline"
                  sx={{
                    color: 'primary.main',
                    fontWeight: 700,
                    fontSize: '17px',
                    lineHeight: '25.5px',
                    pl: 2,
                  }}
                  onClick={() => console.log('clicked', data)}
                >
                  ({data.pronouns})
                </Typography>
              </Grid>
              <Grid item>
                <EditIconButtonBorder
                  onClick={() => {
                    dispatch(setGeneralModalComponent('introduce_member'))
                    dispatch(setGeneralModalOpen(true))
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography display="inline" sx={{}}>
              Edit Mode
            </Typography>
            <Switch
              checked={!editMode}
              onChange={() => {
                dispatch(setEditMode())
              }}
            />
            <Typography display="inline" sx={{ color: 'primary.main' }}>
              Trainer View
            </Typography>
          </Grid>
        </Grid>
        <MemberGeneralInfo viewMemberId={viewMemberId} />
        <MemberOtherInfo memberName={memberName} viewMemberId={viewMemberId} />
      </Grid>
    )
  } else if (
    viewMemberId &&
    viewMemberIsSuccess &&
    viewMemberData &&
    viewMemberData.id !== cognitoUserId
  ) {
    return (
      <Grid container sx={{ p: '32px' }}>
        <Grid
          container
          style={{ width: '100%', justifyContent: 'space-between' }}
        >
          <Grid item>
            <Grid container style={{ justifyContent: 'flex-start' }}>
              <Grid item>
                <Typography
                  display="inline"
                  sx={{
                    fontWeight: 700,
                    fontSize: '34px',
                    lineHeight: '25.5px',
                  }}
                >
                  {viewMemberData.displayName}'s Fitness Profile
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  display="inline"
                  sx={{
                    color: 'primary.main',
                    fontWeight: 700,
                    fontSize: '17px',
                    lineHeight: '25.5px',
                    pl: 2,
                  }}
                  onClick={() => console.log('clicked', viewMemberData)}
                >
                  ({viewMemberData.pronouns})
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <MemberGeneralInfo />
        <MemberOtherInfo memberName={viewMemberData.displayName} />
      </Grid>
    )
  } /* else if (isSuccess) {
    const memberName = `${
      cognitoUser?.given_name
    } ${cognitoUser?.family_name.substring(0, 1)}.`
    return (
      <Grid container sx={{ p: '32px' }}>
        <Grid
          container
          style={{ width: '100%', justifyContent: 'space-between' }}
        >
          <Grid item>
            <Grid container style={{ justifyContent: 'flex-start' }}>
              <Grid item>
                <Typography
                  display="inline"
                  sx={{
                    fontWeight: 700,
                    fontSize: '34px',
                    lineHeight: '25.5px',
                  }}
                >
                  {memberName}'s Fitness Profile
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  display="inline"
                  sx={{
                    color: 'primary.main',
                    fontWeight: 700,
                    fontSize: '17px',
                    lineHeight: '25.5px',
                    pl: 2,
                  }}
                  onClick={() => console.log('clicked', data)}
                >
                  ({data.pronouns})
                </Typography>
              </Grid>
              <Grid item>
                <EditIconButtonBorder
                  onClick={() => {
                    dispatch(setGeneralModalComponent('introduce_member'))
                    dispatch(setGeneralModalOpen(true))
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography display="inline" sx={{}}>
              Edit Mode
            </Typography>
            <Switch
              checked={!editMode}
              onChange={() => {
                dispatch(setEditMode())
              }}
            />
            <Typography display="inline" sx={{ color: 'primary.main' }}>
              Trainer View
            </Typography>
          </Grid>
        </Grid>
        <MemberGeneralInfo viewMemberId={viewMemberId} />
        <MemberOtherInfo memberName={memberName} viewMemberId={viewMemberId} />
      </Grid>
    )
  } */
}

export default MemberProfile
