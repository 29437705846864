import '../App.css'
import React, { useCallback, useEffect, useState } from 'react'

import S3Image from '../components/S3Image'
import {
  Box,
  Button,
  Fab,
  Grid,
  IconButton,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material'
import {
  ChevronRight,
  Circle,
  CircleOutlined,
  HdrPlusRounded,
  PlusOneRounded,
} from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import Header from '../components/header/Header'
import SessionCard from '../components/session_card/SessionCard'
import SessionCardv2 from '../components/session_card/SessionCardv2'
import TrainerCardv2 from '../components/member-dashboard/TrainerCardv2'
import Footer from '../components/footer/Footer'

function Components() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [image, setImage] = useState(null)
  const [curPage, setCurPage] = useState('main')
  const [currentSlide, setCurrentSlide] = useState(0)
  const [pageName, setPageName] = useState('Sports Coaching')
  const theme = useTheme()
  const large = useMediaQuery(theme.breakpoints.up('md'))

  const personalTrainers = [
    {
      image: 'trainerpersonal1.png',
      name: 'Kai M.',
      location: 'San Francisco, CA',
      specialty: 'CrossFit | Stretching',
    },
    {
      image: 'trainerpersonal2.png',
      name: 'Tito V.',
      location: 'New York, NY',
      specialty: 'Resistance | Weight ',
    },
    {
      image: 'trainerpersonal3.png',
      name: 'Jasmine C.',
      location: 'San Francisco, CA',
      specialty: 'Yoga | Core Strength',
    },
    {
      image: 'trainerpersonal4.png',
      name: 'Sean F.',
      location: 'Chicago, IL',
      specialty: 'Rock Climbing | Sports',
    },
  ]

  const personalTrainersAdventure = [
    {
      image: 'trainerpersonal1.png',
      name: 'Kai M.',
      location: 'San Francisco, CA',
      specialty: 'CrossFit | Stretching',
    },
    {
      image: 'trainerpersonal2.png',
      name: 'Tito V.',
      location: 'New York, NY',
      specialty: 'Resistance | Weight ',
    },
    {
      image: 'trainerpersonal3.png',
      name: 'Jasmine C.',
      location: 'San Francisco, CA',
      specialty: 'Yoga | Core Strength',
    },
    {
      image: 'trainerpersonal4.png',
      name: 'Sean F.',
      location: 'Chicago, IL',
      specialty: 'Rock Climbing | Sports',
    },
  ]
  const sportsTrainers = [
    {
      image: 'trainer5.png',
      name: 'Sarah J.',
      location: 'Denver, CO',
      specialty: 'Skiing | HIIT',
    },
    {
      image: 'trainer6.png',
      name: 'Kevin R.',
      location: 'Los Angeles, CA',
      specialty: 'Sports | Weight ',
    },
    {
      image: 'trainer7.png',
      name: 'James W.',
      location: 'Atlanta, GA',
      specialty: 'Golf | Core Strength',
    },
    {
      image: 'trainer8.png',
      name: 'Leo I.',
      location: 'Chicago, IL',
      specialty: 'Tennis | Sports',
    },
  ]
  const adventureTrainers = [
    {
      image: 'trainer9.png',
      name: 'Jordan L.',
      location: 'Los Angeles, CA',
      specialty: 'Paddle Board | Kayaking',
    },
    {
      image: 'trainer10.png',
      name: 'Tim F.',
      location: 'Los Angeles, CA',
      specialty: 'Surfing | Snow Boarding ',
    },
    {
      image: 'trainer11.png',
      name: 'Katie M.',
      location: 'Atlanta, GA',
      specialty: 'Marathon | Running',
    },
    {
      image: 'trainer12.png',
      name: 'Tom P.',
      location: 'Chicago, IL',
      specialty: 'Road Cycling | Mountain Biking',
    },
  ]

  const personalSessions = [
    {
      title: 'Boxing 101',
      name: 'John S',
      location: 'New York, NY',
      price: 50,
      image: 'personaltrain1.png',
      trainerImage: 'personalcoach1.png',
    },
    {
      title: 'Core Strength',
      name: 'Jim K',
      location: 'New York, NY',
      price: 50,
      image: 'personaltrain2.png',
      trainerImage: 'personalcoach2.png',
    },
    {
      title: 'Sports Conditioning',
      name: 'Tim O',
      location: 'New York, NY',
      price: 50,
      image: 'personaltrain3.png',
      trainerImage: 'personalcoach3.png',
    },
    {
      title: 'Calisthenics',
      name: 'Bri B',
      location: 'New York, NY',
      price: 50,
      image: 'personaltrain4.png',
      trainerImage: 'personalcoach4.png',
    },
  ]

  const adventureSessions = [
    {
      title: 'Surfing 101',
      name: 'Tim F.',
      location: 'Los Angeles, CA',
      price: 50,
      image: 'adventuretrain1.png',
      trainerImage: 'trainer10.png',
    },
    {
      title: 'Rock Climbing',
      name: 'Jim K',
      location: 'Los Angeles, CA',
      price: 75,
      image: 'adventuretrain2.png',
      trainerImage: 'personalcoach2.png',
    },
    {
      title: 'Canoeing',
      name: 'Tim O',
      location: 'Atlanta, GA',
      price: 50,
      image: 'adventuretrain3.png',
      trainerImage: 'personalcoach3.png',
    },
    {
      title: 'Morning Paddleboard',
      name: 'Bri B',
      location: 'New York, NY',
      price: 50,
      image: 'adventuretrain4.png',
      trainerImage: 'personalcoach4.png',
    },
  ]

  const sportsSessions = [
    {
      title: 'Sports Conditioning',
      name: 'Tim O',
      location: 'New York, NY',
      price: 50,
      image: 'personaltrain3.png',
      trainerImage: 'personalcoach3.png',
    },
    {
      title: 'Boxing 101',
      name: 'John S',
      location: 'New York, NY',
      price: 50,
      image: 'personaltrain1.png',
      trainerImage: 'personalcoach1.png',
    },
    {
      title: 'Tennis',
      name: 'Tim O',
      location: 'Atlanta, GA',
      price: 50,
      image: 'sports1.png',
      trainerImage: 'personalcoach3.png',
    },
    {
      title: 'Olympic Skiing',
      name: 'Bri B',
      location: 'New York, NY',
      price: 50,
      image: 'sports2.png',
      trainerImage: 'personalcoach4.png',
    },
  ]

  const subheadings = [
    'Because working out alone sucks',
    "When average isn't enough",
    "Because you're not getting any younger",
    'Make your workout the best part of your day',
  ]
  const [heroCopy, setHeroCopy] = useState({
    titleOne: 'LIVE TRAINING',
    titleTwo: 'ANYWHERE',
    subheading: 'Make your workout the best part of your day',
  })

  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * subheadings.length)
    const subheading = subheadings[index]
    if (heroCopy.titleOne == 'PERSONAL TRAINING') {
      setHeroCopy({
        titleOne: 'ONE ON ONE',
        titleTwo: 'TRAINING',
        subheading: subheading,
      })
    } else if (heroCopy.titleOne == 'ONE ON ONE') {
      setHeroCopy({
        titleOne: 'PERSONAL TRAINING',
        titleTwo: 'ANYWHERE',
        subheading: subheading,
      })
    }
  }, [heroCopy.titleOne, heroCopy.titleTwo, subheadings])

  useEffect(() => {
    const intervalID = setInterval(shuffle, 5000)
    return () => clearInterval(intervalID)
  }, [shuffle])

  return (
    <div className="App">
      {/* <Box
        sx={{
          height: '70vh',
          width: '100%',
          backgroundImage: `url(/Ellipse.png)`,
          backgroundSize: '60% 100%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom right',
          position: 'absolute',
          zIndex: -1,
        }}
      ></Box> */}
      <Header style={{ zIndex: -1 }} />
      {curPage === 'main' && (
        <video
          playsinline
          autoPlay
          loop
          muted
          style={{ zIndex: -1, width: '100%', paddingBottom: '32px' }}
        >
          <source src="/landingVideo.mov" type="video/mp4" />
        </video>
      )}
      {curPage !== 'main' && (
        <Typography
          sx={{ color: 'gunMetal.main', fontWeight: '700', fontSize: '32px' }}
        >
          {curPage.toUpperCase() + ' TRAINING'}
        </Typography>
      )}
      {curPage !== 'main' && (
        <Button
          variant="outlined"
          onClick={() => setCurPage('main')}
          sx={{ fontWeight: '400', fontSize: '18px' }}
        >
          Explore More
        </Button>
      )}

      {/* {Hero()} */}
      {AdventureSportPersonal()}
      <Grid
        container
        sx={{ justifyContent: 'flex-start', pl: 4, pt: 2, textAlign: 'left' }}
      >
        <Typography
          sx={{ fontWeight: 700, fontSize: '18px', color: 'primary.main' }}
        >
          Working out alone sucks. Get Traind and make your workout the best
          part of your day.
        </Typography>
      </Grid>

      {SessionRow()}
      {TrainerRow()}
      <Grid container sx={{ p: 4, pt: 0 }}>
        <Grid item xs={12} md={7} sx={{ p: 4 }}>
          <Box
            sx={{
              height: 400,
              width: '100%',
              backgroundImage:
                curPage === 'sports'
                  ? `url(/landingsports.png)`
                  : curPage === 'adventure'
                  ? `url(/landingpageadventure.png)`
                  : curPage === 'personal'
                  ? `url(/landingpersonal.png)`
                  : `url(/homepagejointeam.png)`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderRadius: '12px',
            }}
          />
        </Grid>
        <Grid item xs={12} md={7} sx={{ p: 4, pb: 0 }}>
          <Grid container sx={{ pb: 2 }}>
            <Typography sx={{ fontWeight: 500, fontSize: 32 }}>
              Join the Get Traind Team
            </Typography>
          </Grid>
          <Grid container sx={{ pb: 2 }}>
            <Typography sx={{ fontSize: 18 }}>
              Are You a Coach, Trainer, or Instructor?
            </Typography>
          </Grid>
          <Grid container sx={{ pb: 0 }}>
            <Button variant="contained" onClick={() => navigate('onboarding')}>
              <Typography
                sx={{ fontSize: 18, fontWeight: 700, color: 'white' }}
              >
                Join Get Traind Now!
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {BlogRow()}
      {/* <Grid container sx={{ justifyContent: 'space-around' }}>
        <img
          src="/branding1.png"
          style={{
            width: large ? '40%' : '100%',
            borderRadius: large ? '16px' : '0',
          }}
        />
        <img
          src="/branding2.png"
          style={{
            width: large ? '40%' : '100%',
            borderRadius: large ? '16px' : '0',
          }}
        />
      </Grid> */}

      <Footer />
    </div>
  )

  const blogsList = [
    // Main

    // Personal Training
    {
      title: '30 Hilarious Reasons Why You Should Get Personal Training:',
      subtitle:
        'The real reasons you should ditch working out alone and get yourself a trainer.',
      image: '30Reasons.jpeg',
    },
    {
      title: 'Weightlifting Vs. Body Weight Training:',
      subtitle: 'Clash of the Titans in the Fitness Arena',
      image: 'weightvsbody.jpeg',
    },
    {
      title: 'The Future Unveiled:',
      subtitle:
        'Why Online Personal Training is Revolutionizing Personalized Fitness',
      image: 'future.jpeg',
    },
    // Sports Coaching
    {
      title: 'Unleashing the Inner Athlete:',
      subtitle: 'Mastering Discipline for Success',
      image: 'discipline.jpeg',
    },
    {
      title: 'The Allure of Learning a New Sport:',
      subtitle: 'Embrace the Thrill of the Unfamiliar',
      image: 'allure.jpeg',
    },
    {
      title: 'Discover Your Inner Champion:',
      subtitle: '5 Strategies to Maximize Your Sports Performance',
      image: 'champion.jpeg',
    },
    // Adventure Sports
    {
      title: 'Benefits of Exercising Outdoors:',
      subtitle: 'Thinking out of the BOX',
      image: 'benefitsoutdoors.jpeg',
    },
    {
      title: 'How to Stay Safe While Exploring Adventure Fitness:',
      subtitle: 'Working out alone doesn’t just suck… it can be dangerous',
      image: 'staysafe.jpeg',
    },
    {
      title: 'Be Prepared!',
      subtitle: 'Best Exercises to Prepare for Adventure Fitness',
      image: 'prepared.jpeg',
    },
  ]

  function BlogRow() {
    const mainBlogs = [
      {
        title: '30 Hilarious Reasons Why You Should Get Personal Training:',
        subtitle:
          'The real reasons you should ditch working out alone and get yourself a trainer.',
        image: '30Reasons.jpeg',
        link: 'https://blog.gettraind.net/get-traind-fitness-blog/30-hilarious-reasons-why-you-should-get-personal-training',
      },
      {
        title: 'Unlocking the Power of Movement:',
        subtitle: 'How Exercise Transforms Your Brain',
        image: 'powerofmovement.jpeg',
        link: 'https://blog.gettraind.net/get-traind-fitness-blog/unlocking-the-power-of-movement-how-exercise-transforms-your-brain',
      },
      {
        title: 'The Many Benefits of Rock Climbing:',
        subtitle: 'Hit the Mountain and Take Your Fitness to New Heights',
        image: 'benefitsofclimbing.jpeg',
        link: 'https://blog.gettraind.net/en/adventure-fitness/the-many-benefits-of-rock-climbing',
      },
    ]
    const personalBlogs = [
      {
        title: '30 Hilarious Reasons Why You Should Get Personal Training:',
        subtitle:
          'The real reasons you should ditch working out alone and get yourself a trainer.',
        image: '30Reasons.jpeg',
        link: 'https://blog.gettraind.net/get-traind-fitness-blog/30-hilarious-reasons-why-you-should-get-personal-training',
      },
      {
        title: 'Weightlifting Vs. Body Weight Training:',
        subtitle: 'Clash of the Titans in the Fitness Arena',
        image: 'weightvsbody.jpeg',
        link: 'https://blog.gettraind.net/get-traind-fitness-blog/weightlifting-vs.-body-weight-training-clash-of-the-titans-in-the-fitness-arena',
      },
      {
        title: 'The Future Unveiled:',
        subtitle:
          'Why Online Personal Training is Revolutionizing Personalized Fitness',
        image: 'future.jpeg',
        link: 'https://blog.gettraind.net/get-traind-fitness-blog/the-future-unveiled-why-online-personal-training-is-revolutionizing-personalized-fitness',
      },
    ]
    const sportsBlogs = [
      {
        title: 'Unleashing the Inner Athlete:',
        subtitle: 'Mastering Discipline for Success',
        image: 'discipline.jpeg',
        link: 'https://blog.gettraind.net/sports-coaching/unleashing-the-inner-athlete-mastering-discipline-for-success',
      },
      {
        title: 'The Allure of Learning a New Sport:',
        subtitle: 'Embrace the Thrill of the Unfamiliar',
        image: 'allure.jpeg',
        link: 'https://blog.gettraind.net/sports-coaching/the-allure-of-learning-a-new-sport-embrace-the-thrill-of-the-unfamiliar',
      },
      {
        title: 'Discover Your Inner Champion:',
        subtitle: '5 Strategies to Maximize Your Sports Performance',
        image: 'champion.jpeg',
        link: 'https://blog.gettraind.net/sports-coaching/unleash-your-inner-champion-5-strategies-to-maximize-your-sports-performance',
      },
    ]
    const adventureBlogs = [
      {
        title: 'Benefits of Exercising Outdoors:',
        subtitle: 'Thinking out of the BOX',
        image: 'benefitsoutdoors.jpeg',
        link: 'https://blog.gettraind.net/en/adventure-fitness/benefits-of-exercising-outdoors',
      },
      {
        title: 'How to Stay Safe While Exploring Adventure Fitness:',
        subtitle: 'Working out alone doesn’t just suck… it can be dangerous',
        image: 'staysafe.jpeg',
        link: 'https://blog.gettraind.net/en/adventure-fitness/how-to-stay-safe-while-exploring-adventure-fitness',
      },
      {
        title: 'Be Prepared!',
        subtitle: 'Best Exercises to Prepare for Adventure Fitness',
        image: 'prepared.jpeg',
        link: 'https://blog.gettraind.net/en/adventure-fitness/be-prepared-best-exercises-to-build-strength-for-adventure-fitness',
      },
    ]
    return (
      <Grid container sx={{ p: 4, justifyContent: 'flex-start', pt: 0 }}>
        <Typography sx={{ fontWeight: 700, fontSize: '24px' }}>Blog</Typography>

        <Grid container>
          {curPage === 'main' &&
            mainBlogs.map((blog) => {
              return BlogItem(blog)
            })}
          {curPage === 'personal' &&
            personalBlogs.map((blog) => {
              return BlogItem(blog)
            })}
          {curPage === 'sports' &&
            sportsBlogs.map((blog) => {
              return BlogItem(blog)
            })}
          {curPage === 'adventure' &&
            adventureBlogs.map((blog) => {
              return BlogItem(blog)
            })}
        </Grid>
      </Grid>
    )

    function BlogItem(blog) {
      return (
        <Grid item xs={12} md={4} sx={{ p: 2 }}>
          <Box
            sx={{
              height: 400,
              width: '100%',
              backgroundImage: `url(/${blog.image})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderRadius: '12px',
            }}
          >
            <Grid
              container
              sx={{
                bgcolor: 'rgba(0, 0, 0, 0.2)',
                borderRadius: '12px',
                width: '100%',
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Grid container sx={{ width: '100%' }}>
                <Box
                  sx={{
                    background: `rgba(196, 196, 196, 0.64)`,

                    width: '100%',
                    borderRadius: '12px 12px 0 0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'white',
                      fontWeight: 700,
                      fontSize: '24px',
                      p: 1,
                    }}
                  >
                    {blog.title}
                  </Typography>
                </Box>
              </Grid>
              <Grid container sx={{ width: '100%' }}>
                <Box
                  sx={{
                    width: '90%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'left',
                  }}
                >
                  <Typography
                    sx={{ color: 'white', fontWeight: 700, fontSize: '20px' }}
                  >
                    {blog.subtitle}
                  </Typography>
                </Box>
              </Grid>
              <Grid container sx={{}}>
                <Box
                  sx={{
                    background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 2.6%, #000000 100%)`,

                    width: '100%',
                    borderRadius: '0 0 12px 12px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <a href={blog.link} target="blank">
                    <Typography
                      sx={{
                        color: 'white',
                        fontWeight: 700,
                        fontSize: '18px',
                        pb: 2,
                        textDecoration: 'underline',
                      }}
                    >
                      Read On
                    </Typography>
                  </a>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )
    }
  }

  function TrainerRow() {
    return (
      <Grid container sx={{ p: 4, justifyContent: 'flex-start', pt: 0 }}>
        <Typography sx={{ fontWeight: 700, fontSize: '24px' }}>
          Top Coaches
        </Typography>
        {!large && (
          <Tabs
            value={0}
            onChange={() => console.log('handleChangeCoachIndex')}
            variant="scrollable"
            scrollButtons
            aria-label="scrollable force tabs example"
            sx={{
              backgroundColor: 'white',
              color: '#7C7C7C',
              width: '100%',
            }}
          >
            {curPage === 'personal' ||
              (curPage === 'main' &&
                personalTrainers.map((trainer) => (
                  <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                    <Grid container>
                      <TrainerCardv2
                        trainer={trainer}
                        onClick={() => navigate('/onboarding')}
                      />
                    </Grid>
                  </Grid>
                )))}
            {curPage === 'personal' &&
              personalTrainers.map((trainer) => (
                <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                  <TrainerCardv2
                    trainer={trainer}
                    onClick={() => navigate('/onboarding')}
                  />
                </Grid>
              ))}
            {curPage === 'sports' &&
              sportsTrainers.map((trainer) => (
                <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                  <TrainerCardv2
                    trainer={trainer}
                    onClick={() => navigate('/onboarding')}
                  />
                </Grid>
              ))}
            {curPage === 'adventure' &&
              adventureTrainers.map((trainer) => (
                <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                  <TrainerCardv2
                    trainer={trainer}
                    onClick={() => navigate('/onboarding')}
                  />
                </Grid>
              ))}
          </Tabs>
        )}
        {large && (
          <Grid container sx={{ justifyContent: 'center' }}>
            {curPage === 'personal' ||
              (curPage === 'main' &&
                personalTrainers.map((trainer) => (
                  <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                    <Grid container>
                      <TrainerCardv2
                        trainer={trainer}
                        onClick={() => navigate('/onboarding')}
                      />
                    </Grid>
                  </Grid>
                )))}
            {curPage === 'personal' &&
              personalTrainers.map((trainer) => (
                <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                  <TrainerCardv2
                    trainer={trainer}
                    onClick={() => navigate('/onboarding')}
                  />
                </Grid>
              ))}
            {curPage === 'sports' &&
              sportsTrainers.map((trainer) => (
                <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                  <TrainerCardv2
                    trainer={trainer}
                    onClick={() => navigate('/onboarding')}
                  />
                </Grid>
              ))}
            {curPage === 'adventure' &&
              adventureTrainers.map((trainer) => (
                <Grid item xs={12} sm={3} md={4} lg={3} sx={{ p: 1 }}>
                  <Grid container>
                    <TrainerCardv2
                      trainer={trainer}
                      onClick={() => navigate('/onboarding')}
                    />
                  </Grid>
                </Grid>
              ))}
          </Grid>
        )}
      </Grid>
    )
  }

  function SessionRow() {
    return (
      <Grid
        container
        sx={{ p: 4, justifyContent: 'flex-start', textAlign: 'left' }}
      >
        <Typography sx={{ fontWeight: 700, fontSize: '24px' }}>
          {curPage === 'adventure'
            ? 'Trending Adventure Fitness Sessions'
            : curPage === 'sports'
            ? 'Trending Sports Coaching Sessions'
            : 'Trending Personal Training Sessions'}
        </Typography>
        {!large && (
          <Tabs
            value={0}
            onChange={() => console.log('handleChangeCoachIndex')}
            variant="scrollable"
            scrollButtons
            aria-label="scrollable force tabs example"
            sx={{
              backgroundColor: 'white',
              color: '#7C7C7C',
              width: '100%',
            }}
          >
            {curPage === 'main' &&
              personalSessions.map((session) => (
                <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                  <Grid
                    container
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <SessionCardv2
                      session={session}
                      onClick={() => navigate('/onboarding')}
                    />
                  </Grid>
                </Grid>
              ))}
            {curPage === 'personal' &&
              personalSessions.map((session) => (
                <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                  <SessionCardv2
                    session={session}
                    onClick={() => navigate('/onboarding')}
                  />
                </Grid>
              ))}
            {curPage === 'adventure' &&
              adventureSessions.map((session) => (
                <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                  <SessionCardv2
                    session={session}
                    onClick={() => navigate('/onboarding')}
                  />
                </Grid>
              ))}
            {curPage === 'sports' &&
              sportsSessions.map((session) => (
                <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                  <SessionCardv2
                    session={session}
                    onClick={() => navigate('/onboarding')}
                  />
                </Grid>
              ))}
          </Tabs>
        )}
        {large && (
          <Grid container sx={{ justifyContent: 'center' }}>
            {curPage === 'main' &&
              personalSessions.map((session) => (
                <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                  <Grid
                    container
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <SessionCardv2
                      session={session}
                      onClick={() => navigate('/onboarding')}
                    />
                  </Grid>
                </Grid>
              ))}
            {curPage === 'personal' &&
              personalSessions.map((session) => (
                <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                  <SessionCardv2
                    session={session}
                    onClick={() => navigate('/onboarding')}
                  />
                </Grid>
              ))}
            {curPage === 'adventure' &&
              adventureSessions.map((session) => (
                <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                  <SessionCardv2
                    session={session}
                    onClick={() => navigate('/onboarding')}
                  />
                </Grid>
              ))}
            {curPage === 'sports' &&
              sportsSessions.map((session) => (
                <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                  <SessionCardv2
                    session={session}
                    onClick={() => navigate('/onboarding')}
                  />
                </Grid>
              ))}
          </Grid>
        )}
      </Grid>
    )
  }

  function AdventureSportPersonal() {
    return (
      <Grid
        container
        sx={{
          justifyContent: 'space-around',
          pt: 1,
          gap: large ? '0px' : 2,
          p: large ? null : 2,
        }}
      >
        {(curPage === 'sports' || curPage === 'main') && (
          <Grid item xs={12} sm={3.5} md={3.5}>
            <Box
              sx={{
                height: 400,
                width: '100%',
                backgroundImage: `url(/SportsCoachingLanding.png)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                display: 'flex',
                borderRadius: '16px',
                border: curPage === 'sports' ? '2px solid #00C7C7' : null,
                boxSizing: 'border-box',
                cursor: 'pointer',
                filter:
                  curPage === 'adventure' || curPage === 'personal'
                    ? 'grayscale(100%)'
                    : null,
              }}
              onClick={() => setCurPage('sports')}
            >
              <Box
                sx={{
                  background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 2.6%, #000000 100%)`,
                  height: '139px',
                  width: '100%',
                  borderRadius: '0 0 12px 12px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{ color: 'white', fontWeight: 700, fontSize: '32px' }}
                >
                  {' '}
                  Sports Coaching
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}
        {(curPage === 'adventure' || curPage === 'main') && (
          <Grid item xs={12} sm={3.5} md={3.5}>
            <Box
              sx={{
                height: 400,
                width: '100%',
                backgroundImage: `url(/AdventureFitness.png)`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                display: 'flex',
                borderRadius: '12px',
                border: curPage === 'adventure' ? '2px solid #00C7C7' : null,
                boxSizing: 'border-box',
                cursor: 'pointer',
                filter:
                  curPage === 'sports' || curPage === 'personal'
                    ? 'grayscale(100%)'
                    : null,
              }}
              onClick={() => setCurPage('adventure')}
            >
              <Box
                sx={{
                  background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 2.6%, #000000 100%)`,
                  height: '139px',
                  width: '100%',
                  borderRadius: '0 0 12px 12px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{ color: 'white', fontWeight: 700, fontSize: '32px' }}
                >
                  {' '}
                  Adventure Fitness
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}

        {(curPage === 'personal' || curPage === 'main') && (
          <Grid item xs={12} sm={3.5} md={3.5}>
            <Box
              sx={{
                height: 400,
                width: '100%',
                backgroundImage: `url(/PersonalTraining.png)`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                display: 'flex',
                borderRadius: '12px',
                border: curPage === 'personal' ? '2px solid #00C7C7' : null,
                boxSizing: 'border-box',
                cursor: 'pointer',

                filter:
                  curPage === 'sports' || curPage === 'adventure'
                    ? 'grayscale(100%)'
                    : null,
              }}
              onClick={() => setCurPage('personal')}
            >
              <Box
                sx={{
                  background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 2.6%, #000000 100%)`,
                  height: '139px',
                  width: '100%',
                  borderRadius: '0 0 12px 12px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{ color: 'white', fontWeight: 700, fontSize: '32px' }}
                >
                  {' '}
                  Personal Training
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    )
  }

  function Hero() {
    return (
      <Grid
        container
        sx={{
          mt: 1,
          mb: 4,
          flexGrow: 1,
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <Grid
          container
          sx={{
            width: '95%',
            height: '70vh',
            bgcolor: 'transparent',
            backgroundImage: `url(/Runner.png)`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom right',
            pt: 0,
            minHeight: '400px',
          }}
        >
          <Grid
            container
            sx={{
              height: '90%',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              pb: 4,
            }}
          >
            <Grid
              item
              sx={{
                flexGrow: 1,
                pl: 4,
                height: '100%',
                alignItems: 'flex-start',
              }}
            >
              <Grid
                container
                sx={{
                  p: 2,
                  pb: 0,
                  pt: 0,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                }}
              >
                <Typography
                  sx={{
                    color: '#2B303E',
                    fontFamily: 'Poppins',
                    fontSize: '8rem',
                    zIndex: -1,
                    fontWeight: 700,
                  }}
                >
                  {heroCopy.titleOne}
                </Typography>
              </Grid>
              <Grid
                container
                sx={{
                  p: 2,
                  pt: 0,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                }}
              >
                <Typography
                  sx={{
                    color: '#C4C4C4',
                    fontFamily: 'Poppins',
                    fontWeight: 700,
                    fontSize: '8rem',
                    zIndex: -1,
                  }}
                >
                  {heroCopy.titleTwo}
                </Typography>
              </Grid>
              <Grid
                container
                sx={{
                  p: 2,
                  pt: 0,
                  pb: 0,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                }}
              >
                <Typography
                  sx={{
                    color: 'primary.main',
                    fontSize: '2rem',
                    fontFamily: 'Poppins',
                    fontWeight: 400,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="25"
                    viewBox="0 -960 960 960"
                    width="48"
                    fill=""
                  >
                    <path d="M479.825-185q-18.45 0-31.637-12.625Q435-210.25 435-231v-203H230q-18.375 0-31.688-13.56Q185-461.119 185-479.86q0-20.14 13.312-32.64Q211.625-525 230-525h205v-205q0-19.775 13.358-32.388Q461.716-775 480.158-775t32.142 12.612Q526-749.775 526-730v205h204q18.8 0 32.4 12.675 13.6 12.676 13.6 32.316 0 19.641-13.6 32.825Q748.8-434 730-434H526v203q0 20.75-13.65 33.375Q498.699-185 479.825-185Z" />
                  </svg>
                  {heroCopy.subheading}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default Components
