import { Grid } from '@mui/material'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import DashboardWorkoutsPage from '../components/member-dashboard/DashboardWorkoutsPage'

function WorkoutsPage() {
  return (
    <Grid container>
      <Header />
      <DashboardWorkoutsPage />
      <Footer />
    </Grid>
  )
}

export default WorkoutsPage
