import { Grid, LinearProgress, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetMemberByIdQuery } from '../../redux/api/api'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'
import BorderSelectButton from '../buttons/BorderSelectButton'
import TitleC from '../text/TitleC'

export default function FitnessGoals(props) {
  const { onboarding } = props

  const dispatch = useDispatch()

  const cognitoUserId = useSelector((state) => state.auth.cognitoUser?.sub)
  const userFormFields = useSelector((state) => state.forms.userProfileModal)
  const isTrainerOnboard = useSelector((state) => state.ui.isTrainerOnboard)
  const fitnessGoals = userFormFields?.fitnessGoals || []

  const fitnessList = [
    'Be More Active',
    'Improve Endurance',
    'Lose Weight',
    'Injury Recovery',
    'Improve Flexibility',
    'Build Muscle',
    'Toning',
    'Body Recomposition',
    'Train for Competition',
  ]

  const { data, isLoading, isSuccess } = useGetMemberByIdQuery(cognitoUserId, {
    skip: !cognitoUserId,
    keepUnusedData: true,
  })

  //const [fitnessGoals, setFitnessGoals] = useState([])

  const handleFitnessGoalsClick = (fitness) => {
    const copy = [...fitnessGoals]

    const filtered = copy.includes(fitness)
      ? fitnessGoals?.filter(function (item) {
          return item !== fitness
        })
      : [...fitnessGoals, fitness]
    console.log('filtered', filtered)
    // setFitnessGoals(() => {
    //   return fitnessGoals.includes(fitness)
    //     ? fitnessGoals.filter(function (item) {
    //         return item !== fitness
    //       })
    //     : [...fitnessGoals, fitness]
    // })
    dispatch(
      setUserProfileModalField({ field: 'fitnessGoals', value: filtered })
    )
  }

  if (isLoading) {
    return <LinearProgress />
  }

  return (
    <>
      <Grid item xs={12}>
        <TitleC
          text={
            isTrainerOnboard
              ? 'What are your areas of expertise?'
              : 'What are your fitness goals?'
          }
        />
      </Grid>
      <Grid
        container
        style={{ paddingTop: '16px', justifyContent: 'flex-start' }}
        spacing={'12px'}
      >
        {fitnessList.map((fitness) => {
          return (
            <Grid item>
              <BorderSelectButton
                active={fitnessGoals?.includes(fitness)}
                text={fitness}
                onClick={() => handleFitnessGoalsClick(fitness)}
              />
            </Grid>
          )
        })}
      </Grid>
      <Grid container xs={12}>
        <Typography
          align="center"
          sx={{
            paddingTop: '32px',
            paddingBottom: '32px',
            width: 290,
            color: 'primary.main',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '15px',
          }}
        >
          The more information you provide, the more personalized your workouts
          will be.
        </Typography>
      </Grid>
    </>
  )
}
