import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    memberSignedIn: false,
    cognitoUser: null,
    isTrainer: false,
    trainerId: 456,
    hasTrainerProfile: false,
  },
  reducers: {
    setCognitoUserField: (state, action) => {
      state.cognitoUser[action.payload.field] = action.payload.value
    },
    setIsTrainer: (state, action) => {
      state.isTrainer = action.payload
    },
    signOutCognitoUser: (state, action) => {
      state.memberSignedIn = false
      state.cognitoUser = null
    },
    setMemberSignedIn: (state, action) => {
      state.memberSignedIn = action.payload
    },
    setCognitoUser: (state, action) => {
      state.cognitoUser = action.payload
      if (action.payload['custom:user_type'] === 'trainer') {
        state.hasTrainerProfile = true
      } else {
        state.hasTrainerProfile = false
      }
      if (action.payload['custom:stripe_customer_id']) {
        state.stripeCustomerId = action.payload['custom:stripe_customer_id']
      }
      if (action.payload['custom:stripe_acct_id']) {
        state.stripeAccountId = action.payload['custom:stripe_acct_id']
      }
      state.memberSignedIn = true
    },
  },
})

export const {
  setIsTrainer,
  signOutCognitoUser,
  setCognitoUser,
  setMemberSignedIn,
  setCognitoUserField,
} = authSlice.actions
export default authSlice.reducer
