import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import * as React from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { setSessionRequestOpen } from '../../redux/slices/uiSlice'

import BorderSelectButton from '../buttons/BorderSelectButton'
import SolidColorButton from '../buttons/SolidColorButton'
import GreyTextButton from '../buttons/GreyTextButton'
import { ReactComponent as LocationIcon } from '../../assets/icons/location_pin.svg'
import { ReactComponent as WorkoutIcon } from '../../assets/icons/workout.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import { ReactComponent as UploadProfileIcon } from '../../assets/icons/upload_profile.svg'
import { ReactComponent as UploadIcon } from '../../assets/icons/upload_up.svg'
import { ReactComponent as CheckCircle } from '../../assets/icons/CheckCircle.svg'
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg'

import { borderRadius, Box } from '@mui/system'
import BorderColorButton from '../buttons/BorderColorButton'

export default function SessionRequest() {
  const dispatch = useDispatch()
  const sessionRequestOpen = useSelector((state) => state.ui.sessionRequestOpen)
  const [submitted, setSubmitted] = React.useState(false)
  const PageTitle = (props) => {
    const { text } = props
    return (
      <>
        <Typography
          align="center"
          sx={{ color: 'primary.main', fontWeight: 700, fontSize: 26 }}
        >
          {text}
        </Typography>
      </>
    )
  }
  const DarkSubtitle = (props) => {
    const { text, onClick } = props
    return (
      <>
        <Typography
          sx={{
            color: 'gunMetal.main',
            fontWeight: 700,
            fontSize: 17,
            lineHeight: '25.5px',
          }}
          onClick={onClick}
        >
          {text}
        </Typography>
      </>
    )
  }
  const LightSubtitle = (props) => {
    const { text, onClick } = props
    return (
      <>
        <Typography
          sx={{
            color: 'gunMetal.main',
            fontWeight: 400,
            fontSize: 15,
            lineHeight: '22.5px',
          }}
          onClick={onClick}
        >
          {' '}
          {text}
        </Typography>
      </>
    )
  }
  const renderTrainingRequest = () => {
    return (
      <>
        <DialogContent style={{ width: '95%' }}>
          <Typography
            align={'center'}
            sx={{ fontSize: '26px', fontWeight: 700, pb: '32px' }}
          >
            Training Session Request
          </Typography>
          <Grid container sx={{ justifyContent: 'left' }}>
            <Grid item>
              <Typography sx={{ fontWeight: 700 }}>
                Type new session for review
              </Typography>
            </Grid>
            <Grid container>
              <TextField
                sx={{
                  height: 78,
                  '& fieldset': {
                    borderRadius: '20px',
                    height: 78,
                  },
                }}
                multiline
                fullWidth
              />
            </Grid>
            <Grid
              container
              sx={{ justifyContent: 'center', padding: 3 }}
              spacing={3}
            >
              <Grid item>
                <BorderColorButton text="Cancel" />
              </Grid>
              <Grid item>
                <SolidColorButton
                  text="Submit"
                  onClick={() => {
                    setSubmitted(true)
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    )
  }
  const renderConfirmation = () => {
    return (
      <DialogContent style={{ width: '95%' }}>
        <Typography
          align={'center'}
          sx={{ fontSize: '26px', fontWeight: 700, pb: '32px' }}
        >
          Request Sent!
        </Typography>
        <Grid container style={{ padding: '16px' }}>
          <Typography sx={{ fontWeight: 700 }}>
            Your request has been submitted to our team.
          </Typography>
        </Grid>
        <Grid container style={{ padding: '16px' }}>
          <SolidColorButton
            text="Close"
            onClick={() => {
              dispatch(setSessionRequestOpen())
            }}
          />
        </Grid>
      </DialogContent>
    )
  }
  return (
    <Dialog
      open={sessionRequestOpen}
      PaperProps={{
        sx: {
          overflow: 'hidden',
          width: '100%',
          maxWidth: '666px !important',
          //height: '100%',
          //maxHeight: '777px !important',
          alignItems: 'center',
          borderRadius: '16px',
        },
      }}
    >
      {' '}
      <DialogTitle style={{ width: '95%' }}>
        <Grid container justifyContent={'space-between'} xs={12}>
          <IconButton
            onClick={() => {
              dispatch(setSessionRequestOpen())
            }}
            style={{ width: '20px', height: '20px', marginLeft: '20px' }}
          >
            <ArrowBackIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              dispatch(setSessionRequestOpen())
            }}
            style={{ width: '20px', height: '20px', marginRight: '20px' }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      {!submitted ? renderTrainingRequest() : renderConfirmation()}
    </Dialog>
  )
}
