import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  InputBase,
  Typography,
} from '@mui/material'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import MemberDashboard from '../components/member-dashboard/MemberDashboardDrawer'
import { useNavigate, useParams } from 'react-router-dom'
import S3Image from '../components/S3Image'
import dayjs from 'dayjs'
import { ArrowBackIosNew, SendOutlined } from '@mui/icons-material'
import {
  useCreateMessageMutation,
  useUpdateBookingStatusMutation,
} from '../redux/api/api'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import formatFromDB from '../helpers/datetime'
function ChatDetails() {
  const [message, setMessage] = useState('')
  const dispatch = useDispatch()
  const { messageRoomId } = useParams()
  const selectedChat = useSelector((state) => state.ui.selectedChat)

  const navigate = useNavigate()

  const [
    createMessageTrigger, // This is the mutation trigger
    //{ isLoading: isUpdating }, // This is the destructured mutation result
  ] = useCreateMessageMutation()

  const userId = useSelector((state) => state.auth.cognitoUser?.sub)
  //const userId = cognitoUser?.sub
  const messageEnd = useRef(null)
  useEffect(() => {
    if (messageEnd.current) {
      messageEnd.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  const handleSendMessage = async () => {
    if (message.trim() === '') {
      return
    }
    try {
      // const newMessageRoom = {
      //   messageRoomMemberId: '95da19a8-b876-4d50-aaef-e06d0f7416b7',
      //   messageRoomTrainerId: 'b6dc0151-a2ba-4aca-8660-3cc6f0d1f834',
      // }
      const newMessage = {
        content: message.trim(),
        read: 'false',
        messageRoomMessagesId: messageRoomId,
        messageSenderId: userId,
      }
      setMessage('')
      // const messageRoomCreate = await API.graphql(
      //   graphqlOperation(createMessageRoom, {
      //     input: newMessageRoom,
      //   })
      // )
      const createdMessage = await createMessageTrigger(newMessage).unwrap()
      console.log('created message: ', createdMessage)
      console.log('selectedChat: ', selectedChat)
      var copyMessages = [...selectedChat.messages.items, createdMessage]
      console.log('copyMessages: ', copyMessages)

      //refetchChatRooms()
      //console.log(messageRoomCreate.data.createMessageRoom)
    } catch (error) {
      console.log('error creating message: ', error)
    }
  }

  return (
    <Grid container>
      <Header />
      <Grid container>
        <Grid container justifyContent="flex-start" sx={{ p: 1, pl: 2 }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosNew />
          </IconButton>
        </Grid>
        <Grid
          container
          alignItems="flex-end"
          sx={{ height: '100%', width: '100%' }}
        >
          <Grid item sx={{ bgcolor: '#F6F7F8', height: '100%' }}>
            <Grid container alignItems="flex-end" sx={{ height: '100%' }}>
              <Box
                style={{
                  overflow: 'auto',
                  width: '100%',
                }}
              >
                {selectedChat &&
                  [...selectedChat.messages.items]
                    .sort((a, b) =>
                      dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1
                    )
                    .map((message) => (
                      <Grid
                        key={message.id}
                        container
                        justifyContent={
                          message.messageSenderId !== userId ? 'left' : 'right'
                        }
                      >
                        <Grid item sx={{ p: 1, maxWidth: '70%' }}>
                          <Grid
                            container
                            justifyContent={'left'}
                            sx={{
                              p: 2,
                              bgcolor:
                                message.messageSenderId !== userId
                                  ? 'white'
                                  : 'primary.light',
                              borderRadius:
                                message.messageSenderId !== userId
                                  ? '20px 20px 20px 0px'
                                  : '20px 20px 0px 20px',
                            }}
                          >
                            <Typography>{message.content}</Typography>
                            <Grid
                              container
                              justifyContent={
                                message.messageSenderId !== userId
                                  ? 'left'
                                  : 'right'
                              }
                            >
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: '9px',
                                  lineHeight: '13.5px',
                                }}
                              >
                                {formatFromDB(
                                  message.createdAt,
                                  'MMM Do h:mm A'
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <div
                          style={{
                            float: 'left',
                            clear: 'both',
                            overscrollBehavior: 'none',
                          }}
                          ref={messageEnd}
                        ></div>
                      </Grid>
                    ))}
              </Box>
              {selectedChat && (
                <Grid container sx={{ minHeight: '5vh' }}>
                  <Grid
                    container
                    sx={{ bgcolor: 'white', borderRadius: '100px' }}
                  >
                    <InputBase
                      sx={{ ml: 2, flex: 1 }}
                      placeholder="Message Your Trainer if needed"
                      inputProps={{
                        'aria-label': 'Message Your Trainer if needed',
                      }}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <IconButton
                      color="primary"
                      type="button"
                      sx={{ p: '10px' }}
                      aria-label="search"
                      onClick={handleSendMessage}
                    >
                      <SendOutlined />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  )
}

export default ChatDetails
