import { Box, Grid, Paper, Typography, useTheme } from '@mui/material'
import SubTitleB from '../../text/SubTitleB'
import Workout from './Workout'
import SubTitleA from '../../text/SubTitleA'
import S3Image from '../../S3Image'
import formatFromDB from '../../../helpers/datetime'

const EntryCard = (props) => {
  const { active, booking, onClick } = props
  const theme = useTheme()
  return (
    <Paper
      onClick={() => onClick(booking)}
      style={{
        height: 122,
        width: 480,
        borderRadius: 20,
        backgroundColor: active ? theme.palette.primary.light : 'transparent',
      }}
    >
      <Box sx={{ p: 1 }}>
        <Grid
          container
          sx={{
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <Grid item sx={{ p: 1 }}>
            <S3Image
              imagePath={`${booking?.trainer?.id}/Profile`}
              dimensions={{ borderRadius: '50%', width: 48, height: 48 }}
            />
          </Grid>
          <Grid item sx={{ p: 1 }}>
            <SubTitleB text={booking?.trainer?.displayName} />
            <Typography>
              {booking?.session?.city}, {booking?.session?.state}{' '}
            </Typography>
          </Grid>
          <Grid item sx={{ marginLeft: 'auto', p: 1 }}>
            <Workout text={booking?.session?.category} />
          </Grid>
        </Grid>
        <Grid container>
          <SubTitleA
            text={formatFromDB(booking?.startTime, 'MMM Do YYYY, h:mm A')}
          />
        </Grid>
      </Box>
    </Paper>
  )
}

export default EntryCard
