import { Typography } from "@mui/material"

export default function SubTitleA(props) {
  const { text, inline, color } = props
  return (
    <Typography
      sx={{
        fontWeight: 700,
        fontSize: '17px',
        lineHeight: '25.5px',
        padding: '8px 0px',
        display: inline ? 'inline' : 'block',
        color: color ? color : 'gunMetal.main',
      }}
    >
      {text}
    </Typography>
  )
}
