import * as React from 'react'
import {
  Box,
  Button,
  Grid,
  Typography,
  Container,
  IconButton,
} from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined'
import TwitterIcon from '@mui/icons-material/Twitter'
import { useNavigate } from 'react-router-dom'

export default function Footer() {
  const navigate = useNavigate()
  return (
    <Grid container sx={{ p: 5 }}>
      <Grid
        container
        style={{
          textAlign: 'center',
          alignItems: 'flex-start',
        }}
      >
        <Grid item sx={{ p: 2, m: 2 }}>
          <Button target="blank" href="http://24227794.hs-sites.com/en/faqs">
            <Typography sx={{ fontWeight: 700 }}>FAQ</Typography>
          </Button>

          <Typography
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate('/onboarding')}
          >
            Become a member
          </Typography>
          <Typography
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate('/onboarding')}
          >
            Become a trainer
          </Typography>
        </Grid>
        <Grid item sx={{ p: 2, m: 2 }}>
          <Button href="mailto:support@gettraind.net">
            <Typography sx={{ fontWeight: 700 }}>Contact Us</Typography>
          </Button>
          <Typography>support@gettraind.net</Typography>
        </Grid>
      </Grid>
      <Grid container style={{}}>
        <IconButton
          sx={{
            m: 1,
            backgroundColor: 'primary.main',
            height: 50,
            width: 50,
            borderRadius: '50%',
            color: 'white',
          }}
        >
          <InstagramIcon />
        </IconButton>
        <IconButton
          sx={{
            m: 1,
            backgroundColor: 'primary.main',
            height: 50,
            width: 50,
            borderRadius: '50%',
            color: 'white',
          }}
        >
          <FacebookOutlinedIcon />
        </IconButton>
        <IconButton
          sx={{
            m: 1,
            backgroundColor: 'primary.main',
            height: 50,
            width: 50,
            borderRadius: '50%',
            color: 'white',
          }}
        >
          <TwitterIcon />
        </IconButton>
      </Grid>
      <Grid container>
        <Grid item sx={{ m: 2 }}>
          <Typography>&copy;Copyright GET TRAIND 2022</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
