import { Grid, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setCognitoUserField } from '../../redux/slices/authSlice'
import SubTitleA from '../text/SubTitleA'
import TitleC from '../text/TitleC'

const DisplayName = () => {
  const cognitoGivenName = useSelector(
    (state) => state.auth.cognitoUser.given_name
  )
  const cognitoFamilyName = useSelector(
    (state) => state.auth.cognitoUser.family_name
  )
  const dispatch = useDispatch()
  const handleChangeFirstName = (e) => {
    dispatch(
      setCognitoUserField({ field: 'given_name', value: e.target.value })
    )
  }
  const handleChangeLastName = (e) => {
    dispatch(
      setCognitoUserField({ field: 'family_name', value: e.target.value })
    )
  }

  return (
    <>
      <Grid item xs={12}>
        <TitleC text="Update Your Name" />
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
        <SubTitleA text="First Name" />
        <TextField
          sx={{
            '& fieldset': {
              borderRadius: '100px',
            },
          }}
          defaultValue={cognitoGivenName}
          onChange={handleChangeFirstName}
          fullWidth
          placeholder="First Name"
        />
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
        <SubTitleA text="Last Name" />
        <TextField
          sx={{
            '& fieldset': {
              borderRadius: '100px',
            },
          }}
          defaultValue={cognitoFamilyName}
          onChange={handleChangeLastName}
          fullWidth
          placeholder="Last Name"
        />
      </Grid>
    </>
  )
}

export default DisplayName
