import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Typography,
  useTheme,
} from '@mui/material'
import * as React from 'react'

import { useSelector, useDispatch } from 'react-redux'

export default function BorderColorButton(props) {
  const { text, onClick } = props
  const theme = useTheme()
  const StyledButton = styled(Button)(({theme})=>({
    fontWeight: 700,
    fontSize: 15,
    width: 140,
    height: 69,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    }
  }));
  return (
    <StyledButton
      variant="outlined"
      onClick={onClick}
    >
      {text}
    </StyledButton>
  )
}
