import { Grid } from '@mui/material'
import { useGetTrainingByTrainerIdQuery } from '../../../redux/api/api'
import SessionCard from '../../session_card/SessionCard'

const TrainerSessions = (props) => {
  return (
    <Grid
      container
      spacing={3}
      sx={{ width: '100%', justifyContent: 'flex-start' }}
    >
      {props.sessions?.map((session, index) => {
        return (
          <Grid item key={index} xs={3}>
            <SessionCard session={session} />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default TrainerSessions
