import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { ReactComponent as CertifiedBadge } from '../../assets/icons/CertifiedBadge.svg'
import { useParams } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { useState } from 'react'
import {
  useGetSessionDetailsByIdQuery,
  useUpdateSessionMutation,
} from '../../redux/api/api'
const TrainerSetAvailability = () => {
  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState(false)

  const [updateSessionTrigger, updateSessionResponse] =
    useUpdateSessionMutation()
  const { sessionId } = useParams()
  const { data: sessionData } = useGetSessionDetailsByIdQuery(sessionId, {
    skip: !sessionId,
    keepUnusedDataFor: 100000,
    pollingInterval: 100000,
  })

  const [amPm, setAmPm] = useState('am')

  const handleAmClick = (index, toFrom) => {
    if (toFrom === 'from') {
      const copy = [...availabilityStartTimes]
      const dayCopy = { ...availabilityStartTimes[index] }
      dayCopy.amPm = 'am'
      copy[index] = dayCopy
      setAvailabilityStartTimes(copy)
    } else {
      const copy = [...availabilityEndTimes]
      const dayCopy = { ...availabilityEndTimes[index] }
      dayCopy.amPm = 'am'
      copy[index] = dayCopy
      setAvailabilityEndTimes(copy)
    }
    //setAmPm('am')
    //dispatch(setCreateBookingTime({ field: 'amPm', value: 'AM' }))
  }
  const handlePmClick = (index, toFrom) => {
    if (toFrom === 'from') {
      const copy = [...availabilityStartTimes]
      const dayCopy = { ...availabilityStartTimes[index] }
      dayCopy.amPm = 'pm'
      copy[index] = dayCopy
      setAvailabilityStartTimes(copy)
    } else {
      const copy = [...availabilityEndTimes]
      const dayCopy = { ...availabilityEndTimes[index] }
      dayCopy.amPm = 'pm'
      copy[index] = dayCopy
      setAvailabilityEndTimes(copy)
    }
    //dispatch(setCreateBookingTime({ field: 'amPm', value: 'PM' }))
  }

  const currentUserId = useSelector((state) => state.auth.cognitoUser?.sub)
  //const daysSelected = useSelector((state) => state.session?.daysSelected)
  const [availabilityStartTimes, setAvailabilityStartTimes] = useState([
    {
      time: sessionData?.mondayStartTime?.split(' ')[0] || null,
      amPm: sessionData?.mondayStartTime?.split(' ')[1] || 'am',
    },
    {
      time: sessionData?.tuesdayStartTime?.split(' ')[0] || null,
      amPm: sessionData?.tuesdayStartTime?.split(' ')[1] || 'am',
    },
    {
      time: sessionData?.wednesdayStartTime?.split(' ')[0] || null,
      amPm: sessionData?.wednesdayStartTime?.split(' ')[1] || 'am',
    },
    {
      time: sessionData?.thursdayStartTime?.split(' ')[0] || null,
      amPm: sessionData?.thursdayStartTime?.split(' ')[1] || 'am',
    },
    {
      time: sessionData?.fridayStartTime?.split(' ')[0] || null,
      amPm: sessionData?.fridayStartTime?.split(' ')[1] || 'am',
    },
    {
      time: sessionData?.saturdayStartTime?.split(' ')[0] || null,
      amPm: sessionData?.saturdayStartTime?.split(' ')[1] || 'am',
    },
    {
      time: sessionData?.sundayStartTime?.split(' ')[0] || null,
      amPm: sessionData?.sundayStartTime?.split(' ')[1] || 'am',
    },
  ])

  const [availabilityEndTimes, setAvailabilityEndTimes] = useState([
    {
      time: sessionData?.mondayEndTime?.split(' ')[0] || null,
      amPm: sessionData?.mondayEndTime?.split(' ')[1] || 'pm',
    },
    {
      time: sessionData?.tuesdayEndTime?.split(' ')[0] || null,
      amPm: sessionData?.tuesdayEndTime?.split(' ')[1] || 'pm',
    },
    {
      time: sessionData?.wednesdayEndTime?.split(' ')[0] || null,
      amPm: sessionData?.wednesdayEndTime?.split(' ')[1] || 'pm',
    },
    {
      time: sessionData?.thursdayEndTime?.split(' ')[0] || null,
      amPm: sessionData?.thursdayEndTime?.split(' ')[1] || 'pm',
    },
    {
      time: sessionData?.fridayEndTime?.split(' ')[0] || null,
      amPm: sessionData?.fridayEndTime?.split(' ')[1] || 'pm',
    },
    {
      time: sessionData?.saturdayEndTime?.split(' ')[0] || null,
      amPm: sessionData?.saturdayEndTime?.split(' ')[1] || 'pm',
    },
    {
      time: sessionData?.sundayEndTime?.split(' ')[0] || null,
      amPm: sessionData?.sundayEndTime?.split(' ')[1] || 'pm',
    },
  ])

  const handleChangeAvailabilityStartTimes = (index, value) => {
    const copy = [...availabilityStartTimes]
    const dayCopy = { ...availabilityStartTimes[index] }
    dayCopy.time = value
    copy[index] = dayCopy
    setAvailabilityStartTimes(copy)
  }

  const handleChangeAvailabilityEndTimes = (index, value) => {
    const copy = [...availabilityEndTimes]
    const dayCopy = { ...availabilityEndTimes[index] }
    dayCopy.time = value
    copy[index] = dayCopy
    setAvailabilityEndTimes(copy)
  }

  const handleUpdateSessionAvailability = async () => {
    try {
      const formatted = {
        id: sessionId,
        mondayStartTime:
          availabilityStartTimes[0].time + ' ' + availabilityStartTimes[0].amPm,
        mondayEndTime:
          availabilityEndTimes[0].time + ' ' + availabilityEndTimes[0].amPm,
        tuesdayStartTime:
          availabilityStartTimes[1].time + ' ' + availabilityStartTimes[1].amPm,
        tuesdayEndTime:
          availabilityEndTimes[1].time + ' ' + availabilityEndTimes[1].amPm,
        wednesdayStartTime:
          availabilityStartTimes[2].time + ' ' + availabilityStartTimes[2].amPm,
        wednesdayEndTime:
          availabilityEndTimes[2].time + ' ' + availabilityEndTimes[2].amPm,
        thursdayStartTime:
          availabilityStartTimes[3].time + ' ' + availabilityStartTimes[3].amPm,
        thursdayEndTime:
          availabilityEndTimes[3].time + ' ' + availabilityEndTimes[3].amPm,
        fridayStartTime:
          availabilityStartTimes[4].time + ' ' + availabilityStartTimes[4].amPm,
        fridayEndTime:
          availabilityEndTimes[4].time + ' ' + availabilityEndTimes[4].amPm,
        saturdayStartTime:
          availabilityStartTimes[5].time + ' ' + availabilityStartTimes[5].amPm,
        saturdayEndTime:
          availabilityEndTimes[5].time + ' ' + availabilityEndTimes[5].amPm,
        sundayStartTime:
          availabilityStartTimes[6].time + ' ' + availabilityStartTimes[6].amPm,
        sundayEndTime:
          availabilityEndTimes[6].time + ' ' + availabilityEndTimes[6].amPm,
      }
      console.log('formatted', formatted)
      const update = await updateSessionTrigger(formatted).unwrap()
      setSnackbarSuccessOpen(true)
      console.log('updated', update)
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <Box
      sx={{
        border: 2,
        borderColor: 'black',
        borderRadius: '12px',
        width: '100%',
      }}
    >
      <Grid
        container
        sx={{
          p: 4,
          position: 'relative',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Typography
          variant="h6"
          onClick={() => console.log('test: ', sessionData)}
        >
          Set Your Session Availability
        </Typography>
        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day, index) => {
          return (
            <Grid
              container
              sx={{
                p: 4,
                position: 'relative',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 4,
              }}
            >
              <Grid item>
                <Typography variant="body1">{day}</Typography>
              </Grid>

              <Grid item>
                <Grid container sx={{ minWidth: '200px' }}>
                  <Typography variant="body1">From:</Typography>
                </Grid>
                <Grid container>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">From</InputLabel>
                    <Select
                      sx={{ minWidth: '150px' }}
                      labelId="demo-simple-select-label"
                      value={availabilityStartTimes[index].time}
                      label="From"
                      onChange={(event) =>
                        handleChangeAvailabilityStartTimes(
                          index,
                          event.target.value
                        )
                      }
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={11}>11</MenuItem>
                      <MenuItem value={12}>12</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container>
                  <Box
                    sx={{
                      border: 1,
                      borderColor: 'primary.main',
                      borderRadius: '30px',
                      m: 2,
                      mr: 0,
                    }}
                  >
                    <IconButton
                      onClick={() => handleAmClick(index, 'from')}
                      sx={{
                        height: '40px',
                        width: '40px',
                        color:
                          availabilityStartTimes[index].amPm === 'am'
                            ? 'white'
                            : 'primary.main',
                        bgcolor:
                          availabilityStartTimes[index].amPm === 'am'
                            ? 'primary.main'
                            : 'inherit',
                        m: 0.5,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: '15px',
                          lineHeight: '22.5px',
                        }}
                      >
                        AM
                      </Typography>
                    </IconButton>
                    <IconButton
                      onClick={() => handlePmClick(index, 'from')}
                      sx={{
                        height: '40px',
                        width: '40px',
                        color:
                          availabilityStartTimes[index].amPm === 'pm'
                            ? 'white'
                            : 'primary.main',
                        bgcolor:
                          availabilityStartTimes[index].amPm === 'pm'
                            ? 'primary.main'
                            : 'inherit',
                        m: 0.5,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: '15px',
                          lineHeight: '22.5px',
                        }}
                      >
                        PM
                      </Typography>
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container sx={{ minWidth: '200px' }}>
                  <Typography variant="body1">Until:</Typography>
                </Grid>
                <Grid container sx={{}}>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Until</InputLabel>
                    <Select
                      sx={{ minWidth: '150px' }}
                      labelId="demo-simple-select-label"
                      value={availabilityEndTimes[index].time}
                      label="Until"
                      onChange={(event) =>
                        handleChangeAvailabilityEndTimes(
                          index,
                          event.target.value
                        )
                      }
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={11}>11</MenuItem>
                      <MenuItem value={12}>12</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container sx={{}}>
                  <Box
                    sx={{
                      border: 1,
                      borderColor: 'primary.main',
                      borderRadius: '30px',
                      m: 2,
                      mr: 0,
                    }}
                  >
                    <IconButton
                      onClick={() => handleAmClick(index, 'until')}
                      sx={{
                        height: '40px',
                        width: '40px',
                        color:
                          availabilityEndTimes[index].amPm === 'am'
                            ? 'white'
                            : 'primary.main',
                        bgcolor:
                          availabilityEndTimes[index].amPm === 'am'
                            ? 'primary.main'
                            : 'inherit',
                        m: 0.5,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: '15px',
                          lineHeight: '22.5px',
                        }}
                      >
                        AM
                      </Typography>
                    </IconButton>
                    <IconButton
                      onClick={() => handlePmClick(index, 'until')}
                      sx={{
                        height: '40px',
                        width: '40px',
                        color:
                          availabilityEndTimes[index].amPm === 'pm'
                            ? 'white'
                            : 'primary.main',
                        bgcolor:
                          availabilityEndTimes[index].amPm === 'pm'
                            ? 'primary.main'
                            : 'inherit',
                        m: 0.5,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: '15px',
                          lineHeight: '22.5px',
                        }}
                      >
                        PM
                      </Typography>
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
      <Grid container sx={{ pb: 2 }}>
        <Button onClick={handleUpdateSessionAvailability} variant="contained">
          <Typography sx={{ fontWeight: 700, color: 'white' }}>
            Save Availability
          </Typography>
        </Button>
        <Snackbar
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={snackbarSuccessOpen}
          onClose={() => setSnackbarSuccessOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarSuccessOpen(false)}
            severity="success"
            sx={{ width: '100%' }}
          >
            Successfully Saved Availability!
          </Alert>
        </Snackbar>
      </Grid>
    </Box>
  )
}

export default TrainerSetAvailability
