import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import DashboardOverview from './DashboardOverview'
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from '@mui/material'
import PaymentsHistoryTable from './PaymentsHistoryTable'
import BrowseSessions from '../browser_sessions/BrowseSessions'
import ChatPreview from '../messages/ChatPreview'
import {
  Search,
  SearchOffOutlined,
  SearchOutlined,
  SendOutlined,
} from '@mui/icons-material'
import {
  useCreateMessageMutation,
  useGetChatRoomByMemberIdQuery,
  useGetChatsQuery,
  useGetMessageByChatRoomIdQuery,
  useGetMessageByReceiverSenderIdQuery,
  useGetMessagesQuery,
} from '../../redux/api/api'
import { API, graphqlOperation } from 'aws-amplify'
import { createMessage, createMessageRoom } from '../../graphql/mutations'
import { compareDates, formatFromDB } from '../../helpers/datetime'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import theme from '../../styles/theme'
import { useNavigate } from 'react-router-dom'
import {
  setSelectedChat,
  setVirtualSessionModalOpen,
} from '../../redux/slices/uiSlice'
import LiveModal from '../modals/LiveModal'

export default function DashboardMessagesPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const large = useMediaQuery(theme.breakpoints.up('sm'))
  const handleClickChat = (chat) => {
    if (large) {
      dispatch(setSelectedChat(chat))
    } else {
      dispatch(setSelectedChat(chat))
      navigate(`/chat-details/${chat.id}`)
    }
  }
  const userId = useSelector((state) => state.auth.cognitoUser?.sub)
  const isTrainer = useSelector((state) => state.auth.isTrainer)
  //const userId = cognitoUser?.sub
  const messageEnd = React.useRef(null)
  React.useEffect(() => {
    if (messageEnd.current) {
      messageEnd.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  const {
    data: chatRooms,
    isLoading: isLoadingChatRoom,
    isSuccess: isSuccessChatRoom,
    refetch: refetchChatRooms,
  } = useGetChatsQuery(
    { userType: isTrainer ? 'trainer' : 'member', userId },
    { skip: !userId, pollingInterval: 10000 }
  )

  const selectedChat = useSelector((state) => state.ui.selectedChat)

  const Chat = (props) => {
    const [message, setMessage] = React.useState('')

    const [
      createMessageTrigger, // This is the mutation trigger
      //{ isLoading: isUpdating }, // This is the destructured mutation result
    ] = useCreateMessageMutation()

    const handleSendMessage = async () => {
      if (message.trim() === '') {
        return
      }
      try {
        // const newMessageRoom = {
        //   messageRoomMemberId: '95da19a8-b876-4d50-aaef-e06d0f7416b7',
        //   messageRoomTrainerId: 'b6dc0151-a2ba-4aca-8660-3cc6f0d1f834',
        // }
        const newMessage = {
          content: message.trim(),
          read: 'false',
          messageRoomMessagesId: selectedChat.id,
          messageSenderId: userId,
        }
        setMessage('')
        // const messageRoomCreate = await API.graphql(
        //   graphqlOperation(createMessageRoom, {
        //     input: newMessageRoom,
        //   })
        // )
        const createdMessage = await createMessageTrigger(newMessage).unwrap()
        console.log('created message: ', createdMessage)
        console.log('selectedChat: ', selectedChat)
        var copyMessages = [...selectedChat.messages.items, createdMessage]
        console.log('copyMessages: ', copyMessages)
        dispatch(
          setSelectedChat({
            ...selectedChat,
            messages: {
              ...selectedChat.messages,
              items: copyMessages,
            },
          })
        )

        refetchChatRooms()
      } catch (error) {
        console.log('error creating message: ', error)
      }
    }
    return (
      <Grid item sx={{ bgcolor: '#F6F7F8', height: '100%' }} xs={8}>
        <Grid
          container
          alignItems="flex-end"
          sx={{ height: '100%', pl: 3, pr: 3 }}
        >
          <Box
            style={{
              maxHeight: '45vh',
              overflow: 'auto',
              width: '100%',
            }}
          >
            {selectedChat &&
              [...selectedChat.messages.items]
                .sort((a, b) =>
                  dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1
                )
                .map((message) => (
                  <Grid
                    key={message.id}
                    container
                    justifyContent={
                      message.messageSenderId !== userId ? 'left' : 'right'
                    }
                  >
                    <Grid item sx={{ p: 1, maxWidth: '70%' }}>
                      <Grid
                        container
                        justifyContent={'left'}
                        sx={{
                          p: 2,
                          bgcolor:
                            message.messageSenderId !== userId
                              ? 'white'
                              : 'primary.light',
                          borderRadius:
                            message.messageSenderId !== userId
                              ? '20px 20px 20px 0px'
                              : '20px 20px 0px 20px',
                        }}
                      >
                        <Typography>{message.content}</Typography>
                        <Grid
                          container
                          justifyContent={
                            message.messageSenderId !== userId
                              ? 'left'
                              : 'right'
                          }
                        >
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: '9px',
                              lineHeight: '13.5px',
                            }}
                          >
                            {formatFromDB(message.createdAt, 'MMM Do h:mm A')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <div
                      style={{
                        float: 'left',
                        clear: 'both',
                        overscrollBehavior: 'none',
                      }}
                      ref={messageEnd}
                    ></div>
                  </Grid>
                ))}
          </Box>
          {selectedChat && (
            <Grid container sx={{ minHeight: '5vh' }}>
              <Grid container sx={{ bgcolor: 'white', borderRadius: '100px' }}>
                <InputBase
                  sx={{ ml: 2, flex: 1 }}
                  placeholder="Message Your Trainer if needed"
                  inputProps={{
                    'aria-label': 'Message Your Trainer if needed',
                  }}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <IconButton
                  color="primary"
                  type="button"
                  sx={{ p: '10px' }}
                  aria-label="search"
                  onClick={handleSendMessage}
                >
                  <SendOutlined />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }
  if (!isSuccessChatRoom || isLoadingChatRoom || !chatRooms) {
    return (
      <>
        <CircularProgress />
      </>
    )
  } else {
    return (
      <Box
        sx={{
          width: '100%',
          minHeight: '50vh',
          bgcolor: 'white',
          overscrollBehavior: 'none',
        }}
      >
        <Grid container sx={{ bgcolor: '#EBEBEB', height: '70vh' }}>
          <Grid item sx={{ height: '100%', p: 2 }} xs={12} md={4}>
            <Grid container sx={{ minHeight: '5vh', p: 1, mb: 2 }}>
              <Grid container sx={{ bgcolor: 'white', borderRadius: '100px' }}>
                <InputBase
                  sx={{ ml: 2, flex: 1 }}
                  placeholder={`Search Messages`}
                  inputProps={{ 'aria-label': 'search Messages' }}
                />
                <IconButton
                  color="primary"
                  type="button"
                  sx={{ p: '10px' }}
                  aria-label="search"
                >
                  <Search />
                </IconButton>
              </Grid>
            </Grid>
            <Box style={{ maxHeight: '60vh', overflow: 'auto', width: '100%' }}>
              {chatRooms?.length === 0 ? (
                <Typography variant="h6">No Messages</Typography>
              ) : (
                <Typography variant="h6">
                  {chatRooms?.length} Message{chatRooms?.length > 1 && 's'}
                </Typography>
              )}
              {/* <LiveModal />
              <Typography
                onClick={() => dispatch(setVirtualSessionModalOpen(true))}
              >
                Testing!!!
              </Typography> */}

              {chatRooms.map((chatRoom) => (
                <Grid key={chatRoom.id} container>
                  <ChatPreview
                    chat={chatRoom}
                    partnerID={
                      isTrainer
                        ? chatRoom?.messageRoomMemberId
                        : chatRoom?.messageRoomTrainerId
                    }
                    trainer={chatRoom.messageRoomTrainerId}
                    selected={selectedChat?.id === chatRoom.id}
                    onClick={() => {
                      handleClickChat(chatRoom)
                    }}
                  />
                </Grid>
              ))}
            </Box>
          </Grid>
          {large && <Chat />}
        </Grid>
      </Box>
    )
  }
}
