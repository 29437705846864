import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SubTitleA from '../text/SubTitleA'
import PopMap from '../location/PopMap'
import {
  setSessionCommuteField,
  setSessionIndoorLocationField,
  setSessionOutdoorLocationField,
} from '../../redux/slices/formsSlice'

const LocationsCardEditMode = (props) => {
  const { session } = props
  const [indoorChecked, setIndoorChecked] = useState(
    session?.indoorLocation?.address
      ? session?.indoorLocation?.address !== ''
      : false
  )
  const [outdoorChecked, setOutdoorChecked] = useState(
    session?.outdoorLocation?.address
      ? session?.outdoorLocation?.address !== ''
      : false
  )
  const [checkedCommute, setCheckedCommute] = useState(
    session?.commuteToMember ? session?.commuteToMember : false
  )

  const [checkedVirtual, setCheckedVirtual] = useState(
    session?.virtual ? session?.virtual : false
  )

  const [commuteMaxDistance, setCommuteMaxDistance] = useState(
    session?.maxCommuteDistance ? session?.maxCommuteDistance : 0
  )

  const [commuteFlatFee, setCommuteFlatFee] = useState(
    session?.commuteFlatFee ? session?.commuteFlatFee : 0
  )

  //TODO: useQuery for Location ID

  const dispatch = useDispatch()
  const createSessionForm = useSelector(
    (state) => state.forms.createSessionForm
  )

  const handleIndoorCheckClick = (e) => {
    console.log('handleIndoorCheckClick', e.target.checked)
    setIndoorChecked(e.target.checked)
    // TODO: trigger location update here for enabled
    // ALSO CHECK IF INDOOR LOCATION HAS AN ID OR NOT (IF NOT, CREATE ONE)

    console.log('indoor location: ', session.indoorLocation)
    // dispatch(
    //   setSessionIndoorField({
    //     field: 'indoorLocation',
    //     value: e.target.checked,
    //   })
    // )
  }

  const handleChangeIndoorField = (address) => {
    console.log('change indoor field: ', address)
    var cityContext = address?.context?.find((item) =>
      item.id.includes('place')
    )
    dispatch(
      setSessionIndoorLocationField({
        field: 'address',
        value: address.place_name,
      })
    )
    if (cityContext) {
      dispatch(
        setSessionOutdoorLocationField({
          field: 'city',
          value: cityContext?.text,
        })
      )
    }
    dispatch(
      setSessionIndoorLocationField({
        field: 'lat',
        value: address.center[1],
      })
    )
    dispatch(
      setSessionIndoorLocationField({
        field: 'lng',
        value: address.center[0],
      })
    )
  }

  const handleChangeIndoorDirections = (e) => {
    console.log('change indoor directions: ', e.target.value)
    dispatch(
      setSessionIndoorLocationField({
        field: 'directions',
        value: e.target.value,
      })
    )
  }

  const handleChangeOutdoorDirections = (e) => {
    console.log('change outdoor directions: ', e.target.value)
    dispatch(
      setSessionOutdoorLocationField({
        field: 'directions',
        value: e.target.value,
      })
    )
  }

  const handleChangeOutdoorField = (address) => {
    console.log('change outdoor field: ', address)
    var cityContext = address?.context?.find((item) =>
      item?.id.includes('place')
    )
    dispatch(
      setSessionOutdoorLocationField({
        field: 'address',
        value: address.place_name,
      })
    )
    if (cityContext) {
      dispatch(
        setSessionOutdoorLocationField({
          field: 'city',
          value: cityContext?.text,
        })
      )
    }

    dispatch(
      setSessionOutdoorLocationField({
        field: 'lat',
        value: address.center[1],
      })
    )
    dispatch(
      setSessionOutdoorLocationField({
        field: 'lng',
        value: address.center[0],
      })
    )
  }

  const handleOutdoorCheckClick = (e) => {
    console.log('handleOutdoorCheckClick', e.target.checked)
    setOutdoorChecked(e.target.checked)
    // dispatch(
    //   setSessionIndoorField({
    //     field: 'indoorLocation',
    //     value: e.target.checked,
    //   })
    // )
  }

  const handleChangeVirtual = (e) => {
    console.log('handleChangeVirtual', e.target.checked)
    setCheckedVirtual(e.target.checked)
    dispatch(
      setSessionCommuteField({
        field: 'virtual',
        value: e.target.checked,
      })
    )
  }

  const handleChangeCommute = (e) => {
    console.log('handleChangeCommute', e.target.checked)
    setCheckedCommute(e.target.checked)
    dispatch(
      setSessionCommuteField({
        field: 'commuteToMember',
        value: e.target.checked,
      })
    )
  }
  const handleChangeMaxCommuteDistance = (e) => {
    dispatch(
      setSessionCommuteField({
        field: 'maxCommuteDistance',
        value: e.target.value,
      })
    )
    setCommuteMaxDistance(e.target.value)
  }

  const handleChangeCommuteFlatFee = (e) => {
    dispatch(
      setSessionCommuteField({
        field: 'commuteFlatFee',
        value: e.target.value,
      })
    )
    setCommuteFlatFee(e.target.value)
  }

  return (
    <Grid
      container
      sx={{
        p: 2,
        bgcolor: 'white',
        borderRadius: '16px',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
      }}
    >
      <Grid item xs={12} sx={{ justifyContent: 'flex-start', pl: 2 }}>
        <Typography sx={{ fontWeight: 700, fontSize: '24px' }}>
          For search purposes, what major metropolitan area are you closest to?
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Metropolitan Area
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={exercise.name}
            // label="Exercise Name"
            // onChange={handleChangeExerciseName}
          >
            <MenuItem value={10}>Los Angeles</MenuItem>
            <MenuItem value={20}>Atlanta</MenuItem>
            <MenuItem value={30}>Miami</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        container
        justifyContent="left"
        sx={{ p: 2, pb: 0 }}
        onClick={() => console.log('test: ', session?.indoorLocation)}
      >
        <Typography
          sx={{
            color: 'gunMetal.primary',
            fontWeight: 700,
            fontSize: '26px',
            lineHeight: '39px',
          }}
        >
          Indoor Location
        </Typography>
      </Grid>
      <Grid container justifyContent="left" sx={{ p: 2, pt: 0 }}>
        <Typography
          sx={{
            color: 'gunMetal.primary',
            fontWeight: 400,
            fontSize: '15px',
            lineHeight: '22.5px',
          }}
        >
          They come to your basecamp, gym or studio:
        </Typography>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        alignItems="start"
        sx={{ p: 2, pt: 0 }}
      >
        <Grid item>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={indoorChecked}
                  onChange={handleIndoorCheckClick}
                />
              }
              label="Gym or Indoor Facility Available"
            />
          </FormGroup>
        </Grid>

        {(indoorChecked || session?.indoorLocation?.address) && (
          <>
            <Grid item>
              <SubTitleA
                text={
                  'Address: ' +
                  (createSessionForm?.indoorLocation?.address ||
                    session?.indoorLocation?.address)
                }
              />
            </Grid>
            <Grid item sx={{ width: '100%', height: 400 }}>
              <PopMap
                editMode={true}
                setLocationData={handleChangeIndoorField}
                lat={createSessionForm?.indoorLocation?.lat}
                lng={createSessionForm?.indoorLocation?.lng}
              />
            </Grid>
            <Grid container justifyContent="flex-start">
              <SubTitleA text="Additional Directions (Optional) " />
            </Grid>
            <Grid container justifyContent="flex-start">
              <TextField
                multiline
                sx={{
                  width: '100%',
                  pb: '32px',
                  '& fieldset': { borderRadius: '25px' },
                }}
                placeholder=""
                value={createSessionForm?.indoorLocation?.directions}
                onChange={handleChangeIndoorDirections}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid container justifyContent="left" sx={{ p: 2, pb: 0 }}>
        <Typography
          sx={{
            color: 'gunMetal.primary',
            fontWeight: 700,
            fontSize: '26px',
            lineHeight: '39px',
          }}
        >
          Outdoor Location
        </Typography>
      </Grid>
      <Grid container justifyContent="left" sx={{ p: 2, pt: 0 }}>
        <Typography
          sx={{
            color: 'gunMetal.primary',
            fontWeight: 400,
            fontSize: '15px',
            lineHeight: '22.5px',
          }}
        >
          Meet up at a local park or outdoor location:
        </Typography>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        alignItems="start"
        sx={{ p: 2, pt: 0 }}
      >
        <Grid item>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={outdoorChecked}
                  onChange={handleOutdoorCheckClick}
                />
              }
              label="Outdoor Location Available"
            />
          </FormGroup>
        </Grid>
        {(outdoorChecked || session?.outdoorLocation?.address) && (
          <>
            <Grid item>
              <SubTitleA
                text={
                  'Address: ' +
                  (createSessionForm?.outdoorLocation?.address ||
                    session?.outdoorLocation?.address)
                }
              />
            </Grid>
            <Grid item sx={{ width: '100%', height: 400 }}>
              <PopMap
                editMode={true}
                setLocationData={handleChangeOutdoorField}
                lat={createSessionForm?.outdoorLocation?.lat}
                lng={createSessionForm?.outdoorLocation?.lng}
              />
            </Grid>
            <Grid container justifyContent="flex-start">
              <SubTitleA text="Additional Directions (Optional) " />
            </Grid>
            <Grid container justifyContent="flex-start">
              <TextField
                multiline
                sx={{
                  width: '100%',
                  pb: '32px',
                  '& fieldset': { borderRadius: '25px' },
                }}
                placeholder=""
                value={createSessionForm?.outdoorLocation?.directions}
                onChange={handleChangeOutdoorDirections}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Grid container justifyContent="left" sx={{ p: 2 }}>
        <Typography
          sx={{
            color: 'gunMetal.primary',
            fontWeight: 700,
            fontSize: '26px',
            lineHeight: '39px',
          }}
        >
          Commute to Member
        </Typography>
        <Grid container justifyContent="left" sx={{ p: 0 }}>
          <Switch checked={checkedCommute} onChange={handleChangeCommute} />
          <Typography onClick={() => console.log('SESSION!!', session)}>
            Enable if you are willing to commute to your Member Bookings.
          </Typography>
        </Grid>
        {checkedCommute && (
          <>
            <Grid container justifyContent="left" sx={{ p: 0 }}>
              <Typography>
                {' '}
                What is the max distance you're willing to travel?
              </Typography>
              <FormControl sx={{ m: 1 }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={commuteMaxDistance}
                  label="maxCommuteDistance"
                  onChange={handleChangeMaxCommuteDistance}
                >
                  <MenuItem value={5}>5 MI</MenuItem>
                  <MenuItem value={10}>10 MI</MenuItem>
                  <MenuItem value={15}>15 MI</MenuItem>
                  <MenuItem value={20}>20 MI</MenuItem>
                  <MenuItem value={25}>25 MI</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid container justifyContent="left" sx={{ p: 0 }}>
              <Typography>
                Up to 10 miles free of commute to Member and after that is a
                Flat Fee
              </Typography>
            </Grid>
            <Grid container justifyContent="left" sx={{ p: 0 }}>
              <Typography>Input dollar amount</Typography>
              <FormControl sx={{ m: 1 }}>
                <OutlinedInput
                  type="number"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  id="outlined-adornment-amount"
                  value={commuteFlatFee}
                  onChange={handleChangeCommuteFlatFee}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </>
        )}
        <Grid container justifyContent="left" sx={{ pt: 2 }}>
          <Typography
            sx={{
              color: 'gunMetal.primary',
              fontWeight: 700,
              fontSize: '26px',
              lineHeight: '39px',
            }}
          >
            Virtual Sessions
          </Typography>
          <Grid container justifyContent="left" sx={{ p: 0 }}>
            <Switch checked={checkedVirtual} onChange={handleChangeVirtual} />
            <Typography onClick={() => console.log('SESSION!!', session)}>
              Enable if you offer this session virtually
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LocationsCardEditMode
