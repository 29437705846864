import { Button, styled } from '@mui/material'

const FitnessGoal = (props) => {
  const { text } = props
  const StyledButton = styled(Button)(({ theme }) => ({
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '25.5px',
    padding: '10.5px 16px',
    marginRight: '16px',
    color: 'gunMetal.main',
    backgroundColor: theme.palette.primary.light,
    '&:disabled': {
      backgroundColor: theme.palette.primary.light,
    },
  }))
  return <StyledButton variant="contained">{text}</StyledButton>
}

export default FitnessGoal
