import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import SessionMain from '../components/session_page/SessionMain'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { useGetMemberByIdQuery } from '../redux/api/api'
import theme from '../styles/theme'

function TrainerThankYou() {
  const large = useMediaQuery(theme.breakpoints.up('sm'))
  const navigate = useNavigate()
  const userEmail = useSelector((state) => state.auth?.cognitoUser?.email)

  const userId = useSelector((state) => state.auth.cognitoUser?.sub)
  const { data: currentUser } = useGetMemberByIdQuery(userId)

  async function generateTruaLink() {
    try {
      const link = await axios.post(
        'https://6vax2q3yum73q2nan4j2nxs2su0qorjn.lambda-url.us-east-2.on.aws/',
        {
          crim: true,
          email: userEmail,
        }
      )
      console.log('link: ', link)
      // navigate to link
      const w = window.open('', '_blank')
      const url = link?.data
      w.location = url
    } catch (error) {
      console.log('error resending code:', error)
    }
  }

  async function generateTruaLinkVirtualOnly() {
    try {
      const link = await axios.post(
        'https://6vax2q3yum73q2nan4j2nxs2su0qorjn.lambda-url.us-east-2.on.aws/',
        {
          email: userEmail,
        }
      )
      console.log('link: ', link)
      // navigate to link
      const w = window.open('', '_blank')
      const url = link?.data
      w.location = url
    } catch (error) {
      console.log('error resending code:', error)
    }
  }
  return (
    <Grid container>
      <Header />
      <Grid
        container
        style={{
          background:
            'linear-gradient(225.09deg, #00C7C7 14.44%, #0BDE8F 85.56%)',
          width: '100%',
          height: large ? '50vh' : '80vh',
          margin: 0,
          padding: 0,
        }}
        sx={{ m: large ? 2 : 0 }}
      >
        <Box
          sx={{
            bgcolor: 'white',

            justifyContent: 'center',
            alignItems: 'center',

            pt: large ? 4 : 2,
            pb: large ? 4 : 2,
          }}
        >
          {/*content*/}
          <Grid container sx={{ height: '100%' }}>
            <Grid container sx={{ p: 1 }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: large ? '64px' : '36px',
                  lineHeight: large ? '96px' : '48px',
                  color: 'gunMetal.main',
                  textAlign: 'center',
                }}
              >
                {' '}
                Welcome to Get Traind!
              </Typography>
            </Grid>
            <Grid container sx={{ mb: 4, p: 1 }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: large ? '34px' : '18px',
                  lineHeight: large ? '51px' : '24px',
                  color: 'gunMetal.main',
                  textAlign: 'center',
                }}
              >
                {' '}
                You're standing at the finish line...
              </Typography>
            </Grid>
            <Grid container sx={{ mb: 4, p: 1 }}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: large ? '34px' : '18px',
                  lineHeight: large ? '51px' : '24px',
                  color: 'gunMetal.main',
                  textAlign: 'center',
                }}
              >
                {currentUser?.verified
                  ? `Your verification is complete!`
                  : `Verify your identity below and we will email you when you are
                ready to go!`}
              </Typography>
            </Grid>
            <Grid container sx={{ mb: 4, p: 1 }}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: large ? '24px' : '12px',
                  lineHeight: large ? '36px' : '24px',
                  color: 'gunMetal.main',
                  textAlign: 'center',
                }}
              >
                {currentUser?.verified
                  ? ``
                  : `* Have your ID, your training certifications, and CPR certification ready to upload.`}
              </Typography>
            </Grid>

            {currentUser?.verified && (
              <Grid container sx={{ mb: 4 }}>
                <Button
                  variant="contained"
                  onClick={() => navigate(`/trainer/${userId}`)}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: '15px',
                      lineHeight: '22.5px',
                      color: 'gunMetal.main',
                      textAlign: 'center',
                      m: 1,
                    }}
                  >
                    Go To Your Profile
                  </Typography>
                </Button>
              </Grid>
            )}
            {!currentUser?.verified && (
              <Grid container spacing={3} sx={{ mb: 4 }}>
                <Grid item>
                  <Button variant="contained" onClick={generateTruaLink}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '15px',
                        lineHeight: '22.5px',
                        color: 'white',
                        textAlign: 'center',
                        m: 1,
                      }}
                    >
                      Verify My Identity
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={generateTruaLinkVirtualOnly}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '15px',
                        lineHeight: '22.5px',
                        color: 'gunMetal.main',
                        textAlign: 'center',
                        m: 1,
                      }}
                    >
                      Verify My Identity (Virtual Only)
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
      <Footer />
    </Grid>
  )
}

export default TrainerThankYou
