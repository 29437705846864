const categories = [
  {
    parent: 'Personal',
    name: 'Resistance',
    description:
      'A variety of exercises using different types of equipment to target specific muscle groups. Can include calisthenics, isometrics (holding positions) and/or plyometrics (jump training), and can be aerobic or anaerobic depending on your goals.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="M479.812-689.421q20.971 0 34.985-14.108 14.015-14.109 14.015-34.515 0-20.971-13.963-34.797-13.963-13.826-34.849-13.826-20.594 0-34.515 13.908-13.92 13.909-13.92 34.715 0 20.241 13.92 34.432 13.921 14.191 34.327 14.191Zm121.507 0h62.84q33.319 0 58.073 21.515 24.754 21.515 29.174 54.428l57.131 402.173q5.652 39.986-20.474 70.356-26.126 30.37-66.672 30.37H238.986q-40.712 0-66.943-30.37-26.232-30.37-20.58-70.356l57.131-402.173q4.42-32.913 29.174-54.428 24.754-21.515 58.45-21.515h62.84q-5.769-11.536-8.443-23.359-2.674-11.822-2.674-25.453 0-54.553 38.58-93.305 38.58-38.753 93.479-38.753t93.667 38.753q38.768 38.752 38.768 93.305 0 13.719-2.862 25.498-2.862 11.778-8.254 23.314Z" />
      </svg>
    ),
  },
  {
    parent: 'Personal',
    name: 'Power Yoga',
    description:
      'A fast-paced style of yoga with a focus on building strength and endurance. Prepare to sweat, feel the burn, and have an awesome savasana.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="m490-80-20-398-149-52H40v-60h243l275-197 39 46-149 107 97 34 340-200 35 42-346 251-24 427h-60ZM240.08-647q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Z" />
      </svg>
    ),
  },
  {
    parent: 'Personal',
    name: 'Weight Training',
    description:
      'Strength Training with weights. One of the best ways to increase strength, endurance, and muscle size. Choose whether you want to meet at a gym, have weights of your own, or need your trainer to bring some for you.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="M509.377-104.884q-12.305-12.304-12.305-28.703 0-16.398 12.305-29.08l113.579-113.014-347.275-347.275-113.014 113.579q-12.45 12.45-28.892 12.044-16.442-.406-28.891-12.478-12.073-12.073-12.073-29.203 0-17.131 12.073-29.203l27.014-27.015-25.13-25.13Q94.13-633.377 94.13-651.42q0-18.044 12.638-30.682l54.695-54.695-15.564-15.565q-13.015-13.014-12.797-31.275.217-18.261 13.231-31.276 13.015-12.638 31.276-12.638t30.899 12.638l15.13 15.942 54.26-54.261q12.638-12.638 30.682-12.638 18.043 0 31.058 12.638l25.565 25.565 28.014-27.449q12.305-12.305 28.703-12.305 16.399 0 28.703 12.305 12.305 12.304 12.305 28.703 0 16.398-12.305 28.703L337.421-684.696l347.275 347.275L797.71-450.623q12.073-12.45 29.015-12.45t29.391 12.45q12.073 12.072 12.073 28.92 0 16.848-12.073 28.92l-28.014 28.015 25.13 25.13q12.638 13.015 12.638 31.058 0 18.044-12.638 30.682l-55.362 55.362 15.565 15.564q13.014 13.015 13.014 31.059 0 18.043-13.014 30.681-12.638 13.015-30.566 13.015-17.927 0-30.942-13.015l-15.565-15.797-54.26 54.261Q669.464-94.13 651.42-94.13q-18.043 0-31.058-12.638l-25.565-25.942-28.014 27.826q-12.305 12.305-28.703 12.305-16.399 0-28.703-12.305Z" />
      </svg>
    ),
  },
  // Exercise
  {
    parent: 'Personal',
    name: 'CrossFit',
    description:
      'Varied functional movements performed at high intensity with specific equipment to strengthen muscles used in everyday activities.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="M440-200q-101 0-170.5-69.5T200-440q0-11 1-23t3-21q-5 2-12 3t-12 1q-44 0-72-28t-28-72q0-44 28-72t72-28q31 0 57 18.5t37 46.5q31-28 74.5-46.5T441-680h439v120H680v120q0 101-69.5 170.5T440-200ZM180-540q18 0 29-11t11-29q0-18-11-29t-29-11q-18 0-29 11t-11 29q0 18 11 29t29 11Zm260 240q60 0 100-40t40-100q0-60-40-100t-100-40q-60 0-100 40t-40 100q0 60 40 100t100 40Zm0-60q33 0 56.5-23.5T520-440q0-33-23.5-56.5T440-520q-33 0-56.5 23.5T360-440q0 33 23.5 56.5T440-360Zm0-80Z" />
      </svg>
    ),
  },

  // Sports handball
  {
    parent: 'Personal',
    name: 'Core',
    description:
      'Your core muscles are key to stabilizing your spine and pelvis, and strengthening them improves posture, balance, and functional movement propensity. Having a strong core basically makes you better at everything you want to do with your body. Yes, I mean everything.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="M400.08-734q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Zm259.779 504Q706-230 738-261.859q32-31.859 32-78T738.141-418q-31.859-32-78-32T582-418.141q-32 31.859-32 78T581.859-262q31.859 32 78 32ZM864-94 757-201q-22 15-46.324 23-24.325 8-50.676 8-70.833 0-120.417-49.618Q490-269.235 490-340.118 490-411 539.618-460.5q49.617-49.5 120.5-49.5Q731-510 780.5-460.417 830-410.833 830-340q0 26.351-8 50.676Q814-265 799-243l107 107-42 42ZM447-80v-173q10 25 25 46.5t35 38.5v88h-60Zm-154 0v-533q-64.033-4.583-127.017-13.292Q103-635 40-650l15-60q85.268 20 171.044 28.5 85.776 8.5 174.09 8.5 88.314 0 174.09-8.5Q660-690 745-710l15 60q-63 15-125.983 23.708Q571.033-617.583 507-613v101q-35 32-56 76.5T430-340h-77v260h-60Z" />
      </svg>
    ),
  },
  //Kayak

  {
    parent: 'Adventure',
    name: 'Surfing',
    description:
      'We ALL want to know how to surf. Incredible workout and so fun once you get the hang of it. Our surf instructors are ready to help you commune with mother nature, get ripped, or just check out the waves in their town any time the surf is up!',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="M80-40v-60h40q32 0 61.5-10.5T240-149q29 28 58.5 38.5T360-100q31 0 62-11t58-38q29 28 58.5 38.5T600-100q31 0 62-11t58-38q27 27 58 38t62 11h40v60h-40q-29 0-59.5-8.5T720-80q-30 23-60.5 31.5T600-40q-29 0-59.5-8.5T480-80q-30 23-60.5 31.5T360-40q-29 0-59.5-8.5T240-80q-30 23-60.5 31.5T120-40H80Zm262-756 218 40q11 2 22.5 9.5T602-725l35 62q26 46 71.5 75T810-557v60q-72-3-132.5-42.5T580-642q-4 3-18 12.5t-31.5 21Q513-597 496-585.5T470-568q11 8 33.5 25.5t47 37q24.5 19.5 47 37.5l32.5 26v161q17 12 32 23.5t31 24.5q-20 16-43.5 26.5T600-196q-34 0-65-18t-55-47q-24 29-55 47t-65 18h-4.5q-2.5 0-4.5-1-103-72-162-131.5T130-429q0-25 21-33t43-8q29 0 69.5 10t91.5 28l-22-137q-4-23 2-42t23-30l106-71q-7-1-22-4t-32-6q-17-3-32-6.5t-22-4.5l-108 74-34-49 128-88Zm62 279 17 114q38 18 75.5 38.5T570-322v-80q-7-5-30-21t-50-35q-27-19-52-36t-34-23Zm276-237q-30 0-51.5-21.5T607-827q0-30 21.5-51.5T680-900q30 0 51.5 21.5T753-827q0 30-21.5 51.5T680-754Z" />
      </svg>
    ),
  },
  // Physical therapy
  {
    parent: 'Sports',
    name: 'Running',
    description:
      'Whether you want to start running but have no idea where to begin, or you would like to increase your speed or perfect your stride, or want an expert to help you meet a goal like weight loss, one of our trainers is ready to create a challenge for you and run by your side, literally or virtually!',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="m216-160-42-42 408-408H440v80h-60v-140h223q14 0 27 5t23 15l120 119q29 29 67 44t80 17v60q-52-2-100-19.5T736-484l-46-46-114 114 86 86-244 141-30-52 176-102-82-82-266 265Zm-96-280v-60h200v60H120ZM40-570v-60h200v60H40Zm750-80q-29 0-49.5-20.5T720-720q0-29 20.5-49.5T790-790q29 0 49.5 20.5T860-720q0 29-20.5 49.5T790-650Zm-670-50v-60h200v60H120Z" />
      </svg>
    ),
  },
  //SUrfing

  // SPORTS GYMNASTICS
  {
    parent: 'Personal',
    name: 'Iyengar Yoga',
    description:
      'Focuses on proper alignment and precise technique. Through slower movements and an emphasis on quality over quantity, Iyengar yoga combines poses and breath to build strength, stamina, and flexibility. Great for injury recovery and prevention, those new to yoga and/or exercise, and as a supplement to other sports and exercise techniques.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="M261-160q-26 0-43-17t-17-43q0-18 9-34.5t26-23.5l172-68v-138q-66 81-134 122t-154 41v-60q68 0 124-31.5T345-501l58-65q10-12 23.5-20t29.5-8h48q16 0 30 8t24 20l58 65q48 55 102 87.5T840-381v60q-85 0-153.5-41T552-484v138l172 68q17 7 26 23.5t9 34.5q0 26-17 43t-43 17H396v-11q0-26 16-42t42-16h133q8 0 14-6t6-14q0-8-6-14t-14-6H454q-44 0-70 27t-26 71v11h-97Zm219-494q-30 0-51.5-21.5T407-727q0-30 21.5-51.5T480-800q30 0 51.5 21.5T553-727q0 30-21.5 51.5T480-654Z" />
      </svg>
    ),
  },

  // SPRINT
  {
    parent: 'Sports',
    name: 'Road Cycling',
    description:
      'There is always room to improve, whether it be your speed, endurance, or mental stamina. Get Traind coaches are available to show you new paths, elevate your skill level, and ride with you wherever the road may lead.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="M619.08-754q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM422-548l89 95v247h-60v-200L315-525q-9-8-15-21.5t-6-27.5q0-14 6.5-27t14.5-21l119-119q8-8 22.5-14.5T487-762q16 0 30.5 6.5T540-741l78 78q27 27 64.538 46.5Q720.077-597 767-597v60q-59.564 0-107.782-22.5Q611-582 573-620l-40-40-111 112Zm-221.912 78Q283-470 339-413.912q56 56.087 56 139Q395-192 338.912-136q-56.087 56-139 56Q117-80 61-136.088q-56-56.087-56-139Q5-358 61.088-414q56.087-56 139-56ZM200-129q62 0 104-42t42-104q0-62-42-104t-104-42q-62 0-104 42T54-275q0 62 42 104t104 42Zm560.088-341Q843-470 899-413.912q56 56.087 56 139Q955-192 898.912-136q-56.087 56-139 56Q677-80 621-136.088q-56-56.087-56-139Q565-358 621.088-414q56.087-56 139-56ZM760-129q62 0 104-42t42-104q0-62-42-104t-104-42q-62 0-104 42t-42 104q0 62 42 104t104 42Z" />
      </svg>
    ),
  },
  // directions bike
  {
    parent: 'Sports',
    name: 'Marathon',
    description:
      'It’s pretty amazing what one can achieve with the right coaching, the discipline to put in the time required, and the drive to see it through to the finish line. We handle the coaching part; all you have to do is show up.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="M535-40v-239l-108-99-42 188-265-55 11-56 199 40 73-369-100 47v134h-60v-175l164-69q32-14 45.5-17.5T480-714q20 0 35.5 8.5T542-680l42 67q26 42 71 73t105 31v60q-67 0-119.5-31T543-573l-39 158 91 84v291h-60Zm5-714q-30 0-51.5-21.5T467-827q0-30 21.5-51.5T540-900q30 0 51.5 21.5T613-827q0 30-21.5 51.5T540-754Z" />
      </svg>
    ),
  },

  //Directions Run
  {
    parent: 'Sports',
    name: 'Tennis',
    description:
      'Tennis is one of those sports that is way harder than you think it’s going to be. Correct technique is everything. Book one of our personal coaches today and see what a little help can do for your game. Besides, it takes two to tennis…',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="m123-159-43-43 174.092-174.092Q287-409 301-458.5T315-597q0-56.976 25-111.988T416-815q88-88 193.086-100Q714.172-927 782-859q68 68 56 174T738-491q-51 51-106.012 75T520-392q-89 0-139.5 13T297-333L123-159Zm290-332q49 49 133 36.5T697-534q66.876-66.583 79.938-149.292Q790-766 741-815t-132.5-36.5Q525-839 458-772t-80.5 149.587Q364-539.825 413-491ZM736-40q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102T736-40Zm0-60q36 0 60-24t24-60q0-36-24-60t-60-24q-36 0-60 24t-24 60q0 36 24 60t60 24Zm0-84Z" />
      </svg>
    ),
  },
  //Tennis Court
  {
    parent: 'Sports',
    name: 'Golf',
    description:
      'Anyone who has attempted to play golf needs coaching. Nuff said.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="M791-123q-20.417 0-34.708-14.292Q742-151.583 742-172q0-20.417 14.292-34.708Q770.583-221 791-221q20.417 0 34.708 14.292Q840-192.417 840-172q0 20.417-14.292 34.708Q811.417-123 791-123ZM396-80q-97 0-166.5-19T160-148.643Q160-170 200-188.5t94-27.5v76h72v-740l292 142-232 122v395.537Q516-215 574-195t58 46q0 31-69.5 50T396-80Z" />
      </svg>
    ),
  },

  //Golf Course
  {
    parent: 'Adventure',
    name: 'Hiking',
    description:
      'Add fresh air, the beauty of the great outdoors, and cross-training, and what do you get? A strong dose of athletic bliss.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="m282-40 121-620q5-24 23-37t38-13q20 0 37.5 9t28.5 26l39 64q19 32 52.5 57t78.5 39v-75h40v550h-40v-414q-51-11-96-39t-80-69l-29 145 85 81v296h-60v-244l-100-95-74 339h-64Zm17-403-75-14q-10-2-17.5-13t-5.5-22l30-157q5-29 29-44.5t53-10.5l36 7-50 254Zm231-311q-30 0-51.5-21.5T457-827q0-30 21.5-51.5T530-900q30 0 51.5 21.5T603-827q0 30-21.5 51.5T530-754Z" />
      </svg>
    ),
  },
  // HIKING
  {
    parent: 'Adventure',
    name: 'Paddle Board',
    description:
      'Great core, shoulder, and arm strength built in a beautiful and serene environment. Go ahead, mix it up.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="M80-40v-60h40q35 0 65.5-12.5T240-149q23 23 54 35.5t66 13.5q34 1 64-11.5t56-37.5q24 24 54.5 36.5T600-100q35 0 64.5-12t55.5-37q26 25 55.5 37t64.5 12h40v60h-40q-35 0-64.5-10T720-80q-26 20-55 30t-65 10q-35 0-64.5-10T480-80q-25 20-54.5 30T361-40q-35 0-66-10t-55-30q-26 20-55 30t-65 10H80Zm280-156q-23 0-47.5-10T268-233q16-14 32-25.5t33-24.5l-66-127q-8-16-12.5-34t-4.5-36v-160q0-29 20.5-49.5T320-710h120q40 0 76.5-14t65.5-40l42 43q-38 35-84.5 53T440-650h-66v160h118l69 77q69-29 119.5-43t85.5-14q30 0 47 10.5t17 30.5q0 41-54.5 101T623-203q-5 2-11.5 4.5T600-196q-32 0-63-17t-57-48q-26 32-57 48.5T360-196Zm28-125q20-12 54.5-31.5T504-386l-40-44-119 5 43 104Zm-68-446q-30 0-51.5-21.5T247-840q0-30 21.5-51.5T320-913q30 0 51.5 21.5T393-840q0 30-21.5 51.5T320-767Zm334-11-29-29 113-113h57L654-778Z" />
      </svg>
    ),
  },
  /* //USE KITE SURF
  {
    parent: 'Adventure',
    name: 'SUP Yoga',
    description:
      'Yoga while trying to balance on a surfboard. It’s harder than it looks.',
    icon: null,
  }, */
  // NEED CUSTOM ICON
  {
    parent: 'Adventure',
    name: 'Rock Climbing',
    description:
      'Few sports give you a more exhilarating all-over body workout like rock climbing. It is extreme functional movement and bodyweight training with an added dose of fear. Certainly gets the heart pounding.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="M113.638-233.029q-27.406 0-39.42-24.08-12.016-24.079 4.463-46.21l166.362-222.145q13.203-17.29 34.957-17.29t35.334 17.29l133.348 177.623q8.289 11.334 22.094 13.428 13.804 2.094 24.572-6.761 9.768-8 12.978-19.667 3.21-11.666-2.978-22.246L401.333-521.102l123.71-164.362q13.203-17.29 34.957-17.29t35.334 17.29l285.985 382.145q16.667 22.131 4.558 46.21-12.109 24.08-39.515 24.08H113.638Z" />
      </svg>
    ),
  },
  // LANDSCAPE
  {
    parent: 'Adventure',
    name: 'Kayaking',
    description:
      'Now if you have never tried Kayaking or better yet Kayak surfing, you really should. Great workout, beautiful scenery, water… beats running on the treadmill ANY day.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="M80-40v-60h40q31 0 61.5-10.5T240-149q28 28 58.5 38.5T360-100q30 0 61.5-11t58.5-38q29 28 59 38.5t61 10.5q30 0 61.5-11t58.5-38q27 27 58.5 38t61.5 11h40v60h-40q-29 0-59.5-8.5T720-80q-30 23-60.5 31.5T600-40q-29 0-59.5-8.5T480-80q-30 23-60.5 31.5T360-40q-29 0-59.5-8.5T240-80q-30 23-60.5 31.5T120-40H80Zm280-156q-33 0-65-18t-55-47q-13 14-31 27.5T174-209q-39-8-84.5-21.5T0-260q53-21 137.5-42T293-335l56-179q8-28 34-39t51 2l106 56 121-64 70-162-20-53 48-105 109 49-47 105-53 20-156 363q91 11 191 35t157 47q-44 16-89.5 29.5T786-209q-17-11-35-24.5T720-261q-23 29-55 47t-65 18q-33 0-65-18t-55-47q-23 29-55 47t-65 18Zm209-151 52-124-81 42-76-38-36 117h52q23 0 44.5.5T569-347Zm-89-247q-30 0-51.5-21.5T407-667q0-30 21.5-51.5T480-740q30 0 51.5 21.5T553-667q0 30-21.5 51.5T480-594Z" />
      </svg>
    ),
  },
  {
    parent: 'Adventure',
    name: 'Skiing',
    description:
      'As a workout, skiing involves bearing your weight on your legs and using your knees to turn and move. This strengthens the larger muscles in the lower part of the body and the bones and joints in your legs, knees, and hips. This can decrease the risk of osteoporosis and knee damage in the future. And if you don’t mind being cold and falling on your ass a lot, it’s good fun.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="M741-40q-24 0-48-4t-47-11L81-261l13-38 289 105 70-181-159-166q-23-26-18-59t34-50l155-90q14-8 30-8.5t30 4.5q14 5 25.5 16t16.5 26l13 43q17 54 49.5 87t79.5 49l21-64 38 11-39 121q-68-14-119-54t-78-108l-132 76 124 145-85 222 143 52 94-292q10 3 19 5.5t18 4.5l-94 296 41 14q19 7 39 10.5t42 3.5q29 0 56-5.5t53-18.5l30 30q-33 17-67 25.5T741-40Zm-73-674q-30 0-51.5-21.5T595-787q0-30 21.5-51.5T668-860q30 0 51.5 21.5T741-787q0 30-21.5 51.5T668-714Z" />
      </svg>
    ),
  },
  //Sports (the whistle)
  {
    parent: 'Personal',
    name: 'Sports Perform',
    description:
      'This is a specialized form of training meant to enhance your overall athleticism and prepare you for the specific challenges of your sport.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="M386.812-111.84q-98.596 13.681-166.936 2.145-68.341-11.536-85.079-26.232-15.015-18.565-26.088-87.42-11.072-68.855 2.798-163.798L386.812-111.84Zm107.16-17.218L128.724-494.871q15.855-64.231 45.739-119.891 29.884-55.659 72.478-98.21 42.218-42.217 98.877-72.007 56.659-29.79 120.195-46.529l365.161 365.915q-16.55 64.55-46.55 121.181-30 56.63-71.087 97.717-42.232 41.898-97.891 71.826-55.659 29.927-121.674 45.811ZM349.783-349.783q12.637 13.015 30.964 13.015 18.326 0 30.34-12.015l199.13-199.506q12.87-12.87 12.87-30.964 0-18.095-12.87-30.964-12.637-13.015-30.964-13.015-18.326 0-29.964 12.015l-199.506 199.13q-12.87 13.246-12.87 31.34 0 18.095 12.87 30.964Zm498.609-222.97L573.84-848.537q100.451-13.826 167.502-1.746 67.05 12.08 83.861 26.21 14.087 18.782 26.022 86.739 11.935 67.956-2.833 164.581Z" />
      </svg>
    ),
  },
  // CUSTOM ICON NEEDED
  {
    parent: 'Personal',
    name: 'Athletic Stretching',
    description:
      'All the benefits of yoga without the Woo Woo. If you work out, you gotta stretch. Just do it man, it’s a game changer.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="M609-73q8 3 15 2t13-5q6-4 9.5-10.5T650-101v-169q0-13-8.5-21.5T620-300H375q-26 0-42.5 16T312-247q-4 21 5.5 42t35.5 31L609-73Zm160-447q29 0 50 21t21 51q0 23-13.5 41T791-383l-81 23H375q-52 0-88.5 36.5T250-235q0 20 7 39t20 36H150q-13 0-21.5-8.5T120-190v-130q0-83 58.5-141.5T320-520h449ZM400-880q66 0 113 47t47 113q0 66-47 113t-113 47q-66 0-113-47t-47-113q0-66 47-113t113-47Z" />
      </svg>
    ),
  },
  //Skiiing
  {
    parent: 'Adventure',
    name: 'Snowboarding',
    description:
      'Snowboarding is a full-body workout (especially for the back, legs, and core muscles). It burns calories, improves cardiovascular endurance, increases stamina, and enhances core strength. Really, snowboarding has countless health benefits.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="M752-60q-8 0-17.5-1T716-64L211-172q-42-9-75.5-36T82-276l-2-8q0-8 6.5-14t14.5-6q6 0 10 3t7 8q17 32 43.5 54t57.5 29l23 5 149-101-33-156q-2-12-.5-27.5T366-515l110-175H358l-73 118-51-31 92-147h222q23 0 37.5 13t19.5 24l21 48q21 48 64.5 80t99.5 35v60q-65-2-120-38t-91-93l-85 136 137 110 40 261 53 11q7 2 14 2.5t14 .5q24 0 49-10t45-29q1-1 13-5 9 0 15 6.5t6 15.5q0 4-2 8t-5 6q-26 24-58 36t-63 12Zm-144-67-33-215-136-97 20 160-135 91 284 61Zm39-647q-30 0-51.5-21.5T574-847q0-30 21.5-51.5T647-920q30 0 51.5 21.5T720-847q0 30-21.5 51.5T647-774Z" />
      </svg>
    ),
  },
  // SELF IMPROVEMENT
  {
    parent: 'Personal',
    name: 'Pilates',
    description:
      'A strengthening and lengthening form of bodyweight exercise that focuses on your core (trunk) muscles while also training your arms and legs.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="M814.044-692.044q12.87 12.869 12.87 30.869t-12.87 30.87l-38.333 38.334q-13.248 13.203-31.392 13.485-18.145.283-31.261-13.332-13.29-13.238-12.79-31.464t13.703-31.429l38.334-37.667q13.203-12.58 31.036-12.558 17.834.022 30.703 12.892ZM113.333-141.419q-18.5 0-31.286-12.82-12.787-12.82-12.787-31.37t12.787-31.253q12.786-12.703 31.286-12.703h733.334q18.5 0 31.286 12.82 12.787 12.82 12.787 31.37t-12.787 31.253q-12.786 12.703-31.286 12.703H113.333Zm366.784-684.205q18.55 0 31.253 12.974 12.703 12.975 12.703 31.475v53.334q0 18.5-12.82 31.286-12.82 12.787-31.37 12.787t-31.253-12.787q-12.703-12.786-12.703-31.286v-53.334q0-18.5 12.82-31.475 12.82-12.974 31.37-12.974Zm-332.828 131.58q12.87-12.87 30.87-12.87t30.869 12.87l39.001 39q13.203 13.247 13.485 31.392.283 18.144-12.92 31.608-13.58 12.797-31.913 12.37-18.334-.428-31.392-13.631l-38.333-39q-12.58-13.203-12.559-31.036.022-17.834 12.892-30.703ZM189.26-309.26q0-124.537 84.044-213.008Q357.348-610.74 480-610.74t206.696 88.472q84.044 88.471 84.044 213.008H189.26Z" />
      </svg>
    ),
  },
  //Snowboard
  {
    parent: 'Adventure',
    name: 'Mountain Biking',
    description:
      'Mountain biking is a low-impact sport, meaning it puts less stress on your joints than other aerobic activities such as running. Cycling is also considered a non-load bearing sport, which means that the act of sitting takes pressure off of your joints and reduces the risk of injuring them. And any sport that takes you into the heart of nature has immeasurable benefits for your mental and emotional well-being.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="#7C7C7C"
      >
        <path d="M157-200q-65 0-111-45.5T0-357q0-66 45.5-111.5T156-514l-47-36H0v-50h181l99 60 159-60h140l-78-100h-89v-60h119l86 111 129-64v104h-98l85 110q17-7 34-10.5t35-3.5q66 0 112 45.5T960-356q0 66-46 111t-112 45q-65 0-110.5-45.5T646-356q0-30 10-57t30-49l-35-45-146 223H395l-81-73q0 66-45.5 111.5T157-200Zm0-60q41 0 69-28.5t28-68.5q0-40-28-68.5T157-454q-40 0-68.5 28T60-357q0 41 28.5 69t68.5 28Zm294-280-173 65 173-65h150-150Zm351 280q41 0 69.5-28t28.5-68q0-41-28.5-69.5T802-454q-40 0-68 28.5T706-356q0 40 28 68t68 28Zm-329-84 128-196H451l-173 65 139 131h56Z" />
      </svg>
    ),
  },
  //Two Wheeler
]

export default categories
