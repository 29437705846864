import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import TitleC from '../text/TitleC'
import SubTitleA from '../text/SubTitleA'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import HeaderWorkoutTypeSelect from '../header/HeaderWorkoutTypeSelect'
import { ReactComponent as LocationIcon } from '../../assets/icons/location_pin.svg'
import { useState } from 'react'
import { CheckCircle, InfoOutlined, SaveOutlined } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCreateSessionField,
  setUserProfileModalField,
} from '../../redux/slices/formsSlice'
import InfoText from '../text/InfoText'
import theme from '../../styles/theme'

const SessionPricingCard = () => {
  const dispatch = useDispatch()
  const [editing, setEditing] = useState(false)
  const [location, setLocation] = useState('home')
  const sessionForm = useSelector(
    (state) => state.forms.userProfileModal.homeLocation
  )

  const handleChangePricing = (e, item) => {
    console.log('handleChangePricing', e.target.value)
    let field
    if (item === 30) {
      field = 'thirtyMinsPrice'
    } else if (item === 60) {
      field = 'sixtyMinsPrice'
    } else if (item === 90) {
      field = 'ninetyMinsPrice'
    } else {
      field = 'oneTwentyMinsPrice'
    }

    dispatch(
      setCreateSessionField({
        field,
        value: parseInt(e.target.value),
      })
    )
    //setPricing(e.target.value)
  }

  return (
    <Grid
      container
      sx={{
        p: 2,
        pl: 4,
        bgcolor: 'white',
        borderRadius: '16px',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
      }}
    >
      <Grid container justifyContent="start" alignItems="start" sx={{ p: 2 }}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '26px',
            lineHeight: '39px',
            color: 'gunMetal.main',
          }}
        >
          {' '}
          Select Session duration and input price below
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="start"
        alignItems="start"
        sx={{ p: 2, pt: 0 }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '15px',
            lineHeight: '22.5px',
            color: 'gunMetal.main',
          }}
        >
          {' '}
          Input dollar amount
        </Typography>
      </Grid>
      {[30, 60, 90, 120].map((item) => (
        <Grid
          container
          justifyContent="start"
          alignItems="start"
          sx={{ p: 2, pt: 0 }}
        >
          <Button
            variant="contained"
            sx={{
              bgcolor: 'gunMetal.main',
              color: 'white',
              height: 45,
              mr: 2,
            }}
          >
            {' '}
            {item} MINS
          </Button>
          <Button
            variant="outlined"
            sx={{
              height: 45,
              width: 110,
              bgcolor: 'white',
              borderColor: 'gunMetal.main',
            }}
          >
            <TextField
              hiddenLabel
              size="small"
              variant="filled"
              defaultvalue={'$'}
              sx={{ bgcolor: 'white', color: 'gunMetal.main', height: 39 }}
              InputProps={{
                disableUnderline: true,
                style: { backgroundColor: 'white' },
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              onChange={(e) => handleChangePricing(e, item)}
            />
          </Button>
          {item === 30 && (
            <Typography sx={{ pl: 2, pt: 1 }}>
              {' '}
              30 minute sessions are limited to virtual only
            </Typography>
          )}
        </Grid>
      ))}
      <Grid container justifyContent="start" alignItems="center" sx={{ p: 2 }}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '26px',
            lineHeight: '39px',
            color: 'gunMetal.main',
          }}
        >
          {' '}
          Default preview pricing
        </Typography>
        <InfoOutlined sx={{ pl: 2, opacity: 0.5 }} color="error" />
        <Grid justifyContent="start" alignItems="center" container>
          <Button
            variant="contained"
            sx={{
              bgcolor: 'gunMetal.main',
              color: 'white',
              height: 45,
              mr: 2,
            }}
          >
            {' '}
            $50 / HR
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default SessionPricingCard
