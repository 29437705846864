import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'

import AboutBox from './AboutBox'
import TrainerOfferings from './TrainerOfferings'
import TrainerCardv2 from '../../components/member-dashboard/TrainerCardv2'
import { useParams } from 'react-router-dom'
import { useGetTrainerByIdQuery } from '../../redux/api/api'
import SessionCard from '../session_card/SessionCard'
import { useSelector } from 'react-redux'
import SessionCardEmpty from '../session_card/SessionCardEmpty'

const TrainerAbout = () => {
  const { trainerId } = useParams()
  const { refetch, data: trainer } = useGetTrainerByIdQuery(trainerId, {
    skip: !trainerId,
  })
  const currentUserId = useSelector((state) => state.auth.cognitoUser?.sub)

  return (
    <Grid item xs={12} sm={12} md={12} sx={{ p: 2 }}>
      <Grid container sx={{ justifyContent: 'flex-start' }}>
        <Grid
          container
          sx={{ justifyContent: 'flex-start', p: 2, pb: 0 }}
          onClick={() => console.log('test: ', trainer?.sessions?.items[0])}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            Get Traind sessions by {trainer?.displayName}.
          </Typography>
        </Grid>
        <Grid container sx={{ justifyContent: 'flex-start' }}>
          <TableContainer
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  {currentUserId === trainerId && (
                    <TableCell
                      style={{
                        border: 'none',
                        paddingLeft: 0,
                        paddingBottom: '35px',
                        paddingTop: 0,
                        marginTop: 0,
                      }}
                    >
                      <SessionCardEmpty />
                    </TableCell>
                  )}
                  {trainer?.sessions?.items.map((row) => (
                    <TableCell
                      style={{
                        border: 'none',
                      }}
                    >
                      <SessionCard session={row} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TrainerAbout
