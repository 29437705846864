import * as React from 'react'
import Popover from '@mui/material/Popover'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import MapBox from './MapBox'
import SearchIcon from '@mui/icons-material/SearchOutlined'
import { EditOutlined, LocationCity, LocationOn } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
//import { setLocation } from '../../redux/tripSlice'

export default function PopMap(props) {
  const { editMode } = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  // const location = useSelector((state) => state.location) :
  const dispatch = useDispatch()
  const [location, setLocation] = React.useState(null)

  const handleChangeLocation = (result) => {
    console.log('location change', result)
    //dispatch(setLocation(e))
    // setLocation(e)
    props.setLocationData(result)
  }

  return (
    <div style={{ width: '100%' }}>
      <Box
        sx={{
          p: 0,
          pt: 1,
          width: '100%',
          height: 343,
          pb: 0,
          mb: 0,
          borderRadius: '20px',
          backgroundColor: 'transparent',
        }}
      >
        <MapBox
          setDestinationData={handleChangeLocation}
          searchable={editMode}
          initialLocation={{
            lng: props.lng,
            lat: props.lat,
          }}
        />
      </Box>
    </div>
  )
}
