import { API, Auth, graphqlOperation } from 'aws-amplify'
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAuthModalOpen,
  setVirtualSessionModalOpen,
} from '../../redux/slices/uiSlice'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import TitleC from '../text/TitleC'
import TitleB from '../text/TitleB'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Space, SpaceEvent, getUserMedia } from '@mux/spaces-web'
import LiveParticipant from './LiveParticipant'

const JWT =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkppVjdldjZORHVYdEkzOEowMFV3OHhLSXpTVjcwMW5KREtvSVdtbGZlSXowMEUifQ.eyJleHAiOjE2ODAyOTg4MTcsImF1ZCI6InJ0Iiwic3ViIjoieWNVSHpMMWIzZ2Y5anN3blB2RjdtWXVXcjhJazRnNFUwMUZQWjAwWE9TeTAxcyJ9.f2wPlQWWjX_LsABUrgmk2l1y4sFLOMQgfsv4ew6NHzhwBasLoHqRf6eumbGNgVA8mJPjyZ3oETkQ0Hnn_fDu4EtSZxG_6GVYVgEA7QGM43QQs-8lFawLUz356120QUTLJzP36OfRYBYSs_zZoa0QGu0c-OilhfBwRX6k0eIufhNVzLJOJbRUHZ_0bdfHqXbA7cXE0xmAz9RhMmLDVj5pHn7a7ylAzk_EhxHI8D3UNhmuvZxbD-uGlR3H00fncHzThm805MMxQ_VXzMu9ufQrRcTrcq9aCJXurT9gAEeuyKp5tIrh3AbdEIJ4sY_fhPloWTFcOvJPYicErvpe97ebww'

export default function LiveModal() {
  const dispatch = useDispatch()
  const spaceRef = useRef(null)
  const [localParticipant, setLocalParticipant] = useState(null)
  const joined = !!localParticipant
  // Our list of participants in React state
  const [participants, setParticipants] = useState([])

  const virtualSessionModalOpen = useSelector(
    (state) => state.ui.virtualSessionModalOpen
  )

  // Set the participant state to the current participants and the new one
  const addParticipant = useCallback(
    (participant) => {
      setParticipants((currentParticipants) => [
        ...currentParticipants,
        participant,
      ])
    },
    [setParticipants]
  )

  // Filter out the participant who left and set the participant state
  const removeParticipant = useCallback(
    (participantLeaving) => {
      setParticipants((currentParticipants) =>
        currentParticipants.filter(
          (currentParticipant) =>
            currentParticipant.connectionId !== participantLeaving.connectionId
        )
      )
    },
    [setParticipants]
  )

  const handleClose = () => {
    dispatch(setVirtualSessionModalOpen(false))
  }

  useEffect(() => {
    const space = new Space(JWT)
    // Setup event listeners for other people joining and leaving
    space.on(SpaceEvent.ParticipantJoined, addParticipant)
    space.on(SpaceEvent.ParticipantLeft, removeParticipant)

    spaceRef.current = space

    return () => {
      // Cleanup event listeners when the component is unmounted
      space.off(SpaceEvent.ParticipantJoined, addParticipant)
      space.off(SpaceEvent.ParticipantLeft, removeParticipant)
    }
  }, [addParticipant, removeParticipant])

  const join = useCallback(async () => {
    // Join the Space
    let localParticipant = await spaceRef.current.join()

    // Get and publish our local tracks
    let localTracks = await getUserMedia({
      audio: true,
      video: true,
    })
    await localParticipant.publishTracks(localTracks)

    // Set the local participant so it will be rendered
    setLocalParticipant(localParticipant)
  }, [])

  return (
    <Dialog
      open={virtualSessionModalOpen}
      maxWidth="lg"
      PaperProps={{
        sx: {
          minWidth: '50%',
          overflow: 'hidden',
          //width: '100%',
          //maxWidth: '666px !important',
          //height: '100%',
          //maxHeight: '777px !important',
          alignItems: 'center',
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle style={{ width: '95%' }}>
        <Grid container sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <IconButton
              onClick={handleClose}
              style={{ width: '20px', height: '20px', marginRight: '20px' }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent style={{}}>
        <Grid container sx={{ p: 2 }}>
          <button onClick={join} disabled={joined}>
            Join Space
          </button>

          {localParticipant && (
            <LiveParticipant
              key={localParticipant.connectionId}
              participant={localParticipant}
            />
          )}

          {participants.map((participant) => {
            return (
              <LiveParticipant
                key={participant.connectionId}
                participant={participant}
              />
            )
          })}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
