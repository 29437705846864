import * as React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CardActionArea, Chip, Grid, IconButton } from '@mui/material'
import {
  Add,
  AddRounded,
  DriveEta,
  Favorite,
  HeartBrokenTwoTone,
  LocationOn,
  LocationOnOutlined,
  Phone,
  PhoneAndroidOutlined,
  PlusOne,
  RocketLaunchOutlined,
  Star,
} from '@mui/icons-material'
import S3Image from '../S3Image'
import { ReactComponent as TrainerStarRating } from '../../assets/icons/trainerStarRating.svg'
import {
  useCreateEmptySessionMutation,
  useGetMemberByIdQuery,
} from '../../redux/api/api'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import mixpanel from 'mixpanel-browser'

export default function SessionCardEmpty() {
  const navigate = useNavigate()
  const currentUserId = useSelector((state) => state.auth.cognitoUser?.sub)
  const { data: trainerInfo } = useGetMemberByIdQuery(currentUserId, {
    skip: !currentUserId,
  })
  const [createSessionTrigger, createSessionResponse] =
    useCreateEmptySessionMutation()

  const handleCreateSession = async () => {
    let defaults = {}
    defaults['trainerSessionsId'] = currentUserId
    if (trainerInfo?.pronouns === 'he/him') {
      defaults['trainerGender'] = 'm'
    } else if (trainerInfo?.pronouns === 'she/her') {
      defaults['trainerGender'] = 'f'
    }
    if (trainerInfo?.trainingStyles?.length > 0) {
      if (trainerInfo?.trainingStyles.includes('Workout Buddy')) {
        defaults['workoutBuddy'] = 1
      }
      if (trainerInfo?.trainingStyles.includes('Professor')) {
        defaults['professor'] = 1
      }
      if (trainerInfo?.trainingStyles.includes('Cheerleader')) {
        defaults['cheerleader'] = 1
      }
      if (trainerInfo?.trainingStyles.includes('Drill Sergeant')) {
        defaults['drillSergeant'] = 1
      }
    }
    // Set initial city for session to be trainers city
    defaults['city'] = trainerInfo?.homeCity
    defaults['searchable'] = false
    console.log('defaults: ', defaults)
    const newSession = await createSessionTrigger(defaults).unwrap()
    console.log('New Session Hit', newSession)
    const newId = newSession.data.createSession.id
    console.log('newId: ', newId)
    mixpanel.track(`New Session Created`, {
      'Event Type': 'New Session Created',
    })
    navigate(`/session/${newId}`)
  }
  return (
    <Card
      sx={{
        width: '294px',
        height: '365px',
        borderRadius: '16px',
        left: '20px',
        top: '20px',
        position: 'relative',
        backgroundColor: '#F2F2F2',
      }}
    >
      <CardActionArea
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        onClick={handleCreateSession}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  lineHeight: '16px',
                  letterSpacing: '0.02em',
                  color: '#000000',
                }}
              >
                {' '}
                Add Session{' '}
              </Typography>
            </Box>
            <Button
              variant="outlined"
              sx={{ width: '100%', color: 'gunMetal.main', bgcolor: 'white' }}
            >
              <AddRounded />
              Add Session
            </Button>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
