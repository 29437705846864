import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import DashboardOverview from './DashboardOverview'
import {
  Button,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  useMediaQuery,
} from '@mui/material'
import PaymentsHistoryTable from './PaymentsHistoryTable'
import BrowseSessions from '../browser_sessions/BrowseSessions'
import UpcomingSessionPreview from '../session_card/UpcomingSessionPreview'
import { Search, SendOutlined } from '@mui/icons-material'
import PreSessionBookingModal from '../calendar/PreSessionBookingModal'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { listBookings } from '../../graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'
import {
  useCreateMessageMutation,
  useGetBookingsQuery,
} from '../../redux/api/api'
import dayjs from 'dayjs'
import { dateToNow } from '../../helpers/datetime'
import { useNavigate } from 'react-router-dom'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function DashboardWorkoutsPage() {
  const dispatch = useDispatch()
  const [value, setValue] = React.useState(0)
  const [session, setSession] = React.useState(null)
  const [preSessionOpen, setPreSessionOpen] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const large = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const navigate = useNavigate()

  const [
    createMessageTrigger, // This is the mutation trigger
    { isLoading: isUpdating }, // This is the destructured mutation result
  ] = useCreateMessageMutation()
  const handleClickCancel = () => {
    setPreSessionOpen(true)
  }
  const handleClickReschedule = () => {
    setPreSessionOpen(true)
  }
  const handleClosePreSessionBookingModal = () => {
    setPreSessionOpen(false)
  }
  const handleChange = (event, newValue) => {
    setValue(newValue)
    setSession(null)
  }
  const userId = useSelector((state) => state.auth.cognitoUser?.sub)
  const bookingsData = useGetBookingsQuery(userId, {
    skip: !userId,
    keepUnusedDataFor: 60 * 60 * 24,
    //pollingInterval: 10000,
  })
  const [month, setMonth] = React.useState('')

  const handleChangeMonth = (event) => {
    setMonth(event.target.value)
  }

  const upcomingWorkouts =
    bookingsData?.data?.filter((booking) => {
      return booking.status === 'accepted'
    }) || []
  /*
    {
      id: 21,
      trainer: 'Tim O',
      location: 'Los Angeles, CA',
      time: 'Jun 12th 2021 11:00 AM PDT',
      subheading: 'SPORTS PERFORMANCE TRAINING',
      colored: true,
    },
    */
  const pendingRequests =
    bookingsData?.data?.filter((booking) => {
      return booking.status === 'pending'
    }) || []

  if (bookingsData.isLoading) {
    return <div>Loading Bookings</div>
  }

  if (bookingsData.error) {
    return <div>Loading Bookings Error</div>
  }

  const handleSendMessage = async () => {
    if (message.trim() === '') {
      return
    }
    try {
      // const newMessageRoom = {
      //   messageRoomMemberId: '95da19a8-b876-4d50-aaef-e06d0f7416b7',
      //   messageRoomTrainerId: 'b6dc0151-a2ba-4aca-8660-3cc6f0d1f834',
      // }
      const newMessage = {
        content: message.trim(),
        read: 'false',
        messageRoomMessagesId: '7744d602-c9b2-4b35-90b4-ac03e91b49fc',
        messageSenderId: userId,
      }
      setMessage('')
      // const messageRoomCreate = await API.graphql(
      //   graphqlOperation(createMessageRoom, {
      //     input: newMessageRoom,
      //   })
      // )
      return createMessageTrigger(newMessage)

      //console.log(messageRoomCreate.data.createMessageRoom)
    } catch (error) {
      console.log('error creating message: ', error)
    }
  }

  const handleClickBooking = (booking) => {
    setSession(null)
    if (large) {
      setSession(booking)
      return
    } else {
      navigate(`/session/${booking?.id}`)
    }
  }

  const pendingWorkoutsComponent = () => (
    <Box
      sx={{
        bgcolor: 'white',
        pt: 2,
        pr: 5,
        p: 2,
        borderRadius: '30px',
        height: '100%',
      }}
    >
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          sx={{ bgcolor: '#EBEBEB', borderRadius: '20px', p: 2 }}
          xs={12}
          sm={4}
        >
          <Grid container sx={{ minHeight: '5vh', p: 1, mb: 2 }}>
            <Grid container sx={{ bgcolor: 'white', borderRadius: '100px' }}>
              <InputBase
                sx={{ ml: 2, flex: 1 }}
                placeholder="Search Messages"
                inputProps={{ 'aria-label': 'search Messages' }}
              />
              <IconButton
                color="primary"
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
              >
                <Search />
              </IconButton>
            </Grid>
          </Grid>
          <Box style={{ maxHeight: '35vh', overflow: 'auto', width: '100%' }}>
            {bookingsData?.data
              ?.filter((booking) => {
                return booking.status === 'pending'
              })
              .map((workout) => (
                <Grid
                  key={workout?.id}
                  container
                  style={{
                    cursor: 'pointer',
                    opacity: workout?.id === session?.id ? 1 : 0.5,
                  }}
                  onClick={() => handleClickBooking(workout)}
                >
                  <UpcomingSessionPreview
                    colored={workout?.id === session?.id}
                    workout={workout}
                  />
                </Grid>
              ))}
          </Box>
        </Grid>
        <Grid item sx={{ height: '100%' }} xs={12} sm={8}>
          {session && (
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-end"
              sx={{ height: '100%', pl: 3, pr: 3 }}
            >
              <Grid
                container
                sx={{ width: '100%', pb: 2 }}
                justifyContent="flex-end"
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 17,
                    lineHeight: '25.5px',
                    color: 'gunMetal.main',
                  }}
                >
                  Time since Request sent:{' '}
                </Typography>
                <Typography
                  sx={{
                    ml: 1,
                    fontWeight: 700,
                    fontSize: 17,
                    lineHeight: '25.5px',
                    color: 'primary.main',
                  }}
                >
                  {dateToNow(session?.createdAt)}
                </Typography>
              </Grid>

              <Grid
                container
                sx={{ p: 1, pt: 4 }}
                justifyContent="space-around"
              >
                <Grid
                  container
                  justifyContent={session.sent ? 'left' : 'right'}
                >
                  <UpcomingSessionPreview workout={session} />
                </Grid>
                <Grid
                  container
                  sx={{
                    bgcolor: 'white',
                    borderRadius: '100px',
                    mb: 3,
                    boxShadow: 1,
                  }}
                >
                  <InputBase
                    sx={{ ml: 2, flex: 1 }}
                    placeholder="Message Your Trainer if needed"
                    inputProps={{
                      'aria-label': 'Message Your Trainer if needed',
                    }}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <IconButton
                    color="primary"
                    type="button"
                    sx={{ p: '10px' }}
                    aria-label="search"
                    onClick={handleSendMessage}
                  >
                    <SendOutlined />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ bgcolor: 'white', p: 2 }}
                    onClick={handleClickCancel}
                  >
                    Cancel Request
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleClickReschedule}
                    variant="outlined"
                    color="primary"
                    sx={{ bgcolor: 'white', p: 2 }}
                  >
                    Reschedule Request
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  )
  const upcomingWorkoutsComponent = () => (
    <Box
      sx={{
        bgcolor: 'white',
        pt: 2,
        pr: 5,
        p: 2,
        borderRadius: '30px',
        height: '100%',
      }}
    >
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          sx={{ bgcolor: '#EBEBEB', borderRadius: '20px', p: 2 }}
          xs={12}
          sm={4}
        >
          <Grid container sx={{ minHeight: '5vh', p: 1, mb: 2 }}>
            <Grid container sx={{ bgcolor: 'white', borderRadius: '100px' }}>
              <InputBase
                sx={{ ml: 2, flex: 1 }}
                placeholder="Search Messages"
                inputProps={{ 'aria-label': 'search Messages' }}
              />
              <IconButton
                color="primary"
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
              >
                <Search />
              </IconButton>
            </Grid>
          </Grid>
          <Box style={{ maxHeight: '35vh', overflow: 'auto', width: '100%' }}>
            {upcomingWorkouts.map((workout) => (
              <Grid
                container
                style={{
                  cursor: 'pointer',
                  opacity: workout?.id === session?.id ? 1 : 0.5,
                }}
                onClick={() => handleClickBooking(workout)}
              >
                <UpcomingSessionPreview
                  colored={workout?.id === session?.id}
                  workout={workout}
                />
              </Grid>
            ))}
          </Box>
        </Grid>
        <Grid item sx={{ height: '100%' }} xs={12} sm={8}>
          {session && (
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-end"
              sx={{ height: '100%', pl: 3, pr: 3 }}
            >
              <Grid
                container
                sx={{ width: '100%', pb: 2 }}
                justifyContent="flex-end"
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 17,
                    lineHeight: '25.5px',
                    color: 'gunMetal.main',
                  }}
                >
                  Time since Request sent:{' '}
                </Typography>
                <Typography
                  sx={{
                    ml: 1,
                    fontWeight: 700,
                    fontSize: 17,
                    lineHeight: '25.5px',
                    color: 'primary.main',
                  }}
                >
                  {dateToNow(session?.createdAt)}
                </Typography>
              </Grid>
              {session && <UpcomingSessionPreview colored workout={session} />}

              <Grid
                container
                sx={{ p: 1, pt: 4 }}
                justifyContent="space-around"
              >
                <Grid
                  container
                  sx={{
                    bgcolor: 'white',
                    borderRadius: '100px',
                    mb: 3,
                    boxShadow: 1,
                  }}
                >
                  <InputBase
                    sx={{ ml: 2, flex: 1 }}
                    placeholder="Message Your Trainer if needed"
                    inputProps={{
                      'aria-label': 'Message Your Trainer if needed',
                    }}
                  />
                  <IconButton
                    color="primary"
                    type="button"
                    sx={{ p: '10px' }}
                    aria-label="search"
                  >
                    <SendOutlined />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ bgcolor: 'white', p: 2 }}
                    onClick={handleClickCancel}
                  >
                    Cancel Request
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ bgcolor: 'white', p: 2 }}
                    onClick={handleClickReschedule}
                  >
                    Change date and time
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  )
  return (
    <Box sx={{ width: '100%', bgcolor: '#F6F7F8' }}>
      <PreSessionBookingModal
        selectedValue={session}
        onClose={handleClosePreSessionBookingModal}
        open={preSessionOpen}
      />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Upcoming" {...a11yProps(0)} />
          <Tab label="Pending" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container justifyContent="space-between">
          <Typography
            sx={{
              color: 'gunMetal.main',
              fontSize: 26,
              fontWeight: 700,
              lineHeight: '39px',
            }}
          >
            {' '}
            Your Upcoming Sessions
          </Typography>
          <Typography
            sx={{
              color: 'gunMetal.main',
              fontSize: 26,
              fontWeight: 700,
              lineHeight: '39px',
            }}
          >
            {bookingsData.length}
          </Typography>
        </Grid>

        {upcomingWorkoutsComponent()}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container justifyContent="space-between">
          <Typography
            sx={{
              color: 'gunMetal.main',
              fontSize: 26,
              fontWeight: 700,
              lineHeight: '39px',
            }}
          >
            Your Pending Requests
          </Typography>
        </Grid>
        {pendingWorkoutsComponent()}
      </TabPanel>
    </Box>
  )
}
