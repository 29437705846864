import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { closeErrorAlert } from '../../redux/slices/uiSlice'
import CloseIcon from '@mui/icons-material/Close'
import { ErrorOutline } from '@mui/icons-material'

export default function ErrorModal() {
  const open = useSelector((state) => state.ui.errorAlertOpen)
  const message = useSelector((state) => state.ui.errorAlertMessage)

  const dispatch = useDispatch()

  const handleCloseErrorModal = () => {
    dispatch(closeErrorAlert())
  }

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      PaperProps={{
        sx: {
          minWidth: '50%',
          overflow: 'hidden',
          //width: '100%',
          //maxWidth: '666px !important',
          //height: '100%',
          //maxHeight: '777px !important',
          alignItems: 'center',
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle style={{ width: '95%' }}>
        <Grid container sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            {/*  {' '}
            <IconButton
              onClick={() => {
                //lastPage()
              }}
              style={{ width: '20px', height: '20px', marginLeft: '20px' }}
            >
              <ArrowBackIcon />
            </IconButton> */}
          </Grid>

          <Grid item>
            <IconButton
              onClick={handleCloseErrorModal}
              style={{ width: '20px', height: '20px', marginRight: '20px' }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent style={{}}>
        <Grid container sx={{ p: 2 }}>
          <ErrorOutline
            sx={{
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '39px',
              pr: 1,
            }}
            color="error"
          />
          <Typography
            sx={{ fontWeight: 700, fontSize: '26px', lineHeight: '39px' }}
          >
            Oops!
          </Typography>
          <Grid container sx={{ p: 1 }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '17px',
                lineHeight: '25.5px',
                textAlign: 'center',
              }}
            >
              {message
                ? message
                : "Sorry about that, we're having some issues with that action. Please contact support or  try again later"}
            </Typography>
          </Grid>
          <Grid container sx={{ justifyContent: 'space-around', pt: 2 }}>
            <Button
              variant="outlined"
              sx={{ color: 'gunMetal.main', pt: 2, pb: 2 }}
            >
              Contact Support
            </Button>
            <Button
              variant="contained"
              sx={{ color: 'gunMetal.main', pt: 2, pb: 2 }}
            >
              Return Home
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
