import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

export const formatFromDB = (date, format) => {
  return dayjs(date).format(format)
}

export const getDayJSFromString = (dateString, currentFormat) => {
  return dayjs(dateString, currentFormat)
}

export const compareDates = (a, b, currentFormat) => {
  return dayjs(a).isAfter(dayjs(b)) ? 1 : -1
}

export const dateToNow = (date) => {
  return dayjs().to(dayjs(date))
}

export const isPast = (a) => {
  return dayjs().isAfter(dayjs(a))
}

export default formatFromDB
