import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import SubTitleB from '../text/SubTitleB'
import { ReactComponent as PencilLine } from '../../assets/icons/pencil_line.svg'
import { ReactComponent as Trash } from '../../assets/icons/trash.svg'
import { ReactComponent as FloppyDisk } from '../../assets/icons/floppy_disk.svg'
import { ReactComponent as Plus } from '../../assets/icons/plus.svg'

import InfoText from '../text/InfoText'
import TitleB from '../text/TitleB'
import SubTitleA from '../text/SubTitleA'
import {
  useCreateConditionMutation,
  useDeleteConditionMutation,
  useGetMemberByIdQuery,
  useGetMemberConditionsByIdQuery,
  useUpdateConditionMutation,
} from '../../redux/api/api'
import { useSelector } from 'react-redux'
import { Check } from '@mui/icons-material'
import { useParams } from 'react-router-dom'

const Conditions = (props) => {
  const { viewMemberId } = useParams()
  const { data: viewMemberData, isSuccess: viewMemberIsSuccess } =
    useGetMemberByIdQuery(viewMemberId, {
      skip: viewMemberId === undefined || viewMemberId === null,
      keepUnusedData: true,
    })

  const editMode = props.editMode
  const cognitoUserId = useSelector((state) => state.auth.cognitoUser?.sub)
  //const cognitoUserId = cognitoUser?.sub
  const { data, isSuccess, refetch } = useGetMemberConditionsByIdQuery(
    cognitoUserId,
    {
      skip: !cognitoUserId,
      keepUnusedDataFor: 60 * 60 * 24,
      pollingInterval: 50000,
    }
  )
  const [conditions, setConditions] = useState([])
  const [addingNewCondition, setAddingNewCondition] = useState(false)
  const [newConditionTitle, setNewConditionTitle] = useState('')
  const [newConditionDescription, setNewConditionDescription] = useState('')
  const [
    createConditionTrigger, // This is the mutation trigger
    { isLoading: isCreating }, // This is the destructured mutation result
  ] = useCreateConditionMutation()

  const [
    updateConditionTrigger, // This is the mutation trigger
    { isLoading: isUpdating }, // This is the destructured mutation result
  ] = useUpdateConditionMutation()

  const [
    deleteConditionTrigger, // This is the mutation trigger
    { isLoading: isDeleting }, // This is the destructured mutation result
  ] = useDeleteConditionMutation()

  // useEffect(() => {
  //   if (isLoading) {
  //     return
  //   }
  //   if (data) {
  //     console.log('data', data)
  //     const temp = data?.map((condition) => {
  //       return {
  //         condition: condition.title,
  //         description: condition.description,
  //         id: condition.id,
  //         edit: false,
  //       }
  //     })
  //     setConditions(temp)
  //   }
  // }, [data])

  const handleAddCondition = async () => {
    console.log('handleAddCondition')
    const newCondition = {
      title: newConditionTitle,
      description: newConditionDescription,
      userMedicalConditionsId: cognitoUserId,
    }
    console.log('newCondition: ', newCondition)
    try {
      const resp = await createConditionTrigger(newCondition).unwrap()
      console.log('resp', resp)
      refetch()
      setAddingNewCondition(false)
      setNewConditionTitle('')
      setNewConditionDescription('')
      return resp
    } catch (error) {
      console.log('error', error)
      return null
    }
    //return createConditionTrigger(newCondition)
  }

  const handleChangeNewConditionTitle = (e) => {
    setNewConditionTitle(e.target.value)
  }
  const handleChangeNewConditionDescription = (e) => {
    setNewConditionDescription(e.target.value)
  }

  async function editCondition(id, newCondition) {
    console.log('newcondition', newCondition)
    const temp = conditions.map((condition) => {
      if (condition.id === id) {
        console.log('Found Matching Condition: ', condition)
        if (condition.edit) {
          console.log('Condition is in Edit Mode Trying To Save: ', condition)
          const updatedCondition = {
            id: condition.id,
            title: newCondition.condition,
            description: newCondition.description,
            userMedicalConditionsId: cognitoUserId,
          }
          console.log('updatedCondition: ', updatedCondition)
          updateConditionTrigger(updatedCondition)
          return {
            ...newCondition,
            edit: false,
          }
        }
        if (!condition.edit) {
          return { ...condition, edit: true }
        } else {
          return { ...newCondition, edit: false }
        }
      } else {
        return condition
      }
    })
    setConditions(temp)
  }

  async function deleteCondition({ id }) {
    console.log('delete condition', id)
    deleteConditionTrigger({ id })
    refetch()
  }

  const ConditionItem = (props) => {
    const { condition } = props
    const [editingCondition, setEditingCondition] = useState(false)
    const [tempCondition, setTempCondition] = useState({
      title: condition.title,
      description: condition.description,
      id: condition.id,
      userMedicalConditionsId: cognitoUserId,
    })
    const handleEditActionClicked = async () => {
      console.log('handleEditActionClicked')
      if (editingCondition) {
        console.log('editingCondition')
        console.log('tempCondition: ', tempCondition)
        const updatedConditionResponse = await updateConditionTrigger(
          tempCondition
        ).unwrap()
        setTempCondition(updatedConditionResponse)
        setEditingCondition(false)
        refetch()
      } else {
        setEditingCondition(true)
      }
    }
    return (
      <li key={`condition${condition.id}`}>
        <Grid container spacing={1} sx={{ justifyContent: 'flex-start' }}>
          <Grid item onClick={() => console.log(condition)}>
            {' '}
            {editingCondition ? (
              <>
                <TextField
                  value={tempCondition.title}
                  onChange={(e) =>
                    setTempCondition({
                      ...tempCondition,
                      title: e.target.value,
                    })
                  }
                />
                <TextField
                  value={tempCondition.description}
                  onChange={(e) =>
                    setTempCondition({
                      ...tempCondition,
                      description: e.target.value,
                    })
                  }
                />
              </>
            ) : (
              <>
                <SubTitleB text={condition.title} /> - {condition.description}
              </>
            )}
          </Grid>
          {editMode && (
            <Grid item>
              <IconButton
                sx={{
                  backgroundColor: 'primary.main',
                  height: '32px',
                  width: '32px',
                }}
                onClick={handleEditActionClicked}
                //onClick={() => editCondition(condition.id, condition)}
              >
                {editingCondition ? (
                  <Check style={{ color: 'white' }} />
                ) : (
                  <PencilLine />
                )}
              </IconButton>
            </Grid>
          )}
          {editMode && (
            <Grid item>
              <IconButton
                sx={{ backgroundColor: 'primary.main', width: '32px' }}
                onClick={() =>
                  deleteCondition({
                    id: condition.id,
                  })
                }
              >
                <Trash />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </li>
    )
  }

  return (
    <Grid container justifyContent="start" sx={{ pt: 2 }}>
      <TitleB
        text={
          viewMemberId === cognitoUserId
            ? 'What Your Trainer Should Know'
            : 'Member Information To Review'
        }
      />
      <Paper
        sx={{
          //height: 180,
          width: '100%',
          borderRadius: '16px',
          mt: '16px',
          p: 2,
        }}
      >
        <Box
          style={{ padding: '32px' }}
          onClick={() => console.log('data: ', data, viewMemberData)}
        >
          <SubTitleA
            text={
              viewMemberId === cognitoUserId
                ? 'Do you have any medical issues or conditions we should be aware of to better serve you?'
                : "Member's Medical Conditions to Review"
            }
          />
          {cognitoUserId === viewMemberId &&
            isSuccess &&
            data?.length === 0 && <InfoText text="No conditions added yet" />}
          {cognitoUserId !== viewMemberId &&
            viewMemberIsSuccess &&
            viewMemberData?.length === 0 && (
              <InfoText text="No conditions added yet" />
            )}

          {/*  {isSuccess &&
            data?.length > 0 &&
            data.map((condition) => (
              <ConditionItem key={condition.id} condition={condition} />
            ))} */}

          <ul>
            {viewMemberId === cognitoUserId &&
              isSuccess &&
              data?.length > 0 &&
              data.map((condition) => {
                return (
                  <ConditionItem key={condition.id} condition={condition} />
                )
              })}
            {viewMemberId !== cognitoUserId &&
              viewMemberIsSuccess &&
              viewMemberData?.medicalConditions?.items?.length > 0 &&
              viewMemberData?.medicalConditions?.items?.map((condition) => {
                return (
                  <ConditionItem key={condition.id} condition={condition} />
                )
              })}
          </ul>

          {addingNewCondition && (
            <Grid container style={{ justifyContent: 'flex-start' }}>
              <InfoText text="Health condition or injury" />
              <Grid item xs={12}>
                <TextField
                  onChange={handleChangeNewConditionTitle}
                  sx={{
                    pb: '16px',
                    width: '300px',
                    '& .MuiInputBase-input': {
                      p: 0,
                      pl: '10px',
                    },
                    '& fieldset': { borderRadius: '20px' },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InfoText text="Describe the Condition (details, severity, etc.)" />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  onChange={handleChangeNewConditionDescription}
                  multiline
                  fullWidth
                  sx={{
                    pb: '16px',
                    '& fieldset': { borderRadius: '20px' },
                  }}
                  placeholder="More about your condition"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => handleAddCondition()}
                  variant="outlined"
                  startIcon={<FloppyDisk />}
                  style={{ color: 'gunMetal.main' }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          )}
          {cognitoUserId === viewMemberId && !addingNewCondition && (
            <Button
              variant="outlined"
              startIcon={<Plus />}
              style={{ color: 'gunMetal.main' }}
              onClick={() => setAddingNewCondition(true)}
            >
              Add Condition
            </Button>
          )}
        </Box>
      </Paper>
    </Grid>
  )
}

export default Conditions
