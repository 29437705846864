import {
  Box,
  Button,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import TitleC from '../text/TitleC'
import { ReactComponent as LocationIcon } from '../../assets/icons/location_pin.svg'
import { useState } from 'react'
import { CheckCircle, SaveOutlined } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'
import state_abbr from '../../constants/states_abbr'

const ChooseLocation = () => {
  const dispatch = useDispatch()
  const [editing, setEditing] = useState(false)
  const [location, setLocation] = useState('home')
  const [selectedState, setSelectedState] = useState('STATE')

  const handleChangeHomeCity = (e) => {
    console.log('changed: ', e.target.value)
    // Check Regex for city, ST
    dispatch(
      setUserProfileModalField({ field: 'homeCity', value: e.target.value })
    )
  }

  const handleChangeSelectedState = (value) => {
    setSelectedState(value)
    dispatch(setUserProfileModalField({ field: 'homeState', value: value }))
  }

  const handleChangeHomeZip = (e) => {
    console.log('changed: ', e.target.value)
    // Check Regex for city, ST
    dispatch(
      setUserProfileModalField({ field: 'homeZip', value: e.target.value })
    )
  }

  //TODO: should turn text into state select, city text, and zipcode field

  // we should make this the address field
  return (
    <>
      <Grid item xs={12}>
        <TitleC text="Where is home?" />
      </Grid>
      <DialogContent>
        <Box
          component="form"
          sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
        >
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <TextField
              onChange={handleChangeHomeCity}
              label="City"
              variant="outlined"
            />
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">State</InputLabel>

            <Select
              labelId="demo-simple-select-label"
              value={selectedState}
              label="State"
              onChange={(e) => handleChangeSelectedState(e.target.value)}
            >
              {state_abbr.map((state) => (
                <MenuItem value={state.abbr}>{state.abbr}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <TextField
              onChange={handleChangeHomeZip}
              label="Zipcode"
              variant="outlined"
            />
          </FormControl>
        </Box>
      </DialogContent>
    </>
  )
}

export default ChooseLocation
