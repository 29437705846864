import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Typography,
  useTheme,
} from '@mui/material'
import * as React from 'react'

import { useSelector, useDispatch } from 'react-redux'

export default function BorderSelectButton(props) {
  const { text, onClick, active, value, fullWidth, light } = props
  const theme = useTheme()
  const StyledButton = styled(Button)(({ theme }) => ({
    color: active ? 'white' : theme.palette.gunMetal.main,
    borderColor: active ? theme.palette.primary.main : theme.palette.gunMetal.main,
    fontWeight: light ? 500 : 700,
    fontSize: light ? 13 : 15,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: active ? theme.palette.primary.main : 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      border: '1px solid transparent',
    },
  }))
  return (
    <StyledButton
      fullWidth={fullWidth}
      value={value}
      variant="outlined"
      onClick={onClick}
    >
      {text}
    </StyledButton>
  )
}
