import * as React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CardActionArea, Chip, Grid, IconButton } from '@mui/material'
import {
  DriveEta,
  Favorite,
  HeartBrokenTwoTone,
  LocationOn,
  LocationOnOutlined,
  Phone,
  PhoneAndroidOutlined,
  RocketLaunchOutlined,
  Star,
} from '@mui/icons-material'

export default function TrainerCard(props) {
  const [favorited, setFavorited] = React.useState(false)
  return (
    <Box sx={{ width: '207px', height: '210px' }}>
      <Card sx={{ width: '207px', height: '210px', borderRadius: '20px' }}>
        <CardActionArea onClick={props.onClick}>
          <Box style={{ backgroundColor: 'white' }}>
            <CardMedia
              component="img"
              height="131"
              src="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318"
              alt="green iguana"
              style={{
                zIndex: 1,
              }}
            ></CardMedia>
          </Box>

          <CardContent sx={{ p: 0, m: 0, width: 210 }} style={{ padding: 0 }}>
            <Box
              sx={{
                backgroundColor: 'primary.light',
                p: 0,
                pl: 1,
                height: '27px',
              }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontWeight: 400,
                  fontSize: '17px',
                  lineHeight: '25.5px',
                  color: 'gunMetal.main',
                }}
              >
                Tim O
              </Typography>
            </Box>

            <Grid
              container
              sx={{ width: '100%', m: 0, p: 1, pb: 0 }}
              style={{ justifyContent: 'space-between' }}
            >
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Grid
                  container
                  style={{
                    justifyContent: 'space-between',
                  }}
                  alignItems="start"
                >
                  <Grid item>
                    <Chip
                      label="RUNNING"
                      sx={{
                        backgroundColor: 'gunMetal.main',
                        color: 'white',
                        ml: 0,
                        fontWeight: 600,
                        height: '24px',
                      }}
                    />
                    <Grid container style={{ justifyContent: 'left', ml: 1 }}>
                      <LocationOn fontSize="small" sx={{ mr: 0.5 }} />
                      <Typography variant="body2" color="text.secondary">
                        Location, ST
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container style={{ justifyContent: 'left' }}>
                      <Star fontSize="small" sx={{ color: 'gunMetal.main' }} />
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ fontWeight: 700 }}
                      >
                        4.8
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ p: 1, bgcolor: 'red' }}>
                  <Grid container style={{ justifyContent: 'left' }}>
                    <LocationOn fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography variant="body2" color="text.secondary">
                      Location , ST - 2 miles away
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  )
}
