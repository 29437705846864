import { TextField } from '@mui/material'

const RoundTextField = (props) => {
  const { width, placeholder, value, defaultValue, onChange } = props
  return (
    <>
      <TextField
        sx={{
          pb: '16px',
          width: width,
          '& .MuiInputBase-input': {
            p: 0,
            pl: '10px',
          },
          '& fieldset': { borderRadius: '20px' },
        }}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </>
  )
}

export default RoundTextField
