import {
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { logDOM } from '@testing-library/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetMemberByIdQuery } from '../../redux/api/api'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'
import BorderSelectButton from '../buttons/BorderSelectButton'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DesktopDatePicker } from '@mui/x-date-pickers'

export default function IntroduceMember(props) {
  const dispatch = useDispatch()
  const { onboarding } = props
  const [year, setYear] = React.useState('')
  const [dob, setDOB] = React.useState('')

  const cognitoUserId = useSelector((state) => state.auth.cognitoUser?.sub)
  const formPronouns = useSelector(
    (state) => state.forms.userProfileModal.pronouns
  )
  const formGender = useSelector((state) => state.forms.userProfileModal.gender)
  const formBirthYear = useSelector(
    (state) => state.forms.userProfileModal.birthyear
  )
  const formDOB = useSelector((state) => state.forms.userProfileModal?.dob)

  const { data, isLoading } = useGetMemberByIdQuery(cognitoUserId, {
    skip: !cognitoUserId,
    keepUnusedData: true,
  })
  const [localData, setLocalData] = useState()
  const handleClickPronoun = (newPronouns) => {
    dispatch(
      setUserProfileModalField({ field: 'pronouns', value: newPronouns })
    )
  }
  const handleClickGender = (newGender) => {
    dispatch(setUserProfileModalField({ field: 'gender', value: newGender }))
  }
  const handleYearChange = (event) => {
    const year = event.target.value
    setYear(year)
    dispatch(setUserProfileModalField({ field: 'birthyear', value: year }))
  }

  const handleChangeDOB = (event, date) => {
    console.log('dob event: ', event, date)
    let validDate = new Date(event)

    if (validDate instanceof Date && !isNaN(validDate)) {
      //only update here if it's a valid date
      console.log('event is date')
      setDOB(validDate)
      dispatch(setUserProfileModalField({ field: 'dob', value: validDate }))
    }
    // let d = new Date(date),
    //   month = '' + (d.getMonth() + 1),
    //   day = '' + d.getDate(),
    //   year = d.getFullYear()

    // if (month.length < 2) month = '0' + month
    // if (day.length < 2) day = '0' + day
    // const dateString = [year, month, day].join('-')
    // console.log('dateString: ', dateString)
  }

  const yearsObj = () => {
    const d = new Date()
    let dummy = []
    for (let i = d.getFullYear() - 100; i <= d.getFullYear(); i++) {
      dummy.unshift(i)
    }
    return dummy
  }
  if (isLoading) {
    return <CircularProgress />
  } else {
    return (
      <>
        <Grid item xs={12} style={{ paddingBottom: '20px' }}>
          <Typography
            sx={{
              color: 'gunMetal.main',
              fontWeight: 700,
              fontSize: 17,
              lineHeight: '25.5px',
            }}
          >
            Preferred pronouns
          </Typography>
          <Grid
            container
            style={{ paddingTop: '16px', justifyContent: 'flex-start' }}
            spacing={'20px'}
          >
            {['he/him', 'she/her', 'they/them', 'other'].map((e, i) => (
              <Grid item>
                <BorderSelectButton
                  text={e}
                  active={formPronouns === e}
                  onClick={() => handleClickPronoun(e)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: '20px' }}>
          <Typography
            sx={{
              color: 'gunMetal.main',
              fontWeight: 700,
              fontSize: 17,
              lineHeight: '25.5px',
            }}
          >
            Date of Birth
          </Typography>
          <Grid
            container
            style={{ paddingTop: '10px', justifyContent: 'flex-start' }}
            spacing={'20px'}
          >
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date of Birth"
                  inputFormat="MM/DD/YYYY"
                  value={formDOB ? formDOB : dob}
                  onChange={handleChangeDOB}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              {/* <FormControl>
                <Select
                  inputProps={{ 'aria-label': 'Without label' }}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                  displayEmpty
                  value={formBirthYear ? formBirthYear : year}
                  style={{ width: '152px', borderRadius: '40px' }}
                  onChange={handleYearChange}
                >
                  <MenuItem value={''}>Select</MenuItem>
                  {yearsObj().map((year) => {
                    return <MenuItem value={year}>{year}</MenuItem>
                  })}
                </Select>
              </FormControl> */}
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }
}
