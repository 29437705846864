import { Button, Grid, Input, TextField, Typography } from '@mui/material'
import InfoText from '../text/InfoText'
import SubTitleA from '../text/SubTitleA'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import { Auth } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'
export default function MemberInfoA(props) {
  const { editMode } = props
  const dispatch = useDispatch()
  const cognitoUser = useSelector((state) => state.auth.cognitoUser)

  const getAuth = async () => {
    try {
      const auth = await Auth.currentAuthenticatedUser()
    } catch (error) {
      console.log('error getting auth: ', error)
    }
  }

  const updateUserAccountId = async (accountId) => {
    // Add accountId to user
    const user = await Auth.currentAuthenticatedUser()
    // dispatch cognito user update
    const updatedUser = await Auth.updateUserAttributes(user, {
      'custom:stripe_acct_id': accountId,
    })
  }
  useEffect(() => {
    if (!cognitoUser) {
      getAuth()
    }
  }, [cognitoUser])

  const handleChangeCognitoField = (value, field) => {
    dispatch(setUserProfileModalField({ value, field }))
  }
  return (
    <>
      {' '}
      <Grid
        container
        style={{
          width: '50%',
          justifyContent: 'space-around',
          rowGap: '16px',
        }}
      >
        <Grid item xs={12}>
          <SubTitleA text="First name:" />
          {editMode ? (
            <TextField
              fullWidth
              placeholder="First name"
              onChange={(e) =>
                handleChangeCognitoField(e.target.value, 'given_name')
              }
              defaultValue={cognitoUser?.given_name}
            />
          ) : (
            <InfoText text={cognitoUser?.given_name} />
          )}
        </Grid>

        <Grid item xs={12}>
          <SubTitleA text="Last name:" />
          {editMode ? (
            <TextField
              fullWidth
              placeholder="Last Name"
              onChange={(e) =>
                handleChangeCognitoField(e.target.value, 'family_name')
              }
              defaultValue={cognitoUser?.family_name}
            />
          ) : (
            <InfoText text={cognitoUser?.family_name} />
          )}
        </Grid>

        <Grid item xs={12}>
          <SubTitleA text="Email address:" />
          {editMode ? (
            <TextField
              fullWidth
              placeholder="Email Address"
              onChange={(e) =>
                handleChangeCognitoField(e.target.value, 'email')
              }
              defaultValue={cognitoUser?.email}
            />
          ) : (
            <InfoText text={cognitoUser?.email} />
          )}
        </Grid>

        <Grid tem xs={12} style={{ justifyContent: 'flex-start' }}>
          <SubTitleA text="Date of birth" />
          {editMode ? (
            <TextField
              fullWidth
              placeholder="Birth Date"
              onChange={(e) =>
                handleChangeCognitoField(e.target.value, 'birthdate')
              }
              defaultValue={cognitoUser?.birthdate}
            />
          ) : (
            <InfoText text={cognitoUser?.birthdate} />
          )}
        </Grid>
        <Grid tem xs={12} style={{ justifyContent: 'flex-start' }}>
          <SubTitleA text="Phone number" />
          {editMode ? (
            <TextField
              fullWidth
              placeholder="Phone Number "
              onChange={(e) =>
                handleChangeCognitoField(e.target.value, 'phone_number')
              }
              defaultValue={cognitoUser?.phone_number}
            />
          ) : (
            <InfoText text={cognitoUser?.phone_number} />
          )}
        </Grid>
      </Grid>
    </>
  )
}
