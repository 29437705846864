import { Typography } from "@mui/material"

export default function TitleB(props) {
  const { text } = props
  return (
    <Typography
      sx={{
        fontWeight: 700,
        fontSize: '26px',
        lineHeight: '39px',
        //padding: '32px 0 0 0',
      }}
    >
      {text}
    </Typography>
  )
}
