import { CancelOutlined, Check } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'

const CertificationRow = ({ certification }) => {
  const { name, confirmed } = certification
  return (
    <Grid item xs={12} sx={{ p: 1 }} key={name}>
      <Grid
        container
        sx={{
          alignItems: 'center',
          justifyContent: 'left',
          gap: 2,
        }}
      >
        <Grid
          container
          sx={{
            height: '25px',
            width: '25px',
          }}
        >
          {confirmed ? (
            <Check color="primary" />
          ) : (
            <CancelOutlined color="error" />
          )}
        </Grid>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontSeight: 500,
            fontSize: '16px',
          }}
        >
          {name}
        </Typography>
      </Grid>
    </Grid>
  )
}
export default CertificationRow
