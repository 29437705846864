import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import { ReactComponent as GTLogoFull } from '../assets/GTLogoFull.svg'
import { useState } from 'react'
import { CancelOutlined } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAuthModalOpen,
  setBecomeTrainerModalOpen,
  setIsTrainerOnboard,
  setOnboardOpen,
  setOnboardStep,
  setSignUpSignIn,
} from '../redux/slices/uiSlice'
import Onboard from '../components/onboarding_modals/Onboard'
import { useNavigate } from 'react-router-dom'
import theme from '../styles/theme'
import axios from 'axios'
import mixpanel from 'mixpanel-browser'

function Onboarding() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [comingSoonModalOpen, setComingSoonModalOpen] = useState(false)
  const [emailField, setEmailField] = useState('')
  const userId = useSelector((state) => state.auth.cognitoUser?.sub)

  const handleBecomeBetaTesterClick = () => {
    mixpanel.track(`Become Beta Tester Click`, {
      'Event Type': 'Become Beta Tester Click',
    })
    dispatch(setAuthModalOpen(true))
    setComingSoonModalOpen(false)
    // dispatch(setOnboardOpen(true))
    // dispatch(setOnboardStep(1))
  }

  const handleBecomeTrainerClick = () => {
    // set flag that this is trainer onboard
    mixpanel.track(`Become Trainer Click`, {
      'Event Type': 'Become Trainer Click',
    })
    dispatch(setSignUpSignIn('signUp'))
    dispatch(setIsTrainerOnboard(true))
    dispatch(setOnboardStep(1))
    userId ? dispatch(setOnboardOpen(true)) : dispatch(setAuthModalOpen(true))
  }

  const handleBecomeMemberClick = () => {
    // set flag that this is trainer onboard
    mixpanel.track(`Become Member Click`, {
      'Event Type': 'Become Member Click',
    })
    dispatch(setSignUpSignIn('signUp'))
    dispatch(setIsTrainerOnboard(false))
    dispatch(setOnboardStep(1))
    userId ? dispatch(setOnboardOpen(true)) : dispatch(setAuthModalOpen(true))
  }

  const handleSubmit = async () => {
    console.log('emailField', emailField)
    try {
      const hubspotFunctionUrl =
        'https://fp4hy2e4xtzp6zrj3m4fbbyemy0jhpag.lambda-url.us-east-2.on.aws/'
      const hubspotResponse = await axios.post(hubspotFunctionUrl, {
        email: emailField,
        beta: true,
        first_name: null,
        last_name: null,
      })
      navigate('/thank-you-member')
    } catch (error) {
      console.debug('hubspot error', error)
    }
  }

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleChangeEmail = (e) => {
    setEmailField(e.target.value)
  }

  const ComingSoon = () => {
    return (
      <Dialog
        fullScreen={fullScreen}
        onClose={() => setComingSoonModalOpen(false)}
        maxWidth="md"
        open={comingSoonModalOpen}
        PaperProps={{ sx: { borderRadius: '24px', width: '100%' } }}
      >
        <DialogTitle sx={{ bgcolor: 'gunMetal.main' }}>
          <Grid
            container
            sx={{
              justifyContent: 'space-between',
              alignContent: 'center',
            }}
          >
            <img
              src="GT_LOGO_ICON_P.png"
              alt="GT Logo"
              style={{ height: '48px' }}
            />

            <Grid item>
              <IconButton
                color="error"
                onClick={() => setComingSoonModalOpen(false)}
                style={{ width: '20px', height: '20px', marginRight: '20px' }}
              >
                <CancelOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Grid container sx={{}}>
          <Grid item xs={12} md={6} sx={{ p: 4 }}>
            <Grid container sx={{ textAlign: 'left' }}>
              <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                Yes! You made it. Boring workouts, average exercise videos and
                stagnant gym routines be gone.
              </Typography>
            </Grid>
            <Grid container sx={{ textAlign: 'left', pt: 2 }}>
              <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                Get ready for Get Traind - your portal to excellence, where
                every session is live and expertly crafted to meet your specific
                needs and goals every workout, anywhere you are.
              </Typography>
            </Grid>
            <Grid container sx={{ textAlign: 'left', pt: 2 }}>
              <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                We are in the process of gathering up the best trainers,
                instructors, sports coaches and adventure fitness pros for you
                across the country. RIGHT NOW!
              </Typography>
            </Grid>
            <Grid container sx={{ textAlign: 'left', pt: 2 }}>
              <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                Please drop your email below and we will be sure to let you know
                the second we are ready to train, motivate, laugh, educate,
                sweat, and strive with you all the way across that finish line.
              </Typography>
            </Grid>
            <Grid container sx={{ textAlign: 'left', pt: 2 }}>
              <TextField
                sx={{ width: '100%' }}
                label="Email"
                variant="outlined"
                value={emailField}
                onChange={handleChangeEmail}
              />
            </Grid>

            <Grid container sx={{ textAlign: 'center', pt: 4 }}>
              <Button
                disabled={emailField === ''}
                sx={{ color: 'white' }}
                variant="contained"
                onClick={handleSubmit}
              >
                BE THE FIRST TO KNOW!
              </Button>
            </Grid>
            {/*  <Grid container sx={{ textAlign: 'center', pt: 4 }}>
              <Button
                href="https://share.hsforms.com/190TegLcRTKiDx0pwjVMixAefaaa"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: 'white' }}
                variant="contained"
                onClick={() => navigate('/thank-you-member')}
              ></Button>
            </Grid> */}
            {/* <Grid container sx={{ textAlign: 'center', pt: 2 }}>
              <Button variant="contained" onClick={handleBecomeBetaTesterClick}>
                Become a Beta Tester
              </Button>
            </Grid> */}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              height: '100%',
              width: '100%',
            }}
          >
            <Box
              sx={{
                height: 600,
                width: '100%',
                backgroundImage: `url(/comingsoon.png)`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                display: 'flex',
              }}
            ></Box>
          </Grid>
        </Grid>
      </Dialog>
    )
  }

  const textFunc = () => {
    // dispatch(setOnboardOpen(true))
    // dispatch(setOnboardStep(1))
    dispatch(setBecomeTrainerModalOpen(true))
  }
  const large = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Grid container>
      <Header />
      {ComingSoon()}
      <Onboard />
      <Grid
        container
        sx={{
          justifyContent: 'space-between',
          alignItems: large ? 'center' : 'flex-start',
          padding: 0,
          height: large ? '75vh' : null,
          pt: large ? 0 : 2,
          pr: large ? 10 : 2,
          pl: large ? 10 : 2,
        }}
      >
        <Grid item xs={12} sm={12} md={6} sx={{ pb: large ? 0 : 2 }}>
          <Grid
            container
            sx={{ justifyContent: 'flex-start', alignItems: 'center' }}
          >
            <Typography
              sx={{ fontSize: '36px', fontWeight: 600 }}
              onClick={textFunc}
            >
              Ready To Get Started?
            </Typography>
          </Grid>
          <Grid
            container
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'center',
              pt: 2,

              maxWidth: '363px',
            }}
          >
            <Typography sx={{ fontSize: '16px' }}>
              We’re glad you made it! It’s about time you discovered how
              remarkable live, personalized fitness can be. We have a ton of
              different ways to work your body, clear your head and ramp up your
              exercise routine. Jump in and let us make working out the best
              part of your day!
            </Typography>
          </Grid>
          <Grid
            container
            sx={{ justifyContent: 'flex-start', alignItems: 'center', pt: 4 }}
          >
            <Typography sx={{ color: '#7AE267' }}>
              Both fields require an ID or license to verify before
            </Typography>
          </Grid>
          <Grid
            container
            sx={{ justifyContent: 'flex-start', alignItems: 'center' }}
          >
            <Typography sx={{ color: 'rgba(0, 199, 199, 1)' }}>
              accepting or requesting training services.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} sx={{}}>
          <Grid container spacing={large ? 3 : 1}>
            <Grid item xs={6} sm={6}>
              <Box
                onClick={handleBecomeMemberClick}
                sx={{
                  boxSizing: 'border-box',
                  height: large ? 400 : 200,
                  width: '100%',
                  backgroundImage: `url(/onboarding.png)`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  display: 'flex',
                  borderRadius: '12px',
                  cursor: 'pointer',
                  '&:hover': {
                    border: '2px solid #00C7C7',
                    borderRadius: '12px',
                  },
                }}
              >
                <Box
                  sx={{
                    background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 2.6%, #000000 100%)`,
                    height: '139px',
                    pb: 2,
                    width: '100%',
                    borderRadius: '0 0 12px 12px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'white',
                      fontWeight: 700,
                      fontSize: large ? '32px' : '18px',
                    }}
                  >
                    {' '}
                    Members
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box
                onClick={handleBecomeTrainerClick}
                sx={{
                  boxSizing: 'border-box',
                  height: large ? 400 : 200,
                  width: '100%',
                  backgroundImage: `url(/onboardingtrainer.png)`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  display: 'flex',
                  borderRadius: '12px',
                  cursor: 'pointer',
                  '&:hover': {
                    border: '4px solid #00C7C7',
                    borderRadius: '12px',
                  },
                }}
              >
                <Box
                  sx={{
                    background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 2.6%, #000000 100%)`,
                    height: '139px',
                    width: '100%',
                    borderRadius: '0 0 12px 12px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    pb: 2,
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'white',
                      fontWeight: 700,
                      fontSize: large ? '32px' : '18px',
                    }}
                  >
                    {' '}
                    Trainers
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  )
}

export default Onboarding
