import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { useState } from 'react'
import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import axios from 'axios'
import { useSelector } from 'react-redux'

const PaymentMethodCard = ({ onSubmit, onNext }) => {
  const stripe = useStripe()
  const elements = useElements()
  const cognitoUser = useSelector((state) => state.auth.cognitoUser)
  const userId = cognitoUser?.sub
  const stripeCustomerId = useSelector((state) => state.auth.stripeCustomerId)
  const [errorMessage, setErrorMessage] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const handleSubmit = async (event) => {
    setSubmitting(true)
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    try {
      const confirmationCall = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: 'https://example.com/account/payments/setup-complete',
        },
      })
      console.log('success!: ', confirmationCall.setupIntent)
      if (confirmationCall.setupIntent.status === 'succeeded') {
        updateUserDefaultPaymentMethod(
          confirmationCall.setupIntent.payment_method
        )
      } else if (confirmationCall.error) {
        console.log('error', confirmationCall.error)
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setErrorMessage(confirmationCall.error.message)
        setSubmitting(false)
        window.alert('error adding payment method but continuing')
        onSubmit()
      }
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      setSubmitting(false)
      window.alert('successfully added payment method!')
      onSubmit()
    } catch (error) {
      console.log('error', error)
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message)
      setSubmitting(false)
      window.alert('error adding payment method but continuing')
      onSubmit()
    }
  }

  const updateUserDefaultPaymentMethod = async (paymentMethodId) => {
    console.log('updating user default payment method')
    try {
      const result = await axios.post(
        'https://snw3gnnh2vgtklc5pbdgsiudlm0ogmto.lambda-url.us-east-2.on.aws/',

        {
          customerId: stripeCustomerId,
          paymentMethodId,
        }
      )

      console.log('result', result)
    } catch (error) {
      console.log('error updating user default payment method', error)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {errorMessage && (
        <Grid container style={{ color: 'red' }}>
          <Typography>{errorMessage}</Typography>
        </Grid>
      )}
      {submitting ? (
        <CircularProgress />
      ) : (
        <Grid container sx={{ p: 2 }}>
          <Grid item sx={{ p: 2 }}>
            <Button
              variant="outlined"
              sx={{
                color: 'gunMetal.main',
                fontWeight: 700,
                p: 2,
                pl: 3,
                pr: 3,
                fontSize: '15px',
              }}
              onClick={() => console.log('closeModal')}
            >
              {' '}
              Cancel
            </Button>
          </Grid>
          <Grid item sx={{ p: 2 }}>
            <Button
              variant="contained"
              sx={{
                color: 'white',
                fontWeight: 700,
                p: 2,
                pl: 3,
                pr: 3,
                fontSize: '15px',
              }}
              disabled={!stripe}
              onClick={handleSubmit}
            >
              {' '}
              Save Payment Method
            </Button>
          </Grid>
        </Grid>
      )}
    </form>
  )
}
export default PaymentMethodCard
