import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Typography,
  useTheme,
} from '@mui/material'
import * as React from 'react'

import { useSelector, useDispatch } from 'react-redux'

export default function GreyTextButton(props) {
  const { text, onClick } = props
  const theme = useTheme()
  const StyledButton = styled(Button)(({theme})=>({
    color: theme.palette.gunMetal.main,
    borderColor: theme.palette.gunMetal.main,
    fontWeight: 700,
    fontSize: 15,
    paddingLeft: 16,
    paddingRight: 16,
    '&:hover': {
      //backgroundColor: theme.palette.primary.main,
      color: theme.palette.gunMetal.main,
    },
    '&:focus': {
      //backgroundColor: theme.palette.primary.main,
      color: theme.palette.gunMetal.main,
    }
  }));
  return (
    <StyledButton
      variant="text"
      sx={{ color: 'rgba(205, 210, 213, 1)', borderColor: 'gunMetal.main' }}
      onClick={onClick}
    >
      {text}
    </StyledButton>
  )
}
