import { Button, Grid, Input, Typography } from '@mui/material'
import InfoText from '../text/InfoText'
import SubTitleA from '../text/SubTitleA'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import { ReactComponent as UploadIcon } from '../../assets/icons/upload_up.svg'
import { ReactComponent as CheckCircle } from '../../assets/icons/CheckCircle.svg'
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useGetMemberByIdQuery } from '../../redux/api/api'
import axios from 'axios'
export default function BackgroundCheck(props) {
  const navigate = useNavigate()
  const userEmail = useSelector((state) => state.auth?.cognitoUser?.email)

  const userId = useSelector((state) => state.auth.cognitoUser?.sub)
  const { data: currentUser } = useGetMemberByIdQuery(userId)
  const handleDLFrontClick = async (e) => {
    async function onChange(e) {
      const file = e.target.files[0]
      try {
        await Storage.put(file.name, file, {
          contentType: 'image/png', // contentType is optional
        })
      } catch (error) {
        console.log('Error uploading file: ', error)
      }
    }
  }

  async function generateTruaLink() {
    try {
      const link = await axios.post(
        'https://6vax2q3yum73q2nan4j2nxs2su0qorjn.lambda-url.us-east-2.on.aws/',
        {
          crim: true,
          email: userEmail,
        }
      )
      console.log('link: ', link)
      // navigate to link
      const w = window.open('', '_blank')
      const url = link?.data
      w.location = url
    } catch (error) {
      console.log('error resending code:', error)
    }
  }

  async function generateTruaLinkVirtualOnly() {
    try {
      const link = await axios.post(
        'https://6vax2q3yum73q2nan4j2nxs2su0qorjn.lambda-url.us-east-2.on.aws/',
        {
          email: userEmail,
        }
      )
      console.log('link: ', link)
      // navigate to link
      const w = window.open('', '_blank')
      const url = link?.data
      w.location = url
    } catch (error) {
      console.log('error resending code:', error)
    }
  }

  return (
    <>
      {currentUser?.verified && (
        <Grid item xs={12}>
          <SubTitleA text="Your Identity Has Been Verified!" />
        </Grid>
      )}

      {!currentUser?.verified && (
        <Grid container sx={{ justifyContent: 'left', gap: 2 }}>
          <Grid item xs={12}>
            <SubTitleA text="You will be limited to virtual sessions only without a background check being completed." />
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={generateTruaLink}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '15px',
                  lineHeight: '22.5px',
                  color: 'white',
                  textAlign: 'center',
                  m: 1,
                }}
              >
                Verify For Virtual & In-Person Identity
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={generateTruaLinkVirtualOnly}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '15px',
                  lineHeight: '22.5px',
                  color: 'gunMetal.main',
                  textAlign: 'center',
                  m: 1,
                }}
              >
                Verify For Virtual Instruction Only
              </Typography>
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}
