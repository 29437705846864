import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  setBookingReviewModalOpen,
  setPaymentsModalOpen,
} from '../../redux/slices/uiSlice'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import { API, graphqlOperation } from 'aws-amplify'
import {
  createBooking,
  createExcersize,
  updateUser,
} from '../../graphql/mutations'
import {
  useCreateChatMutation,
  useCreateExerciseMutation,
  useCreateLocationMutation,
  useCreateMessageMutation,
  useGetChatsQuery,
  useGetMemberByIdQuery,
  useGetSessionDetailsByIdQuery,
  useUpdateBookingReviewMutation,
  useUpdateBookingStatusMutation,
} from '../../redux/api/api'
import { useEffect, useState } from 'react'
import {
  Add,
  CancelOutlined,
  ChangeCircle,
  CheckCircle,
  EditTwoTone,
} from '@mui/icons-material'
import PaymentMethodCard from '../payments/PaymentMethodCard'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  setPaymentMethods,
  setStripeClientSecret,
} from '../../redux/slices/paymentsSlice'
import axios from 'axios'
import paymentCompanyIcons from '../../assets/payments/paymentCompanyIcons'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import theme from '../../styles/theme'
import EditIconButtonBorder from '../buttons/EditIconButtonBorder'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  'pk_test_51MEHOJAsCikE6AYhDJypiY8Yg4fiBIUnTuQjShVM3jZqPLXCaJcBOUrV22Gb4T9LSOkWFZhOZus1ZVRtpQ4eieGo00RAkLfJUJ'
)

export default function PaymentsModal() {
  const navigate = useNavigate()
  const { sessionId } = useParams()
  const bookingFormData = useSelector((state) => state.forms.createBookingForm)
  const bookingCommute = useSelector(
    (state) => state.forms.createBookingCommute
  )

  const currentUserId = useSelector((state) => state.auth.cognitoUser?.sub)

  // CHATROOM DATA
  const { data: chatroomData } = useGetChatsQuery(
    {
      userType: 'member',
      userId: currentUserId,
    },
    { skip: !currentUserId, keepUnusedData: true }
  )

  const [createLocationTrigger, createLocationResponse] =
    useCreateLocationMutation()

  const {
    data: session,
    isLoading: isLoadingSession,
    refetch,
  } = useGetSessionDetailsByIdQuery(sessionId, {
    //refetchOnMountOrArgChange: true,
    skip: !sessionId,
    //pollingInterval: 10000,
    keepUnusedDataFor: 10000,
  })

  const [createChatTrigger, createChatResponse] = useCreateChatMutation()
  const [createMessageTrigger, createMessageResponse] =
    useCreateMessageMutation()
  const clientSecret = useSelector((state) => state.payments.stripeClientSecret)
  const stripeCustomerId = useSelector((state) => state.auth.stripeCustomerId)
  const paymentMethods = useSelector((state) => state.payments.paymentMethods)
  const open = useSelector((state) => state.ui.paymentsModalOpen)
  const createBookingForm = useSelector(
    (state) => state.forms.createBookingForm
  )

  const [paymentMethodSelected, setPaymentMethodSelected] = useState(null)
  const [addingPaymentMethod, setAddingPaymentMethod] = useState(false)
  const [fetching, setFetching] = useState(false)

  const createBookingTime = useSelector(
    (state) => state.forms.createBookingTime
  )
  const dispatch = useDispatch()
  const handleCloseModal = async () => {
    dispatch(setPaymentsModalOpen(false))
  }

  const onAddPaymentMethodSuccess = async () => {
    setAddingPaymentMethod(false)
    setFetching(true)
    listPaymentMethods()
  }

  const listPaymentMethods = async () => {
    try {
      console.log('Listing Payment Methods')
      const paymentMethods = await axios.post(
        'https://lsahibzjo5j5sr2thusqn676z40ajcmd.lambda-url.us-east-2.on.aws/',
        {
          customerId: stripeCustomerId,
        }
      )
      console.log('paymentMethods.data', paymentMethods.data)
      dispatch(setPaymentMethods(paymentMethods.data.data))
    } catch (error) {
      console.log('paymentMethods error', error)
    }
    setFetching(false)
  }

  const fetchClientSecret = async () => {
    try {
      const stripeIntent = await axios.post(
        'https://auibul7a6pvvdywpofvw5rkkgy0kfduu.lambda-url.us-east-2.on.aws/',
        {
          customerId: stripeCustomerId,
        }
      )
      console.log('stripeIntent.data', stripeIntent.data)

      const secret = stripeIntent.data.client_secret
      console.log('secret', secret)
      //dispatch(setStripeIntent(stripeIntent.data.id))
      dispatch(setStripeClientSecret(secret))
      // dispatch(setGeneralModalComponent('payment_method'))
      // dispatch(setGeneralModalOpen())
    } catch (error) {
      console.log('error creating stripe intent: ', error)
    }
    setFetching(false)
  }

  const handleAddPaymentMethod = () => {
    fetchClientSecret()
    setAddingPaymentMethod(true)
  }

  const options = {
    // passing the client secret obtained from the server
    clientSecret,
  }

  const handleCreateCommuteLocation = async () => {
    console.log('bookingCommute: ', bookingCommute)
    let commuteId
    try {
      const commuteLocationResponse = await createLocationTrigger({
        location: bookingCommute,
        sessionId: session?.id,
      }).unwrap()
      console.log('commuteLocationResponse: ', commuteLocationResponse)
      commuteId = commuteLocationResponse?.data?.createLocation?.id
      console.log('commuteId: ', commuteId)
    } catch (error) {
      console.log('Error found: ', error)
      commuteId = null
    }
    return commuteId
  }

  useEffect(() => {
    if (stripeCustomerId && !clientSecret && paymentMethods?.length === 0) {
      setFetching(true)
      fetchClientSecret()
    }
    if (paymentMethods === null) {
      console.log('paymentMethods is null')
      setFetching(true)
      listPaymentMethods()
    } else {
      console.log('paymentMethods is not null: ', paymentMethods)
    }
  }, [stripeCustomerId, clientSecret, paymentMethods])

  const handleSubmit = async () => {
    // Check Chatrooms for existing chatroom between trainer and user
    let chatroomId
    console.log(chatroomData)
    console.log('session?.trainerSessionsId: ', session?.trainerSessionsId)
    const hasRoomWithTrainer = (element) =>
      element.messageRoomTrainerId === session?.trainerSessionsId &&
      session?.trainerSessionsId !== null
    const chatroom = chatroomData?.findIndex(hasRoomWithTrainer)
    console.log('chatroom: ', chatroom)
    if (chatroom === -1 || chatroom === undefined) {
      // Create Chatroom
      console.log('creating chatroom')
      const newChatCreatedResult = await createChatTrigger({
        trainerId: session?.trainerSessionsId,
        memberId: currentUserId,
      }).unwrap()
      console.log('newChatCreatedResult: ', newChatCreatedResult)
      const newChatroomId = newChatCreatedResult.data.createMessageRoom.id
      console.log('newChatroomId: ', newChatroomId)
      chatroomId = newChatroomId
    } else {
      // Create Message
      chatroomId = chatroomData[chatroom].id
    }
    // Create Message
    const message = {
      messageRoomMessagesId: chatroomId,
      messageSenderId: currentUserId,
      content: 'Booking Request',
    }
    const newMessage = await createMessageTrigger(message).unwrap()
    console.log('newMessage: ', newMessage)
    // Create Booking
    try {
      console.log('bookingFormData: ', bookingFormData)
      console.log('createBookingTime: ', createBookingTime)
      const { amPm, date, duration, month, time, year, zone } =
        createBookingTime
      const formattedStartTime = dayjs(
        `${month}/${date}/${year} ${time}${amPm}`,
        'M/D/YYYY hA'
      )
      const testzone = 'America/Chicago'
      const testerr = dayjs(formattedStartTime).tz(testzone)
      testerr.format('YYYY-MM-DDTHH:mm:ss.sssZ')
      // TODO: add timezone to booking
      // TODO: change api call to useMutation trigger
      const input = {
        ...bookingFormData,
        startTime: testerr.format('YYYY-MM-DDTHH:mm:ss.sssZ'),
        bookingMessageRoomId: chatroomId,
      }
      console.log('testerr: ', input)
      const copyData = { ...bookingFormData }
      if (bookingFormData.bookingSelectedLocationId === 'commute') {
        // Create New Location Here for the commute and set the bookingFormData selectedLocationId piece here
        const newLocationId = await handleCreateCommuteLocation()
        console.log('newLocationId: ', newLocationId)
        copyData.bookingSelectedLocationId = newLocationId
        console.log('copyData: ', copyData)
      }
      const bookingData = await API.graphql(
        graphqlOperation(createBooking, {
          input: {
            ...copyData,
            startTime: testerr.format('YYYY-MM-DDTHH:mm:ss.sssZ'),
            paymentStatus: 'awaiting_payment',
            bookingMessageRoomId: chatroomId,
          },
        })
      )
      const booking = bookingData.data.createBooking
      console.log('booking: ', booking)
      navigate('/success-booking')
    } catch (error) {
      console.log('error requesting booking: ', error)
    }
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      PaperProps={{
        sx: {
          overflow: 'hidden',
          width: '100%',
          //maxWidth: '666px !important',
          //height: '100%',
          //maxHeight: '777px !important',
          alignItems: 'center',
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle style={{ width: '100%' }}>
        <Grid container sx={{ justifyContent: 'space-between', pl: 2, pr: 2 }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '24px',
              color: 'primary.main',
              maxWidth: '80%',
            }}
          >
            Confirm Your Booking on: {createBookingTime.month}/
            {createBookingTime.date}/{createBookingTime.year}{' '}
            {createBookingTime.time}
            {createBookingTime.amPm} {createBookingTime.zone}
          </Typography>

          <Grid item>
            <IconButton
              color="error"
              onClick={handleCloseModal}
              style={{ width: '20px', height: '20px', marginRight: '20px' }}
            >
              <CancelOutlined />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ width: '100%', p: 4, pt: 0 }}>
        {paymentMethods?.length > 0 && !addingPaymentMethod && (
          <Grid
            container
            sx={{ width: '100%', pl: 2, pr: 2, justifyContent: 'flex-start' }}
          >
            <Grid container sx={{ justifyContent: 'flex-start', pb: 1 }}>
              <Typography sx={{ fontWeight: 600 }}>
                {paymentMethodSelected ? 'Current ' : 'Confirm '}
                Payment Method:
              </Typography>
            </Grid>
            <Grid
              container
              gap={1}
              sx={{
                justifyContent: 'space-between',
                p: 2,
                border: '1px solid',
                borderColor: paymentMethodSelected ? 'primary.main' : '#E0E0E0',
                borderRadius: '8px',
              }}
            >
              <Grid item>
                <Grid container gap={2}>
                  <img
                    src={paymentCompanyIcons[paymentMethods[0].card.brand]}
                    style={{ maxHeight: '24px' }}
                  />
                  <Typography>
                    **** **** **** {paymentMethods[0].card.last4} | Exp:{' '}
                    {paymentMethods[0].card.exp_month}/
                    {paymentMethods[0].card.exp_year}
                  </Typography>
                </Grid>
              </Grid>

              {!paymentMethodSelected && (
                <Grid item>
                  <Grid container gap={2}>
                    <Tooltip title="Change Payment Method">
                      <IconButton
                        onClick={handleAddPaymentMethod}
                        color="error"
                      >
                        <ChangeCircle />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Confirm Payment Method">
                      <IconButton
                        onClick={() =>
                          setPaymentMethodSelected(paymentMethods[0])
                        }
                        color="primary"
                      >
                        <CheckCircle />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
              {paymentMethodSelected && (
                <Tooltip title="Change Payment Method">
                  <IconButton onClick={handleAddPaymentMethod} color="error">
                    <ChangeCircle />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            {/* {paymentMethods?.map((paymentMethod) => (
              
            ))} */}
            <Grid container sx={{ justifyContent: 'space-around' }}></Grid>
          </Grid>
        )}
        {paymentMethods?.length === 0 && !addingPaymentMethod && (
          <Grid
            container
            sx={{ width: '100%', pl: 4, justifyContent: 'flex-start' }}
          >
            <Typography>No Payment Methods Available</Typography>
            <Grid container>
              <Button
                variant="outlined"
                sx={{
                  color: 'gunMetal.main',
                  fontWeight: 700,

                  fontSize: '12px',
                }}
                onClick={handleAddPaymentMethod}
              >
                {' '}
                Add Payment Method
              </Button>
            </Grid>
          </Grid>
        )}

        {clientSecret && addingPaymentMethod && (
          <Grid
            container
            sx={{ width: '100%', pl: 4, justifyContent: 'center', pt: 4 }}
          >
            <Elements stripe={stripePromise} options={options}>
              <PaymentMethodCard onSubmit={onAddPaymentMethodSuccess} />
            </Elements>
          </Grid>
        )}
        {paymentMethodSelected && !addingPaymentMethod && (
          <Grid
            container
            sx={{
              width: '100%',
              pl: 2,
              pr: 2,
              justifyContent: 'space-between',
            }}
          >
            <Grid container sx={{ justifyContent: 'flex-start', p: 2 }}>
              <Typography>
                Your payment method will be charged{' '}
                <b
                  style={{ color: theme.palette.primary.main }}
                  onClick={() => console.log('color', theme)}
                >
                  ${createBookingForm.price}.00
                </b>{' '}
                24 hours before the start of your session. You can cancel, or
                reschedule with no fee until then. Learn more about our payment
                policies <a href="/faq">here</a>.
              </Typography>
            </Grid>
            <Grid container sx={{ justifyContent: 'space-around', pt: 2 }}>
              <Button
                variant="contained"
                sx={{
                  color: 'gunMetal.main',
                  fontWeight: 700,

                  fontSize: '18px',
                }}
                onClick={handleSubmit}
              >
                {' '}
                Request Booking
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}
