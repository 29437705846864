import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import DashboardOverview from './DashboardOverview'
import {
  Button,
  Chip,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  useMediaQuery,
} from '@mui/material'
import PaymentsHistoryTable from './PaymentsHistoryTable'
import BrowseSessions from '../browser_sessions/BrowseSessions'
import UpcomingSessionPreview from '../session_card/UpcomingSessionPreview'
import { Search, SendOutlined } from '@mui/icons-material'
import {
  setBookingReviewModalOpen,
  setSelectedRequest,
  setViewMemberId,
} from '../../redux/slices/uiSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  useGetTrainerBookingsQuery,
  useGetTrainerByIdQuery,
  useUpdateBookingStatusMutation,
} from '../../redux/api/api'
import dayjs from 'dayjs'
import S3Image from '../S3Image'
import { dateToNow, isPast } from '../../helpers/datetime'
import { setBookingReviewField } from '../../redux/slices/formsSlice'
import theme from '../../styles/theme'
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function DashboardTrainerBookings() {
  const [value, setValue] = React.useState(0)
  const large = useMediaQuery(theme.breakpoints.up('sm'))
  const isTrainer = useSelector((state) => state.auth?.isTrainer)
  const trainerId = useSelector((state) => state.auth?.cognitoUser?.sub)
  const selectedRequest = useSelector((state) => state.ui.selectedRequest)

  //const userId = useSelector((state) => state.auth?.cognitoUser?.sub)
  const { data: trainer } = useGetTrainerByIdQuery(trainerId, {
    skip: !trainerId,
    keepUnusedData: true,
  })
  const bookingsData = trainer?.bookings?.items || []
  const upcomingWorkouts =
    bookingsData?.filter((booking) => {
      return booking.status === 'accepted' && !isPast(booking.startTime)
    }) || []
  const requestedWorkouts =
    bookingsData?.filter((booking) => {
      return booking.status === 'pending'
    }) || []

  const pastWorkouts =
    bookingsData?.filter((booking) => {
      return booking.status === 'accepted' && isPast(booking.startTime)
    }) || []

  const handleChange = (event, newValue) => {
    setValue(newValue)
    dispatch(setSelectedRequest(null))
  }

  const [updateBookingStatusTrigger, updateBookingData] =
    useUpdateBookingStatusMutation()

  const handleAcceptBookingRequest = () => {
    // Call Update Booking Trigger Here
    console.log('selectedRequest?.id', selectedRequest?.id)
    const id = selectedRequest.id
    console.log('id', id)
    updateBookingStatusTrigger({ newStatus: 'accepted', bookingId: id })
  }

  const handleCancelBookingRequest = () => {
    // Call Update Booking Trigger Here
    console.log('selectedRequest?.id', selectedRequest?.id)
    const id = selectedRequest.id
    console.log('id', id)
    updateBookingStatusTrigger({ newStatus: 'canceled', bookingId: id })
  }

  const handleRescheduleBookingRequest = (newStartTime) => {
    // Call Update Booking Trigger Here
    console.log('selectedRequest?.id', selectedRequest?.id)
    const id = selectedRequest.id
    console.log('id', id)
    updateBookingStatusTrigger({
      newStatus: 'rescheduled',
      bookingId: id,
      startTime: newStartTime,
    })
  }
  const handleClickBooking = (booking) => {
    if (large) {
      dispatch(setSelectedRequest(booking))
      dispatch(setViewMemberId(booking?.member?.id))
    } else {
      dispatch(setSelectedRequest(booking))
      dispatch(setViewMemberId(booking?.member?.id))
      navigate(`/booking-details/${booking?.id}`)
    }
  }

  const handleOpenBookingReview = () => {
    console.log('clicked: ', selectedRequest)
    dispatch(
      setBookingReviewField({
        field: 'id',
        value: selectedRequest.id,
      })
    )
    dispatch(setBookingReviewModalOpen(true))
  }

  const handleGoToProfile = () => {
    const profileId = selectedRequest?.member?.id

    dispatch(setViewMemberId(profileId))
    navigate(`/member/${profileId}`)
  }
  const requestsComponent = () => (
    <Grid
      container
      sx={{
        bgcolor: 'white',
        p: 2,
        borderRadius: '20px',
      }}
      alignItems="flex-start"
    >
      <Grid
        item
        xs={12}
        md={4}
        sx={{ bgcolor: '#F6F7F8', p: 2, borderRadius: '20px' }}
      >
        <Grid container>
          <Grid
            container
            sx={{ bgcolor: 'white', borderRadius: '100px', mb: 2 }}
          >
            <InputBase
              sx={{ ml: 2, flex: 1 }}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search' }}
            />
            <IconButton
              color="primary"
              type="button"
              sx={{ p: '10px' }}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </Grid>
          <Grid container>
            {requestedWorkouts.map((workout) => (
              <Grid key={workout?.id} container sx={{ p: 1 }}>
                <Grid
                  item
                  sx={{
                    borderRadius: '20px',
                    opacity: selectedRequest?.id === workout?.id ? 1 : 0.33,
                    border:
                      selectedRequest?.id === workout?.id
                        ? 'none'
                        : '1px solid grey',
                    bgcolor:
                      selectedRequest?.id === workout?.id
                        ? 'primary.light'
                        : 'transparent',
                    width: '100%',
                    p: 2,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleClickBooking(workout)}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <S3Image
                            imagePath={`${workout?.member?.id}/Profile`}
                            dimensions={{
                              height: 60,
                              width: 60,
                              borderRadius: '50%',
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            justifyContent="flex-start"
                            onClick={() =>
                              console.log('open: ', workout.member)
                            }
                          >
                            <Typography
                              sx={{
                                fontWeight: 700,
                                color: 'gunMetal.primary',
                                fontSize: '17px',
                              }}
                            >
                              {workout?.member?.displayName}
                            </Typography>
                          </Grid>
                          <Grid container justifyContent="flex-start">
                            <Typography>
                              {workout?.member?.homeLocation
                                ? workout?.member?.homeLocation
                                : 'Member Location'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container>
                        <Typography>
                          {dayjs(workout?.startTime).format('MMM Do YYYY')}
                        </Typography>
                      </Grid>
                      <Grid container>
                        <Typography>
                          {dayjs(workout?.startTime).format('h:mm A z')}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-start" sx={{ pt: 2 }}>
                      <Chip
                        label={workout?.session?.category}
                        sx={{ bgcolor: 'gunMetal.main', color: 'white' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {large && (
        <Grid item xs={8}>
          {selectedRequest && (
            <Grid item>
              <Grid
                container
                alignItems="flex-end"
                sx={{ height: '100%', pl: 3, pr: 3 }}
              >
                <Grid container sx={{ p: 1 }} justifyContent="space-around">
                  <Grid container justifyContent="flex-end" sx={{ pb: 2 }}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: 17,
                        lineHeight: '25.5px',
                        color: 'gunMetal.main',
                      }}
                    >
                      Time since Request sent:{' '}
                    </Typography>
                    <Typography
                      sx={{
                        ml: 1,
                        fontWeight: 700,
                        fontSize: 17,
                        lineHeight: '25.5px',
                        color: 'primary.main',
                      }}
                    >
                      {dateToNow(selectedRequest?.createdAt)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="flex-start" sx={{ pb: 2 }}>
                    <Typography>
                      *New client, please conduct pre-session preview and
                      biometrics eval
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="flex-start" sx={{ pb: 2 }}>
                    <Button variant="outlined" onClick={handleGoToProfile}>
                      &rarr; Go to {selectedRequest?.member?.displayName}'s
                      Profile
                    </Button>
                  </Grid>
                  <Grid container justifyContent="flex-start" sx={{ pb: 2 }}>
                    <Grid
                      item
                      sx={{
                        borderRadius: '20px',
                        bgcolor: 'primary.light',

                        p: 2,
                      }}
                    >
                      <Grid
                        container
                        alignItems="flex-start"
                        justifyContent="space-between"
                      >
                        <Grid item sx={{ pr: 4 }}>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              <S3Image
                                imagePath={`${selectedRequest?.member?.id}/Profile`}
                                dimensions={{
                                  borderRadius: '50%',
                                  width: 60,
                                  height: 60,
                                }}
                              />
                            </Grid>
                            <Grid item sx={{ p: 1 }}>
                              <Grid
                                container
                                sx={{
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 700,
                                    color: 'gunMetal.primary',
                                    fontSize: '17px',
                                    lineHeight: '25.5px',
                                  }}
                                >
                                  {selectedRequest?.member?.displayName}
                                </Typography>
                              </Grid>
                              <Grid container>
                                <Typography
                                  sx={{
                                    fontWeight: 400,
                                    color: 'gunMetal.primary',
                                    fontSize: '14px',
                                    lineHeight: '25.5px',
                                  }}
                                >
                                  {selectedRequest?.member?.homeLocation}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sx={{ pl: 4 }}>
                          <Chip
                            sx={{ bgcolor: 'gunMetal.main', color: 'white' }}
                            label={selectedRequest?.session?.category}
                          />
                        </Grid>
                      </Grid>
                      <Grid container sx={{ pt: 1, pb: 2 }}>
                        <Typography
                          sx={{
                            color: 'gunMetal.primary',
                            fontWeight: 700,
                            fontSize: '26px',
                          }}
                        >
                          {dayjs(selectedRequest?.startTime).format(
                            'MMM Do YYYY, h:mm A'
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/*  <Grid
                  container
                  sx={{
                    bgcolor: 'white',
                    borderRadius: '100px',
                    mb: 3,
                    boxShadow: 2,
                  }}
                >
                  <InputBase
                    sx={{ ml: 2, flex: 1 }}
                    placeholder="Message your client if needed"
                    inputProps={{
                      'aria-label': 'Message your client if needed',
                    }}
                  />
                  <IconButton
                    color="primary"
                    type="button"
                    sx={{ p: '10px' }}
                    aria-label="search"
                  >
                    <SendOutlined />
                  </IconButton>
                </Grid> */}
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ bgcolor: 'white', p: 2 }}
                    >
                      Decline
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ bgcolor: 'white', p: 2 }}
                    >
                      Ask to reschedule
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ p: 2 }}
                      onClick={handleAcceptBookingRequest}
                    >
                      Accept
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  )

  const upcomingComponent = () => (
    <Grid
      container
      sx={{
        bgcolor: 'white',
        p: 2,
        borderRadius: '20px',
      }}
      alignItems="flex-start"
    >
      <Grid
        item
        xs={12}
        md={4}
        sx={{ bgcolor: '#F6F7F8', p: 2, borderRadius: '20px' }}
      >
        <Grid container>
          <Grid
            container
            sx={{ bgcolor: 'white', borderRadius: '100px', mb: 2 }}
          >
            <InputBase
              sx={{ ml: 2, flex: 1 }}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search' }}
            />
            <IconButton
              color="primary"
              type="button"
              sx={{ p: '10px' }}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </Grid>
          <Grid container>
            {upcomingWorkouts.map((workout) => (
              <Grid key={workout?.id} container sx={{ p: 1 }}>
                <Grid
                  item
                  sx={{
                    opacity: selectedRequest?.id === workout?.id ? 1 : 0.33,
                    border:
                      selectedRequest?.id === workout?.id
                        ? 'none'
                        : '1px solid grey',
                    bgcolor:
                      selectedRequest?.id === workout?.id
                        ? 'primary.light'
                        : 'transparent',
                    borderRadius: '20px',
                    width: '100%',
                    p: 2,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleClickBooking(workout)}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <S3Image
                            imagePath={`${workout?.member?.id}/Profile`}
                            dimensions={{
                              borderRadius: '50%',
                              width: 60,
                              height: 60,
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Grid container justifyContent="flex-start">
                            <Typography
                              sx={{
                                fontWeight: 700,
                                color: 'gunMetal.primary',
                                fontSize: '17px',
                              }}
                            >
                              {workout?.member?.displayName}
                            </Typography>
                          </Grid>
                          <Grid container justifyContent="flex-start">
                            <Typography>
                              {workout?.member?.homeLocation}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container>
                        <Typography>
                          {dayjs(workout?.startTime).format('MMM Do YYYY')}
                        </Typography>
                      </Grid>
                      <Grid container>
                        <Typography>
                          {' '}
                          {dayjs(workout?.startTime).format('h:mm A ')}
                          PDT
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-start" sx={{ pt: 2 }}>
                      <Chip
                        label={workout?.session?.category}
                        sx={{ bgcolor: 'gunMetal.main', color: 'white' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {large && (
        <Grid item xs={8}>
          {selectedRequest && (
            <Grid item>
              <Grid
                container
                alignItems="flex-end"
                sx={{ height: '100%', pl: 3, pr: 3 }}
              >
                <Grid container sx={{ p: 1 }} justifyContent="space-around">
                  <Grid
                    container
                    justifyContent="flex-start"
                    spacing={3}
                    sx={{ pb: 2 }}
                  >
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{ bgcolor: 'white', p: 2 }}
                      >
                        Cancel booking
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button variant="contained" color="primary" sx={{ p: 2 }}>
                        Ask to Reschedule
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid container justifyContent="flex-start" sx={{ pb: 2 }}>
                    <Grid
                      item
                      sx={{
                        borderRadius: '20px',
                        bgcolor: 'primary.light',

                        p: 2,
                      }}
                    >
                      <Grid
                        container
                        alignItems="flex-start"
                        justifyContent="space-between"
                      >
                        <Grid item sx={{ pr: 4 }}>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              <S3Image
                                imagePath={`${selectedRequest?.member?.id}/Profile`}
                                dimensions={{
                                  borderRadius: '50%',
                                  width: 60,
                                  height: 60,
                                }}
                              />
                            </Grid>
                            <Grid item sx={{ p: 1 }}>
                              <Grid container justifyContent="flex-start">
                                <Typography
                                  sx={{
                                    fontWeight: 700,
                                    color: 'gunMetal.primary',
                                    fontSize: '17px',
                                    lineHeight: '25.5px',
                                  }}
                                >
                                  {selectedRequest?.member.displayName}
                                </Typography>
                              </Grid>
                              <Grid container>
                                <Typography
                                  sx={{
                                    fontWeight: 400,
                                    color: 'gunMetal.primary',
                                    fontSize: '14px',
                                    lineHeight: '25.5px',
                                  }}
                                >
                                  {selectedRequest?.member?.homeLocation}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sx={{ pl: 4 }}>
                          <Chip
                            sx={{ bgcolor: 'gunMetal.main', color: 'white' }}
                            label={selectedRequest?.session?.category}
                          />
                        </Grid>
                      </Grid>
                      <Grid container sx={{ pt: 1, pb: 2 }}>
                        <Typography
                          sx={{
                            color: 'gunMetal.primary',
                            fontWeight: 700,
                            fontSize: '26px',
                          }}
                        >
                          {dayjs(selectedRequest?.startTime).format(
                            'MMM Do YYYY'
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      bgcolor: 'white',
                      borderRadius: '100px',
                      mb: 3,
                      boxShadow: 2,
                    }}
                  >
                    <InputBase
                      sx={{ ml: 2, flex: 1 }}
                      placeholder="Message your client if needed"
                      inputProps={{
                        'aria-label': 'Message your client if needed',
                      }}
                    />
                    <IconButton
                      color="primary"
                      type="button"
                      sx={{ p: '10px' }}
                      aria-label="search"
                    >
                      <SendOutlined />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  )

  const historyComponent = () => (
    <Grid
      container
      sx={{
        bgcolor: 'white',
        p: 2,
        borderRadius: '20px',
      }}
      alignItems="flex-start"
    >
      <Grid
        item
        xs={12}
        md={4}
        sx={{ bgcolor: '#F6F7F8', p: 2, borderRadius: '20px' }}
      >
        <Grid container>
          <Grid
            container
            sx={{ bgcolor: 'white', borderRadius: '100px', mb: 2 }}
          >
            <InputBase
              sx={{ ml: 2, flex: 1 }}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search' }}
            />
            <IconButton
              color="primary"
              type="button"
              sx={{ p: '10px' }}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </Grid>
          <Grid container>
            {pastWorkouts.map((workout) => (
              <Grid key={workout?.id} container sx={{ p: 1 }}>
                <Grid
                  item
                  sx={{
                    opacity: selectedRequest?.id === workout?.id ? 1 : 0.33,
                    border:
                      selectedRequest?.id === workout?.id
                        ? 'none'
                        : '1px solid grey',
                    bgcolor:
                      selectedRequest?.id === workout?.id
                        ? 'primary.light'
                        : 'transparent',
                    borderRadius: '20px',
                    width: '100%',
                    p: 2,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleClickBooking(workout)}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <S3Image
                            imagePath={`${workout?.member?.id}/Profile`}
                            dimensions={{
                              borderRadius: '50%',
                              width: 60,
                              height: 60,
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Grid container justifyContent="flex-start">
                            <Typography
                              sx={{
                                fontWeight: 700,
                                color: 'gunMetal.primary',
                                fontSize: '17px',
                              }}
                            >
                              {workout?.member?.displayName}
                            </Typography>
                          </Grid>
                          <Grid container justifyContent="flex-start">
                            <Typography>
                              {workout?.member?.homeLocation}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container>
                        <Typography>
                          {dayjs(workout?.startTime).format('MMM Do YYYY')}
                        </Typography>
                      </Grid>
                      <Grid container>
                        <Typography>
                          {' '}
                          {dayjs(workout?.startTime).format('h:mm A ')}
                          PDT
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-start" sx={{ pt: 2 }}>
                      <Chip
                        label={workout?.session?.category}
                        sx={{ bgcolor: 'gunMetal.main', color: 'white' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        {selectedRequest && large && (
          <Grid item>
            <Grid
              container
              alignItems="flex-end"
              sx={{ height: '100%', pl: 3, pr: 3 }}
            >
              <Grid container sx={{ p: 1 }} justifyContent="space-around">
                <Grid container justifyContent="flex-start" sx={{ pb: 2 }}>
                  <Grid
                    item
                    sx={{
                      borderRadius: '20px',
                      bgcolor: 'primary.light',

                      p: 2,
                    }}
                  >
                    <Grid
                      container
                      alignItems="flex-start"
                      justifyContent="space-between"
                    >
                      <Grid item sx={{ pr: 4 }}>
                        <Grid container justifyContent="space-between">
                          <Grid item>
                            <S3Image
                              imagePath={`${selectedRequest?.member?.id}/Profile`}
                              dimensions={{
                                borderRadius: '50%',
                                width: 60,
                                height: 60,
                              }}
                            />
                          </Grid>
                          <Grid item sx={{ p: 1 }}>
                            <Grid container>
                              <Typography
                                sx={{
                                  fontWeight: 700,
                                  color: 'gunMetal.primary',
                                  fontSize: '17px',
                                  lineHeight: '25.5px',
                                }}
                              >
                                {selectedRequest?.member.dispayName}
                              </Typography>
                            </Grid>
                            <Grid container>
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  color: 'gunMetal.primary',
                                  fontSize: '14px',
                                  lineHeight: '25.5px',
                                }}
                              >
                                {selectedRequest?.member?.homeLocation}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sx={{ pl: 4 }}>
                        <Chip
                          sx={{ bgcolor: 'gunMetal.main', color: 'white' }}
                          label={selectedRequest?.session?.category}
                        />
                      </Grid>
                    </Grid>
                    <Grid container sx={{ pt: 1, pb: 2 }}>
                      <Typography
                        sx={{
                          color: 'gunMetal.primary',
                          fontWeight: 700,
                          fontSize: '26px',
                        }}
                      >
                        {dayjs(selectedRequest?.startTime).format(
                          'MMM Do YYYY'
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Button variant="outlined" onClick={handleOpenBookingReview}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '26px',
                      }}
                    >
                      Review Session
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
  const dispatch = useDispatch()
  const viewMemberId = useSelector((state) => state.ui.viewMemberId)
  const navigate = useNavigate()
  return (
    <Box sx={{ width: '100%', bgcolor: '#F6F7F8' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Requests" {...a11yProps(0)} />
          <Tab label="Upcoming" {...a11yProps(1)} />
          <Tab label="History" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container justifyContent="space-between">
          <Typography> Your Booking Requests</Typography>
        </Grid>

        {requestsComponent()}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container justifyContent="space-between">
          <Typography>Your Upcoming Bookings</Typography>
        </Grid>
        {upcomingComponent()}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container justifyContent="space-between">
          <Typography>Your Past Bookings</Typography>
        </Grid>
        {historyComponent()}
      </TabPanel>
    </Box>
  )
}
