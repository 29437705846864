import { Grid } from '@mui/material'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import DashboardFavoritesPage from '../components/member-dashboard/DashboardFavoritesPage'

function Favorites() {
  return (
    <Grid container>
      <Header />
      <DashboardFavoritesPage />
      <Footer />
    </Grid>
  )
}

export default Favorites
