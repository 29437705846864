import * as React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CardActionArea, Chip, Grid, IconButton } from '@mui/material'
import theme from '../../styles/theme'

import { ReactComponent as TrainerStarRating } from '../../assets/icons/trainerStarRating.svg'
import { useGetMemberByIdQuery } from '../../redux/api/api'
import { useNavigate } from 'react-router-dom'
import S3Image from '../S3Image'
import { AccessTime } from '@mui/icons-material'

export default function TrainerCard(props) {
  const navigate = useNavigate()
  const { trainer } = props
  const [favorited, setFavorited] = React.useState(false)

  const {
    data: trainerResp,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetMemberByIdQuery(trainer?.id, {
    keepUnusedDataFor: 100000,
    pollingInterval: 100000,
  })
  if (isSuccess) {
    return (
      <Grid item sx={{ p: 1 }} xs={12}>
        <Card
          sx={{
            width: '40%',
            height: '150px',
            borderRadius: '16px',
          }}
        >
          <CardActionArea
            onClick={() => {
              navigate(`/trainer/${trainer.id}`)
            }}
          >
            <Grid container sx={{ width: '100%', p: '25px' }}>
              <Grid item xs={2}>
                <S3Image
                  imagePath={`${trainer?.id}/${trainerResp?.profilePhotoUrl}`}
                  //imagePath={`${trainer?.id}/${trainer?.profilePhotoUrl}`}
                  dimensions={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontStyle: ' normal',
                      fontWeight: ' 500',
                      fontSize: ' 34px',
                      lineHeight: ' 21px',
                      /* identical to box height */
                      textAlign: ' center',
                      color: 'black',
                    }}
                  >
                    {trainer?.displayName}
                  </Typography>
                </Grid>
                <Grid container>
                  <Box
                    sx={{
                      width: '70%',
                      padding: '5px',
                      m: '10px',
                      textAlign: ' center',
                      background: ' #000000',
                      borderRadius: ' 50px',
                      display: ' flex',
                      flexDirection: ' row',
                      justifyContent: ' center',
                      alignItems: ' center',
                      gap: ' 4px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontStyle: ' normal',
                        fontWeight: 500,
                        fontSize: ' 16px',
                        lineHeight: ' 21px',
                        /* identical to box height */

                        color: ' #FFFFFF',
                      }}
                    >
                      {' '}
                      {trainerResp?.homeCity}
                    </Typography>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.00083 7.83203C8.32206 7.83203 8.59657 7.71765 8.82435 7.48888C9.05213 7.26013 9.16602 6.98513 9.16602 6.66388C9.16602 6.34265 9.05163 6.06814 8.82287 5.84036C8.59411 5.61259 8.31911 5.4987 7.99787 5.4987C7.67663 5.4987 7.40213 5.61308 7.17435 5.84185C6.94657 6.0706 6.83268 6.3456 6.83268 6.66685C6.83268 6.98808 6.94707 7.26259 7.17583 7.49036C7.40459 7.71814 7.67959 7.83203 8.00083 7.83203ZM7.99935 13.3487C9.47713 12.0043 10.5688 10.7848 11.2743 9.69036C11.9799 8.59592 12.3327 7.63203 12.3327 6.7987C12.3327 5.48992 11.9144 4.41828 11.0778 3.58378C10.2412 2.74928 9.21509 2.33203 7.99935 2.33203C6.7836 2.33203 5.75745 2.74928 4.92088 3.58378C4.0843 4.41828 3.66602 5.48992 3.66602 6.7987C3.66602 7.63203 4.02713 8.59592 4.74935 9.69036C5.47157 10.7848 6.5549 12.0043 7.99935 13.3487ZM7.99935 14.6654C6.21046 13.1431 4.87435 11.7293 3.99102 10.4237C3.10768 9.11814 2.66602 7.90981 2.66602 6.7987C2.66602 5.13203 3.20213 3.80425 4.27435 2.81536C5.34657 1.82648 6.58824 1.33203 7.99935 1.33203C9.41046 1.33203 10.6521 1.82648 11.7243 2.81536C12.7966 3.80425 13.3327 5.13203 13.3327 6.7987C13.3327 7.90981 12.891 9.11814 12.0077 10.4237C11.1243 11.7293 9.78824 13.1431 7.99935 14.6654Z"
                        fill="white"
                      />
                    </svg>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container sx={{ gap: 1 }}>
                  <Grid item xs={6}>
                    <AccessTime color="primary" />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      <b style={{ color: theme.palette.primary.main }}>
                        {trainerResp?.yearsExperience}{' '}
                      </b>
                      Years of Experience
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardActionArea>
        </Card>
      </Grid>
    )
  }
}
