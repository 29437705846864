const trainingStyles = [
  {
    title: 'Drill Sargent',
    subtitle: 'The Pusher',
    image: 'drillsarg.png',
  },
  {
    title: 'Cheerleader',
    subtitle: 'The Motivator',
    image: 'cheerleader.png',
  },
  {
    title: 'Professor',
    subtitle: 'The Technical Educator',
    image: 'Prof.png',
  },
  {
    title: 'Workout Buddy',
    subtitle: 'The Partner',
    image: 'buddy.png',
  },
]

export default trainingStyles
