import {
  Button,
  Chip,
  Grid,
  IconButton,
  InputBase,
  Typography,
} from '@mui/material'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import MemberDashboard from '../components/member-dashboard/MemberDashboardDrawer'
import { useNavigate, useParams } from 'react-router-dom'
import S3Image from '../components/S3Image'
import dayjs from 'dayjs'
import { ArrowBackIosNew, SendOutlined } from '@mui/icons-material'
import { useUpdateBookingStatusMutation } from '../redux/api/api'
import { useDispatch, useSelector } from 'react-redux'
function BookingDetails() {
  const dispatch = useDispatch()
  const { bookingId } = useParams()
  const selectedRequest = useSelector((state) => state.ui.selectedRequest)

  const [updateBookingStatusTrigger, updateBookingData] =
    useUpdateBookingStatusMutation()

  const handleAcceptBookingRequest = () => {
    // Call Update Booking Trigger Here
    console.log('selectedRequest?.id', selectedRequest?.id)
    const id = selectedRequest.id
    console.log('id', id)
    updateBookingStatusTrigger({ newStatus: 'accepted', bookingId: id })
  }

  const handleCancelBookingRequest = () => {
    // Call Update Booking Trigger Here
    console.log('selectedRequest?.id', selectedRequest?.id)
    const id = selectedRequest.id
    console.log('id', id)
    updateBookingStatusTrigger({ newStatus: 'canceled', bookingId: id })
  }

  const handleRescheduleBookingRequest = (newStartTime) => {
    // Call Update Booking Trigger Here
    console.log('selectedRequest?.id', selectedRequest?.id)
    const id = selectedRequest.id
    console.log('id', id)
    updateBookingStatusTrigger({
      newStatus: 'rescheduled',
      bookingId: id,
      startTime: newStartTime,
    })
  }

  const navigate = useNavigate()
  return (
    <Grid container>
      <Header />
      <Grid container>
        <Grid container justifyContent="flex-start" sx={{ p: 1, pl: 2 }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosNew />
          </IconButton>
        </Grid>
        <Grid
          container
          alignItems="flex-end"
          sx={{ height: '100%', pl: 3, pr: 3 }}
        >
          <Grid container sx={{ p: 1 }} justifyContent="space-around">
            <Grid
              container
              justifyContent="space-around"
              spacing={3}
              sx={{ pb: 2 }}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  color="error"
                  sx={{ bgcolor: 'white', p: 2 }}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item>
                <Button variant="outlined" color="primary" sx={{ p: 2 }}>
                  <Typography sx={{ fontWeight: 700, color: 'gunMetal.main' }}>
                    Reschedule
                  </Typography>
                </Button>
              </Grid>
              {selectedRequest.status === 'accepted' && (
                <Grid item>
                  <Button variant="contained" color="primary" sx={{ p: 2 }}>
                    <Typography sx={{ fontWeight: 700 }}>Accept</Typography>
                  </Button>
                </Grid>
              )}
            </Grid>

            <Grid container justifyContent="flex-start" sx={{ pb: 2 }}>
              <Grid
                item
                sx={{
                  borderRadius: '20px',
                  bgcolor: 'primary.light',

                  p: 2,
                }}
              >
                <Grid
                  container
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Grid item sx={{ pr: 4 }}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <S3Image
                          imagePath={`${selectedRequest?.member?.id}/Profile`}
                          dimensions={{
                            borderRadius: '50%',
                            width: 60,
                            height: 60,
                          }}
                        />
                      </Grid>
                      <Grid item sx={{ p: 1 }}>
                        <Grid container justifyContent="flex-start">
                          <Typography
                            sx={{
                              fontWeight: 700,
                              color: 'gunMetal.primary',
                              fontSize: '17px',
                              lineHeight: '25.5px',
                            }}
                          >
                            {selectedRequest?.member.displayName}
                          </Typography>
                        </Grid>
                        <Grid container>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              color: 'gunMetal.primary',
                              fontSize: '14px',
                              lineHeight: '25.5px',
                            }}
                          >
                            {selectedRequest?.member?.homeLocation}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ pl: 4 }}>
                    <Chip
                      sx={{ bgcolor: 'gunMetal.main', color: 'white' }}
                      label={selectedRequest?.session?.category}
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ pt: 1, pb: 2 }}>
                  <Typography
                    sx={{
                      color: 'gunMetal.primary',
                      fontWeight: 700,
                      fontSize: '26px',
                    }}
                  >
                    {dayjs(selectedRequest?.startTime).format('MMM Do YYYY')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                bgcolor: 'white',
                borderRadius: '100px',
                mb: 3,
                boxShadow: 2,
              }}
            >
              <InputBase
                sx={{ ml: 2, flex: 1 }}
                placeholder="Message your client if needed"
                inputProps={{
                  'aria-label': 'Message your client if needed',
                }}
              />
              <IconButton
                color="primary"
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
              >
                <SendOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  )
}

export default BookingDetails
