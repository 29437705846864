import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import PersonIcon from '@mui/icons-material/Person'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import { blue } from '@mui/material/colors'
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material'
import { Add, Cancel, InfoOutlined } from '@mui/icons-material'
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { StaticDateRangePicker } from '@mui/lab'

function SetUpCalendarDialog(props) {
  const { onClose, selectedValue, open } = props
  const [value, setValue] = React.useState([null, null])

  const handleClose = () => {
    onClose(selectedValue)
  }

  const handleListItemClick = (value) => {
    onClose(value)
  }

  return (
    <Dialog
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'md'}
      open={open}
      sx={{}}
    >
      <Grid
        container
        justifyContent="flex-end"
        alignItems="start"
        sx={{ p: 2, pb: 0 }}
      >
        <Grid item>
          <IconButton onClick={handleClose}>
            <Cancel />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container sx={{ p: 2, pt: 0, pb: 0 }}>
        <Typography
          sx={{ fontWeight: 700, fontSize: '26px', color: 'gunMetal.main' }}
        >
          Set Up Calendar
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        sx={{ p: 2, pb: 0 }}
      >
        <Typography
          sx={{ fontWeight: 700, fontSize: '26px', color: 'gunMetal.main' }}
        >
          Session duration
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        sx={{ p: 2, pt: 0 }}
      >
        <Typography
          sx={{ fontWeight: 400, fontSize: '17px', color: 'gunMetal.main' }}
        >
          Choose one to enable calendar
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        sx={{ p: 2, pt: 0 }}
      >
        {[30, 60, 90, 120].map((duration) => (
          <Grid item sx={{ pr: 2 }}>
            <Button variant={'outlined'} sx={{ width: '100px' }}>
              <Typography sx={{ fontWeight: 600, color: 'gunMetal.main' }}>
                {duration} min
              </Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2 }}
      >
        <Grid item>
          <Typography
            sx={{ fontWeight: 700, fontSize: '26px', color: 'gunMetal.main' }}
          >
            Days Available
          </Typography>
        </Grid>
        <Grid item>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Select All"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        sx={{ p: 2, pt: 0 }}
      >
        {['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'].map((day) => (
          <Grid item sx={{ pr: 2 }}>
            <Chip
              onClick={() => console.log('click')}
              label={day}
              sx={{ bgcolor: 'gunMetal.main', color: 'white' }}
            />
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        sx={{ p: 2, pt: 0 }}
      >
        <Grid item>
          <Typography
            sx={{ fontWeight: 700, fontSize: '26px', color: 'gunMetal.main' }}
          >
            Max Sessions per day
          </Typography>
          <TextField sx={{ width: '100px' }} />
        </Grid>
        <Grid item sx={{ pl: 4 }}>
          <Typography
            sx={{ fontWeight: 700, fontSize: '26px', color: 'gunMetal.main' }}
          >
            Min time between sessions
          </Typography>
          <Grid container justifyContent="start">
            <TextField sx={{ width: '100px' }} />
            <Select />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        sx={{ p: 2 }}
      >
        {' '}
        <Typography
          sx={{ fontWeight: 700, fontSize: '26px', color: 'gunMetal.main' }}
        >
          Min time client can book before start of session
        </Typography>
        <Grid container justifyContent="start">
          <TextField sx={{ width: '100px' }} />
          <Select />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        sx={{ p: 2 }}
      >
        {' '}
        <Typography
          sx={{ fontWeight: 700, fontSize: '26px', color: 'gunMetal.main' }}
        >
          Dates
        </Typography>
        <Grid container justifyContent="start">
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="select"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="select"
                control={<Radio />}
                label={
                  <Grid container justifyContent="start">
                    <Typography>Select date range</Typography>
                    <InfoOutlined sx={{ ml: 2 }} color="error" />
                  </Grid>
                }
              />
              <FormControlLabel
                value="repeat"
                control={<Radio />}
                label={
                  <Grid container justifyContent="start">
                    <Typography>Repeat (no end)</Typography>
                  </Grid>
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Box
          sx={{
            border: 2,
            borderColor: 'primary.main',
            width: '90%',
            borderRadius: '20px',
            p: 2,
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="year"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>Test</Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid container>
        <Grid item sx={{ p: 2 }}>
          <Button variant="outlined" sx={{ color: 'gunMetal.main' }}>
            Cancel
          </Button>
        </Grid>
        <Grid item sx={{ p: 2 }}>
          <Button variant="contained" sx={{ color: 'white' }}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

SetUpCalendarDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
}

export default function AvailabilityModal() {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)
  }

  return (
    <>
      <Grid container justifyContent="start" alignItems="start" sx={{ pt: 2 }}>
        <Button variant="outlined" onClick={handleClickOpen}>
          <Add sx={{ pr: 1 }} color="primary" />
          <Typography
            sx={{
              fontWeight: 600,
              color: 'gunMetal.main',
            }}
          >
            Availability
          </Typography>
        </Button>
      </Grid>
      <SetUpCalendarDialog open={open} onClose={handleClose} />
    </>
  )
}
