import { IconButton, Typography, Box } from '@mui/material'
import * as React from 'react'

import { useSelector, useDispatch } from 'react-redux'

export default function TextSwitch(props) {
  const { textA, textB, defaultState, onSelect, oneOption } = props
  const [switchLeft, setSwitchLeft] = React.useState(defaultState)
  return (
    <Box
      sx={{
        border: 1,
        borderColor: oneOption ? 'text.secondary' : 'primary.main',
        borderRadius: '30px',
        m: 2,
        mr: 0,
      }}
    >
      <IconButton
        onClick={oneOption ? onSelect : () => setSwitchLeft(true)}
        sx={{
          height: '40px',
          width: '40px',
          color: switchLeft || oneOption ? 'white' : 'primary.main',
          bgcolor: oneOption
            ? 'text.secondary'
            : switchLeft
            ? 'primary.main'
            : 'inherit',
          m: 0.5,
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '15px',
            lineHeight: '22.5px',
          }}
        >
          {textA}
        </Typography>
      </IconButton>
      <IconButton
        onClick={() => setSwitchLeft(false)}
        sx={{
          height: '40px',
          width: '40px',
          color: !switchLeft ? 'white' : 'primary.main',
          bgcolor: !switchLeft ? 'primary.main' : 'inherit',
          m: 0.5,
        }}
      >
        {!oneOption && (
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '15px',
              lineHeight: '22.5px',
            }}
          >
            {textB}
          </Typography>
        )}
      </IconButton>
    </Box>
  )
}
