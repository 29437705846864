import { Grid, Typography } from '@mui/material'

const Notes = ({ content }) => {
  return (
    <Grid
      sx={{
        border: '1px solid black',
        minHeight: 84,
        borderRadius: '20px',
        p: 1,
      }}
    >
      <Typography>{content ? content : ''}</Typography>
    </Grid>
  )
}
export default Notes
