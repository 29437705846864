import { API, Auth, graphqlOperation } from 'aws-amplify'
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAuthModalOpen,
  setOnboardOpen,
  setOnboardStep,
  setSignUpSignIn,
} from '../../redux/slices/uiSlice'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'
import TitleC from '../text/TitleC'
import TitleB from '../text/TitleB'
import SubTitleA from '../text/SubTitleA'
import SubTitleB from '../text/SubTitleB'
import { Refresh, Visibility, VisibilityOff } from '@mui/icons-material'
import SolidColorButton from '../buttons/SolidColorButton'
import GreyTextButton from '../buttons/GreyTextButton'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { createUser } from '../../graphql/mutations'

export default function AuthModal() {
  const [authErrorMessage, setAuthErrorMessage] = useState('')
  const [textFields, setTextFields] = useState({
    username: '',
    password: '',
    firstName: '',
    lastName: '',
    confirmationPassword: '',
    verificationCode: '',
  })
  const [agreed, setAgreed] = useState(false)
  const [confirming, setConfirming] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const signUpSignIn = useSelector((state) => state.ui.signUpSignIn)
  const userCognitoID = useSelector((state) => state.auth.cognitoUser?.sub)
  async function signUp() {
    const { username, password, firstName, lastName, confirmationPassword } =
      textFields
    console.debug(username, password, firstName, lastName, confirmationPassword)
    try {
      const hubspotFunctionUrl =
        'https://fp4hy2e4xtzp6zrj3m4fbbyemy0jhpag.lambda-url.us-east-2.on.aws/'
      const hubspotResponse = await axios.post(hubspotFunctionUrl, {
        email: username,
        first_name: firstName,
        last_name: lastName,
      })
    } catch (error) {
      console.debug('hubspot error', error)
    }
    try {
      const stripeAcct = await axios.post(
        'https://fbfnzssjr6bnkmknrdcx743ls40xeqdr.lambda-url.us-east-2.on.aws/',
        {
          name: firstName + ' ' + lastName,
          email: username,
        }
      )
      const data = stripeAcct.data
      const stripe_id = data.id
      console.log('stripe_id: ', stripe_id)
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          'custom:user_type': 'member',
          'custom:stripe_customer_id': stripe_id,
          given_name: firstName,
          family_name: lastName,
          email: username, // optional
          //phone_number, // optional - E.164 number convention
          // other custom attributes
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed
          enabled: true,
        },
      })
      console.log(user)
      setConfirming(true)
    } catch (error) {
      console.log('error signing up:', error)

      if (
        error ==
        'InvalidPasswordException: Password did not conform with policy: Password must have symbol characters'
      ) {
        setAuthErrorMessage(
          'Password must contain at least one symbol character'
        )
      }
      if (
        error ==
        'InvalidPasswordException: Password did not conform with policy: Password must have uppercase characters'
      ) {
        setAuthErrorMessage(
          'Password must contain at least one uppercase character'
        )
      }
      if (
        error ==
        'InvalidPasswordException: Password did not conform with policy: Password must have lowercase characters'
      ) {
        setAuthErrorMessage(
          'Password must contain at least one lowercase character'
        )
      }
      if (
        error ==
        'InvalidPasswordException: Password did not conform with policy: Password must have numeric characters'
      ) {
        setAuthErrorMessage('Password must contain at least one number')
      }
      if (
        error ==
        'InvalidPasswordException: Password did not conform with policy: Password not long enough'
      ) {
        setAuthErrorMessage('Password must be at least 8 characters long')
      }
    }
  }

  async function signIn() {
    const { username, password } = textFields
    console.debug(username, password)
    try {
      const { user } = await Auth.signIn({
        username,
        password,
      })
      console.log(user)
      handleCloseAuthModal()
    } catch (error) {
      console.log('error signing up:', error)
      if (error == 'NotAuthorizedException: Incorrect username or password.') {
        setAuthErrorMessage('Incorrect username or password. Please try again.')
      }
      if (error == 'UserNotFoundException: User does not exist.') {
        setAuthErrorMessage("We couldn't find an account with that email.")
      }
    }
  }

  const open = useSelector((state) => state.ui.authModalOpen)

  const dispatch = useDispatch()

  const handleCloseAuthModal = () => {
    dispatch(setAuthModalOpen(false))
  }

  const handleChangeText = (prop) => (event) => {
    setTextFields({ ...textFields, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleAuthButtonClick = async () => {
    if (signUpSignIn === 'signUp') {
      console.debug('Sign up here', textFields.username)
      await signUp()
    } else {
      console.debug('Sign in here', textFields.username)
      await signIn()
    }
  }

  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(textFields.username)
      console.log('code resent successfully')
    } catch (err) {
      console.log('error resending code: ', err)
    }
  }

  async function handleConfirmAccount() {
    try {
      await Auth.confirmSignUp(textFields.username, textFields.verificationCode)
      setConfirming(false)
      handleCloseAuthModal()
      dispatch(setOnboardOpen(true))
      dispatch(setOnboardStep(1))
    } catch (error) {
      console.log('error confirming sign up', error)
      //setAuthErrorMessage()
    }
  }

  async function handleForgotPassword() {
    //Set the page for forgot password
    try {
      const data = await Auth.forgotPassword(textFields.username)
      console.log(data)
      setForgotPassword(true)
      setConfirming(true)
    } catch (err) {
      console.log(err)
    }
  }

  // Collect confirmation code and new password
  async function handleForgotPasswordSubmit(username, code, newPassword) {
    try {
      const data = await Auth.forgotPasswordSubmit(
        textFields.username,
        textFields.verificationCode,
        textFields.confirmationPassword
      )
      console.log(data)
      // Todo: redirect to login page and set alert
      setConfirming(false)
      setForgotPassword(false)
    } catch (err) {
      console.log(err)
    }
  }

  const signupDisabled =
    textFields.username === '' ||
    textFields.password === '' ||
    textFields.firstName === '' ||
    textFields.lastName === '' ||
    textFields.confirmationPassword === '' ||
    !agreed

  const signinDisabled =
    textFields.username === '' || textFields.password === ''

  const handleSwitchSignUpSignIn = () => {
    if (signUpSignIn === 'signUp') {
      dispatch(setSignUpSignIn('signIn'))
    } else {
      dispatch(setSignUpSignIn('signUp'))
    }
  }

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      PaperProps={{
        sx: {
          minWidth: '50%',
          overflow: 'hidden',
          //width: '100%',
          //maxWidth: '666px !important',
          //height: '100%',
          //maxHeight: '777px !important',
          alignItems: 'center',
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle style={{ width: '95%' }}>
        <Grid container sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            {/*  {' '}
            <IconButton
              onClick={() => {
                //lastPage()
              }}
              style={{ width: '20px', height: '20px', marginLeft: '20px' }}
            >
              <ArrowBackIcon />
            </IconButton> */}
          </Grid>

          <Grid item>
            <IconButton
              onClick={handleCloseAuthModal}
              style={{ width: '20px', height: '20px', marginRight: '20px' }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {confirming ? (
        <DialogContent style={{}}>
          <Grid container sx={{ p: 2 }}>
            <SubTitleA
              text={`Please Check Your Email To ${
                forgotPassword ? 'Reset' : 'Verify'
              } Your Account`}
            />
          </Grid>
          {forgotPassword && (
            <Grid container sx={{ justifyContent: 'flex-start', pb: 4 }}>
              <SubTitleB text={'New Password'} />
              <TextField
                type={showPassword ? 'text' : 'password'}
                value={textFields.confirmationPassword}
                onChange={handleChangeText('confirmationPassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility color="primary" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                fullWidth
                placeholder="Confirm Password"
              />
            </Grid>
          )}
          <Grid container sx={{ justifyContent: 'flex-start' }}>
            <SubTitleB text={'Verification Code'} />
            <TextField
              fullWidth
              sx={{ textAlign: 'center' }}
              value={textFields.verificationCode}
              onChange={handleChangeText('verificationCode')}
              variant="standard"
              placeholder="######"
              inputProps={{ min: 0, style: { textAlign: 'center' } }}
            />
          </Grid>

          {!forgotPassword && (
            <Grid container sx={{ p: 2 }}>
              <Button
                onClick={resendConfirmationCode}
                variant="text"
                color="primary"
                sx={{ textTransform: 'none' }}
              >
                Resend Confirmation
                <Refresh />
              </Button>
            </Grid>
          )}
          <Grid container sx={{ p: 2 }}>
            <SolidColorButton
              onClick={
                !forgotPassword
                  ? handleConfirmAccount
                  : handleForgotPasswordSubmit
              }
              text="Submit"
            />
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent style={{}}>
          <Grid container sx={{ p: 2 }}>
            <TitleB
              text={
                signUpSignIn === 'signUp'
                  ? "We're excited to have you!"
                  : 'Welcome Back!'
              }
            />
          </Grid>
          <Grid container sx={{ p: 2 }}>
            <TitleB text={signUpSignIn === 'signUp' ? 'Sign Up' : 'Login'} />
          </Grid>
          {signUpSignIn === 'signUp' && (
            <Grid container sx={{ p: 2, justifyContent: 'flex-start' }}>
              <SubTitleB text={'First Name'} />
              <TextField
                value={textFields.firstName}
                onChange={handleChangeText('firstName')}
                variant="standard"
                fullWidth
                placeholder="First Name"
              />
            </Grid>
          )}
          {signUpSignIn === 'signUp' && (
            <Grid container sx={{ p: 2, justifyContent: 'flex-start' }}>
              <SubTitleB text={'Last Name'} />
              <TextField
                value={textFields.lastName}
                onChange={handleChangeText('lastName')}
                variant="standard"
                fullWidth
                placeholder="Last Name"
              />
            </Grid>
          )}
          <Grid container sx={{ p: 2, justifyContent: 'flex-start' }}>
            <SubTitleB text={'Email'} />
            <TextField
              value={textFields.username}
              onChange={handleChangeText('username')}
              variant="standard"
              fullWidth
              placeholder="example@email.com"
            />
          </Grid>
          <Grid container sx={{ p: 2, justifyContent: 'flex-start' }}>
            <SubTitleB text={'Password'} />
            <TextField
              type={showPassword ? 'text' : 'password'}
              value={textFields.password}
              onChange={handleChangeText('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="standard"
              fullWidth
              placeholder="Password"
            />
            {/* TODO: CHACK OUT THIS FORGOT PASSWORD*/}
            {signUpSignIn === 'signIn' && (
              <Button
                sx={{ borderRadius: 0, mt: 1 }}
                disabled={textFields.username === ''}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                  }}
                  onClick={handleForgotPassword}
                >
                  Forgot Password?
                </Typography>
              </Button>
            )}
          </Grid>
          {signUpSignIn === 'signUp' && (
            <Grid container sx={{ p: 2, justifyContent: 'flex-start' }}>
              <SubTitleB text={'Confirm Password'} />
              <TextField
                type={showPassword ? 'text' : 'password'}
                value={textFields.confirmationPassword}
                onChange={handleChangeText('confirmationPassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility color="primary" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                fullWidth
                placeholder="Confirm Password"
              />
            </Grid>
          )}

          {signUpSignIn === 'signUp' && (
            <Grid container>
              <Grid container sx={{ p: 1 }}>
                <Typography
                  sx={{
                    color: 'error.main',
                    fontSize: '10px',
                    fontStyle: 'italic',
                  }}
                >
                  *Please include a lowercase letter, capital letter, number,
                  and symbol
                </Typography>
              </Grid>
            </Grid>
          )}
          {authErrorMessage && (
            <Alert variant="outlined" severity="error" authErro>
              {authErrorMessage}
            </Alert>
          )}

          {!signUpSignIn === 'signUp' && (
            <Grid container>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked={false} />}
                  label="Remember Me"
                />
              </FormGroup>
            </Grid>
          )}
          <Grid container sx={{ p: 1 }}>
            <SolidColorButton
              disabled={
                signUpSignIn === 'signUp' ? signupDisabled : signinDisabled
              }
              onClick={handleAuthButtonClick}
              text={signUpSignIn === 'signUp' ? 'Sign Up' : 'Login'}
            />
          </Grid>

          <Grid container sx={{ p: 1 }}>
            {signUpSignIn === 'signUp' && (
              <Grid container>
                <Checkbox
                  checked={agreed}
                  onChange={() => setAgreed(!agreed)}
                />
                <Typography sx={{ color: 'gunMetal.main', ml: 1 }}>
                  I agree to the following{' '}
                  <a
                    target="_blank"
                    href="/privacy"
                    rel="noopener noreferrer"
                    underline="none"
                    sx={{ color: 'primary.main' }}
                  >
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a
                    target="_blank"
                    href="/terms"
                    rel="noopener noreferrer"
                    underline="none"
                    sx={{ color: 'primary.main' }}
                  >
                    Terms and Conditions
                  </a>
                </Typography>
              </Grid>
            )}
            {!signUpSignIn === 'signUp' && (
              <Grid container>
                <Grid item sx={{ pr: 2 }}>
                  {' '}
                  <a
                    target="_blank"
                    href="/privacy"
                    rel="noopener norefferer"
                    underline="none"
                    sx={{ color: 'primary.main' }}
                  >
                    Privacy Policy
                  </a>{' '}
                </Grid>
                <Grid item>
                  {' '}
                  <a
                    target="_blank"
                    href="/privacy"
                    rel="noopener"
                    underline="none"
                    sx={{ color: 'primary.main' }}
                  >
                    Privacy Policy
                  </a>{' '}
                </Grid>
              </Grid>
            )}
            <Typography>
              {signUpSignIn === 'signUp'
                ? 'Already have an account?'
                : 'No Account?'}
            </Typography>
            <Button
              onClick={handleSwitchSignUpSignIn}
              variant="text"
              color="primary"
              sx={{ textTransform: 'none' }}
            >
              {signUpSignIn === 'signUp' ? 'Login' : 'Sign up'}
            </Button>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  )
}
