import { IconButton } from "@mui/material"
import { useSelector } from "react-redux"
import { ReactComponent as Pencil } from '../../assets/icons/pencil.svg'
const EditIconButtonBorder = (props) => {
  const { text, onClick, editModeOverride } = props
  const editMode = useSelector((state) => state.ui.editMode)
  return (
    (editMode || editModeOverride) && (
      <IconButton onClick={onClick} size={'small'}>
        <Pencil />
      </IconButton>
    )
  )
}

export default EditIconButtonBorder