import { Button, Grid, Paper } from '@mui/material'
import TitleB from '../../text/TitleB'
import SearchBar from './SearchBar'
import EntryCard from './EntryCard'
import AddInfoIconButton from './AddInfoIconButton'
import SubTitleB from '../../text/SubTitleB'
import Stat from './Stat'
import Notes from './Notes'
import { ReactComponent as Pencil } from '../../../assets/icons/pencil.svg'

const MemberStatus = () => {
  return (
    <Paper
      style={{
        minheight: 620,
        borderRadius: 16,
      }}
    >
      <Grid
        container
        style={{
          padding: '32px 64px',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Grid item>
          <TitleB text="My Workout Entries" />
          <SearchBar />
          {/*
            <EntryCard active />
          <EntryCard />
          <EntryCard />
            */}

          <Grid container sx={{ justifyContent: 'flex-end', pt: 2 }}>
            <AddInfoIconButton text="Create New Entry" onClick={() => {}} />
          </Grid>
        </Grid>
        <Grid item>
          <Grid container style={{ justifyContent: 'space-between' }}>
            <Grid item>
              <TitleB text="My Stats" />
            </Grid>
            <Grid item>
              <SubTitleB text=" Jun 12th 2021, 11:00 AM PDT" />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item>
              <Stat title="Max heart rate"></Stat>
            </Grid>
            <Grid item>
              <Stat title="Avg heart rate"></Stat>
            </Grid>
            <Grid item>
              <Stat title="Calories burned"></Stat>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <TitleB text="My Notes" />
            </Grid>
            <Grid item xs={12}>
              <Notes />
            </Grid>
          </Grid>
          <Grid
            container
            style={{ justifyContent: 'flex-end', paddingTop: '32px' }}
          >
            <Button
              variant="outlined"
              startIcon={<Pencil />}
              style={{ color: 'gunMetal.main' }}
            >
              Edit
            </Button>
          </Grid>
          <Grid container>
            <Grid item xs={12}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default MemberStatus
