import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import TitleC from '../text/TitleC'
import { ReactComponent as LocationIcon } from '../../assets/icons/location_pin.svg'
import { useState } from 'react'
import { CheckCircle, SaveOutlined } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'

const ChooseTrainingStyles = () => {
  const dispatch = useDispatch()
  const isTrainerOnboard = useSelector((state) => state.ui.isTrainerOnboard)
  const userFormFields = useSelector((state) => state.forms.userProfileModal)
  const trainingStyles = userFormFields?.trainingStyles || []

  const handleTrainingStyleClick = (trainingStyle) => {
    console.log('trainingStyle', trainingStyle)
    const copy = [...trainingStyles]

    const filtered = copy.includes(trainingStyle)
      ? trainingStyles?.filter(function (item) {
          return item !== trainingStyle
        })
      : [...trainingStyles, trainingStyle]
    console.log('filtered', filtered)
    dispatch(
      setUserProfileModalField({ field: 'trainingStyles', value: filtered })
    )
  }

  return (
    <>
      <Grid item xs={12}>
        <TitleC
          text={
            isTrainerOnboard
              ? 'What is Your Training Style?'
              : 'What Training Style Do You Prefer'
          }
        />
      </Grid>
      <Grid container>
        <Typography>
          {isTrainerOnboard
            ? 'Choose up to two options that best describe your training style'
            : 'Choose up to two options that best describe your ideal trainer'}
        </Typography>
      </Grid>
      <Grid container sx={{ gap: '16px', paddingTop: '16px' }}>
        <Box
          sx={{
            width: '196px',
            height: '277px',
            borderRadius: '12px',
            backgroundImage: `url(/drillsarg.png)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            cursor: 'pointer',
            boxSizing: 'border-box',
            border: trainingStyles.includes('Drill Sargent')
              ? '4px solid #00C7C7'
              : '',
          }}
          onClick={handleTrainingStyleClick.bind(this, 'Drill Sargent')}
        >
          <Box
            sx={{
              width: '100%',
              height: '75px',
              borderRadius: '0px 0px 12px 12px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontStyle: ' normal',
                fontWeight: ' 500',
                fontSize: ' 14px',
                lineHeight: ' 21px',
                /* identical to box height */

                textAlign: ' center',

                color: 'white',
              }}
            >
              {' '}
              Drill Sargent
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontStyle: ' normal',
                fontWeight: ' 500',
                fontSize: ' 14px',
                lineHeight: ' 21px',
                /* identical to box height */

                textAlign: ' center',

                color: 'white',
              }}
            >
              {' '}
              The Pusher
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: '196px',
            height: '277px',
            borderRadius: '12px',
            backgroundImage: `url(/cheerleader.png)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            cursor: 'pointer',
            boxSizing: 'border-box',
            border: trainingStyles.includes('Cheerleader')
              ? '4px solid #00C7C7'
              : '',
          }}
          onClick={handleTrainingStyleClick.bind(this, 'Cheerleader')}
        >
          <Box
            sx={{
              width: '100%',
              height: '75px',
              borderRadius: '0px 0px 12px 12px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontStyle: ' normal',
                fontWeight: ' 500',
                fontSize: ' 14px',
                lineHeight: ' 21px',
                /* identical to box height */

                textAlign: ' center',

                color: 'white',
              }}
            >
              {' '}
              Cheerleader
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontStyle: ' normal',
                fontWeight: ' 500',
                fontSize: ' 14px',
                lineHeight: ' 21px',
                /* identical to box height */

                textAlign: ' center',

                color: 'white',
              }}
            >
              {' '}
              The Motivator
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: '196px',
            height: '277px',
            borderRadius: '12px',
            backgroundImage: `url(/Prof.png)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            cursor: 'pointer',
            boxSizing: 'border-box',
            border: trainingStyles.includes('Professor')
              ? '4px solid #00C7C7'
              : '',
          }}
          onClick={handleTrainingStyleClick.bind(this, 'Professor')}
        >
          <Box
            sx={{
              width: '100%',
              height: '75px',
              borderRadius: '0px 0px 12px 12px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontStyle: ' normal',
                fontWeight: ' 500',
                fontSize: ' 14px',
                lineHeight: ' 21px',
                /* identical to box height */

                textAlign: ' center',

                color: 'white',
              }}
            >
              {' '}
              Professor
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontStyle: ' normal',
                fontWeight: ' 500',
                fontSize: ' 14px',
                lineHeight: ' 21px',
                /* identical to box height */

                textAlign: ' center',

                color: 'white',
              }}
            >
              {' '}
              The Technical Educator
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: '196px',
            height: '277px',
            borderRadius: '12px',
            backgroundImage: `url(/buddy.png)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            cursor: 'pointer',
            boxSizing: 'border-box',
            border: trainingStyles.includes('Workout Buddy')
              ? '4px solid #00C7C7'
              : '',
          }}
          onClick={handleTrainingStyleClick.bind(this, 'Workout Buddy')}
        >
          <Box
            sx={{
              width: '100%',
              height: '75px',
              borderRadius: '0px 0px 12px 12px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontStyle: ' normal',
                fontWeight: ' 500',
                fontSize: ' 14px',
                lineHeight: ' 21px',
                /* identical to box height */

                textAlign: ' center',

                color: 'white',
              }}
            >
              {' '}
              Workout Buddy
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontStyle: ' normal',
                fontWeight: ' 500',
                fontSize: ' 14px',
                lineHeight: ' 21px',
                /* identical to box height */

                textAlign: ' center',

                color: 'white',
              }}
            >
              {' '}
              The Partner
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  )
}

export default ChooseTrainingStyles
