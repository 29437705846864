import { Button, Grid, Typography } from '@mui/material'
import Header from '../components/header/Header'
import Carousel from '../components/carousel/Carousel'
import SessionCard from '../components/session_card/SessionCard'
import LandingWorkouts from '../components/landing_workouts/LandingWorkouts'
import FeaturedSessionsRow from '../components/landing_featured/FeaturedSessionsRow'
import LandingFooterTrainer from '../components/landing_footer/LandingFooterTrainer'
import Footer from '../components/footer/Footer'
import Onboard from '../components/onboarding_modals/Onboard'
import SessionRequest from '../components/session_request/SessionRequest'

function Landing() {
  return (
    <Grid container>
      <Header />
      <Onboard />
      <SessionRequest />
      <Grid item xs={12}>
        <Carousel />
      </Grid>
      <Grid item xs={12}>
        <LandingWorkouts />
      </Grid>
      <Grid item xs={12}>
        <FeaturedSessionsRow />
      </Grid>
      <Grid item xs={12}>
        <LandingFooterTrainer />
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  )
}

export default Landing
