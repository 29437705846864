import { Grid } from '@mui/material'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUserProfilePicture } from '../../redux/slices/formsSlice'
import {
  setGeneralModalOpen,
  updateSavedUrls,
} from '../../redux/slices/uiSlice'
import TitleB from '../text/TitleB'
import { Cropper } from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import SolidColorButton from '../buttons/SolidColorButton'
import { Storage } from 'aws-amplify'
import {
  useGetImageQuery,
  useGetMemberByIdQuery,
  useUpdateMemberProfileMutation,
} from '../../redux/api/api'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import TitleC from '../text/TitleC'

const PhotoCropper = (props) => {
  const [updateUserTrigger, updateUserResponse] =
    useUpdateMemberProfileMutation()
  const { sessionMode, onUpload, reload, onboard } = props
  const dispatch = useDispatch()
  const cognitoUser = useSelector((state) => state.auth.cognitoUser?.sub)
  const { refetch: refetchMember } = useGetMemberByIdQuery(cognitoUser, {
    skip: !cognitoUser,
  })
  const { refetch } = useGetImageQuery(`${cognitoUser}/Profile`)
  const profilePicture = useSelector(
    (state) => state.forms.userProfileModal.uploadedImage
  )
  const sessionForm = useSelector((state) => state.forms.createSessionForm)
  const sessionPhotos = sessionForm.sessionPhotos
  const sessionId = sessionForm.id

  const { trainerId, viewMemberId } = useParams()

  const cropperRef = useRef(null)
  const [croppedBlob, setCroppedBlob] = useState(null)
  const onCrop = () => {
    const imageElement = cropperRef?.current
    const cropper = imageElement?.cropper
    const canvas = cropper.getCroppedCanvas()
    canvas.toBlob((blob) => {
      if (blob === null) {
        console.log('Blob is null')
        return
      } else {
        //console.log('HERE DA BLOB: ', blob)
        setCroppedBlob(blob)
      }
    }, 'image/jpeg')
  }

  const handleUploadProfileImage = async () => {
    console.log('Uploading profile image')
    try {
      const photoId = uuidv4()
      const data = await Storage.put(`${cognitoUser}/${photoId}`, croppedBlob, {
        contentType: 'image/png', // contentType is optional
      })
      dispatch(
        updateSavedUrls({
          imagePath: `${cognitoUser}/${photoId}`,
          url: URL.createObjectURL(croppedBlob),
        })
      )

      const updatedUser = await updateUserTrigger({
        id: cognitoUser,
        profilePhotoUrl: photoId,
      }).unwrap()
      console.log('updatedUser: ', updatedUser)
      // update user profile in db with new profileid
      refetchMember()
      // refetch()
      // if (reload) {
      //   window.location.reload()
      // } else {
      //   dispatch(setUserProfilePicture(URL.createObjectURL(croppedBlob)))
      // }
      //dispatch(setUserProfilePicture(image))
      //dispatch(setGeneralModalOpen(false))
    } catch (error) {
      console.log('Error uploading file: ', error)
    }

    //dispatch(setUserProfilePicture(image))
    // Call onUpload if it exists
    if (onUpload) {
      onUpload()
    }

    dispatch(setGeneralModalOpen(false))
  }
  //TODO REAL SESSION ID< TRY TO CREATE SESSION INSTEAD OF STARTING WITH 0
  //const sessionId = '34g3ver4'
  const handleUploadSessionImage = async () => {
    try {
      await Storage.put(
        `${cognitoUser}/sessions/${sessionId}/${sessionPhotos.length}`,
        croppedBlob,
        {
          contentType: 'image/png', // contentType is optional
        }
      )
      console.log('Successfully uploaded')
      refetch()
      console.log('REFETCHED')
    } catch (error) {
      console.log('Error uploading file: ', error)
    }

    //dispatch(setUserProfilePicture(image))
    //dispatch(setGeneralModalOpen(false))
  }
  return (
    <>
      {' '}
      <Grid container>
        {onboard ? (
          <TitleC text="Crop and Size" />
        ) : (
          <TitleB text="Crop and Size" />
        )}
      </Grid>
      <Grid sx={{ padding: '32px' }}>
        <Cropper
          src={
            sessionMode
              ? sessionPhotos[sessionPhotos.length - 1]
              : profilePicture
          }
          crop={onCrop}
          ref={cropperRef}
          aspectRatio={1}
          style={{
            height: 300,
            width: 300,
            backgroundColor: 'grey',
          }}
        />
      </Grid>
      <Grid container spacing={2}>
        {!onboard && (
          <Grid item>
            <SolidColorButton
              text="Cancel"
              onClick={() => {
                dispatch(setGeneralModalOpen(false))
              }}
            />
          </Grid>
        )}
        <Grid item>
          <SolidColorButton
            text="Upload"
            onClick={
              sessionMode ? handleUploadSessionImage : handleUploadProfileImage
            }
          />
        </Grid>
      </Grid>
    </>
  )
}

export default PhotoCropper
