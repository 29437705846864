import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

const today = dayjs()
console.log('today: ', today.format('MM/DD/YYYY'))

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    signUpSignIn: 'signUp',
    isTrainerOnboard: false,
    sessionPhotoNumber: 1,
    becomeTrainerModalOpen: false,
    trainerAvailabilityModalOpen: false,
    onboardOpen: false,
    onboardStep: 1,
    sessionRequestOpen: false,
    generalModalOpen: false,
    bookingReviewModalOpen: false,
    sessionPhotoCropModalOpen: false,
    generalModalComponent: null,
    editMode: false,
    authModalOpen: false,
    virtualSessionModalOpen: false,
    errorAlertOpen: false,
    errorAlertMessage: '',
    viewTrainerId: null,
    viewMemberId: null,
    headerSearch: {
      city: null,
      state: null,
      date: today.format('MM/DD/YY'),
      time: null,
      workoutTypes: [],
    },
    savedUrls: [],
    paymentsModalOpen: false,
  },
  reducers: {
    setIsTrainerOnboard: (state, action) => {
      state.isTrainerOnboard = action.payload
    },
    setBecomeTrainerModalOpen: (state, action) => {
      state.becomeTrainerModalOpen = action.payload
    },
    setHeaderSearchData: (state, action) => {
      state.headerSearch[action.payload.field] = action.payload.value
    },
    closeErrorAlert: (state, action) => {
      state.errorAlertMessage = ''
      state.errorAlertOpen = false
    },
    setErrorAlert: (state, action) => {
      state.errorAlertMessage = action.payload
      state.errorAlertOpen = true
    },
    setAuthModalOpen: (state, action) => {
      state.authModalOpen = action.payload
    },
    setOnboardOpen: (state, action) => {
      state.onboardOpen = action.payload
    },
    setOnboardStep: (state, action) => {
      state.onboardStep = action.payload
    },
    setSessionRequestOpen: (state, action) => {
      state.sessionRequestOpen = !state.sessionRequestOpen
    },
    setSessionPhotoNumber: (state, action) => {
      state.sessionPhotoNumber = action.payload
    },
    setTrainerAvailabilityModalVisible: (state, action) => {
      state.trainerAvailabilityModalOpen = action.payload
    },
    setGeneralModalOpen: (state, action) => {
      state.generalModalOpen = action.payload
    },
    setBookingReviewModalOpen: (state, action) => {
      state.bookingReviewModalOpen = action.payload
    },
    setSessionPhotoCropModalOpen: (state, action) => {
      state.sessionPhotoCropModalOpen = action.payload
    },
    setVirtualSessionModalOpen: (state, action) => {
      state.virtualSessionModalOpen = action.payload
    },
    setGeneralModalComponent: (state, action) => {
      state.generalModalComponent = action.payload
    },
    setEditMode: (state, action) => {
      state.editMode = !state.editMode
    },
    setViewTrainerId(state, action) {
      state.viewTrainerId = action.payload
    },
    setViewMemberId(state, action) {
      state.viewMemberId = action.payload
    },
    updateSavedUrls(state, action) {
      if (state.savedUrls[action.payload.imagePath]) {
        state.savedUrls[action.payload.imagePath] = action.payload.url
      } else {
        state.savedUrls = {
          ...state.savedUrls,
          [action.payload.imagePath]: action.payload.url,
        }
      }
    },
    setSelectedRequest(state, action) {
      state.selectedRequest = action.payload
    },
    setSelectedChat(state, action) {
      state.selectedChat = action.payload
    },
    setPaymentsModalOpen(state, action) {
      state.paymentsModalOpen = action.payload
    },
    setSignUpSignIn(state, action) {
      state.signUpSignIn = action.payload
    },
  },
})

export const {
  setBecomeTrainerModalOpen,
  closeErrorAlert,
  setErrorAlert,
  setAuthModalOpen,
  setOnboardOpen,
  setOnboardBOpen,
  setOnboardStep,
  setSessionRequestOpen,
  setTrainerAvailabilityModalVisible,
  setGeneralModalOpen,
  setGeneralModalComponent,
  setEditMode,
  setViewTrainerId,
  setViewMemberId,
  setHeaderSearchData,
  updateSavedUrls,
  setVirtualSessionModalOpen,
  setBookingReviewModalOpen,
  setSessionPhotoCropModalOpen,
  setSessionPhotoNumber,
  setIsTrainerOnboard,
  setSelectedRequest,
  setSelectedChat,
  setPaymentsModalOpen,
  setSignUpSignIn,
} = uiSlice.actions
export default uiSlice.reducer
