import { Typography } from "@mui/material"

export default function SubTitleB(props){
  const { text } = props
  return (
    <Typography
      sx={{
        fontWeight: 700,
        fontSize: '15px',
        lineHeight: '22.5px',
        padding: 0,
        display: 'inline',
      }}
    >
      {text}
    </Typography>
  )
}