import { createSlice } from '@reduxjs/toolkit'

const filtersSlice = createSlice({
  name: 'filters',
  initialState: {
    where: [],
    priceLow: 0,
    priceHigh: null,
    trainerDisplayName: null,
    trainerStyles: [],
    trainerGender: 'all',
    isFavorites: false,
    sessionCity: null,
  },
  reducers: {
    setWhere: (state, action) => {
      state.where = action.payload
    },
    setPriceHigh: (state, action) => {
      state.priceHigh = action.payload
    },
    setPriceLow: (state, action) => {
      state.priceLow = action.payload
    },
    setTrainerDisplayName: (state, action) => {
      state.trainerDisplayName = action.payload
    },
    setTrainerStyles: (state, action) => {
      state.trainerStyles = action.payload
    },
    setTrainerGender: (state, action) => {
      state.trainerGender = action.payload
    },
    setIsFavorites: (state, action) => {
      state.isFavorites = action.payload
    },
    setSessionCity: (state, action) => {
      state.city = action.payload
    },
    setApiFilters: (state, action) => {
      state.apiFilters = action.payload
    },
  },
})

export const {
  setWhere,
  setPriceLow,
  setPriceHigh,
  setTrainerDisplayName,
  setTrainerStyles,
  setTrainerGender,
  setIsFavorites,
  setSessionCity,
  setApiFilters,
} = filtersSlice.actions
export default filtersSlice.reducer
