import * as React from 'react'
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  Box,
  Typography,
  Avatar,
  Chip,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  useGetSessionDetailsByIdQuery,
  useGetTrainerByIdQuery,
} from '../../redux/api/api'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import S3Image from '../S3Image'

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

export default function ReviewCard() {
  const createBookingTime = useSelector(
    (state) => state.forms.createBookingTime
  )
  const currentUserId = useSelector((state) => state.auth.cognitoUser?.sub)
  const viewTrainerId = useSelector((state) => state.ui.viewTrainerId)
  const bookingFormData = useSelector((state) => state.forms.createBookingForm)

  const getDisplayTimefromBookingData = () => {
    const { amPm, date, duration, month, time, year, zone } = createBookingTime
    const formattedStartTime = dayjs(
      `${month}/${date}/${year} ${time}${amPm}`,
      'M/D/YYYY hA'
    )
    return formattedStartTime.format('ddd, MMM Do YYYY h:mm A')
  }
  // TRAINER DATA
  const { data, isLoading, isSuccess, isError, error } = useGetTrainerByIdQuery(
    viewTrainerId,
    {
      skip: viewTrainerId === null,
      keepUnusedDataFor: 100000,
      pollingInterval: 100000,
    }
  )
  // SESSION DATA
  const getSessionByIdRequest = useGetSessionDetailsByIdQuery(
    bookingFormData.bookingSessionId,
    {
      skip:
        bookingFormData?.bookingSessionId === null ||
        !bookingFormData ||
        !bookingFormData?.bookingSessionId,
      keepUnusedDataFor: 100000,
      pollingInterval: 100000,
    }
  )
  return (
    <Grid container sx={{ p: 2 }}>
      <Box
        sx={{
          width: 500,
          bgcolor: 'primary.light',
          borderRadius: '20px',
          height: 150,
        }}
      >
        <Grid container justifyContent="space-between" sx={{ p: 2 }}>
          <Grid item>
            <Grid container>
              <Grid item>
                <S3Image
                  imagePath={`${viewTrainerId}/Profile`}
                  dimensions={{ width: 60, height: 60, borderRadius: '50%' }}
                />
              </Grid>
              <Grid item sx={{ p: 1 }}>
                <Typography
                  onClick={() => console.log('clicked: ', data)}
                  sx={{
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '22.5px',
                    color: 'gunMetal.main',
                  }}
                >
                  {data?.displayName}.
                </Typography>

                <Typography
                  sx={{
                    fontWeight: 300,
                    fontSize: '15px',
                    lineHeight: '22.5px',
                    color: 'gunMetal.main',
                  }}
                >
                  {data?.homeLocation}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end">
              <Chip
                onClick={() =>
                  console.log('clicked: ', getSessionByIdRequest?.data)
                }
                sx={{ bgcolor: 'gunMetal.main', color: 'white' }}
                label={getSessionByIdRequest?.data?.category}
              />
            </Grid>
            <Grid container justifyContent="flex-end">
              <Typography>
                ${getSessionByIdRequest?.data?.sixtyMinsPrice}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '26px',
              lineHeight: '39px',
              color: 'gunMetal.main',
            }}
            onClick={() => console.log('clicked: ', createBookingTime)}
          >
            {getDisplayTimefromBookingData()}
          </Typography>
        </Grid>
      </Box>
    </Grid>
  )
}
