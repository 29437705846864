import { Grid, IconButton, Paper } from '@mui/material'
import TitleB from '../text/TitleB'
import SubTitleB from '../text/SubTitleB'
import CheckCircle from '../../assets/icons/CheckCircle.svg'
import XCircle from '../../assets/icons/XCircle.svg'
import body from '../../assets/body.svg'

const FocusAreas = () => {
  return (
    <Grid container justifyContent="start" sx={{ pt: 2 }}>
      <Grid item>
        <TitleB text="Focus Areas and Injuries " />
      </Grid>
      <Grid item xs={12}>
        <Paper
          style={{
            height: 780,
            width: '100%',
            borderRadius: 16,
            marginTop: '16px',
            //padding: '32px',
          }}
        >
          <Grid
            container
            style={{ padding: '32px', justifyContent: 'flex-start' }}
          >
            <Grid item>
              <IconButton sx={{ color: 'gunMetal.main' }}>
                <img src={CheckCircle} style={{ paddingRight: '16px' }} />
                <SubTitleB text="Focus area" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton sx={{ color: 'gunMetal.main' }}>
                <img src={XCircle} style={{ paddingRight: '16px' }} />
                <SubTitleB text="Injury" />
              </IconButton>
            </Grid>
          </Grid>

          <img src={body} style={{ maxWidth: '100%' }} />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default FocusAreas
