import * as React from 'react'
import { Grid } from '@mui/material'
import FilterDrawer from './FilterDrawerButton/FilterDrawer'
import BorderSelectButton from '../buttons/BorderSelectButton'
import categories from '../../constants/categories'
import { useNavigate } from 'react-router-dom'

export default function FilterBar(props) {
  const { favoritesPage } = props
  const navigate = useNavigate()

  return (
    <Grid container sx={{ pr: 2, pl: 2, pt: 0, mt: 0 }}>
      <Grid item sx={{ flexGrow: 1 }}>
        <FilterDrawer />
      </Grid>
      {/* <Grid item sx={{ pr: 2 }}>
        <BorderSelectButton text="Sort By" />
      </Grid> */}

      {!favoritesPage && (
        <Grid item>
          <BorderSelectButton
            text="More to Explore"
            onClick={() => navigate('/search')}
          />
        </Grid>
      )}
    </Grid>
  )
}
