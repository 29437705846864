import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { CheckRounded } from '@mui/icons-material'

function createData(id, date, status, description, receiverAcct, amount) {
  return { id, date, status, description, receiverAcct, amount }
}

const rows = [
  createData(
    123,
    'Jul 17th 2021',
    true,
    'Tim O - Sports Performance Training Session',
    '************1234',
    'USD $130.31'
  ),
  createData(
    222,
    'Jul 17th 2021',
    true,
    'Tim O - Sports Performance Training Session',
    '************1234',
    'USD $130.31'
  ),
  createData(
    333,
    'Jul 17th 2021',
    false,
    'Tim O - Sports Performance Training Session',
    '************1234',
    'USD $130.31'
  ),
  createData(
    444,
    'Jul 17th 2021',
    false,
    'Tim O - Sports Performance Training Session',
    '************1234',
    'USD $130.31'
  ),
  createData(
    555,
    'Jul 17th 2021',
    true,
    'Tim O - Sports Performance Training Session',
    '************1234',
    'USD $130.31'
  ),
]

export default function PaymentsHistoryTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left">Receiver Account</TableCell>
            <TableCell align="left">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.date}
              </TableCell>
              <TableCell align="center">
                {row.status ? <CheckRounded /> : null}
              </TableCell>
              <TableCell align="left">{row.description}</TableCell>
              <TableCell align="left">{row.receiverAcct}</TableCell>
              <TableCell align="left">{row.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
