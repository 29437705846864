import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import TrainingStyleRow from './TrainingStyleRow'
import { useParams } from 'react-router-dom'
import { useGetTrainerByIdQuery } from '../../redux/api/api'
import { useDispatch, useSelector } from 'react-redux'
import { BorderColor } from '@mui/icons-material'
import {
  setGeneralModalComponent,
  setGeneralModalOpen,
  setIsTrainerOnboard,
} from '../../redux/slices/uiSlice'
import TrainingStyleCard from './TrainingStyleCard'
import trainingStyles from '../../constants/trainingStyles'

const TrainingStyles = ({ editing, ...other }) => {
  const dispatch = useDispatch()
  const { trainerId } = useParams()
  const { data: trainer } = useGetTrainerByIdQuery(trainerId, {
    skip: !trainerId,
  })

  const handleEditTrainingStlyesClick = () => {
    console.log('edit training styles')
    dispatch(setIsTrainerOnboard(true))
    dispatch(setGeneralModalComponent('training_styles'))
    dispatch(setGeneralModalOpen(true))
  }
  const currentUserId = useSelector((state) => state.auth.cognitoUser?.sub)

  const tryFindTrainingStyle = (trainingStyle) => {
    console.log('Test: ', trainingStyle)
    const index = trainingStyles.findIndex(
      (style) => style.title === trainingStyle
    )
    console.log('index: ', index)
    return trainingStyles[index]
  }

  return (
    <Grid container sx={{ pt: 4 }}>
      <Grid item xs={12}>
        <Grid
          container
          sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
        >
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: '24px',
              lineHeight: '36px',
              letterSpacing: '-0.02em',
              mr: 2,
              color: '#000000',
            }}
          >
            Training Styles
          </Typography>
          {currentUserId === trainerId && (
            <Tooltip title="Edit Training Styles">
              <IconButton onClick={handleEditTrainingStlyesClick}>
                <BorderColor color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <Grid container sx={{ justifyContent: 'space-around', gap: 1, pt: 2 }}>
        {trainer?.trainingStyles?.map((style) => (
          <TrainingStyleCard trainingStyle={tryFindTrainingStyle(style)} />
        ))}
      </Grid>
    </Grid>
  )
}
export default TrainingStyles

//Function to find the index of the training style in the trainingStyles array and return the corresponding color
// const findTrainingStyleColor = (trainingStyle) => {
//   const index = trainingStyles.findIndex((style) => style === trainingStyle)
//   return trainingStyleColors[index]
// }
