import * as React from 'react'
import Box from '@mui/material/Box'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import Header from '../header/Header'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'

import 'cropperjs/dist/cropper.css'

import { ReactComponent as File } from '../../assets/icons/file.svg'
import { ReactComponent as Pencil } from '../../assets/icons/pencil.svg'
import { ReactComponent as PencilLine } from '../../assets/icons/pencil_line.svg'
import { ReactComponent as Plus } from '../../assets/icons/plus.svg'
import { ReactComponent as Trash } from '../../assets/icons/trash.svg'
import { ReactComponent as FloppyDisk } from '../../assets/icons/floppy_disk.svg'
//import { ReactComponent as Star } from '../../assets/icons/star.svg'
import { ReactComponent as Heart } from '../../assets/icons/likedtrainers.svg'
import { ReactComponent as Checkmark } from '../../assets/icons/checkmark.svg'
import { ReactComponent as UploadProfileIcon } from '../../assets/icons/upload_profile.svg'
import { ReactComponent as UploadUpIcon } from '../../assets/icons/upload_up.svg'
import { ReactComponent as DownArrow } from '../../assets/icons/down_arrow.svg'

import ProfilePic from '../../assets/profile_picture.png'
import AlbumCover from '../../assets/album_cover.svg'
import body from '../../assets/body.svg'
import styled from '@emotion/styled'

import {
  CalendarMonthOutlined,
  Edit,
  EditOutlined,
  Info,
  LocationOnOutlined,
  Search,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import CheckCircle from '../../assets/icons/CheckCircle.svg'
import XCircle from '../../assets/icons/XCircle.svg'
import { SearchOutlined } from '@mui/icons-material'
import SessionCard from '../session_card/SessionCard'
import SolidColorButton from '../buttons/SolidColorButton'
import { Cropper } from 'react-cropper'
import { useRef } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setGeneralModalComponent,
  setGeneralModalOpen,
} from '../../redux/slices/uiSlice'
import axios from 'axios'
import { Auth } from 'aws-amplify'
import { on } from 'events'

export default function AddPayout(props) {
  const theme = useTheme()

  const stripeAccountId = useSelector((state) => state.auth.stripeAccountId)

  const [hasPayoutMethod, setHasPayoutMethod] = useState(false)
  const [payoutMethods, setPayoutMethods] = useState([])
  const [loading, setLoading] = useState(false)

  React.useEffect(() => {
    if (stripeAccountId) {
      console.log('Fetching Accounts for: ', stripeAccountId)
      setLoading(true)
      fetchPayoutAccounts(stripeAccountId)
    }
  }, [stripeAccountId])

  const fetchPayoutAccounts = async (accountId) => {
    console.log('Fetching Payout Accounts: ', accountId)
    const getPayoutAccountsUrl =
      'https://s5bbhuhgrtit5q6koytdj7c3hy0zurqt.lambda-url.us-east-2.on.aws/'
    console.log('Fetching Payout Accounts: ', getPayoutAccountsUrl)
    try {
      const payoutAccountsResponse = await axios.post(getPayoutAccountsUrl, {
        accountId,
      })
      console.log('Payout Accounts Response', payoutAccountsResponse)
      const payoutAccounts = payoutAccountsResponse.data.data
      console.log('Payout Accounts: ', payoutAccounts)
      if (payoutAccounts.length > 0) {
        setHasPayoutMethod(true)
        setPayoutMethods(payoutAccounts)
      } else {
        setHasPayoutMethod(false)
      }
      setLoading(false)
    } catch (error) {
      console.error('ERROR LISTING PAYOUT ACCOUNTS: ', error)
      setLoading(false)
    }
  }

  const handleCreateStripeConnectedAccount = async () => {
    setLoading(true)
    const url =
      'https://oge53chxtg67juxv5izt7yklye0bkdhi.lambda-url.us-east-2.on.aws/'
    const accountData = await axios.get(url)
    const accountId = accountData.data.id
    // Update User with accountId
    const updated = await updateUserAccountId(accountId)
    // Create Onboarding Link
    const stripeLink = await fetchStripeLink(accountId)
    // Redirect to onboarding link
    window.location.href = stripeLink
    //setLoading(false)
  }

  const fetchStripeLink = async (accountId, type) => {
    // type = account_onboarding || account_update
    if (!loading) setLoading(true)
    const createOnboaringLinkUrl =
      'https://f4jvkthvqzv7cctj744bine35y0dwtzy.lambda-url.us-east-2.on.aws/'
    const onboardingLinkResponse = await axios.post(createOnboaringLinkUrl, {
      accountId,
      type,
    })
    console.log('Onboarding Link Response : ', onboardingLinkResponse)
    const onboardingLink = onboardingLinkResponse.data.url
    console.log('Onboarding Link : ', onboardingLink)
    if (type === 'account_update') {
      window.location.href = onboardingLink
      // setLoading(false)
    } else {
      return onboardingLink
    }
  }

  const updateUserAccountId = async (accountId) => {
    // Add accountId to user
    const user = await Auth.currentAuthenticatedUser()
    // dispatch cognito user update
    const updatedUser = await Auth.updateUserAttributes(user, {
      'custom:stripe_acct_id': accountId,
    })
  }

  const renderPayoutMethodRow = (
    <Grid container>
      <Box
        sx={{
          width: '100%',
          p: 2,
          m: 2,
          border: '1px solid #E0E0E0',
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Grid item sx={{ color: 'gunMetal.main' }}>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            {payoutMethods[0]?.bank_name}
          </Typography>
          <Typography variant="body1">
            {payoutMethods[0]?.last4} <b>|</b>{' '}
            {payoutMethods[0]?.routing_number}
          </Typography>
        </Grid>

        <Tooltip title="Update Payout Method">
          <IconButton
            sx={{ color: 'gunMetal.main' }}
            onClick={() => fetchStripeLink(stripeAccountId, 'account_update')}
          >
            <EditOutlined />
          </IconButton>
        </Tooltip>
      </Box>
    </Grid>
  )

  return (
    <Grid container>
      {loading && (
        <Grid container>
          <CircularProgress />
        </Grid>
      )}
      {!loading && hasPayoutMethod && stripeAccountId && renderPayoutMethodRow}
      {!loading && !hasPayoutMethod && stripeAccountId && (
        <Button
          variant="contained"
          onClick={() => fetchStripeLink(stripeAccountId, 'account_update')}
        >
          <Typography sx={{ fontWeight: 'bold', color: 'white' }}>
            Add Payout Method
          </Typography>
        </Button>
      )}
      {!loading && !stripeAccountId && (
        <Button
          onClick={handleCreateStripeConnectedAccount}
          variant="contained"
          sx={{ p: 2 }}
        >
          <Typography sx={{ fontWeight: 'bold', color: 'white' }}>
            Create Stripe Account
          </Typography>
        </Button>
      )}
    </Grid>
  )
}
