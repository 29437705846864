const Star = (props) => {
  const { x = '99%', width = 50, height = 50, id } = props
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="#EFF399"
      xmlns="http://www.w3.org/2000/svg"
    >
      {' '}
      <linearGradient id={`grad1${id}`} x1={x} y1="0%" x2="100%" y2="0%">
        <stop
          offset="0%"
          style={{ stopColor: '#EFF399', stopOpacity: 1 }}
          //style="stop-color:#EFF399;stop-opacity:1"
        />
        <stop
          offset="0%"
          style={{ stopColor: '#CDD2D5', stopOpacity: 1 }}
          //style="stop-color:#CDD2D5;stop-opacity:1"
        />
      </linearGradient>
      <path
        fill={`url(#grad1${id})`}
        d="M26.0604 41.6832L38.1843 49.6716C39.734 50.6928 41.6581 49.174 41.1982 47.2927L37.6954 32.9621C37.5967 32.5629 37.6084 32.1429 37.7291 31.7504C37.8498 31.3579 38.0745 31.0087 38.3775 30.7431L49.2494 21.3322C50.678 20.0957 49.9406 17.63 48.1053 17.5062L33.9072 16.5478C33.5248 16.5194 33.158 16.3786 32.8496 16.1418C32.5411 15.905 32.3037 15.5819 32.1649 15.2102L26.8697 1.34194C26.7257 0.947406 26.4698 0.607741 26.1359 0.368054C25.8019 0.128367 25.4058 0 25 0C24.5943 0 24.1981 0.128367 23.8642 0.368054C23.5302 0.607741 23.2743 0.947406 23.1303 1.34194L17.8351 15.2102C17.6963 15.5819 17.4589 15.905 17.1504 16.1418C16.842 16.3786 16.4752 16.5194 16.0928 16.5478L1.89472 17.5062C0.0593654 17.63 -0.677956 20.0957 0.750572 21.3322L11.6225 30.7431C11.9255 31.0087 12.1502 31.3579 12.2709 31.7504C12.3916 32.1429 12.4033 32.5629 12.3046 32.9621L9.05611 46.2521C8.50427 48.5098 10.8132 50.3322 12.6729 49.1069L23.9397 41.6832C24.2565 41.4734 24.6244 41.362 25 41.362C25.3756 41.362 25.7435 41.4734 26.0604 41.6832Z"
      />
    </svg>
  )
}

export default Star
