import { Grid, useMediaQuery } from '@mui/material'
import TrainerOverView from './TrainerOverview'
import TrainerAbout from './TrainerAbout'
import { useParams } from 'react-router-dom'
import GeneralModal from '../modals/GeneralModal'
import theme from '../../styles/theme'
import AboutBox from './AboutBox'

function Trainer() {
  const { trainerId } = useParams()
  const large = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Grid container sx={{ p: 2, alignItems: 'flex-start' }}>
      {large && (
        <>
          <TrainerOverView trainerId={trainerId} />
          <Grid item xs={12} sm={6} md={8} sx={{ p: 2 }}>
            <AboutBox />
            <TrainerAbout />
          </Grid>
        </>
      )}
      {!large && (
        <>
          <Grid item xs={12} sm={6} md={8} sx={{ p: 2 }}>
            <AboutBox />
          </Grid>
          <TrainerOverView trainerId={trainerId} />
          <Grid item xs={12}>
            <TrainerAbout />
          </Grid>
        </>
      )}

      <GeneralModal />
    </Grid>
  )
}

export default Trainer
