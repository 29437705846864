import { Search } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField } from '@mui/material'

const SearchBar = () => {
  return (
    <TextField
      style={{
        fontSize: '13px',
        borderRadius: '20px',
        height: '39px',
        width: '360px',
      }}
      placeholder="Search past GET TRAIND sessions"
      sx={{
        pb: 2,
        '& fieldset': {
          borderRadius: '20px',
          height: '39px',
          width: '360px',
        },
        '& .MuiInputBase-input': {
          padding: '8px 0 0 15px',
        },
        '& .MuiIconButton-root': {
          top: 3,
          left: 30,
        },
        '& placeholder': {
          fontSize: '13px',
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <Search sx={{ color: 'primary.main' }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    ></TextField>
  )
}

export default SearchBar
