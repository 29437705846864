import { Box, Grid, Paper, Typography } from '@mui/material'
import { useGetTrainerReviewByTrainerIdQuery } from '../../../redux/api/api'
import ProfilePic from '../../../assets/profile_picture.png'
import SubTitleB from '../../text/SubTitleB'
import Star from './Star'
import InfoText from '../../text/InfoText'
import Workout from './Workout'
import SubTitleA from '../../text/SubTitleA'
import TitleB from '../../text/TitleB'

const TrainerReviews = (props) => {
  // const { data, isLoading, isSuccess, isError, error } =
  //   useGetTrainerReviewByTrainerIdQuery(1)
  const WrittenReview = (props) => {
    const { review } = props
    return (
      <Box
        sx={{
          width: '520px',
          borderRadius: '30px',
          backgroundColor: 'primary.light',
          p: '6px',
        }}
      >
        <Grid
          container
          style={{
            padding: '8px',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <Grid item>
            <Grid container>
              <Grid item>
                <img
                  style={{ borderRadius: '50%', width: 48 }}
                  src={ProfilePic}
                />
              </Grid>
              <Grid item style={{ paddingRight: '12px' }}>
                <SubTitleB
                  text={
                    review.reviewer.firstName + ' ' + review.reviewer.lastName
                  }
                />
                <Typography>
                  {review.reviewer.address.city +
                    ', ' +
                    review.reviewer.address.state}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Star width="24px" height="24px" />
            <Star width="24px" height="24px" />
            <Star width="24px" height="24px" />
            <Star width="24px" height="24px" />
            <Star width="24px" height="24px" />
          </Grid>
        </Grid>
        <Grid container sx={{ padding: '16px 32px ' }}>
          <InfoText text={review.review} />
        </Grid>
        <Grid container spacing={2} style={{ justifyContent: 'flex-end' }}>
          <Grid item>
            <SubTitleB text={review.reviewDateTime} />
          </Grid>
          <Grid item>
            <Workout text={review.training.tagline} />
          </Grid>
        </Grid>
      </Box>
    )
  }
  if (true || 'isSuccess && data?.length > 0') {
    return (
      <Paper
        style={{
          //height: 180,
          width: '100%',
          borderRadius: 16,
          marginTop: '16px',
          //padding: '32px',
        }}
      >
        <Box sx={{ padding: '32px' }}>
          <SubTitleA text="Overview" />
          <Box
            sx={{
              width: '480px',
              borderRadius: '30px',
              backgroundColor: 'primary.light',
              p: '6px',
            }}
          >
            <Grid container>
              <TitleB text="4.8/5.0" />
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <Star />
                <Star />
                <Star />
                <Star />
                <Star x="70%" id="5" />
              </Grid>
              <Grid item>
                <InfoText text="194 total ratings" />
              </Grid>
            </Grid>
          </Box>
          <SubTitleA text="Written Reviews" />
          {/* {bookings?.map((review, index) => {
            return <WrittenReview key={index} review={review} />
          })} */}
        </Box>
      </Paper>
    )
  }
}

export default TrainerReviews
