import * as React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CardActionArea, Chip, Grid, IconButton } from '@mui/material'
import {
  DriveEta,
  Favorite,
  HeartBrokenTwoTone,
  LocationOn,
  LocationOnOutlined,
  Phone,
  PhoneAndroidOutlined,
  RocketLaunchOutlined,
  Star,
} from '@mui/icons-material'
import { ReactComponent as TrainerStarRating } from '../../assets/icons/trainerStarRating.svg'
import S3Image from '../S3Image'

export default function TrainerCardv2(props) {
  const { trainer } = props
  const [favorited, setFavorited] = React.useState(false)
  return (
    <Card
      sx={{
        position: 'relative',

        width: '296px',
        height: '425px',
        borderRadius: '16px',
        background: '#2E2E2E',
      }}
      onClick={props.onClick}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0px',
          gap: '4px',

          position: 'absolute',

          right: '5.41%',
          top: '77.18%',

          borderRadius: '30px 0px 0px 30px',
        }}
      >
        <TrainerStarRating />
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontsStyle: 'normal',
            fontWeight: '700',
            fontSize: '17px',
            lineHeight: '26px',
            /* identical to box height */

            color: '#FFFFFF',
          }}
        >
          {' '}
          4.8
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          padding: '4px 8px',
          gap: '10px',

          position: 'absolute',
          left: '16px',

          top: '77.65%',
          bottom: '17.18%',

          background: '#000000',
          borderRadius: '50px',
        }}
      >
        <Typography
          sx={{
            fontStyle: ' normal',
            fontWeight: ' 400',
            fontSize: ' 12px',
            lineHeight: ' 14px',
            /* identical to box height */

            textAlign: ' center',

            color: ' #FFFFFF',
          }}
        >
          {trainer.location}
        </Typography>
      </Box>

      <Box
        sx={{
          position: ' absolute',

          bottom: ' 2.82%',
          left: ' 5%',
        }}
      >
        <Grid container sx={{ justifyContent: 'flex-start' }}>
          <Typography
            sx={{
              fontStyle: ' normal',
              fontWeight: ' 700',
              fontSize: ' 18px',

              color: ' #FFFFFF',
            }}
          >
            {trainer.name}
          </Typography>
        </Grid>
        <Grid container sx={{}}>
          <Typography
            sx={{
              fontStyle: ' normal',
              fontWeight: ' 500',
              fontSize: ' 18px',

              color: ' #3CFAFA',
            }}
          >
            {trainer.specialty}
          </Typography>
        </Grid>
      </Box>

      <Box
        sx={{
          position: ' absolute',
          left: ' 100%',
          right: ' -100%',
          top: ' 100%',
          bottom: ' -25.65%',

          background: ' #2E2E2E',
          backdropFilter: ' blur(6px)',
        }}
      ></Box>
      <Box
        sx={{
          position: 'absolute',

          width: '200px',
          height: '10px',
          borderRadius: '16px 16px 0px 0px',
        }}
      >
        <Box
          sx={{
            width: '296px',
            height: '316px',

            backgroundImage: `url(/${trainer.image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        />

        {/* <S3Image
          imagePath={`99311543-73b0-49a7-b8e3-08b6e9695b11/Profile`}
          dimensions={{
            width: '296px',
            height: '316px',
          }}
        /> */}
      </Box>
    </Card>
  )
}
