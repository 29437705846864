import { Box, Button, Grid, SvgIcon, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSessionRequestOpen } from '../../redux/slices/uiSlice'
import InfoText from '../text/InfoText'
import SubTitleA from '../text/SubTitleA'
import TitleC from '../text/TitleC'

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg'
import { ReactComponent as WorkoutIcon } from '../../assets/icons/workout.svg'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import SessionRequest from '../session_request/SessionRequest'
import theme from '../../styles/theme'
import { useGetMemberByIdQuery } from '../../redux/api/api'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'
import categories from '../../constants/categories'

const ChooseWorkouts = (props) => {
  const { onboarding } = props
  const [typesOpen, setTypesOpen] = React.useState(false)
  const [typesSelected, setTypesSelected] = React.useState([])
  const [formattedTrainingType, setFormattedTrainingType] = React.useState([])
  const dispatch = useDispatch()
  const [workoutTypes, setWorkoutTypes] = useState([
    { id: 0, parentTrainingId: null, trainingType: 'Strength Training' },
    { id: 1, parentTrainingId: 0, trainingType: 'Hiking' },
    { id: 2, parentTrainingId: 0, trainingType: 'Rock Climbing' },
    { id: 3, parentTrainingId: 0, trainingType: 'Mountain Biking' },
    { id: 4, parentTrainingId: null, trainingType: 'Yoga and Pilates' },
    { id: 5, parentTrainingId: 4, trainingType: 'Vinyasa Yoga' },
    { id: 6, parentTrainingId: 4, trainingType: 'Iyengar Yoga' },
    { id: 7, parentTrainingId: 4, trainingType: 'Pilates Mat' },
    { id: 8, parentTrainingId: 4, trainingType: 'Athletic Stretching' },
    { id: 9, parentTrainingId: null, trainingType: 'Combat Sports' },
    { id: 10, parentTrainingId: 9, trainingType: 'Boxing' },
    { id: 11, parentTrainingId: 9, trainingType: 'MMA' },
    { id: 12, parentTrainingId: 9, trainingType: 'Taichi' },
    { id: 13, parentTrainingId: 9, trainingType: 'Kick boxing' },
    { id: 14, parentTrainingId: 9, trainingType: 'Wingchun' },
    { id: 15, parentTrainingId: 9, trainingType: 'Martial Arts' },
  ])
  const [parents, setParents] = React.useState(
    workoutTypes?.filter((workoutType) => workoutType.parentTrainingId == null)
  )
  const cognitoUser = useSelector((state) => state.auth.cognitoUser)
  const formWorkouts = useSelector(
    (state) => state.forms.userProfileModal.workoutPreferences
  )
  // const { data, isLoading, isSuccess, isError, error } = useGetMemberByIdQuery(
  //   cognitoUser?.sub,
  //   { skip: !cognitoUser?.sub }
  // )

  const handleClickChild = (child) => {
    let tempWorkoutType = workoutTypes.map((type) => {
      if (type.id === child.id) {
        if (typesSelected.includes(child.trainingType)) {
          return { ...type, selected: false }
        } else {
          return { ...type, selected: true }
        }
      } else {
        return { ...type }
      }
    })
    setWorkoutTypes(tempWorkoutType)
    let temp = [...typesSelected]
    console.log('temp', temp)
    //if (temp[child.id]) {
    //delete temp[child.id]
    if (temp.includes(child.trainingType)) {
      temp = temp.filter((a) => a !== child.trainingType)
    } else {
      temp.push(child.trainingType)
      //temp[child.id] = child
    }
    const toDispatch = temp.filter((a) => a)
    console.log('toDispatch', toDispatch)
    setTypesSelected(temp.filter((a) => a))
    dispatch(
      setUserProfileModalField({
        field: 'workoutPreferences',
        value: toDispatch,
      })
    )
  }

  const [categoriesSelected, setCategoriesSelected] = useState([])
  const handleClickCategory = (category) => {
    //const temp = [...categoriesSelected]
    //if category is already selected
    if (categoriesSelected.includes(category)) {
      //remove category from selected
      const removed = categoriesSelected.filter((a) => a !== category)
      setCategoriesSelected(removed)
      dispatch(
        setUserProfileModalField({
          field: 'workoutPreferences',
          value: removed,
        })
      )
    } else {
      //add category to selected
      const added = [...categoriesSelected, category]
      setCategoriesSelected(added)
      dispatch(
        setUserProfileModalField({
          field: 'workoutPreferences',
          value: added,
        })
      )
    }
  }

  useEffect(() => {
    console.log('formWorkouts', formWorkouts)
    setTypesSelected(formWorkouts)
  }, [formWorkouts])

  return (
    <>
      <SessionRequest />
      <Grid item xs={12}>
        <TitleC text="Workout Types" />
      </Grid>
      {/*  <Grid container xs={12} style={{ paddingBottom: '20px' }}>
        <Button
          variant="outlined"
          onClick={() => {
            setTypesOpen(!typesOpen)
          }}
          sx={{
            width: 258,
            height: 64,
            color: 'gunMetal.main',
            borderColor: 'transparent',
            boxShadow: 1,
          }}
        >
          <Grid container sx={{ p: '20px' }} justifyContent={'left'}>
            <SvgIcon
              titleAccess="workout icon"
              component={WorkoutIcon}
              inheritViewBox
              sx={{ mr: 1, fontSize: 24 }}
            />
            <Grid item sx={{ ml: 1 }}>
              <Grid container sx={{ textAlign: 'left' }}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      //textTransform: 'none',
                      fontWeight: 700,
                      fontSize: '13px',
                    }}
                  >
                    WHAT
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: 400, fontSize: '9px' }}>
                    WORKOUT TYPE
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Button>
      </Grid> */}
      <Grid container sx={{ gap: 1 }}>
        {categories.map((category) => {
          return (
            <Grid item xs={3}>
              <Grid
                container
                onClick={() => {
                  handleClickCategory(category.name)
                }}
                sx={{
                  bgcolor: categoriesSelected.includes(category.name)
                    ? 'primary.main'
                    : 'white',

                  height: 100,
                  border: 1,
                  borderColor: 'gunMetal.main',
                  borderRadius: '30px',
                  padding: '10px',
                }}
              >
                <Grid
                  container
                  sx={{
                    color: categoriesSelected.includes(category.name)
                      ? 'white'
                      : 'gunMetal.main',
                  }}
                >
                  {category.icon}
                </Grid>
                <Grid container sx={{ textAlign: 'center' }}>
                  <Typography
                    sx={{
                      color: categoriesSelected.includes(category.name)
                        ? 'white'
                        : 'gunMetal.main',
                    }}
                  >
                    {category.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
      {/* <Grid container>
        {typesOpen && (
          <Grid item xs={6}>
            <Box
              fullWidth
              sx={{
                height: 314,
                border: 1,
                borderColor: 'gunMetal.main',
                borderRadius: '30px',
                padding: '10px',
              }}
            >
              <Box
                sx={{
                  height: 314,
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    marginRight: '10px',
                    maxWidth: '10px',
                    maxHeight: '10px',
                  },
                  '&::-webkit-scrollbar-track': {
                    marginRight: '10px',
                    maxWidth: '1px',
                    hmaxHeight: '10px',
                    //'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                  },
                  '::-webkit-scrollbar-track-piece': {
                    maxHeight: '10px',
                    right: '30px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    maxHeight: '10px',
                    maxWidth: '1px',
                    borderRadius: '30px',
                    backgroundColor: 'rgba(0,0,0,.1)',
                  },
                }}
              >
                {parents.map((parent) => {
                  return (
                    <Box
                      style={{ cursor: 'pointer' }}
                      onMouseEnter={() => {
                        const result = parents.map((type) => {
                          if (type.id == parent.id) {
                            return { ...type, active: true }
                          }
                          return { ...type, active: false }
                        })
                        setParents(result)
                      }}
                    >
                      <Grid
                        container
                        style={{
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        <Grid item>
                          <SubTitleA
                            text={parent.trainingType}
                            onClick={() => {
                              console.log(parent.id)
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <ChevronRightIcon />
                        </Grid>
                      </Grid>

                      {parent.active &&
                        workoutTypes.map((child) => {
                          if (child.parentTrainingId == parent.id) {
                            return (
                              <Grid onClick={() => handleClickChild(child)}>
                                <InfoText
                                  color={
                                    child.selected
                                      ? theme.palette.primary.dark
                                      : 'gunMetal.main'
                                  }
                                  text={child.trainingType}
                                />
                              </Grid>
                            )
                          }
                        })}
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </Grid>
        )}
        {false &&
          workoutTypes.map((child) => {
            if (child.selected) {
              return child.trainingType
            }
          })}
        {typesSelected.length > 0 && (
          <Grid item xs={6}>
            {typesSelected.map((typeSelected) => {
              return (
                <Button
                  variant="contained"
                  sx={{ color: 'white', fontWeight: 500, ml: 1, mb: 1 }}
                >
                  {
                    //typeSelected.trainingType
                    typeSelected
                  }
                </Button>
              )
            })}
          </Grid>
        )}
      </Grid> */}
      <Grid container xs={12} style={{ padding: '20px 0 20px 0' }}>
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(setSessionRequestOpen())
          }}
        >
          <PlusIcon style={{ padding: '5px' }} />
          Can't find session?
        </Button>
      </Grid>
    </>
  )
}

export default ChooseWorkouts
