import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { ReactComponent as CertifiedBadge } from '../../assets/icons/CertifiedBadge.svg'
import { useParams } from 'react-router-dom'
import { useGetTrainerByIdQuery } from '../../redux/api/api'
import {
  AccessTime,
  BorderColor,
  Commute,
  PhoneAndroid,
} from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
  setGeneralModalComponent,
  setGeneralModalOpen,
  setIsTrainerOnboard,
} from '../../redux/slices/uiSlice'
import theme from '../../styles/theme'
const AboutBox = () => {
  const dispatch = useDispatch()
  const { trainerId } = useParams()
  const { data: trainer, refetch } = useGetTrainerByIdQuery(trainerId, {
    skip: !trainerId,
  })
  const currentUserId = useSelector((state) => state.auth.cognitoUser?.sub)
  const generalModalOpen = useSelector((state) => state.ui.generalModalOpen)

  const [editingName, setEditingName] = useState(false)
  const [editingSpecialties, setEditingSpecialties] = useState(false)
  const [editingAbout, setEditingAbout] = useState(false)

  const handleClickEditName = () => {
    dispatch(setGeneralModalComponent('cognito'))
    dispatch(setGeneralModalOpen(true))
  }

  const handleClickEditTrainingBackground = () => {
    dispatch(setGeneralModalComponent('training_background'))
    dispatch(setGeneralModalOpen(true))
  }

  const handleClickEditSpecialties = () => {
    dispatch(setGeneralModalComponent('choose_workouts'))
    dispatch(setGeneralModalOpen(true))
  }

  const handleClickEditLocation = () => {
    dispatch(setGeneralModalComponent('choose_location'))
    dispatch(setGeneralModalOpen(true))
  }

  const handleClickEditAbout = () => {
    dispatch(setIsTrainerOnboard(true))
    dispatch(setGeneralModalComponent('about_member'))
    dispatch(setGeneralModalOpen(true))
  }

  useEffect(() => {
    if (!generalModalOpen) {
      console.log('refetching')
      refetch()
    }
  }, [generalModalOpen])

  return (
    <Box
      sx={{
        border: 2,
        borderColor: 'black',
        borderRadius: '12px',

        width: '100%',
      }}
    >
      <Grid
        container
        sx={{
          p: 4,
          position: 'relative',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Grid item>
          <Grid container sx={{ justifyContent: 'flex-start' }}>
            <Typography
              onClick={() => console.log(trainer)}
              sx={{ fontWeight: 700, fontSize: '40px' }}
            >
              {trainer?.displayName}.
            </Typography>
            {currentUserId === trainerId && (
              <Tooltip title="Edit Name">
                <IconButton onClick={handleClickEditName}>
                  <BorderColor color="primary" />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          <Grid container sx={{ justifyContent: 'flex-start' }}>
            {trainer?.workoutPreferences?.length > 0 && (
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '16px',
                  color: '#00C7C7',
                  mr: 1,
                }}
                onClick={() => console.log('clicked', trainer)}
              >
                {trainer?.workoutPreferences?.join(' | ')}
              </Typography>
            )}
            {currentUserId === trainerId && (
              <Tooltip title="Edit Specialties">
                <IconButton onClick={handleClickEditSpecialties}>
                  <BorderColor color="primary" />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container sx={{ justifyContent: 'flex-start', gap: 2, p: 1 }}>
            <Box
              sx={{
                width: '100%',
                padding: '5px',
                textAlign: ' center',
                background: ' #000000',
                borderRadius: ' 50px',
                display: ' flex',
                flexDirection: ' row',
                justifyContent: ' center',
                alignItems: ' center',
                gap: ' 4px',
                cursor: currentUserId === trainerId ? 'pointer' : 'default',
              }}
              onClick={
                currentUserId === trainerId ? handleClickEditLocation : null
              }
            >
              <Typography
                sx={{
                  fontStyle: ' normal',
                  fontWeight: 500,
                  fontSize: ' 16px',
                  lineHeight: ' 21px',
                  /* identical to box height */

                  color: ' #FFFFFF',
                }}
              >
                {' '}
                {trainer?.homeCity}, {trainer?.homeState}
              </Typography>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00083 7.83203C8.32206 7.83203 8.59657 7.71765 8.82435 7.48888C9.05213 7.26013 9.16602 6.98513 9.16602 6.66388C9.16602 6.34265 9.05163 6.06814 8.82287 5.84036C8.59411 5.61259 8.31911 5.4987 7.99787 5.4987C7.67663 5.4987 7.40213 5.61308 7.17435 5.84185C6.94657 6.0706 6.83268 6.3456 6.83268 6.66685C6.83268 6.98808 6.94707 7.26259 7.17583 7.49036C7.40459 7.71814 7.67959 7.83203 8.00083 7.83203ZM7.99935 13.3487C9.47713 12.0043 10.5688 10.7848 11.2743 9.69036C11.9799 8.59592 12.3327 7.63203 12.3327 6.7987C12.3327 5.48992 11.9144 4.41828 11.0778 3.58378C10.2412 2.74928 9.21509 2.33203 7.99935 2.33203C6.7836 2.33203 5.75745 2.74928 4.92088 3.58378C4.0843 4.41828 3.66602 5.48992 3.66602 6.7987C3.66602 7.63203 4.02713 8.59592 4.74935 9.69036C5.47157 10.7848 6.5549 12.0043 7.99935 13.3487ZM7.99935 14.6654C6.21046 13.1431 4.87435 11.7293 3.99102 10.4237C3.10768 9.11814 2.66602 7.90981 2.66602 6.7987C2.66602 5.13203 3.20213 3.80425 4.27435 2.81536C5.34657 1.82648 6.58824 1.33203 7.99935 1.33203C9.41046 1.33203 10.6521 1.82648 11.7243 2.81536C12.7966 3.80425 13.3327 5.13203 13.3327 6.7987C13.3327 7.90981 12.891 9.11814 12.0077 10.4237C11.1243 11.7293 9.78824 13.1431 7.99935 14.6654Z"
                  fill="white"
                />
              </svg>
            </Box>
          </Grid>
          {currentUserId === trainerId ? (
            <Tooltip title="Edit Training Background">
              <Box
                onClick={handleClickEditTrainingBackground}
                sx={{ cursor: 'pointer' }}
              >
                <Grid container sx={{ justifyContent: 'flex-start', gap: 1 }}>
                  <AccessTime color="primary" />
                  <Typography>
                    <b style={{ color: theme.palette.primary.main }}>
                      {trainer?.yearsExperience}{' '}
                    </b>
                    Years of Experience
                  </Typography>
                </Grid>
              </Box>
            </Tooltip>
          ) : (
            <Box>
              <Grid container sx={{ justifyContent: 'flex-start', gap: 1 }}>
                <AccessTime color="primary" />
                <Typography>
                  <b style={{ color: theme.palette.primary.main }}>
                    {trainer?.yearsExperience}{' '}
                  </b>
                  Years of Experience
                </Typography>
              </Grid>
            </Box>
          )}
        </Grid>
        <Grid container sx={{ justifyContent: 'flex-start', gap: 0 }}>
          <Typography sx={{ fontWeight: 500, fontSize: '28px' }}>
            {' '}
            About
          </Typography>
          {currentUserId === trainerId && (
            <Tooltip title="Edit About">
              <IconButton onClick={handleClickEditAbout}>
                <BorderColor color="primary" />
              </IconButton>
            </Tooltip>
          )}
          <Grid
            container
            sx={{
              justifyContent: 'flex-start',
            }}
          >
            <Typography sx={{ fontWeight: 400, fontSize: '16px' }}>
              {trainer?.about}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ justifyContent: 'flex-start', gap: 0 }}>
          <Typography sx={{ fontWeight: 500, fontSize: '28px' }}>
            {' '}
            Info For Trainees
          </Typography>
          {currentUserId === trainerId && (
            <Tooltip title="Edit Info">
              <IconButton onClick={handleClickEditAbout}>
                <BorderColor color="primary" />
              </IconButton>
            </Tooltip>
          )}
          <Grid
            container
            sx={{
              justifyContent: 'flex-start',
            }}
          >
            <Typography sx={{ fontWeight: 400, fontSize: '16px' }}>
              {trainer?.infoForTrainers}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AboutBox
