import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import AdbIcon from '@mui/icons-material/Adb'
import SvgIcon from '@mui/material/SvgIcon'
import { ReactComponent as BookingIcon } from '../../assets/icons/booking.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard.svg'
import { ReactComponent as JoinIcon } from '../../assets/icons/join.svg'
import { ReactComponent as LikedTrainersIcon } from '../../assets/icons/likedtrainers.svg'
import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg'
import { ReactComponent as MessagesIcon } from '../../assets/icons/messages.svg'
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile.svg'
import { ReactComponent as ReviewsIcon } from '../../assets/icons/reviews.svg'
import { ReactComponent as TravelIcon } from '../../assets/icons/travel.svg'
import { ReactComponent as VirtualIcon } from '../../assets/icons/virtual.svg'
import { ReactComponent as WorkoutIcon } from '../../assets/icons/workout.svg'

import { ReactComponent as GTLogoFull } from '../../assets/GTLogoFull.svg'
import theme from '../../styles/theme'
import HeaderSearch from './HeaderSearch'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AccountCircleOutlined, Phone } from '@mui/icons-material'
import {
  setAuthModalOpen,
  setBecomeTrainerModalOpen,
  setSignUpSignIn,
} from '../../redux/slices/uiSlice'
import AuthModal from '../modals/AuthModal'
import { Auth } from 'aws-amplify'
import ErrorModal from '../modals/ErrorModal'
import BecomeTrainerModal from '../modals/BecomeTrainerModal'
import { setIsTrainer } from '../../redux/slices/authSlice'
import S3Image from '../S3Image'
import LiveModal from '../modals/LiveModal'
import { Tab, Tabs, useMediaQuery } from '@mui/material'
import categories from '../../constants/categories'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { useEffect, useState } from 'react'
import { useGetMemberByIdQuery } from '../../redux/api/api'
import mixpanel from 'mixpanel-browser'
import HeaderTrainerSearch from './HeaderTrainerSearch'

function ElevationScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

const pages = ['Products', 'Pricing', 'Blog']

const gunMetal = '#2B303E'

function Header() {
  const { category } = useParams()
  const [value, setValue] = useState(null)
  const navigate = useNavigate()
  const handleChange = (event, newValue) => {
    mixpanel.track(`Browse ${categories[newValue].name} sessions`, {
      'Event Type': 'Browse Sessions',
    })
    setValue(newValue)
    navigate(`/browse-sessions/${categories[newValue].name}`)
  }

  const large = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    const index = categories.findIndex((cat) => cat.name === category)
    setValue(index)
    if (category) {
      console.log(`Browsing ${category} sessions`)
    }
  }, [category])

  const showTabs = false
  const memberSignedIn = useSelector((state) => state.auth.memberSignedIn)
  const cognitoUser = useSelector((state) => state.auth.cognitoUser)
  const { data: myProfileData } = useGetMemberByIdQuery(cognitoUser?.sub, {
    skip: !cognitoUser?.sub,
  })

  const isTrainer = useSelector((state) => state.auth.isTrainer)
  const hasTrainerProfile = useSelector((state) => state.auth.hasTrainerProfile)

  const dispatch = useDispatch()
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const trainerNav = [
    {
      name: 'Bookings',
      link: '/bookings',
    },

    {
      name: 'Messages',

      link: '/messages',
    },
    {
      name: 'Profile',
      link: `/trainer/${cognitoUser?.sub}`,
    },
    {
      name: 'Calendar',
      link: '/calendar',
    },
    {
      name: 'Settings',
      link: '/settings',
    },
  ]

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event) => {
    console.log('PROCESS.ENV', myProfileData)
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleOpenAuthModal = () => {
    //navigate('/onboarding')
    dispatch(setSignUpSignIn('signIn'))
    dispatch(setAuthModalOpen(true))
  }

  const handleSignOut = async () => {
    console.debug('trying to sign out')
    try {
      await Auth.signOut()
      console.debug('success sign out')
      navigate('/')
      handleCloseUserMenu()
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }

  const settings = [
    {
      name: 'Profile',
      onClick: isTrainer
        ? () => navigate(`/trainer/${cognitoUser?.sub}`)
        : () => navigate(`/member/${cognitoUser?.sub}`),
    },
    {
      name: isTrainer ? 'Bookings' : 'Workouts',
      onClick: isTrainer
        ? () => navigate('/Bookings')
        : () => navigate('/Workouts'),
    },
    { name: 'Messages', onClick: () => navigate('/Messages') },
    { name: 'Calendar', onClick: () => navigate('/Calendar') },
    { name: 'Favorites', onClick: () => navigate('/Liked') },
    { name: 'Settings', onClick: () => navigate('/settings') },
    { name: 'Logout', onClick: () => handleSignOut() },
  ]

  const currPage = useLocation().pathname
  const nav = [
    /*  {
      name: 'Dashboard',
      icon: () => {
        return (
          <DashboardIcon
            stroke={currPage === '/Dashboard' ? '#00C7C7' : gunMetal}
            style={{ width: '22px' }}
          />
        )
      },
      link: '/Dashboard',
    }, */
    {
      name: 'Workouts',
      icon: () => {
        return (
          <WorkoutIcon
            stroke={currPage === '/Workouts' ? '#00C7C7' : gunMetal}
            style={{ width: '22px' }}
          />
        )
      },
      link: '/Workouts',
    },
    {
      name: 'Messages',
      icon: () => {
        return (
          <MessagesIcon
            stroke={currPage === '/Messages' ? '#00C7C7' : gunMetal}
            style={{ width: '22px' }}
          />
        )
      },
      link: '/Messages',
    },
    {
      name: 'Calendar',
      icon: () => {
        return (
          <CalendarIcon
            stroke={currPage === '/Calendar' ? '#00C7C7' : gunMetal}
            style={{ width: '22px' }}
          />
        )
      },
      link: '/Calendar',
    },
    {
      name: 'Profile',
      icon: () => {
        return (
          <ProfileIcon
            stroke={currPage === '/Profile' ? '#00C7C7' : gunMetal}
            style={{ width: '22px' }}
          />
        )
      },
      link: '/Profile',
    },
    {
      name: 'Liked',
      icon: () => {
        return (
          <LikedTrainersIcon
            stroke={currPage === '/Liked' ? '#00C7C7' : gunMetal}
            style={{ width: '22px' }}
          />
        )
      },
      link: '/Liked',
    },
  ]
  const menuItem = (page) => {
    return (
      <MenuItem key={page.name} onClick={() => navigate(page.link)}>
        <SvgIcon
          inheritViewBox
          component={page.icon}
          sx={{ mr: 1, fontSize: 24, stroke: 'white' }}
        />
        <Typography textAlign="center">{page.name}</Typography>
      </MenuItem>
    )
  }

  useEffect(() => {
    if (hasTrainerProfile) {
      //dispatch(setIsTrainer(false))
    }
  }, [hasTrainerProfile])

  return (
    <>
      <ElevationScroll>
        <AppBar
          position="sticky"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            boxShadow: 'null',
            backgroundColor: 'white',
          }}
        >
          <ErrorModal />
          <AuthModal />
          <BecomeTrainerModal />
          <LiveModal />
          <div>
            <Toolbar sx={{ p: 1, justifyContent: 'space-between' }}>
              <SvgIcon
                onClick={() => navigate('/')}
                inheritViewBox
                component={GTLogoFull}
                sx={{ mr: 1, height: 75, fontSize: 136, cursor: 'pointer' }}
              />

              <Box
                sx={{
                  flexGrow: 1,
                  display: {
                    xs: 'none',
                    md: 'flex',
                    justifyContent: 'center',
                  },
                }}
              >
                {/* {!isTrainer &&
                  nav.map((page) => (
                    <Tooltip key={page.name} title={page.name}>
                      <IconButton
                        key={page.name}
                        onClick={() => navigate(page.link)}
                        sx={{ color: 'white', display: 'flex' }}
                      >
                        <SvgIcon
                          inheritViewBox
                          component={page.icon}
                          sx={{ fontSize: 18 }}
                        />
                      </IconButton>
                    </Tooltip>
                  ))} */}
                {isTrainer &&
                  trainerNav.map((page) => (
                    <Tooltip key={page.name} title={page.name}>
                      <Button
                        key={page.name}
                        onClick={() => navigate(page.link)}
                        sx={{
                          color:
                            currPage.toLowerCase() == page.link.toLowerCase()
                              ? 'primary.main'
                              : 'gunMetal.main',
                          display: 'flex',
                          textTransform: 'none',
                        }}
                      >
                        <Typography>{page.name}</Typography>
                      </Button>
                    </Tooltip>
                  ))}
                {!isTrainer && window.location.pathname != "/search" && <HeaderSearch />}
                {!isTrainer && window.location.pathname == "/search" && <HeaderTrainerSearch />}
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                {memberSignedIn && (
                  <>
                    {!isTrainer && !hasTrainerProfile && (
                      <Tooltip title="Become a Trainer">
                        <Button
                          onClick={() =>
                            dispatch(setBecomeTrainerModalOpen(true))
                          }
                          sx={{
                            p: 0,
                            mr: 2,
                            textTransform: 'none',
                            color: 'gunMetal.main',
                          }}
                        >
                          <Typography> Become A Trainer </Typography>
                        </Button>
                      </Tooltip>
                    )}
                    {!isTrainer && hasTrainerProfile && large && (
                      <Tooltip title="Switch to Trainer">
                        <Button
                          onClick={() => dispatch(setIsTrainer(true))}
                          sx={{
                            p: 0,
                            mr: 2,
                            textTransform: 'none',
                            color: 'gunMetal.main',
                          }}
                        >
                          <Typography> Switch to Trainer </Typography>
                        </Button>
                      </Tooltip>
                    )}
                    {isTrainer && large && (
                      <Tooltip title="Switch to member">
                        <Button
                          onClick={() => dispatch(setIsTrainer(false))}
                          sx={{
                            p: 0,
                            mr: 2,
                            textTransform: 'none',
                            color: 'gunMetal.main',
                          }}
                        >
                          <Typography> Switch to member </Typography>
                        </Button>
                      </Tooltip>
                    )}
                    <Tooltip title="Open settings">
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Box
                          sx={{
                            height: '50px',
                            width: '50px',
                            borderRadius: '50%',
                            border: 4,
                            borderColor: isTrainer
                              ? 'gunMetal.main'
                              : 'primary.main',
                          }}
                        >
                          {myProfileData?.profilePhotoUrl && (
                            <S3Image
                              dimensions={{
                                width: '50px',
                                height: '50px',
                                borderRadius: '50%',
                              }}
                              imagePath={`${cognitoUser?.sub}/${myProfileData?.profilePhotoUrl}`}
                            />
                          )}
                        </Box>

                        {/* <Typography>{cognitoUser.sub}</Typography> */}
                        {/* <Avatar
                      alt={
                        cognitoUser.given_name + ' ' + cognitoUser.family_name
                      }
                      src="/static/images/avatar/2.jpg"
                      sx={{
                        border: 4,
                        borderColor: isTrainer
                          ? 'gunMetal.main'
                          : 'primary.main',
                      }}
                    /> */}
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {!memberSignedIn && (
                  <>
                    <Tooltip title="Sign Up">
                      <Button
                        variant="contained"
                        onClick={() => navigate('/onboarding')}
                        sx={{
                          mr: 2,
                          textTransform: 'none',
                          color: 'white',
                        }}
                      >
                        <Typography> Sign Up </Typography>
                      </Button>
                    </Tooltip>
                    {/* TODO MAKE ICON A BIT LARGE */}
                    <Tooltip title="Sign In">
                      <Button
                        variant="outlined"
                        onClick={handleOpenAuthModal}
                        sx={{
                          mr: 2,
                          textTransform: 'none',
                        }}
                      >
                        <Typography> Sign In </Typography>
                      </Button>
                      {/* <IconButton onClick={handleOpenAuthModal}>
                        <AccountCircleOutlined sx={{ fontSize: 36 }} />
                      </IconButton> */}
                    </Tooltip>
                  </>
                )}
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting.name}
                      onClick={
                        setting.onClick ? setting.onClick : handleCloseUserMenu
                      }
                    >
                      <Typography textAlign="center">{setting.name}</Typography>
                    </MenuItem>
                  ))}
                  {!large && isTrainer && (
                    <MenuItem
                      onClick={() => dispatch(setIsTrainer(false))}
                      sx={{ color: 'primary.main' }}
                    >
                      <Typography textAlign="center">Member View</Typography>
                    </MenuItem>
                  )}
                  {!large && !isTrainer && hasTrainerProfile && (
                    <MenuItem
                      onClick={() => dispatch(setIsTrainer(true))}
                      sx={{ color: 'primary.main' }}
                    >
                      <Typography textAlign="center">Trainer View</Typography>
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            </Toolbar>
            {showTabs && !isTrainer && (
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
                sx={{
                  backgroundColor: 'white',
                  color: '#7C7C7C',
                  width: '100%',
                }}
              >
                {categories.map((category) => (
                  <Tab
                    style={{ color: '#7C7C7C' }}
                    icon={category.icon || <Phone />}
                    label={category.name}
                  />
                ))}
              </Tabs>
            )}
          </div>
        </AppBar>
      </ElevationScroll>
      {false && <Toolbar />}
    </>
  )
}
export default Header

//make member icon larger and add call to action next to it just become trainer
