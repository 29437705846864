import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { ReactComponent as CertifiedBadge } from '../../assets/icons/CertifiedBadge.svg'
import { useParams } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { setSessionPhotoCropModalOpen } from '../../redux/slices/uiSlice'
import { useEffect, useRef, useState } from 'react'

import { Cropper } from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import SolidColorButton from '../buttons/SolidColorButton'
import { Storage } from 'aws-amplify'
import {
  useGetImageQuery,
  useGetSessionDetailsByIdQuery,
  useUpdateSessionMutation,
} from '../../redux/api/api'
import TitleB from '../text/TitleB'
import { useDropzone } from 'react-dropzone'
import theme from '../../styles/theme'
import { v4 as uuidv4 } from 'uuid'

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
}

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

const SessionPhotoCropModal = () => {
  const [updateSessionTrigger, updateSessionResponse] =
    useUpdateSessionMutation()
  const [files, setFiles] = useState([])
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    },
  })
  const [page, setPage] = useState(0)
  const dispatch = useDispatch()
  const { sessionId } = useParams()
  const { data: session } = useGetSessionDetailsByIdQuery(sessionId, {
    skip:
      sessionId === 0 ||
      sessionId === '0' ||
      sessionId === undefined ||
      sessionId === null,
    keepUnusedDataFor: 100000,
    pollingInterval: 100000,
  })

  const currentUserId = useSelector((state) => state.auth.cognitoUser?.sub)
  const open = useSelector((state) => state.ui.sessionPhotoCropModalOpen)
  const sessionPhotoNumber = useSelector((state) => state.ui.sessionPhotoNumber)

  const cropperRef = useRef(null)
  const [croppedBlob, setCroppedBlob] = useState(null)
  const onCrop = () => {
    const imageElement = cropperRef?.current
    const cropper = imageElement?.cropper
    const canvas = cropper.getCroppedCanvas()
    canvas.toBlob((blob) => {
      if (blob === null) {
        console.log('Blob is null')
        return
      } else {
        //console.log('HERE DA BLOB: ', blob)
        setCroppedBlob(blob)
      }
    }, 'image/jpeg')
  }

  const handleClose = () => {
    dispatch(setSessionPhotoCropModalOpen(false))
    setFiles([])
    setPage(0)
  }

  const handleUploadSessionImage = async () => {
    console.log('Uploading session image')
    try {
      // try generating a unique id for the image
      const photoId = uuidv4()
      await Storage.put(
        `${currentUserId}/sessions/${sessionId}/${photoId}`,
        croppedBlob,
        {
          contentType: 'image/png', // contentType is optional
        }
      )
      console.log('Successfully uploaded')
      // update session photos on sessino object in database
      var sessionPhotosCopy = [null, null, null]
      let updatedSessionPhotos
      if (session?.sessionPhotos && session?.sessionPhotos.length) {
        if (session?.sessionPhotos[0]) {
          sessionPhotosCopy[0] = session?.sessionPhotos[0]
        }
        if (session?.sessionPhotos[1]) {
          sessionPhotosCopy[1] = session?.sessionPhotos[1]
        }
        if (session?.sessionPhotos[2]) {
          sessionPhotosCopy[2] = session?.sessionPhotos[2]
        }
      }
      if (sessionPhotoNumber === 1) {
        console.log('Updating session photo 1')
        updatedSessionPhotos = [
          photoId,
          sessionPhotosCopy[1],
          sessionPhotosCopy[2],
        ]
      } else if (sessionPhotoNumber === 2) {
        console.log('Updating session photo 2')
        updatedSessionPhotos = [
          sessionPhotosCopy[0],
          photoId,
          sessionPhotosCopy[2],
        ]
      } else if (sessionPhotoNumber === 3) {
        console.log('Updating session photo 3', session)
        updatedSessionPhotos = [
          sessionPhotosCopy[0],
          sessionPhotosCopy[1],
          photoId,
        ]
      }

      const updatedSession = await updateSessionTrigger({
        id: sessionId,
        sessionPhotos: updatedSessionPhotos,
      })

      // refetch()
      // console.log('REFETCHED')
      // TODO CLOSE MODAL AND CLEAR PICTURE
      handleClose()
      window.location.reload()
    } catch (error) {
      console.log('Error uploading file: ', error)
    }

    //dispatch(setUserProfilePicture(image))
    //dispatch(setGeneralModalOpen(false))
  }

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview))
  }, [])

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          //   onLoad={() => {
          //     URL.revokeObjectURL(file.preview)
          //   }}
        />
      </div>
    </div>
  ))

  const renderPageOne = () => {
    return (
      <Grid container>
        <Grid container>
          <section
            className="container"
            style={{
              backgroundColor: theme.palette.primary.main,
              width: '90%',
              padding: '4px',
              borderRadius: '16px',
              display: 'flex',
            }}
          >
            <div
              {...getRootProps({ className: 'dropzone' })}
              style={{
                backgroundColor: 'lightGrey',
                width: '100%',
                height: ' 100%',
                borderRadius: '12px',
                padding: '12px',
                display: 'flex',
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography color="gunMetal" fontWeight={600}>
                    Drag 'n' drop some files here, or click to select files
                  </Typography>
                </Grid>

                <Grid container>
                  <input {...getInputProps()} />
                </Grid>
                <aside style={thumbsContainer}>{thumbs}</aside>
              </Grid>
            </div>
          </section>
        </Grid>

        <Button onClick={() => setPage(1)} disabled={!files[0]?.preview}>
          Continue
        </Button>
      </Grid>
    )
  }

  const renderPageTwo = () => {
    return (
      <>
        {' '}
        <Grid container>
          <TitleB text="Crop and Size..." />
        </Grid>
        <Grid
          sx={{
            padding: '32px',
            display: 'flex',
          }}
        >
          <Cropper
            src={files[0].preview}
            crop={onCrop}
            ref={cropperRef}
            aspectRatio={sessionPhotoNumber === 1 ? 644 / 932 : 644 / 440}
            style={{
              maxHeight: sessionPhotoNumber === 1 ? 932 : 644,
              maxWidth: sessionPhotoNumber === 1 ? 644 : 440,

              backgroundColor: 'white',
            }}
          />
        </Grid>
        <Grid container spacing={2} sx={{ pb: 2 }}>
          <Grid item>
            <SolidColorButton text="Cancel" onClick={handleClose} />
          </Grid>
          <Grid item>
            <SolidColorButton
              text="Upload"
              onClick={handleUploadSessionImage}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  const renderPageThree = () => {
    return (
      <Grid container>
        <Typography> Save Image</Typography>
        <Button onClick={() => setPage(0)}>Save</Button>
      </Grid>
    )
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Upload Session Photo</DialogTitle>
      {page === 0 && renderPageOne()}
      {page === 1 && renderPageTwo()}
      {page === 2 && renderPageThree()}
    </Dialog>
  )
}

export default SessionPhotoCropModal
