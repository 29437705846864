import { useEffect } from 'react'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Grid,
  Typography,
} from '@mui/material'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import FilterTextField from '../../components/filters/FilterTextField'
import SessionMain from '../../components/session_page/SessionMain'
import { useNavigate } from 'react-router-dom'
import { useGetTrainersQuery } from '../../redux/api/api'
import {
  setApiFilters,
  setTrainerDisplayName,
} from '../../redux/slices/filtersSlice'
import { useDispatch, useSelector } from 'react-redux'
import TrainerCard from '../../components/trainer_card/TrainerCard'
function HeaderTrainerSearch() {
  const defaultFilter = {
    trainerDisplayName: null,
  }
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { trainerDisplayName } = useSelector((state) => state.filters)
  const apiFilters = useSelector((state) => state.filters.apiFilters)

  useEffect(() => {
    const apiFilterObject = {}
    if (trainerDisplayName) {
      apiFilterObject.displayName = { contains: trainerDisplayName }
    }
    dispatch(setApiFilters(apiFilterObject))
  }, [trainerDisplayName])
  return (
    <Grid container>
      <Grid
        container
        style={{
          width: '100%',
          margin: 0,
          padding: 0,
        }}
        sx={{ m: 2 }}
      >
        {/*content*/}
        <Grid container>
          <Grid item xs={3}>
            <FilterTextField
              label="Find A Trainer"
              fullWidth
              disablePortal
              id="trainer-name-search"
              value={trainerDisplayName || ''}
              onChange={(event, value) => {
                dispatch(setTrainerDisplayName(event.target.value))
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default HeaderTrainerSearch
