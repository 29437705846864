import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import DashboardOverview from './DashboardOverview'
import { Button, Grid, MenuItem, Select } from '@mui/material'
import PaymentsHistoryTable from './PaymentsHistoryTable'
import BrowseSessions from '../browser_sessions/BrowseSessions'
import SessionCard from '../session_card/SessionCard'
import {
  Add,
  DeleteOutline,
  DriveFileMove,
  DriveFileRenameOutline,
  PauseCircle,
  PauseCircleOutline,
  PlayCircleFilledOutlined,
  RocketLaunch,
  RocketLaunchOutlined,
} from '@mui/icons-material'
import BorderColorButton from '../buttons/BorderColorButton'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  useGetSessionsQuery,
  useUpdateSessionMutation,
} from '../../redux/api/api'
import { updateSession } from '../../graphql/mutations'

export default function TrainerSessionCardsPage() {
  const navigate = useNavigate()
  const trainerId = useSelector((state) => state.auth?.cognitoUser?.sub)
  let filter = {
    trainerSessionsId: {
      eq: trainerId,
    },
  }
  const sessionsData = useGetSessionsQuery(filter, {
    skip: !trainerId,
    keepUnusedDataFor: 100000,
    polligInterval: 100000,
  })
  const activeSessions =
    sessionsData?.data?.filter((session) => {
      return !session.paused
    }) || []
  const inactiveSessions =
    sessionsData?.data?.filter((session) => {
      return session.paused
    }) || []
  const handleEditSession = (id) => {
    navigate(`/session-details/${id}`)
  }

  const [updateSessionTrigger, updateSessionData] = useUpdateSessionMutation()

  const handlePauseSession = (id) => {
    updateSessionTrigger({ id: id, paused: true })
  }
  const handleUnpauseSession = (id) => {
    updateSessionTrigger({ id: id, paused: false })
  }
  //const activeSessions = [1, 2, 3, 4]
  const activeSessionCards = activeSessions.map((session, index) => (
    <Grid key={session.id} item xs={4} sx={{ p: 1 }}>
      <SessionCard session={session} />
      <Grid container justifyContent="flex-start">
        <DriveFileRenameOutline
          color="primary"
          sx={{ p: 1 }}
          onClick={() => handleEditSession(session.id)}
        />
        <PauseCircleOutline
          color="primary"
          sx={{ p: 1 }}
          onClick={() => handlePauseSession(session.id)}
        />
        <RocketLaunchOutlined color="primary" sx={{ p: 1 }} />
        <DeleteOutline color="primary" sx={{ p: 1 }} />
      </Grid>
    </Grid>
  ))
  const inactiveSessionCards = inactiveSessions.map((session, index) => (
    <Grid key={session.id} item xs={4} sx={{ p: 1, opacity: 0.5 }}>
      <SessionCard session={session} />
      <Grid container justifyContent="flex-start">
        <DriveFileRenameOutline
          color="primary"
          sx={{ p: 1 }}
          onClick={() => handleEditSession(session.id)}
        />
        <PlayCircleFilledOutlined
          color="primary"
          sx={{ p: 1 }}
          onClick={() => handleUnpauseSession(session.id)}
        />
        <RocketLaunchOutlined color="primary" sx={{ p: 1 }} />
        <DeleteOutline color="primary" sx={{ p: 1 }} />
      </Grid>
    </Grid>
  ))
  return (
    <Box sx={{ width: '100%', bgcolor: '#F6F7F8' }}>
      <Grid container sx={{ p: 4 }} justifyContent="flex-end">
        <Button
          onClick={() => navigate('/session-details/0')}
          variant="outlined"
          sx={{ bgcolor: 'white', color: 'gunMetal.main' }}
        >
          <Grid container alignItems="center" justifyContent="center">
            <Add color="primary" sx={{ p: 1 }} />
            <Typography sx={{ fontWeight: 700 }}>
              Create new session or class
            </Typography>
          </Grid>
        </Button>
      </Grid>
      <Grid container sx={{ p: 4 }}>
        <Grid container justifyContent="flex-start" sx={{ pl: 4 }}>
          <Typography
            sx={{
              color: 'gunMetal.main',
              fontWeight: 700,
              fontSize: '26px',
              lineHeight: '39px',
            }}
          >
            Active Session Cards
          </Typography>
        </Grid>
        <Grid container justifyContent="flex-start" sx={{ p: 4, pt: 0 }}>
          {activeSessionCards}
        </Grid>
        <Grid container justifyContent="flex-start" sx={{ pl: 4 }}>
          <Typography
            sx={{
              color: 'gunMetal.main',
              fontWeight: 700,
              fontSize: '26px',
              lineHeight: '39px',
            }}
          >
            Inactive Session Cards
          </Typography>
        </Grid>
        <Grid container justifyContent="flex-start" sx={{ p: 4, pt: 0 }}>
          {inactiveSessionCards}
        </Grid>
      </Grid>
    </Box>
  )
}
