import { Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useGetMemberByIdQuery,
  useGetTimeDayPreferenceByIdQuery,
} from '../../redux/api/api'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'
import BorderSelectButton from '../buttons/BorderSelectButton'
import TitleC from '../text/TitleC'

const PreferredTrainingDaysTime = (props) => {
  const dispatch = useDispatch()
  const cognitoUserId = useSelector((state) => state.auth.cognitoUser?.sub)
  const isTrainerOnboard = useSelector((state) => state.ui?.isTrainerOnboard)

  const formTimePreferences = useSelector(
    (state) => state.forms.userProfileModal?.trainingTimePreferences
  )
  const formDayPreferences = useSelector(
    (state) => state.forms.userProfileModal?.trainingDayPreferences
  )
  const { data, isLoading } = useGetMemberByIdQuery(cognitoUserId, {
    skip: !cognitoUserId,
    keepUnusedData: true,
  })
  const { onboarding } = props
  const [localData, setLocalData] = useState([])

  const handleClickDayPreference = (day) => {
    let arr
    if (!formDayPreferences) {
      arr = []
    } else {
      arr = [...formDayPreferences]
    }
    if (day === 'all' && arr.length !== 7) {
      dispatch(
        setUserProfileModalField({
          field: 'trainingDayPreferences',
          value: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
        })
      )
    } else if (day === 'all' && arr.length === 7) {
      dispatch(
        setUserProfileModalField({
          field: 'trainingDayPreferences',
          value: [],
        })
      )
    } else {
      dispatch(
        setUserProfileModalField({
          field: 'trainingDayPreferences',
          value: arr?.includes(day)
            ? [...arr]?.filter((d) => d !== day)
            : [...arr, day],
        })
      )
    }
  }

  const handleClickTimePreference = (time) => {
    let arr
    if (!formTimePreferences) {
      arr = []
    } else {
      arr = [...formTimePreferences]
    }
    if (time === 'Any Time' && arr.length !== 4) {
      dispatch(
        setUserProfileModalField({
          field: 'trainingTimePreferences',
          value: ['Mornings', 'Afternoons', 'Evenings', 'Any Time'],
        })
      )
    } else if (time === 'Any Time' && arr.length === 4) {
      dispatch(
        setUserProfileModalField({
          field: 'trainingTimePreferences',
          value: [],
        })
      )
    } else {
      dispatch(
        setUserProfileModalField({
          field: 'trainingTimePreferences',
          value: arr?.includes(time)
            ? [...arr].filter((d) => d !== time)
            : [...arr, time],
        })
      )
    }
  }

  useEffect(() => {
    if (onboarding) {
      setLocalData({
        timeDayPreference: {
          mon: false,
          tue: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false,
          sun: false,
          morning: false,
          afternoon: false,
          evening: false,
        },
      })
      return
    }
    setLocalData(data)
  }, [data])
  if (isLoading) {
    return <></>
  }
  if (localData) {
    return (
      <>
        <Grid item xs={12}>
          <TitleC
            text={
              isTrainerOnboard
                ? 'When do you want to offer Training?'
                : 'When would you like to GET TRAIND?'
            }
          />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: '20px' }}>
          <Typography
            sx={{
              color: 'gunMetal.main',
              fontWeight: 700,
              fontSize: 17,
              lineHeight: '25.5px',
            }}
          >
            {' '}
            Days of the week
          </Typography>
          <Grid
            container
            style={{ paddingTop: '16px', justifyContent: 'flex-start' }}
            spacing={'20px'}
          >
            {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day) => (
              <Grid item>
                <BorderSelectButton
                  text={day}
                  active={formDayPreferences?.includes(day)}
                  onClick={() => handleClickDayPreference(day)}
                />
              </Grid>
            ))}
            <Grid item>
              <BorderSelectButton
                onClick={() => handleClickDayPreference('all')}
                text="ANY DAY"
                active={
                  formDayPreferences?.includes('mon') &&
                  formDayPreferences?.includes('tue') &&
                  formDayPreferences?.includes('wed') &&
                  formDayPreferences?.includes('thu') &&
                  formDayPreferences?.includes('fri') &&
                  formDayPreferences?.includes('sat') &&
                  formDayPreferences?.includes('sun')
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: '20px' }}>
          <Typography
            sx={{
              color: 'gunMetal.main',
              fontWeight: 700,
              fontSize: 17,
              lineHeight: '25.5px',
            }}
          >
            {' '}
            Time of day
          </Typography>
          <Grid
            container
            style={{ paddingTop: '16px', justifyContent: 'flex-start' }}
            spacing={'20px'}
          >
            {['Mornings', 'Afternoons', 'Evenings', 'Any Time'].map((time) => (
              <Grid item>
                <BorderSelectButton
                  text={time}
                  active={formTimePreferences?.includes(time)}
                  onClick={() => handleClickTimePreference(time)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </>
    )
  }
}

export default PreferredTrainingDaysTime
