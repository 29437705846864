import { Grid, SvgIcon } from '@mui/material'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import { ReactComponent as GTLogoFull } from '../assets/GTLogoFull.svg'

function Feature() {
  return (
    <Grid container>
      <Grid
        container
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Grid item>
          <SvgIcon
            //onClick={() => navigate('/')}
            inheritViewBox
            component={GTLogoFull}
            sx={{ mr: 1, height: 75, fontSize: 136, cursor: 'pointer' }}
          />
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Grid
        container
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Grid item>
          <SvgIcon
            //onClick={() => navigate('/')}
            inheritViewBox
            component={GTLogoFull}
            sx={{ mr: 1, height: 75, fontSize: 136, cursor: 'pointer' }}
          />
        </Grid>
        <Grid item></Grid>
      </Grid>

      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  )
}

export default Feature
