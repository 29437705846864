import { Typography } from "@mui/material"

const InfoText = (props) => {
  const { text, width, color } = props
  return (
    <Typography
      sx={{
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '25.5px',
        width: width ? width : 'auto',
        color: color
      }}
    >
      {text}
    </Typography>
  )
}

export default InfoText