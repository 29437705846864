import * as React from 'react'
import dayjs from 'dayjs'
import Grid from '@mui/material/Grid'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker'
import { MonthPicker } from '@mui/x-date-pickers/MonthPicker'
import { YearPicker } from '@mui/x-date-pickers/YearPicker'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import {
  Box,
  Button,
  IconButton,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { ExpandMore } from '@mui/icons-material'
import CalendarFull from './CalendarFull'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCreateBookingField,
  setCreateBookingTime,
} from '../../redux/slices/formsSlice'
import formatFromDB, { getDayJSFromString } from '../../helpers/datetime'

const minDate = dayjs()
const maxDate = dayjs('2025-01-01T00:00:00.000')

export default function Calendar(props) {
  const { session } = props
  const prices = {
    '30 Mins': session.thrityMinsPrice,
    '60 Mins': session.sixtyMinsPrice,
    '90 Mins': session.ninetyMinsPrice,
    '120 Mins': session.oneTwentyMinsPrice,
  }
  const defaultHeaderDate = useSelector((state) => state.ui.headerSearch.date)
  React.useEffect(() => {
    console.log('defaultHeaderDate', defaultHeaderDate)

    const dayjsObj = getDayJSFromString(defaultHeaderDate, 'MM/DD/YY')
    console.log('test: ', dayjsObj)
    console.log('dayjsObj', formatFromDB(dayjsObj, 'MMM Do YYYY'))
    setDate(dayjsObj)
  }, [defaultHeaderDate])
  const dispatch = useDispatch()
  const StyledDatePicker = styled(StaticDatePicker)({
    '& .MuiPickersCalendarHeader-root ': {
      display: 'none',
    },
    '& .MuiPickersDay-root ': {
      fontWeight: 'bold',
    },
    '& .MuiPickersDay-today ': {
      textEmphasisStyle: 'circle',
      textEmphasisPosition: 'under',
      border: 'none',
      fontWeight: 'bold',
    },
    '& .Mui-disabled ': {
      textDecoration: 'line-through',
      fontWeight: 'bold',
    },
    '& .MuiDayPicker-weekDayLabel ': {
      fontWeight: 'bold',
    },
  })
  const [monthEl, setMonthEl] = React.useState(null)
  const [yearEl, setYearEl] = React.useState(null)
  const [timeZoneEl, setTimeZoneEl] = React.useState(null)
  const [durationEl, setDurationEl] = React.useState(null)
  const [date, setDate] = React.useState(dayjs())
  const [monthOpen, setMonthOpen] = React.useState(false)
  const [timeZoneOpen, setTimeZoneOpen] = React.useState(false)
  const [durationOpen, setDurationOpen] = React.useState(false)
  const [timeZone, setTimeZone] = React.useState('PST')
  const [duration, setDuration] = React.useState('60 Mins')
  const [yearOpen, setYearOpen] = React.useState(false)
  const [amPm, setAmPm] = React.useState('am')
  const [time, setTime] = React.useState(null)

  const clickTimeZone = (e) => {
    setTimeZoneOpen(true)
    setTimeZoneEl(e.currentTarget)
  }

  const clickDuration = (e) => {
    setDurationOpen(true)
    setDurationEl(e.currentTarget)
  }

  const handleAmClick = () => {
    setAmPm('am')
    dispatch(setCreateBookingTime({ field: 'amPm', value: 'AM' }))
  }
  const handlePmClick = () => {
    setAmPm('pm')
    dispatch(setCreateBookingTime({ field: 'amPm', value: 'PM' }))
  }
  const handleSelectTime = (newTime) => {
    setTime(newTime)
    dispatch(setCreateBookingTime({ field: 'time', value: newTime }))
  }
  const times = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const timeSlots = times.map((slot) => (
    <Grid item xs={3} sx={{ p: 1, m: 1 }}>
      <Button
        variant={time === slot ? 'contained' : 'outlined'}
        onClick={(e) => handleSelectTime(slot)}
      >
        {slot}:00 {amPm}
      </Button>
    </Grid>
  ))

  const isDisabled = (date) => {
    const day = date.day()

    return day === 0 || day === 6
  }

  const clickMonth = (e) => {
    setMonthOpen(true)
    setMonthEl(e.currentTarget)
    console.log(date.month())
  }
  const clickYear = (e) => {
    setYearOpen(true)
    setYearEl(e.currentTarget)
    console.log(date.year())
  }
  const handleChangeMonth = (newDate) => {
    // Add 1 to month because dayjs starts at 0
    const newMonth = newDate.month() + 1
    dispatch(setCreateBookingTime({ field: 'month', value: newMonth }))
    setDate(newDate)
    setMonthOpen(false)
  }
  const handleChangeYear = (newDate) => {
    const newYear = newDate.year()
    dispatch(setCreateBookingTime({ field: 'year', value: newYear }))
    setDate(newDate)
    setYearOpen(false)
  }

  const handleChangeTimeZone = (newZone) => {
    setTimeZone(newZone)
    setTimeZoneOpen(false)
    dispatch(setCreateBookingTime({ field: 'zone', value: newZone }))
  }
  const handleChangeDuration = (newDuration) => {
    setDuration(newDuration)
    setDurationOpen(false)
    dispatch(setCreateBookingTime({ field: 'duration', value: newDuration }))
    dispatch(
      setCreateBookingField({
        field: 'price',
        value: prices[newDuration],
      })
    )
  }

  const handleChangeDate = (newDate) => {
    setDate(newDate)
    const day = newDate.date()
    dispatch(setCreateBookingTime({ field: 'date', value: day }))
  }

  const calendarView = (
    <Grid item xs={12} md={6}>
      <Grid container sx={{ justifyContent: 'space-around' }}>
        <Grid item sx={{ mr: 3 }}>
          <Button
            variant="contained"
            sx={{ bgcolor: 'gunMetal.main', color: 'white' }}
            onClick={(e) => {
              clickMonth(e)
            }}
          >
            {date.format('MMMM')} <ExpandMore sx={{ pl: 1 }} />
          </Button>

          <Popover
            PaperProps={{ sx: { borderRadius: '30px' } }}
            open={monthOpen}
            anchorEl={monthEl}
            sx={{ width: '250px', top: '40px' }}
          >
            <MonthPicker
              sx={{ width: '150px', height: '300px' }}
              disablePast
              date={date}
              minDate={minDate}
              maxDate={maxDate}
              onChange={handleChangeMonth}
            />
          </Popover>
        </Grid>
        <Grid item sx={{ mr: 3 }}>
          <Button
            variant="contained"
            sx={{ bgcolor: 'gunMetal.main', color: 'white' }}
            onClick={(e) => {
              clickYear(e)
            }}
          >
            {date.format('YYYY')} <ExpandMore sx={{ pl: 1 }} />
          </Button>
          <Popover
            PaperProps={{ sx: { borderRadius: '30px' } }}
            open={yearOpen}
            anchorEl={yearEl}
            sx={{ width: '250px', top: '40px' }}
          >
            <YearPicker
              sx={{ width: '150px', height: '300px' }}
              disablePast
              date={date}
              minDate={minDate}
              maxDate={maxDate}
              onChange={handleChangeYear}
            />
          </Popover>
        </Grid>
      </Grid>
      <Grid container>
        <StyledDatePicker
          shouldDisableDate={isDisabled}
          disablePast
          displayStaticWrapperAs="desktop"
          value={date}
          views={['day']}
          onChange={(newValue) => {
            handleChangeDate(newValue)
          }}
          componentProps={{
            switchViewButton: { sx: { display: 'none' } },
          }}
        />
      </Grid>
    </Grid>
  )
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid
        container
        sx={{
          border: 1,
          borderColor: 'primary.main',
          borderRadius: 4,
          p: 2,

          alignItems: 'flex-start',
        }}
      >
        {calendarView}
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid container>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  bgcolor: 'gunMetal.main',
                  color: 'white',
                }}
                onClick={(e) => {
                  clickTimeZone(e)
                }}
              >
                {timeZone !== '' ? timeZone : 'Time Zone'}{' '}
                <ExpandMore sx={{ pl: 1 }} />
              </Button>

              <Popover
                PaperProps={{ sx: { borderRadius: '30px' } }}
                open={timeZoneOpen}
                anchorEl={timeZoneEl}
                sx={{ top: '40px', left: '100px' }}
              >
                <Grid
                  container
                  sx={{ bgcolor: 'gunMetal.main', width: '300px' }}
                >
                  <List sx={{ color: 'white' }}>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={(e) => handleChangeTimeZone('EST')}
                        sx={{ color: 'white', width: '100%' }}
                      >
                        <ListItemText primary="EST" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={(e) => handleChangeTimeZone('PST')}
                      >
                        <ListItemText primary="PST" />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Grid>
              </Popover>
            </Grid>
            <Grid container>
              <Grid item sx={{ flexGrow: 1 }}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ bgcolor: 'gunMetal.main', color: 'white' }}
                  onClick={(e) => {
                    clickDuration(e)
                  }}
                >
                  {duration !== '' ? duration : 'Duration'}{' '}
                  <ExpandMore sx={{ pl: 1 }} />
                </Button>
                <Popover
                  PaperProps={{ sx: { borderRadius: '30px' } }}
                  open={durationOpen}
                  anchorEl={durationEl}
                  sx={{ top: '40px' }}
                >
                  <Grid
                    container
                    sx={{
                      bgcolor: 'gunMetal.main',
                      p: 2,
                      justifyContent: 'space-between',

                      pr: 4,
                    }}
                  >
                    <Grid item xs={2}>
                      <Button onClick={(e) => handleChangeDuration('30 Mins')}>
                        {' '}
                        30 Mins
                      </Button>
                    </Grid>
                    <Grid item xs={2}>
                      <Button onClick={(e) => handleChangeDuration('60 Mins')}>
                        {' '}
                        60 Mins
                      </Button>
                    </Grid>
                    <Grid item xs={2}>
                      <Button onClick={(e) => handleChangeDuration('90 Mins')}>
                        {' '}
                        90 Mins
                      </Button>
                    </Grid>
                    <Grid item xs={2}>
                      <Button onClick={(e) => handleChangeDuration('120 Mins')}>
                        {' '}
                        120 Mins
                      </Button>
                    </Grid>
                  </Grid>
                </Popover>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    border: 1,
                    borderColor: 'primary.main',
                    borderRadius: '30px',
                    m: 2,
                    mr: 0,
                  }}
                >
                  <IconButton
                    onClick={handleAmClick}
                    sx={{
                      height: '40px',
                      width: '40px',
                      color: amPm === 'am' ? 'white' : 'primary.main',
                      bgcolor: amPm === 'am' ? 'primary.main' : 'inherit',
                      m: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '15px',
                        lineHeight: '22.5px',
                      }}
                    >
                      AM
                    </Typography>
                  </IconButton>
                  <IconButton
                    onClick={handlePmClick}
                    sx={{
                      height: '40px',
                      width: '40px',
                      color: amPm === 'pm' ? 'white' : 'primary.main',
                      bgcolor: amPm === 'pm' ? 'primary.main' : 'inherit',
                      m: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '15px',
                        lineHeight: '22.5px',
                      }}
                    >
                      PM
                    </Typography>
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
            <Grid container>{timeSlots}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}
