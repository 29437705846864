import { Grid } from '@mui/material'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import SessionMain from '../components/session_page/SessionMain'

function SessionPage() {
  return (
    <Grid container>
      <Header />
      <SessionMain />
      <Footer />
    </Grid>
  )
}

export default SessionPage
