import * as React from 'react'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import {
  Grid,
  Typography,
  Divider,
  Chip,
  TextField,
  Autocomplete,
} from '@mui/material'
import BorderSelectButton from '../../buttons/BorderSelectButton'
import { Close, FilterListOutlined, InfoOutlined } from '@mui/icons-material'
import { useState } from 'react'
import FilterTextField from '../FilterTextField'
import { useDispatch, useSelector } from 'react-redux'
import {
  setApiFilters,
  setPriceHigh,
  setPriceLow,
  setSessionCity,
  setTrainerDisplayName,
  setTrainerGender,
  setTrainerStyles,
  setWhere,
} from '../../../redux/slices/filtersSlice'
import citiesList from '../../../constants/citiesList'
import { useParams } from 'react-router-dom'
import mixpanel from 'mixpanel-browser'

export default function FilterDrawer() {
  const { category } = useParams()

  const [open, setOpen] = React.useState(false)
  const {
    where,
    priceLow,
    priceHigh,
    trainerDisplayName,
    trainerStyles,
    trainerGender,
    isFavorites,
    city,
  } = useSelector((state) => state.filters)
  // TODO: Add state for each filter
  const dispatch = useDispatch()
  const [who, setWho] = useState([])
  const [gear, setGear] = useState([])

  const showResults = () => {
    const updatedFilters = {
      where,
      priceLow,
      priceHigh,
      trainerDisplayName,
      trainerStyles,
      trainerGender,
      isFavorites,
      city,
    }
    const apiFilterObject = {}
    if (where.length > 0) {
      if (where.includes('outdoors')) {
        apiFilterObject.sessionOutdoorLocationId = {
          attributeExists: true,
        }
      }
      if (where.includes('indoors')) {
        apiFilterObject.sessionIndoorLocationId = {
          attributeExists: true,
        }
      }
      if (where.includes('commute')) {
        apiFilterObject.commuteToMember = { eq: true }
      }
      if (where.includes('virtual')) {
        apiFilterObject.virtual = { eq: true }
      }
    }
    if (priceLow && priceHigh) {
      apiFilterObject.sixtyMinsPrice = {
        between: [parseFloat(priceLow), parseFloat(priceHigh)],
      }
    } else if (priceLow) {
      apiFilterObject.sixtyMinsPrice = { ge: parseFloat(priceLow) }
    } else if (priceHigh) {
      apiFilterObject.sixtyMinsPrice = { le: parseFloat(priceHigh) }
    }
    if (trainerStyles.length > 0) {
      trainerStyles.forEach((style) => {
        apiFilterObject[style] = { gt: 0 }
      })
      //apiFilterObject.trainerStyles = { in: trainerStyles }
    }
    if (trainerGender) {
      if (trainerGender !== 'all') {
        apiFilterObject.trainerGender = { eq: trainerGender }
      }
    }
    if (isFavorites) {
      apiFilterObject.isFavorites = { eq: isFavorites }
    }
    if (city) {
      apiFilterObject.city = { eq: city.label }
    }
    if (category) {
      apiFilterObject.category = { eq: category }
    }
    if(trainerDisplayName){
      apiFilterObject.displayName = { contains: trainerDisplayName }
    }
    mixpanel.track(`Filtered Sessions: ${JSON.stringify(apiFilterObject)}`, {
      'Event Type': 'Filtered Sessions',
    })
    dispatch(setApiFilters(apiFilterObject))
    console.log('apiFilterObject: ', apiFilterObject)
    toggleDrawer()
  }

  const toggleDrawer = () => {
    console.log('toggleDrawer')
    setOpen(!open)
    // SHOULD WE CHANGE A FILTERING STATUS HERE TO SAY WHETHER IS SEARCHING OR NOT?
  }

  const handleWhereClicked = (value) => {
    var whereCopy = [...where]
    if (whereCopy.includes(value)) {
      whereCopy = whereCopy.filter((w) => w !== value)
    } else {
      whereCopy = [...whereCopy, value]
    }
    console.log(' ', whereCopy)
    mixpanel.track(`Filtered Sessions by where: ${JSON.stringify(whereCopy)}`, {
      'Event Type': 'Filtered Sessions',
    })
    dispatch(setWhere(whereCopy))
  }

  const handleChangePrice = (value, minOrMax) => {
    if (minOrMax === 'min') {
      mixpanel.track(
        `Filtered Sessions by Min price: ${JSON.stringify(value)}`,
        {
          'Event Type': 'Filtered Sessions',
        }
      )
      dispatch(setPriceLow(value))
    } else {
      mixpanel.track(
        `Filtered Sessions by Max price: ${JSON.stringify(value)}`,
        {
          'Event Type': 'Filtered Sessions',
        }
      )
      dispatch(setPriceHigh(value))
    }
  }
  const handleChangeGender = (value) => {
    if (value === 'any' && trainerGender === 'any') {
      dispatch(setTrainerGender(null))
    } else if (value === 'any' && trainerGender !== 'any') {
      mixpanel.track(`Filtered Sessions by Gender: ${JSON.stringify(value)}`, {
        'Event Type': 'Filtered Sessions',
      })
      dispatch(setTrainerGender('any'))
    } else if (value === 'm' && trainerGender !== 'm') {
      mixpanel.track(`Filtered Sessions by Gender: ${JSON.stringify(value)}`, {
        'Event Type': 'Filtered Sessions',
      })
      dispatch(setTrainerGender('m'))
    } else if (value === 'm' && trainerGender === 'm') {
      dispatch(setTrainerGender(null))
    } else if (value === 'f' && trainerGender !== 'f') {
      mixpanel.track(`Filtered Sessions by Gender: ${JSON.stringify(value)}`, {
        'Event Type': 'Filtered Sessions',
      })
      dispatch(setTrainerGender('f'))
    } else if (value === 'f' && trainerGender === 'f') {
      dispatch(setTrainerGender(null))
    }
  }

  const handleChangeTrainingStyle = (value) => {
    var trainerStylesCopy = [...trainerStyles]
    console.log('trainerStylesCopy', trainerStylesCopy)
    if (trainerStylesCopy.includes(value)) {
      trainerStylesCopy = trainerStylesCopy.filter((w) => w !== value)
    } else {
      trainerStylesCopy = [...trainerStylesCopy, value]
    }
    console.log('trainerStyles', trainerStylesCopy)
    mixpanel.track(
      `Filtered Sessions by Trainer Style: ${JSON.stringify(
        trainerStylesCopy
      )}`,
      {
        'Event Type': 'Filtered Sessions',
      }
    )
    dispatch(setTrainerStyles(trainerStylesCopy))
  }

  const handleChangeCity = (value) => {
    mixpanel.track(`Filtered Sessions by City: ${JSON.stringify(value)}`, {
      'Event Type': 'Filtered Sessions',
    })
    dispatch(setSessionCity(value))
  }

  const list = () => (
    <Box sx={{ width: 400, pt: '75px' }} role="presentation">
      <Grid container sx={{ p: 1, pb: 0 }}>
        <Grid item sx={{ ml: 'auto' }}>
          <Close onClick={toggleDrawer} />
        </Grid>
      </Grid>
      <Grid container sx={{ p: 2, pt: 0 }}>
        <Typography
          sx={{ fontWeight: 700, fontSize: '26px', color: 'primary.main' }}
        >
          Filters and Preferences
        </Typography>
      </Grid>

      <Divider textAlign="left">
        <Typography
          sx={{ color: 'gunMetal.main', fontWeight: 700, fontSize: '17px' }}
        >
          WHERE
        </Typography>
      </Divider>

      <Grid
        container
        sx={{ justifyContent: 'space-around', mt: 1, pl: 3, pr: 3 }}
        spacing={1}
      >
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            disablePortal
            id="combo-box-demo"
            value={city?.label || ''}
            options={citiesList}
            onChange={(event, value) => handleChangeCity(value)}
            renderInput={(params) => (
              <TextField {...params} label="City" value={city?.label || ''} />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Outdoors"
            active={where.includes('outdoors')}
            onClick={() => handleWhereClicked('outdoors')}
          />
        </Grid>
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Indoors"
            active={where.includes('indoors')}
            onClick={() => handleWhereClicked('indoors')}
          />
        </Grid>
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Commute"
            active={where.includes('commute')}
            onClick={() => handleWhereClicked('commute')}
          />
        </Grid>
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Virtual"
            active={where.includes('virtual')}
            onClick={() => handleWhereClicked('virtual')}
          />
        </Grid>

        <Grid item xs={6}></Grid>
      </Grid>

      <Divider textAlign="left">
        <Typography
          sx={{ color: 'gunMetal.main', fontWeight: 700, fontSize: '17px' }}
        >
          HOW MUCH
        </Typography>
      </Divider>

      <Grid
        container
        sx={{ justifyContent: 'space-around', mt: 1, p: 1, pl: 3, pr: 3 }}
        spacing={1}
      >
        <Grid item xs={6}>
          <FilterTextField
            fullWidth
            placeholder={'MIN PRICE'}
            onChange={(e) => handleChangePrice(e.target.value, 'min')}
          />
        </Grid>
        <Grid item xs={6}>
          <FilterTextField
            fullWidth
            placeholder={'MAX PRICE'}
            onChange={(e) => handleChangePrice(e.target.value, 'max')}
          />
        </Grid>
      </Grid>

      <Divider textAlign="left">
        <Typography
          sx={{ color: 'gunMetal.main', fontWeight: 700, fontSize: '17px' }}
        >
          WHO
        </Typography>
      </Divider>

      <Grid
        container
        spacing={1}
        sx={{ justifyContent: 'space-between', mt: 1, p: 1, pl: 3, pr: 3 }}
      >
        <Grid item xs={12}>
          <Typography>Trainer Name</Typography>
        </Grid>
        <Grid item xs={12}>
          <FilterTextField
            fullWidth
            disablePortal
            id="trainer-name-search"
            value={trainerDisplayName || ''}
            onChange={(event, value) =>{dispatch(setTrainerDisplayName(event.target.value))}}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Trainer Preference</Typography>
        </Grid>
        {/* <Grid item justifyContent="flex-start" xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Any"
            active={trainerGender === 'any'}
            onClick={() => handleChangeGender('any')}
          />
        </Grid> */}
        {/* <Grid item xs={6}></Grid> */}
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Female"
            active={trainerGender === 'any' || trainerGender === 'f'}
            onClick={() => handleChangeGender('f')}
          />
        </Grid>
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Male"
            active={trainerGender === 'any' || trainerGender === 'm'}
            onClick={() => handleChangeGender('m')}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Typography>Trainer Style</Typography>
        </Grid>
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Cheerleader"
            active={trainerStyles.includes('cheerleader')}
            onClick={() => handleChangeTrainingStyle('cheerleader')}
          />
        </Grid>
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Drill Sargeant"
            active={trainerStyles.includes('drillSargeant')}
            onClick={() => handleChangeTrainingStyle('drillSargeant')}
          />
        </Grid>
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Workout Buddy"
            active={trainerStyles.includes('workoutBuddy')}
            onClick={() => handleChangeTrainingStyle('workoutBuddy')}
          />
        </Grid>
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Professor"
            active={trainerStyles.includes('professor')}
            onClick={() => handleChangeTrainingStyle('professor')}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 1, p: 1 }}>
        <Button
          variant="contained"
          sx={{ color: 'white' }}
          onClick={showResults}
        >
          Show Results
        </Button>
      </Grid>
    </Box>
  )

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={toggleDrawer}
        sx={{ color: 'white' }}
      >
        <FilterListOutlined sx={{ pr: 1 }} />
        Filters
      </Button>
      <SwipeableDrawer
        anchor={'left'}
        open={open}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        {list()}
      </SwipeableDrawer>
    </>
  )
}
