import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import { useRouteError } from 'react-router-dom'
import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import { useGetPostsQuery } from '../redux/api/api'

export default function ErrorPage() {
  const routeError = useRouteError()
  //console.error(routeError)

  // const {
  //   data: sessions,
  //   isLoading,
  //   isSuccess,
  //   isError,
  //   error,
  // } = useGetPostsQuery()

  // let content

  // if (isLoading) {
  //   content = <CircularProgress text="Loading..." />
  // } else if (isSuccess) {
  //   console.log('data: ', sessions)
  //   content = sessions.map((session) => (
  //     <Typography>key={session.session_name}</Typography>
  //   ))
  // } else if (isError) {
  //   console.log('ERROR HIT GERE: ', error)
  //   content = (
  //     <div>
  //       {error.data.error.header.toString()}:
  //       {error.data.error.message.toString()}
  //     </div>
  //   )
  // }

  return (
    <Grid container>
      <Header />
      <Grid
        container
        style={{
          background:
            'linear-gradient(225.09deg, #00C7C7 14.44%, #0BDE8F 85.56%)',
          width: '100%',
          height: '50vh',
          margin: 0,
          padding: 0,
        }}
        sx={{ m: 2 }}
      >
        <Box
          sx={{
            bgcolor: 'white',
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',

            pt: 4,
            pb: 4,
          }}
        >
          {/*content*/}
          <Grid container sx={{ height: '100%' }}>
            <Grid container>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '64px',
                  lineHeight: '96px',
                  color: 'gunMetal.main',
                }}
              >
                {' '}
                404
              </Typography>
            </Grid>
            <Grid container sx={{ mb: 4 }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '34px',
                  lineHeight: '51px',
                  color: 'gunMetal.main',
                  textAlign: 'center',
                }}
              >
                {' '}
                Oops! Page not found
              </Typography>
            </Grid>
            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item>
                <Button variant="outlined">
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: '15px',
                      lineHeight: '22.5px',
                      color: 'gunMetal.main',
                      textAlign: 'center',
                      m: 1,
                    }}
                  >
                    Contact Support
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained">
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: '15px',
                      lineHeight: '22.5px',
                      color: 'gunMetal.main',
                      textAlign: 'center',
                      m: 1,
                    }}
                  >
                    Return Home
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  )
}
