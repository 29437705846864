import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import SessionMain from '../components/session_page/SessionMain'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { useGetMemberByIdQuery } from '../redux/api/api'
import theme from '../styles/theme'

function BookingSuccess() {
  const large = useMediaQuery(theme.breakpoints.up('sm'))
  const navigate = useNavigate()
  const userEmail = useSelector((state) => state.auth?.cognitoUser?.email)

  const userId = useSelector((state) => state.auth.cognitoUser?.sub)
  const { data: currentUser } = useGetMemberByIdQuery(userId)

  return (
    <Grid container>
      <Header />
      <Grid
        container
        style={{
          background:
            'linear-gradient(225.09deg, #00C7C7 14.44%, #0BDE8F 85.56%)',
          width: '100%',
          margin: 0,
        }}
        sx={{ m: large ? 2 : 0, p: large ? 4 : 2, alignItems: 'flex-start' }}
      >
        <Box
          sx={{
            bgcolor: 'white',

            justifyContent: 'center',
            alignItems: 'center',

            p: 4,
            borderRadius: '12px',
          }}
        >
          {/*content*/}
          <Grid container sx={{ height: '100%' }}>
            <Grid container>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: large ? '64px' : '32px',
                  lineHeight: large ? '96px' : '48px',
                  color: 'gunMetal.main',
                  textAlign: large ? 'center' : 'left',
                }}
              >
                {' '}
                Your Request Has Been Sent!
              </Typography>
            </Grid>
            <Grid container sx={{ mb: 2 }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: large ? '24px' : '16px',
                  lineHeight: large ? '51px' : '22.5px',
                  color: 'gunMetal.main',
                  textAlign: large ? 'center' : 'left',
                }}
              >
                {' '}
                Check out the "pending" tab on your workouts page for updates on
                status, reschedule requests and more booking details.
              </Typography>
            </Grid>
            <Grid container sx={{ mb: 2 }}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '18px',
                  color: 'gunMetal.main',
                  textAlign: large ? 'center' : 'left',
                }}
              >
                Keep in touch with your trainer on your messages page
              </Typography>
            </Grid>

            <Grid container sx={{ mb: 4, gap: 2 }}>
              <Button variant="contained" onClick={() => navigate(`/workouts`)}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '15px',
                    lineHeight: '22.5px',
                    color: 'gunMetal.main',
                    textAlign: 'center',
                    m: 1,
                    color: 'white',
                  }}
                >
                  Go To Your Workouts
                </Typography>
              </Button>
              <Button variant="outlined" onClick={() => navigate('/messages')}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '15px',
                    lineHeight: '22.5px',
                    color: 'gunMetal.main',
                    textAlign: 'center',
                    m: 1,
                  }}
                >
                  Go To Messages
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Footer />
    </Grid>
  )
}

export default BookingSuccess
