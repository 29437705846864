import * as React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CardActionArea, Chip, Grid, IconButton } from '@mui/material'
import {
  DriveEta,
  Favorite,
  HeartBrokenTwoTone,
  LocationOn,
  LocationOnOutlined,
  Phone,
  PhoneAndroidOutlined,
  RocketLaunchOutlined,
  Star,
} from '@mui/icons-material'
import S3Image from '../S3Image'
import { ReactComponent as TrainerStarRating } from '../../assets/icons/trainerStarRating.svg'
import { useGetTrainerByIdQuery } from '../../redux/api/api'
import { useNavigate } from 'react-router-dom'

export default function SessionCard(props) {
  const navigate = useNavigate()
  const { session } = props
  const [favorited, setFavorited] = React.useState(false)

  const trainerResp = useGetTrainerByIdQuery(session?.trainerSessionsId, {
    keepUnusedDataFor: 100000,
    pollingInterval: 100000,
  })

  return (
    <Card
      sx={{
        width: '294px',
        height: '365px',
        borderRadius: '16px',
      }}
    >
      <CardActionArea
        onClick={() => {
          console.log('session clicked')
          navigate(`/session/${session?.id}`)
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '73px',
            height: '24px',
            left: '207.55px',
            top: '13px',
          }}
        >
          <Box
            sx={{
              position: ' absolute',
              padding: '5px',
              textAlign: ' center',
              background: ' #000000',
              borderRadius: ' 50px',
            }}
          >
            <Typography
              sx={{
                fontStyle: ' normal',
                fontWeight: ' 700',
                fontSize: ' 14px',
                lineHeight: ' 21px',
                /* identical to box height */

                color: ' #FFFFFF',
              }}
            >
              {' '}
              ${session?.sixtyMinsPrice}/hr
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            left: '4.76%',
            right: '40.12%',
            top: '3.57%',
            bottom: '89.85%',
          }}
        >
          <Box
            sx={{
              position: ' absolute',
              padding: '5px',
              textAlign: ' center',
              background: ' #000000',
              borderRadius: ' 50px',
            }}
          >
            <Typography
              sx={{
                fontStyle: ' normal',
                fontWeight: ' 700',
                fontSize: ' 18px',
                lineHeight: ' 21px',
                /* identical to box height */

                color: ' #FFFFFF',
              }}
            >
              {session.city}
              {/* {session?.city}, {session?.state} */}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            left: '0%',
            right: '0%',
            top: '35.39%',
            bottom: '13.31%',

            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
            borderRadius: '0px 0px 16px 16px',
          }}
        ></Box>
        <Box
          sx={{
            position: ' absolute',
            width: '100%',

            top: ' 82.17%',
            bottom: ' 0%',
            background: '#000000',
          }}
        >
          <Box
            sx={{
              display: ' flex',
              flexDirection: ' column',
              justifyContent: ' center',
              alignItems: ' center',
              padding: ' 0px',

              position: ' absolute',
              width: ' 46px',
              height: ' 39px',
              left: ' 212.14px',
              top: ' 9.18px',
            }}
          >
            <Box
              sx={{
                /* Auto layout */

                display: ' flex',
                flexDirection: ' row',
                alignItems: ' center',
                padding: ' 0px',
                gap: ' 4px',

                width: ' 46px',
                height: ' 26px',

                borderRadius: ' 30px 0px 0px 30px',

                /* Inside auto layout */

                flex: ' none',
                order: ' 0',
                flexGrow: ' 0',
              }}
            >
              <TrainerStarRating />
              <Typography
                sx={{
                  fontFamily: ' Poppins',
                  fontStyle: ' normal',
                  fontWeight: ' 700',
                  fontSize: ' 17px',
                  lineHeight: ' 26px',
                  /* identical to box height */

                  color: '#FFFFFF',
                }}
              >
                4.8
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: ' flex',
              flexDirection: ' row',
              alignItems: ' center',
              padding: ' 0px',
              gap: ' 18px',

              position: ' absolute',
              width: ' 120px',
              height: ' 38px',
              left: '10px',
              top: ' 9.68px',
            }}
            onClick={(e) => {
              e.preventDefault()
              navigate(`/trainer/${session?.trainerSessionsId}`)
            }}
          >
            <S3Image
              imagePath={`${session?.trainerSessionsId}/${trainerResp?.data?.profilePhotoUrl}`}
              //imagePath={`${session?.trainerSessionsId}/${session?.trainer?.profilePhotoUrl}`}
              dimensions={{
                width: '38px',
                height: '38px',
                borderRadius: '50%',
              }}
            />
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontStyle: ' normal',
                fontWeight: ' 500',
                fontSize: ' 14px',
                lineHeight: ' 21px',
                /* identical to box height */

                textAlign: ' center',

                color: ' #FFFFFF',
              }}
            >
              {//trainerResp?.data?.displayName
              session?.trainer?.displayName
              }
            </Typography>
          </Box>
        </Box>
        <Typography
          sx={{
            position: ' absolute',
            left: ' 5.44%',
            right: ' 4.76%',
            top: ' 73.25%',
            bottom: ' 19.07%',

            fontStyle: ' normal',
            fontWeight: ' 700',
            fontSize: ' 24px',
            lineHeight: ' 28px',
            letterSpacing: ' -0.04em',

            color: ' #FFFFFF',

            mixBlendMode: ' hard-light',
          }}
        >
          {' '}
          {session ? session?.title : 'Title with Trainer'}
        </Typography>
        {session?.sessionPhotos &&
        session?.sessionPhotos?.length > 0 &&
        session.sessionPhotos[0] ? (
          <S3Image
            imagePath={`${session.trainerSessionsId}/sessions/${session.id}/${session.sessionPhotos[0]}`}
            dimensions={{
              width: '294px',
              height: '365px',
              borderRadius: '16px',
            }}
          />
        ) : (
          <Box
            sx={{
              width: '294px',
              height: '365px',
              borderRadius: '16px',
              backgroundColor: 'lightGrey',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography>Incomplete Session</Typography>
          </Box>
        )}

        {/* <CardActionArea onClick={props.onClick}>
          <Box style={{ backgroundColor: 'white' }}></Box>

          <CardContent
            sx={{ p: 0, m: 0, width: '100%' }}
            style={{ padding: 0 }}
          >
            <Box sx={{ backgroundColor: 'primary.light', p: 0, pl: 1 }}>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{ fontWeight: 700, color: 'gunMetal.main' }}
              >
                {session ? session?.title : 'Title with Trainer'}
              </Typography>
            </Box>
            <Grid
              container
              sx={{ width: '100%', m: 0, p: 1 }}
              style={{ justifyContent: 'space-between' }}
            >
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Grid
                  container
                  style={{
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item>
                    <Chip
                      label={session ? session.category : 'Running'}
                      sx={{
                        backgroundColor: 'gunMetal.main',
                        color: 'white',
                        ml: 1,
                        fontWeight: 600,
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <Grid container style={{ justifyContent: 'left' }}>
                      <Star
                        fontSize="small"
                        sx={{ mr: 0.5, color: 'gunMetal.main' }}
                      />
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ fontWeight: 700 }}
                      >
                        4.8
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ p: 1 }}>
                  <Grid container style={{ justifyContent: 'left' }}>
                    <LocationOn fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography variant="body2" color="text.secondary">
                      Location , ST - 2 miles away
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ justifyContent: 'space-between', p: 1, pt: 0, pr: 0 }}
              >
                <Grid item>
                  <Box>
                    <Typography>
                      <strong>${session ? session?.defaultPrice : 47}</strong>
                      /hour
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Grid container>
                    <DriveEta sx={{ mr: 1 }} />
                    <RocketLaunchOutlined sx={{ mr: 1 }} />
                    <PhoneAndroidOutlined />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea> */}
      </CardActionArea>
    </Card>
  )
  /* return (
    <Box sx={{ maxWidth: '345px' }}>
      <IconButton
        style={{
          position: 'absoulte',
          top: '35px',
          left: '90%',
          height: 0,
          width: 0,
          zIndex: 2,
          padding: 0,
          margin: 0,
        }}
        onClick={() => setFavorited(!favorited)}
      >
        <Favorite
          fontSize="large"
          sx={{
            color: favorited ? 'primary.main' : 'gunMetal.main',
            opacity: favorited ? 1 : 0.75,
          }}
        />
      </IconButton>
      <Card sx={{ maxWidth: 345, borderRadius: '20px' }}>
        <CardActionArea onClick={props.onClick}>
          <Box style={{ backgroundColor: 'white' }}>
            <CardMedia
              component="img"
              height="140"
              src={
                session ? 'https://via.placeholder.com/160' : session?.imgUrl
              }
              alt="green iguana"
              style={{
                zIndex: 1,
              }}
            ></CardMedia>
          </Box>

          <CardContent
            sx={{ p: 0, m: 0, width: '100%' }}
            style={{ padding: 0 }}
          >
            <Box sx={{ backgroundColor: 'primary.light', p: 0, pl: 1 }}>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{ fontWeight: 700, color: 'gunMetal.main' }}
              >
                {session ? session?.title : 'Title with Trainer'}
              </Typography>
            </Box>
            <Grid
              container
              sx={{ width: '100%', m: 0, p: 1 }}
              style={{ justifyContent: 'space-between' }}
            >
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Grid
                  container
                  style={{
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item>
                    <Chip
                      label={session ? session.category : 'Running'}
                      sx={{
                        backgroundColor: 'gunMetal.main',
                        color: 'white',
                        ml: 1,
                        fontWeight: 600,
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <Grid container style={{ justifyContent: 'left' }}>
                      <Star
                        fontSize="small"
                        sx={{ mr: 0.5, color: 'gunMetal.main' }}
                      />
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ fontWeight: 700 }}
                      >
                        4.8
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ p: 1 }}>
                  <Grid container style={{ justifyContent: 'left' }}>
                    <LocationOn fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography variant="body2" color="text.secondary">
                      Location , ST - 2 miles away
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ justifyContent: 'space-between', p: 1, pt: 0, pr: 0 }}
              >
                <Grid item>
                  <Box>
                    <Typography>
                      <strong>${session ? session?.defaultPrice : 47}</strong>
                      /hour
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Grid container>
                    <DriveEta sx={{ mr: 1 }} />
                    <RocketLaunchOutlined sx={{ mr: 1 }} />
                    <PhoneAndroidOutlined />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  ) */
}
