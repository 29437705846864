import { Button, Grid, TextField, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { setUserStatsField } from '../../redux/slices/formsSlice'
const Stat = (props) => {
  const dispatch = useDispatch()
  const editMode = useSelector((state) => state.ui.editMode)
  const statsFields = useSelector((state) => state.forms.statsForm)
  const { title, value, unit, field } = props
  const handleUpdateUserStat = (input) => {
    dispatch(setUserStatsField(input))
  }
  // TODO: ADD IN UPDATE USER CALL WITH STATS ID ON CREATE USER
  const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: '20px',
    width: 144,
    height: 72,
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '15px',
    padding: '0 8x',
    margin: '8px 16px 8px 0px',
    '&:disabled': {
      backgroundColor: theme.palette.gunMetal.main,
      color: 'white',
    },
  }))
  return (
    <Grid>
      <Typography>{title}</Typography>
      {editMode ? (
        <TextField
          variant="outlined"
          value={statsFields[field]}
          onChange={(e) =>
            handleUpdateUserStat({ field, value: e.target.value })
          }
          style={{ width: '144px', height: '72px', borderRadius: '20px' }}
        />
      ) : (
        <StyledButton variant="contained" disabled>
          {statsFields[field]} {unit}
        </StyledButton>
      )}
    </Grid>
  )
}

export default Stat
