import { Grid, Paper, Typography } from '@mui/material'
import ProfileImageComponent from '../ProfileImageComponent'
import { useSelector } from 'react-redux'
import SubTitleB from '../../text/SubTitleB'
import Star from './Star'
import SubTitleA from '../../text/SubTitleA'
import { ReactComponent as FacilityIcon } from '../../../assets/icons/facility.svg'
import { ReactComponent as CommuteIcon } from '../../../assets/icons/commute.svg'
import CellphoneIcon from '../../../assets/icons/cellphone.png'

import OutdoorIcon from '../../../assets/icons/outdoor.png'

const TrainerGeneralInfo = (props) => {
  const { data } = props
  const editMode = useSelector((state) => state.ui.editMode)
  const trainerLocation = 'New York, NY'
  return (
    <>
      <Grid
        container
        style={{ width: '100%', justifyContent: 'space-between' }}
      >
        <Paper
          style={{
            //minHeight: 620,
            width: '100%',
            borderRadius: 16,
            marginTop: '16px',
          }}
        >
          <Grid
            container
            style={{
              padding: '32px 0px',
              justifyContent: 'flex-start',
              alignItems: 'normal',
            }}
          >
            <Grid item xs={3}>
              <Grid container>
                {' '}
                <ProfileImageComponent editMode={editMode} />
              </Grid>
              <Grid container sx={{ p: 2 }}>
                <SubTitleB text={trainerLocation} />
              </Grid>
              <Grid container>
                <Paper
                  sx={{
                    width: '100px',
                    backgroundColor: 'primary.main',
                    borderRadius: '16px',
                    padding: '3px',
                  }}
                >
                  <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item sx={{ alignItems: 'center' }}>
                      {' '}
                      <Star width="22px" height="22px" />
                    </Grid>
                    <Grid item sx={{ alignItems: 'center' }}>
                      <Typography sx={{ fontWeight: 700 }}>4.8</Typography>
                    </Grid>
                    <Grid item sx={{ alignItems: 'center' }}>
                      <Typography>(194)</Typography>{' '}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid
                container
                sx={{ pt: '50px', justifyContent: 'flex-start' }}
                spacing={1}
              >
                <Grid item xs={12}>
                  <SubTitleA inline text="Years as a trainer"></SubTitleA>{' '}
                  {new Date().getFullYear() - data?.yearStarted}
                </Grid>
                <Grid item xs={12}>
                  <SubTitleA
                    inline
                    text="My favorite aspect of training is"
                  ></SubTitleA>{' '}
                  {data?.favoriteAspect}
                </Grid>
                <Grid item xs={12}>
                  <SubTitleA
                    inline
                    text="Key takeaway for my clients"
                  ></SubTitleA>{' '}
                  {data?.importantTakeAway}
                </Grid>
                <Grid item xs={12}>
                  <SubTitleA
                    inline
                    text="I call my tribe or trainees"
                  ></SubTitleA>{' '}
                  {data?.nameForTrainees}
                </Grid>
                <Grid item xs={12}>
                  <SubTitleA
                    inline
                    text="My certifications include"
                  ></SubTitleA>{' '}
                  {data?.certificationsDescription}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid
                container
                sx={{ pt: '50px', justifyContent: 'flex-start' }}
                spacing={1}
              >
                <Grid container sx={{ justifyContent: 'flex-start', p: 2 }}>
                  <FacilityIcon />{' '}
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: '14px',
                      pl: 2,
                      color: 'primary.main',
                    }}
                  >
                    {' '}
                    Teaches out of facility
                  </Typography>
                </Grid>
                <Grid container sx={{ justifyContent: 'flex-start', p: 2 }}>
                  <img src={OutdoorIcon} />
                  <Typography
                    sx={{
                      color: 'primary.main',
                      fontWeight: 700,
                      fontSize: '14px',
                      pl: 2.5,
                    }}
                  >
                    {' '}
                    Meets Outdoors
                  </Typography>
                </Grid>
                <Grid container sx={{ justifyContent: 'flex-start', p: 2 }}>
                  <CommuteIcon />
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: '14px',
                      pl: 2,
                      color: 'primary.main',
                    }}
                  >
                    {' '}
                    Commutes to You
                  </Typography>
                </Grid>
                <Grid container sx={{ justifyContent: 'flex-start', p: 2 }}>
                  <img src={CellphoneIcon} />
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: '14px',
                      pl: 3.5,
                      color: 'primary.main',
                    }}
                  >
                    {' '}
                    Offers Virtual
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}

export default TrainerGeneralInfo
