import * as React from 'react'
import Box from '@mui/material/Box'

import { Grid, Typography, Divider, Chip } from '@mui/material'

import Profile from '../../assets/profile_picture.png'
import { formatFromDB } from '../../helpers/datetime'
import S3Image from '../S3Image'
import dayjs from 'dayjs'
import { useGetMemberByIdQuery } from '../../redux/api/api'

export default function ChatPreview(props) {
  const {
    outlined,
    selected = false,
    trainer,
    onClick,
    chat,
    partnerID,
  } = props
  const { data: partnerData } = useGetMemberByIdQuery(partnerID, {
    skip: !partnerID,
  })

  return (
    <Box
      sx={{
        border: outlined ? 1 : 0,
        borderColor: 'gunMetal.main',
        width: '100%',
        borderRadius: 3,
        p: 1,
        m: 1,
        backgroundColor: selected ? 'primary.light' : 'white',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Grid container justifyContent="space-between" alignItems="start">
        <Grid item sx={{ p: 1, m: 0 }}>
          <Grid container>
            <S3Image
              imagePath={`${partnerID}/${partnerData?.profilePhotoUrl}`}
              dimensions={{
                width: '60px',
                height: '60px',
                borderRadius: '50%',
              }}
            />

            <Grid item sx={{ p: 1, m: 1 }}>
              <Grid container justifyContent="left">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: 15,
                    lineHeight: '22.5px',
                    color: 'gunMetal.main',
                  }}
                >
                  {partnerData?.displayName}
                </Typography>
              </Grid>
              <Grid container justifyContent="left">
                <Typography
                  sx={{
                    fontWeight: 300,
                    fontSize: 15,
                    lineHeight: '22.5px',
                    color: 'gunMetal.main',
                  }}
                >
                  {chat?.messages?.items[0]?.content}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ p: 1 }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 9,
              lineHeight: '13.5px',
              color: 'gunMetal.main',
            }}
          >
            {formatFromDB(
              chat?.messages?.items[0]?.createdAt,
              'MMM Do YYYY'
            ).toUpperCase()}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
