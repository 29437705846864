import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import TitleC from '../text/TitleC'
import IntroduceMember from './IntroduceMember'
import { ReactComponent as LocationIcon } from '../../assets/icons/location_pin.svg'

import { ReactComponent as UploadProfileIcon } from '../../assets/icons/upload_profile.svg'
import { useEffect, useState } from 'react'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'
import FitnessGoals from './FitnessGoals'
import PreferredTrainingDaysTime from './PreferredTrainingDaysTime'
import ChooseLocation from './ChooseLocation'
import AboutMember from './AboutMember'
import ChooseWorkouts from './ChooseWorkouts'
import EmergencyContacts from './EmergencyContacts'
import BackgroundCheck from './BackgroundCheck'
import MemberInfoB from './MemberInfoB'
import BorderSelectButton from '../buttons/BorderSelectButton'
import PhotoCropper from '../profile/PhotoCropper'
import ProfilePhotoUpload from '../profile/ProfileComponents/ProfilePhotoUpload'
import { setOnboardStep } from '../../redux/slices/uiSlice'
import ChooseTrainingStyles from './ChooseTrainingStyles'
import Background from './Background'
import SubTitleA from '../text/SubTitleA'

export default function OnboardPages() {
  const dispatch = useDispatch()
  const onboardOpen = useSelector((state) => state.ui.onboardOpen)
  const onboardStep = useSelector((state) => state.ui.onboardStep)
  const [crimeCheck, setCrimeCheck] = useState()
  const nextPage = () => {
    dispatch(setOnboardStep(onboardStep + 1))
  }
  const DarkSubtitle = (props) => {
    const { text, onClick } = props
    return (
      <>
        <Typography
          sx={{
            color: 'gunMetal.main',
            fontWeight: 700,
            fontSize: 17,
            lineHeight: '25.5px',
          }}
          onClick={onClick}
        >
          {text}
        </Typography>
      </>
    )
  }
  const LightSubtitle = (props) => {
    const { text, onClick } = props
    return (
      <>
        <Typography
          sx={{
            color: 'gunMetal.main',
            fontWeight: 400,
            fontSize: 15,
            lineHeight: '22.5px',
          }}
          onClick={onClick}
        >
          {' '}
          {text}
        </Typography>
      </>
    )
  }
  const IntroductionStep = () => {
    return (
      <>
        <Grid item xs={12}>
          <TitleC text="Introduce yourself!" />
        </Grid>
        <IntroduceMember onboarding />
      </>
    )
  }

  const ProfileUploadStep = () => {
    const openFile = () => {
      document.getElementById('profile-photo').click()
    }
    return <ProfilePhotoUpload onboard />
  }
  const CrimeStep = () => {
    return (
      <Grid container>
        <Grid item>
          <TitleC text="Have you ever been convicted of a voilent crime?" />
        </Grid>
        <Grid item>
          <FormControlLabel
            //value="true"
            control={<Checkbox />}
            label="Yes"
            checked={crimeCheck}
            onChange={async (e) => {
              setCrimeCheck(true)
              dispatch(
                setUserProfileModalField({ field: 'hasCrime', value: true })
              )
            }}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            //value="false"
            control={<Checkbox />}
            label="No"
            checked={!crimeCheck}
            onChange={async (e) => {
              setCrimeCheck(false)
              dispatch(
                setUserProfileModalField({ field: 'hasCrime', value: false })
              )
              
            }}
          />
        </Grid>
        {crimeCheck && (
          <Grid item xs={12}>
            <SubTitleA text="Please explain what the crime is" />
            <TextField
              sx={{
                height: 229,
                '& fieldset': {
                  borderRadius: '20px',
                  height: 229,
                },
              }}
              onChange={(e) => {
                dispatch(
                  setUserProfileModalField({
                    field: 'descriptionCrime',
                    value: e.target.value,
                  })
                )
              }}
              multiline
              fullWidth
              placeholder="Only if you want to, no pressure..."
            />
          </Grid>
        )}
      </Grid>
    )
  }

  return (
    <>
      {onboardStep === 1 && <IntroductionStep />}
      {onboardStep === 2 && <ChooseLocation />}
      {onboardStep === 3 && <ProfileUploadStep />}
      {onboardStep === 4 && <PhotoCropper onboard onUpload={nextPage} />}
      {onboardStep === 5 && <CrimeStep />}
    </>
  )
}
