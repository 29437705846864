import '../App.css'
import React, { useEffect, useState } from 'react'
import { Storage } from 'aws-amplify'
import { CircularProgress, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { updateSavedUrls } from '../redux/slices/uiSlice'

function S3Image({ imagePath, dimensions }) {
  const [image, setImage] = useState(null)
  const dispatch = useDispatch()
  const savedUrl = useSelector((state) => state.ui.savedUrls[imagePath])

  const getImageTest = async () => {
    try {
      console.log('getImageTest: ', imagePath)
      const signedURL = await Storage.get(imagePath, { download: true })
      const url = URL.createObjectURL(signedURL.Body)
      dispatch(updateSavedUrls({ imagePath, url }))
      setImage(url)
    } catch (error) {
      console.log('error loading image: ', error)
      dispatch(updateSavedUrls({ imagePath, url: 'empty' }))
      setImage('empty')
    }
  }
  useEffect(() => {
    if (!savedUrl) {
      getImageTest()
    } else {
      setImage(savedUrl)
    }
  }, [imagePath])

  return (
    <>
      {!image && <CircularProgress />}
      {image && (
        <img
          src={
            image === 'empty' ? 'https://via.placeholder.com/644x440' : image
          }
          className="Trigger-Detail-Image"
          alt={imagePath}
          style={{ objectFit: 'cover', ...dimensions }}
        />
      )}
    </>
  )
}

export default S3Image
