import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setBookingReviewModalOpen } from '../../redux/slices/uiSlice'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import { API, graphqlOperation } from 'aws-amplify'
import { createExcersize, updateUser } from '../../graphql/mutations'
import {
  useCreateExerciseMutation,
  useGetMemberByIdQuery,
  useUpdateBookingReviewMutation,
  useUpdateBookingStatusMutation,
} from '../../redux/api/api'
import { useEffect, useState } from 'react'
import { Add } from '@mui/icons-material'
import { setBookingReviewField } from '../../redux/slices/formsSlice'

export default function BookingReviewModal() {
  const open = useSelector((state) => state.ui.bookingReviewModalOpen)
  const cognitoUser = useSelector((state) => state.auth.cognitoUser)
  const [exercise, setExercise] = useState({
    name: '',
    duration: '',
    reps: '',
    weight: '',
  })

  const [updateBookingTrigger, updateBookingResponse] =
    useUpdateBookingReviewMutation()
  const [createExerciseTrigger, createExerciseResponse] =
    useCreateExerciseMutation()

  const currentUser = useSelector((state) => state.auth.cognitoUser)
  const { refetch } = useGetMemberByIdQuery(cognitoUser?.sub, {
    skip: !cognitoUser?.sub,
  })
  const bookingReviewForm = useSelector(
    (state) => state.forms.bookingReviewForm
  )

  const [addingExercise, setAddingExercise] = useState(false)

  const handleUpdateBookingReview = async () => {
    console.log('testing')
    try {
      const updatedBooking = await updateBookingTrigger({
        input: bookingReviewForm,
      }).unwrap()
      console.log('Updated Booking: ', updatedBooking)
    } catch (error) {
      console.log('Error Booking: ', error)
    }
  }
  const dispatch = useDispatch()
  const handleCloseModal = async () => {
    //TODO: dispatch update booking

    dispatch(setBookingReviewModalOpen(false))
  }

  const handleAddExercise = async () => {
    try {
      const { name, duration, reps, weight } = exercise
      const input = {
        bookingExcersizesId: bookingReviewForm.id,
        name,
        duration,
        reps,
        weight,
      }
      const exerciseResp = await createExerciseTrigger(input).unwrap()
      console.log('Exercise Response: ', exerciseResp)
      // dispatch Booking update and save profile
      //   const bookingUpdate = await API.graphql(
      //     graphqlOperation(createExcersize, {
      //       input: {
      //         name,
      //         duration,
      //         reps,
      //         LineWeight,
      //         bookingExcersizesId: sessionReviewFields.id,
      //       },
      //     })
      //   )
      //   const response = userUpdate.data.updateUser
      //   console.log(response)
      //   refetch()
    } catch (error) {
      console.log('Error Creating Exercise', error)
    }
    setAddingExercise(false)
  }
  useEffect(() => {
    console.log('BOOKING MODAL MOPEN', open)
  }, [])

  const handleChangeExerciseName = (event, field) => {
    setExercise({
      ...exercise,
      name: event.target.value,
    })
  }
  const handleChangeExerciseDuration = (event, field) => {
    setExercise({
      ...exercise,
      duration: event.target.value,
    })
  }
  const handleChangeExerciseWeight = (event, field) => {
    setExercise({
      ...exercise,
      weight: event.target.value,
    })
  }
  const handleChangeExerciseReps = (event, field) => {
    setExercise({
      ...exercise,
      reps: event.target.value,
    })
  }

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      PaperProps={{
        sx: {
          overflow: 'hidden',
          width: '100%',
          //maxWidth: '666px !important',
          //height: '100%',
          //maxHeight: '777px !important',
          alignItems: 'center',
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle style={{ width: '95%' }}>
        <Grid container sx={{ justifyContent: 'flex-end' }}>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              style={{ width: '20px', height: '20px', marginRight: '20px' }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ width: '100%', p: 4 }}>
        <Grid
          container
          sx={{ width: '100%', pl: 4, justifyContent: 'flex-start' }}
        >
          <Typography>Session Review</Typography>
        </Grid>
        <Grid
          container
          sx={{ width: '100%', pl: 4, justifyContent: 'flex-start' }}
        >
          <Typography>Exercise</Typography>
        </Grid>
        <Grid container sx={{ width: '100%', p: 4, pt: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Exercise</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={exercise.name}
              label="Exercise Name"
              onChange={handleChangeExerciseName}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {addingExercise && (
          <Grid container sx={{ width: '100%', p: 4, pt: 0 }}>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="DURATION"
                variant="outlined"
                value={exercise.duration}
                onChange={handleChangeExerciseDuration}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="WEIGHT"
                variant="outlined"
                value={exercise.weight}
                onChange={handleChangeExerciseWeight}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Reps"
                variant="outlined"
                value={exercise.reps}
                onChange={handleChangeExerciseReps}
              />
            </Grid>
          </Grid>
        )}
        <Grid
          container
          sx={{ width: '100%', pl: 4, pb: 2, justifyContent: 'flex-start' }}
        >
          <Button
            variant="outlined"
            onClick={
              addingExercise ? handleAddExercise : setAddingExercise(true)
            }
          >
            <Add />
            <Typography>
              {addingExercise ? 'SAVE EXERCISE' : 'ADD EXERCISE'}
            </Typography>
          </Button>
        </Grid>
        <Grid
          container
          sx={{ width: '100%', pl: 4, justifyContent: 'flex-start' }}
        >
          <Typography>Stats</Typography>
        </Grid>
        <Grid container sx={{ width: '100%', pl: 4, pt: 2, pr: 4 }}>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Avg heart rate"
              variant="outlined"
              value={bookingReviewForm.sessionMaxHR}
              onChange={(e) =>
                dispatch(
                  setBookingReviewField({
                    field: 'sessionMaxHR',
                    value: e.target.value,
                  })
                )
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Max heart rate"
              variant="outlined"
              value={bookingReviewForm.sessionAvgHR}
              onChange={(e) =>
                dispatch(
                  setBookingReviewField({
                    field: 'sessionAvgHR',
                    value: e.target.value,
                  })
                )
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Calories Burned"
              variant="outlined"
              value={bookingReviewForm.sessionCalories}
              onChange={(e) =>
                dispatch(
                  setBookingReviewField({
                    field: 'sessionCalories',
                    value: e.target.value,
                  })
                )
              }
            />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ width: '100%', p: 4, pb: 2, justifyContent: 'flex-start' }}
        >
          <Typography>Trainer Notes</Typography>
        </Grid>
        <Grid container sx={{ width: '100%', p: 4, pt: 0, pb: 2 }}>
          <TextField
            fullWidth
            multiline
            rows={2}
            id="outlined-basic"
            variant="outlined"
            value={bookingReviewForm.trainerNotes}
            onChange={(e) =>
              dispatch(
                setBookingReviewField({
                  field: 'trainerNotes',
                  value: e.target.value,
                })
              )
            }
          />
        </Grid>
        <Grid
          container
          sx={{
            width: '100%',
            p: 4,
            pb: 2,
            pt: 0,
            justifyContent: 'flex-start',
          }}
        >
          <Typography>Trainer Suggestions</Typography>
        </Grid>
        <Grid container sx={{ width: '100%', p: 4, pt: 0 }}>
          <TextField
            fullWidth
            multiline
            id="outlined-basic"
            variant="outlined"
            value={bookingReviewForm.trainerSuggestions}
            onChange={(e) =>
              dispatch(
                setBookingReviewField({
                  field: 'trainerSuggestions',
                  value: e.target.value,
                })
              )
            }
          />
        </Grid>
        <Grid container sx={{ width: '100%', p: 4, pt: 0 }}>
          <Button variant="outlined" onClick={handleUpdateBookingReview}>
            <Add />
            <Typography>Save Review</Typography>
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
