import { Grid, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'
import TitleC from '../text/TitleC'

export default function Background(props) {
  const dispatch = useDispatch()

  const userFormFields = useSelector((state) => state.forms.userProfileModal)
  const isTrainerOnboard = useSelector((state) => state.ui.isTrainerOnboard)
  const yearsExperience = userFormFields?.yearsExperience || 0

  const handleYearsOfExpChange = (e) => {
    dispatch(
      setUserProfileModalField({
        field: 'yearsExperience',
        value: parseFloat(e.target.value),
      })
    )
  }

  return (
    <>
      <Grid container>
        <TitleC
          text={
            isTrainerOnboard
              ? 'How long have you been a certified trainer?'
              : 'How long have you been working out?'
          }
        />
      </Grid>
      <Grid
        container
        style={{ paddingTop: '16px', justifyContent: 'center' }}
        spacing={'12px'}
      >
        <TextField
          onChange={handleYearsOfExpChange}
          id="outlined-basic"
          label="Years of Experience"
          variant="outlined"
          defaultValue={yearsExperience}
          type="number"
          sx={{
            mt: 2,
            borderRadius: '8px',
            backgroundColor: 'white',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'primary.main',
              },
              '&:hover fieldset': {
                borderColor: 'primary.main',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'primary.main',
              },
            },
          }}
        />
      </Grid>
    </>
  )
}
