import { Grid } from '@mui/material'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import Trainer from '../components/trainer_page/Trainer'

function TrainerPage() {
  return (
    <Grid container>
      <Header />
      <Trainer />
      <Footer />
    </Grid>
  )
}

export default TrainerPage
