import { Box, Button, Grid, Paper, Switch, Typography } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setGeneralModalComponent,
  setGeneralModalOpen,
} from '../../redux/slices/uiSlice'
import EditIconButtonBorder from '../buttons/EditIconButtonBorder'
import FitnessStats from '../onboarding_modals/FitnessStats'
import TitleB from '../text/TitleB'
import { ReactComponent as Plus } from '../../assets/icons/plus.svg'
import { useParams } from 'react-router-dom'

const MemberStats = () => {
  const cognitoUserId = useSelector((state) => state.auth.cognitoUser?.sub)

  const { viewMemberId } = useParams()
  const dispatch = useDispatch()
  const [checked, setChecked] = useState(true)
  let unit = checked ? 'us' : 'metric'
  return (
    <Grid container justifyContent="start" sx={{ pt: 2 }}>
      <Grid container style={{ justifyContent: 'flex-start' }}>
        <TitleB
          text={viewMemberId === cognitoUserId ? 'Your Stats' : 'Member Stats'}
        />

        <EditIconButtonBorder
          onClick={() => {
            dispatch(setGeneralModalComponent('fitness_stats'))
            dispatch(setGeneralModalOpen(true))
          }}
        />
      </Grid>

      {/* <Grid item>
        <Typography display="inline" sx={{}}>
          Metric
        </Typography>
        <Switch
          checked={checked}
          onChange={() => {
            setChecked(!checked)
          }}
        />
        <Typography display="inline" sx={{ color: 'primary.main' }}>
          US
        </Typography>
      </Grid> */}
      <Grid item xs={12}>
        <Paper
          style={{
            //height: 500,
            width: '100%',
            borderRadius: 16,
            marginTop: '16px',
          }}
        >
          <Box style={{ padding: '24px' }}>
            {true ? (
              <FitnessStats unit={unit} />
            ) : (
              <Button
                variant="outlined"
                startIcon={<Plus />}
                style={{ color: 'gunMetal.main' }}
                onClick={() => console.log('clicked')}
              >
                Personal Stats
              </Button>
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default MemberStats
