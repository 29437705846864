import { TabContext, TabPanel } from '@mui/lab'
import { Grid, Tab, Tabs, useMediaQuery } from '@mui/material'
import { useState } from 'react'
import Conditions from '../Conditions'
import MemberStats from '../MemberStats'
import FocusAreas from '../FocusAreas'
import { useSelector } from 'react-redux'
import WorkoutHistory from './WorkoutHistory'
import MemberStatus from './MemberStatus'
import theme from '../../../styles/theme'

const MemberOtherInfo = (props) => {
  const large = useMediaQuery(theme.breakpoints.up('sm'))

  const { memberName, viewMemberId } = props
  const [tabIndex, setTabIndex] = useState(0)
  const editMode = useSelector((state) => state.ui.editMode)

  const ProfileTabs = () => {
    return (
      <Grid
        container
        style={{
          justifyContent: 'flex-start',
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={editMode ? 0 : tabIndex}
          sx={{
            width: '100%',
            margin: '10px',
            borderTop: '3px solid black',
            borderColor: 'divider',
          }}
          TabIndicatorProps={{
            sx: {
              top: 0,
            },
          }}
        >
          <Tab
            wrapped
            label="Fitness stats"
            index={0}
            onClick={() => {
              setTabIndex(0)
            }}
          />
          <Tab
            wrapped
            label="Workout History"
            index={1}
            onClick={() => {
              setTabIndex(1)
            }}
          />
          <Tab
            wrapped
            label="MEMBER’S ENTRIES"
            index={2}
            onClick={() => {
              setTabIndex(2)
            }}
          />
        </Tabs>
      </Grid>
    )
  }
  return (
    <Grid container>
      <TabContext value={tabIndex} sx={{ width: '100%', m: 0 }}>
        {!editMode && <ProfileTabs />}
        <TabPanel
          value={0}
          index={0}
          sx={{ width: '100%', p: large ? 0 : 1, m: 0 }}
        >
          <Conditions editMode={editMode} viewMemberId={viewMemberId} />
          <MemberStats viewMemberId={viewMemberId} />
          <FocusAreas viewMemberId={viewMemberId} />
        </TabPanel>
        <TabPanel value={1} index={1} sx={{ width: '100%', p: 0, m: 0 }}>
          <WorkoutHistory memberName={memberName} viewMemberId={viewMemberId} />
        </TabPanel>
        <TabPanel value={2} index={1} sx={{ width: '100%', p: 0, m: 0 }}>
          <MemberStatus viewMemberId={viewMemberId} />
        </TabPanel>
      </TabContext>
    </Grid>
  )
}

export default MemberOtherInfo
