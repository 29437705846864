import { Button, styled } from '@mui/material'

const Workout = (props) => {
  const { text } = props
  const StyledButton = styled(Button)(({ theme }) => ({
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '15px',
    margin: '5px',
    '&:disabled': {
      backgroundColor: theme.palette.gunMetal.main,
      color: 'white',
    },
  }))
  return (
    <StyledButton variant="contained" disabled>
      {text}
    </StyledButton>
  )
}

export default Workout
