import { Box, Button, Grid, Typography } from '@mui/material'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import { useNavigate } from 'react-router-dom'

function LandingThankYou() {
  const navigate = useNavigate()
  return (
    <Grid container>
      <Header />
      <Grid
        container
        style={{
          background:
            'linear-gradient(225.09deg, #00C7C7 14.44%, #0BDE8F 85.56%)',
          width: '100%',
          height: '50vh',
          margin: 0,
          padding: 0,
        }}
        sx={{ m: 2 }}
      >
        <Box
          sx={{
            bgcolor: 'white',

            justifyContent: 'center',
            alignItems: 'center',

            pt: 4,
            pb: 4,
          }}
        >
          {/*content*/}
          <Grid container sx={{ height: '100%' }}>
            {/* <img src="/3b.jpg" style={{ height: '30vh' }} /> */}
            <Grid container>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '64px',
                  lineHeight: '96px',
                  color: 'gunMetal.main',
                }}
              >
                {' '}
                Thanks for getting in touch!
              </Typography>
            </Grid>
            <Grid container sx={{ mb: 4 }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '34px',
                  lineHeight: '51px',
                  color: 'gunMetal.main',
                  textAlign: 'center',
                }}
              >
                {' '}
                We'll contact you soon!
              </Typography>
            </Grid>
            <Grid container spacing={3} sx={{ mb: 4, pt: 2 }}>
              <Grid item>
                <Button variant="contained" onClick={() => navigate('../')}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: '15px',
                      lineHeight: '22.5px',
                      color: 'gunMetal.main',
                      textAlign: 'center',
                      m: 1,
                    }}
                  >
                    Return Home
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Footer />
    </Grid>
  )
}

export default LandingThankYou
