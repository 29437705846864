import {
  Box,
  Button,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import SessionMain from '../components/session_page/SessionMain'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from 'axios'
import {
  useGetMemberByIdQuery,
  useGetUserByEmailQuery,
  useLazyGetUserByEmailQuery,
  useUpdateMemberProfileMutation,
  useUpdateTrainerCertificationsMutation,
} from '../redux/api/api'
import { SearchOutlined } from '@mui/icons-material'
import { useEffect, useState } from 'react'

function Admin() {
  const navigate = useNavigate()
  const userEmail = useSelector((state) => state.auth?.cognitoUser?.email)
  const [updateMemberTrigger, updateMemberResponse] =
    useUpdateMemberProfileMutation()
  const [updateTrainerTrigger, updateTrainerResponse] =
    useUpdateTrainerCertificationsMutation()

  const userId = useSelector((state) => state.auth.cognitoUser?.sub)
  const { data: currentUser } = useGetMemberByIdQuery(userId)
  const [email, setEmail] = useState('')
  const [user, setUser] = useState(null)
  const [trigger, result, lastPromiseInfo] = useLazyGetUserByEmailQuery()

  // VERIFY USER"S TRUA SCORE & ID
  const verifyUser = async (id) => {
    try {
      const formatted = {
        id: user.id,
        verified: true,
      }
      const userVerified = await updateMemberTrigger(formatted).unwrap()
      console.log('userVerified: ', userVerified)
      //Add to
    } catch (error) {
      console.log('error verifying user:', error)
    }
  }

  // VERIFY USER"S TRUA SCORE & ID
  const verifyUserVirtual = async (id) => {
    try {
      const formatted = {
        id: user.id,
        virtualVerified: true,
      }
      const userVerified = await updateMemberTrigger(formatted).unwrap()
      console.log('userVerified: ', userVerified)
      //Add to
    } catch (error) {
      console.log('error verifying user:', error)
    }
  }

  // VERIFY USER"S CPR CHECK
  const verifyUserCPR = async (id) => {
    console.log('trying to update user CPR')
    try {
      const formatted = {
        id: user.id,
        certification: {
          confirmedCPR: true,
        },
      }
      const userVerified = await updateTrainerTrigger(formatted).unwrap()
      console.log('userVerified: ', userVerified)
      //Add to
    } catch (error) {
      console.log('error verifying user:', error)
    }
  }

  // VERIFY USER"S CERTICATIONS
  const verifyTrainerCertifications = async (id) => {
    console.log('trying to update user CPR')
    try {
      const formatted = {
        id: user.id,
        certification: {
          certsVerified: true,
        },
      }
      const userVerified = await updateTrainerTrigger(formatted).unwrap()
      console.log('userVerified: ', userVerified)
      //Add to
    } catch (error) {
      console.log('error verifying user:', error)
    }
  }

  // VERIFY USER"S Criminal Background Check
  const verifyTrainerCrim = async (id) => {
    console.log('trying to update user CPR')
    try {
      const formatted = {
        id: user.id,
        certification: {
          crimVerified: true,
        },
      }
      const userVerified = await updateTrainerTrigger(formatted).unwrap()
      console.log('userVerified: ', userVerified)
      //Add to
    } catch (error) {
      console.log('error verifying user:', error)
    }
  }

  // VERIFY USER"S Criminal Background Check
  const verifyTrainerIdentity = async (id) => {
    console.log('trying to update user CPR')
    try {
      const formatted = {
        id: user.id,
        certification: {
          confirmedID: true,
        },
      }
      const userVerified = await updateTrainerTrigger(formatted).unwrap()
      console.log('userVerified: ', userVerified)
      //Add to
    } catch (error) {
      console.log('error verifying user:', error)
    }
  }

  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  useEffect(() => {
    if (result?.status === 'fulfilled') {
      console.log('result: ', result)
      setUser(result?.data[0])
    }
  }, [result])

  const handleSearchEmail = async () => {
    console.log('current Email: ', email)
    // Search User By Email
    const searchResponse = trigger(email).unwrap()
    console.log('searchResponse: ', searchResponse)
  }

  return (
    <Grid container>
      <Header />
      <Grid
        container
        style={{
          background:
            'linear-gradient(225.09deg, #00C7C7 14.44%, #0BDE8F 85.56%)',
          width: '100%',
          height: '50vh',
          margin: 0,
          padding: 0,
        }}
        sx={{ m: 2 }}
      >
        <Box
          sx={{
            bgcolor: 'white',

            justifyContent: 'center',
            alignItems: 'center',

            pt: 4,
            pb: 4,
          }}
        >
          {/*content*/}
          <Grid container sx={{ height: '100%' }}>
            <Grid container sx={{ mb: 4 }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '34px',
                  lineHeight: '51px',
                  color: 'gunMetal.main',
                  textAlign: 'center',
                }}
              >
                {' '}
                ADMIN PAGE
              </Typography>
            </Grid>
            {userEmail === 'maliah@gettraind.net' ||
            userEmail === 'malia@gettraind.net' ||
            userEmail === 'sflanary97@gmail.com' ||
            userEmail === 'sean.flanary@stheticsoftware.com' ? (
              <Grid container sx={{ mb: 4 }}>
                <TextField
                  id="outlined-basic"
                  label="User Email To Search"
                  variant="outlined"
                  onChange={handleChangeEmail}
                />
                <IconButton onClick={handleSearchEmail}>
                  <SearchOutlined />
                </IconButton>
              </Grid>
            ) : (
              <Grid container sx={{ p: 2, borderRadius: '16px' }}>
                <Typography onClick={() => console.log(userEmail)}>
                  Sorry! Only admins have access to this page, please return
                  home
                </Typography>
              </Grid>
            )}

            {user && (
              <Grid container spacing={3} sx={{ mb: 4 }}>
                <Grid container>
                  <Typography>USERNAME: {user?.username}</Typography>
                </Grid>
                <Grid container>
                  <Typography>ID: {user?.id}</Typography>
                </Grid>
                <Grid container>
                  <Typography>
                    VERIFIED IN PERSON: {user?.verified ? 'TRUE' : 'FALSE'}
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography>
                    VERIFIED VIRTUAL: {user?.virtualVerified ? 'TRUE' : 'FALSE'}
                  </Typography>
                </Grid>
                <Snackbar
                  open={true}
                  autoHideDuration={6000}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  message="Successfully Verified Trainer"
                />
                <Grid item>
                  <Button variant="contained" onClick={verifyTrainerIdentity}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '15px',
                        lineHeight: '22.5px',
                        color: 'gunMetal.main',
                        textAlign: 'center',
                        m: 1,
                      }}
                    >
                      Verify Trainer Identification
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={verifyTrainerCertifications}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '15px',
                        lineHeight: '22.5px',
                        color: 'gunMetal.main',
                        textAlign: 'center',
                        m: 1,
                      }}
                    >
                      Verify Trainer Certifications
                    </Typography>
                  </Button>
                </Grid>

                <Grid item>
                  <Button variant="contained" onClick={verifyUserCPR}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '15px',
                        lineHeight: '22.5px',
                        color: 'gunMetal.main',
                        textAlign: 'center',
                        m: 1,
                      }}
                    >
                      Verify Trainer CPR
                    </Typography>
                  </Button>
                </Grid>

                <Grid item>
                  <Button variant="outlined" onClick={verifyTrainerCrim}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '15px',
                        lineHeight: '22.5px',
                        color: 'gunMetal.main',
                        textAlign: 'center',
                        m: 1,
                      }}
                    >
                      Verify Trainer Criminal Background Check
                    </Typography>
                  </Button>
                </Grid>

                {/* <Grid item>
                  <Button variant="contained" onClick={verifyUser}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '15px',
                        lineHeight: '22.5px',
                        color: 'gunMetal.main',
                        textAlign: 'center',
                        m: 1,
                      }}
                    >
                      Verify User Score In Person
                    </Typography>
                  </Button>
                </Grid> */}
                {/* <Grid item>
                  <Button variant="outlined" onClick={verifyUserVirtual}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '15px',
                        lineHeight: '22.5px',
                        color: 'gunMetal.main',
                        textAlign: 'center',
                        m: 1,
                      }}
                    >
                      Verify User Score Virtual Only
                    </Typography>
                  </Button>
                </Grid> */}
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
      <Footer />
    </Grid>
  )
}

export default Admin
