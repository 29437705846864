import { Button, useTheme } from '@mui/material'
import { ReactComponent as UploadUpIcon } from '../../../assets/icons/upload_up.svg'

const UploadIconButtonBorder = (props) => {
  const { text, onClick, disabled } = props
  const theme = useTheme()
  return (
    <Button
      variant="outlined"
      startIcon={<UploadUpIcon stroke={theme.palette.primary.main} />}
      style={{
        fontWeight: 700,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  )
}
export default UploadIconButtonBorder
